import React from 'react';
import {Helmet} from 'react-helmet';
import {FormProvider, useForm} from 'react-hook-form';
import {Modal, WizardProvider} from '@openquiz/quiz-ui';
import {CreateGameDto} from '../../api';
import {BaseStep} from './BaseStep';
import {ChooseStep} from './ChooseStep';
import {UploadStep} from './UploadStep';
import {FinishStep} from './FinishStep';

interface CreateGameFormProps {
  packageUuid?: string;
  versionUuid?: string;
  onClose: () => void;
}

const stepsMap = new Map([
  ['base', BaseStep],
  ['choose-package', ChooseStep],
  ['upload-package', UploadStep],
  ['finish', FinishStep]
]);

export const CreateGameForm = ({
  packageUuid,
  versionUuid,
  onClose
}: CreateGameFormProps) => {
  const methods = useForm<CreateGameDto>({
    defaultValues: {
      name: '',
      packageUuid,
      versionUuid,
      settings: {
        prePlay: false,
        teamMode: false,
        slots: 8
      }
    }
  });

  return (
    <Modal onClose={onClose}>
      <Helmet>
        <title>Новая игра</title>
      </Helmet>

      <FormProvider {...methods}>
        <WizardProvider steps={stepsMap} initial="base" />
      </FormProvider>
    </Modal>
  );
};
