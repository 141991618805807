import React from 'react';
import styled from 'styled-components';
import {Modal, ModalProps} from '@openquiz/quiz-modal';
import {FieldGroup, FieldRow, Title} from '@openquiz/quiz-ui';
import LogoSvg from '@/assets/logo.svg?react';
import {OAuthButton, useAuthRefererStore} from '@/features/auth';
import {publicAxios} from '@/libs';

interface SignModalProps extends ModalProps {}

const Logo = styled(LogoSvg)`
  margin: 0 auto 24px auto;
  display: flex;
  width: 80px;
  height: 80px;
`;

const Description = styled.p`
  ${p => p.theme.typography.body.md};
  color: ${p => p.theme.colors.text.subtle};
  text-align: center;
`;

const OtherMethodCaption = styled.div`
  ${p => p.theme.typography.caption.sm};
  color: ${p => p.theme.colors.text.subtle};
  text-align: center;
`;

export const SignModal = ({onCancel}: SignModalProps) => {
  const {referer, update, flush} = useAuthRefererStore();

  const onClickOAuth = (provider: string) => {
    if (!referer) {
      update(window.location.pathname);
    }
    window.location.href = `${publicAxios.defaults.baseURL}/auth/${provider}`;
  };

  const onCancelClick = () => {
    flush();
    onCancel();
  };

  return (
    <Modal size="sm" onCancel={onCancelClick}>
      <Logo />
      <Title style={{textAlign: 'center'}}>Войдите в аккаунт</Title>

      <FieldGroup size="xlg">
        <Description>
          Авторизуйтесь, чтобы играть, создавать свои паки и оценивать паки других
          игроков.
        </Description>
      </FieldGroup>

      <FieldGroup>
        <OAuthButton provider="discord" onClick={onClickOAuth}>
          Войти через Discord
        </OAuthButton>
      </FieldGroup>

      <FieldGroup>
        <OtherMethodCaption>или с помощью</OtherMethodCaption>
      </FieldGroup>

      <FieldGroup size="lg">
        <FieldRow justify="center" size="sm">
          <OAuthButton provider="google" onClick={onClickOAuth} />
          <OAuthButton provider="twitch" onClick={onClickOAuth} />
          <OAuthButton provider="vk" onClick={onClickOAuth} />
        </FieldRow>
      </FieldGroup>
    </Modal>
  );
};
