import {Action} from '@opeq-dev/openquiz-machine';

interface StackConfig {
  start: string;
  end: string;
}

export const stackConfigs: StackConfig[] = [
  {
    start: 'chooseQuestion',
    end: '#system/setEndQuestion'
  }
];

/**
 *
 * @param history
 * @param stackConfigs
 */
export const getStackedActions = (history: Action[], stackConfigs: StackConfig[]) => {
  const result = [];
  let currentGroup = [];

  for (const action of history) {
    const {start, end} = stackConfigs[0];

    if (action.type === end && currentGroup.length > 0) {
      currentGroup.push(action);
      result.push(currentGroup);
      currentGroup = [];
      continue;
    }

    if (action.type === start || currentGroup.length > 0) {
      currentGroup.push(action);
      if (action === history.at(-1)) {
        result.push(currentGroup);
      }
    } else {
      result.push(action);
    }
  }

  return result;
};
