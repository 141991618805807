import React, {useEffect} from 'react';
import {SplashScreen, useSfxPlayer} from '@/features/machine';
import {ChooseResponderHint} from '../../@hints';

export const SecretQuestionSplash = () => {
  const sfxPlayer = useSfxPlayer();

  useEffect(() => {
    sfxPlayer.play('secret');
  }, [sfxPlayer.play]);

  return (
    <SplashScreen
      icon={
        <picture>
          <source
            srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f648/512.webp"
            type="image/webp"
          />
          <img
            src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f648/512.gif"
            alt="🙈"
            width={96}
            height={96}
          />
        </picture>
      }
      action={<ChooseResponderHint />}>
      <h2>Вопрос с секретом</h2>
      <p>
        Этот вопрос можно передать другому игроку, чтобы он ответил на него, или оставить
        себе.
      </p>
    </SplashScreen>
  );
};
