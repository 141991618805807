import React, {useCallback, useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {FormattedDate, FormattedTime} from 'react-intl';
import {addHours} from 'date-fns';
import {Shuffle} from '@phosphor-icons/react';
import {ErrorMessage} from '@hookform/error-message';
import {
  BetaBadge,
  Button,
  ButtonToolbar,
  Callout,
  FieldGroup,
  FieldRow,
  FieldSection,
  Input,
  TipCircle,
  Title,
  Toggle,
  ErrorMessage as UiErrorMessage,
  useWizard
} from '@openquiz/quiz-ui';
import {CreateGameDto, fetchGameRandomName, useCreateGameMutation} from '@/features/game';
import {PackageRow} from '@/features/workshop';
import {usePackageQuery} from '@/features/wshop-packages';
import {formatError} from '@/libs/validation';
import {UploadButton} from './UploadButton';
import {WorkshopButton} from './WorkshopButton';

export const BaseStep = () => {
  const {onChangeStep} = useWizard();

  const {
    formState: {isSubmitting, errors},
    handleSubmit,
    setError,
    setValue,
    register,
    control,
    watch
  } = useFormContext<CreateGameDto>();

  const {data: pkg} = usePackageQuery(watch('packageUuid'));
  const {mutateAsync} = useCreateGameMutation();

  const onClickRandomName = useCallback(async () => {
    setValue('name', await fetchGameRandomName());
  }, [setValue]);

  const onSubmit = async (createGameDto: CreateGameDto) => {
    try {
      const game = await mutateAsync(createGameDto);
      onChangeStep('finish', game);
    } catch (err) {
      formatError<CreateGameDto>(err).forEach(error => {
        setError(error.key, {type: 'custom', message: error.message});
      });
    }
  };

  useEffect(() => {
    onClickRandomName();
  }, [onClickRandomName]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title layoutId="title">{watch('name')}</Title>

      <FieldSection>
        <FieldGroup>
          <Input
            {...register('name', {
              required: 'Обязательное поле.',
              minLength: {value: 3, message: 'Минимальная длинна 3 символа.'},
              maxLength: {value: 32, message: 'Максимальная длинна 32 символа.'}
            })}
            type="hidden"
          />

          <ButtonToolbar onClick={onClickRandomName}>
            <Shuffle size={16} weight="bold" />
            <span>Рандом</span>
          </ButtonToolbar>

          <ErrorMessage
            errors={errors}
            name="name"
            render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>
      </FieldSection>

      <FieldSection label="Игровой пак">
        <input
          type="hidden"
          {...register('packageUuid', {
            required: 'Выберите или загрузите пак.'
          })}
        />

        {watch('packageUuid') ? (
          <>{pkg && <PackageRow pkg={pkg} />}</>
        ) : (
          <>
            <FieldGroup>
              <FieldGroup size="sm">
                <WorkshopButton onClick={() => onChangeStep('choose-package')} />
              </FieldGroup>

              <FieldGroup size="sm">
                <UploadButton onClick={() => onChangeStep('upload-package')} />
              </FieldGroup>

              <ErrorMessage
                errors={errors}
                name="packageUuid"
                render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
              />
            </FieldGroup>
          </>
        )}
      </FieldSection>

      <FieldSection label="Игровое время">
        <FieldGroup label="Активна до" labelWidth="45%" inline={true}>
          <FormattedDate value={addHours(new Date(), 5)} dateStyle="long" />
          &nbsp;
          <FormattedTime value={addHours(new Date(), 5)} />
        </FieldGroup>

        <Callout status="attention" size="sm">
          <p>
            Если в игре не будет активности в течении <b>одного часа</b>, она остановится.
          </p>
        </Callout>
      </FieldSection>

      <FieldSection label="Параметры">
        <FieldGroup size="sm">
          <Controller
            control={control}
            name="settings.prePlay"
            render={({field: {onChange, onBlur, value}}) => (
              <Toggle
                value={value}
                label={
                  <FieldRow size="xs">
                    <span>Режим чтения вопросов</span>
                    <TipCircle message="Нельзя ответить пока воспроизводится вопрос." />
                  </FieldRow>
                }
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup size="sm">
          <Controller
            control={control}
            name="settings.teamMode"
            render={({field: {onChange, onBlur, value}}) => (
              <Toggle
                variants="highlighted"
                value={value}
                label={
                  <FieldRow size="sm">
                    <FieldRow size="xs">
                      <span>Командный режим</span>
                    </FieldRow>
                    <BetaBadge message="Протестируйте новый игровой режим! Максимум 4 команды, по 4 игрока." />
                  </FieldRow>
                }
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </FieldGroup>
      </FieldSection>

      <FieldGroup>
        <Button type="submit" stretch={true} fetching={isSubmitting}>
          Создать игру
        </Button>
      </FieldGroup>
    </form>
  );
};
