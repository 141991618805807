import {PackageVersion} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export interface FetchVersionCreateParams {
  packageUuid: string;
}

/**
 *
 * @param uuid
 */
export const fetchVersionCreate = async ({packageUuid}: FetchVersionCreateParams) => {
  const {data} = await getPrivateAxios().post<PackageVersion>(
    `/workshop/packages/${packageUuid}/versions`
  );
  return data;
};
