import {SearchDto} from '@/features/workshop';

const defaultData: SearchDto = {
  query: '',
  illegal: false,
  sort: 'created_at'
};

/**
 *
 * @param _data
 */
export const getSearchString = (_data: Partial<SearchDto>) => {
  const memoryIllegal = !!window.localStorage.getItem('search.illegal');

  const data: Partial<SearchDto> = {
    ...defaultData,
    ..._data,
    illegal: memoryIllegal || _data.illegal
  };

  return encodeURIComponent(JSON.stringify(data));
};
