import React from 'react';
import styled from 'styled-components';
import {useSelector} from '@xstate/react';
import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {ChooseQuestionEvent, getPkgVersion} from '@opeq-dev/openquiz-machine';
import {QuestionCard} from '../QuestionCard';
import {useGameService} from '../../hooks';
import {TipCircle} from '@openquiz/quiz-ui';

interface ThemeRowProps {
  theme: PackageTheme;
}

const StyledThemeRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 12px;
  }

  @media only screen and (min-width: 1440px) {
    margin-bottom: 16px;
  }

  span {
    white-space: nowrap;
    margin-right: 8px;
  }

  :last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  color: ${p => p.theme.colors.text.muted};
  position: sticky;
  left: 0;
  top: 0;
  min-width: 40px;
  z-index: 100;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1024px) {
    ${p => p.theme.typography.label.sm};
    margin-bottom: 4px;
  }

  @media only screen and (min-width: 1440px) {
    ${p => p.theme.typography.label.md};
    margin-bottom: 4px;
  }

  span {
    white-space: nowrap;
    margin-right: 5px;
    display: inline-flex;
  }
`;

const DashLine = styled.div`
  border-bottom: 1px dashed ${p => p.theme.colors.border.subtle};
  width: 100%;
  height: 1px;
`;

const Grid = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  gap: 8px;
`;

export const ThemeRow = ({theme}: ThemeRowProps) => {
  const {service} = useGameService();

  const questions = useSelector(
    service,
    state =>
      getPkgVersion(state.context)?.questions.filter(q => q.themeUuid === theme.uuid) ??
      []
  );

  const history = useSelector(
    service,
    state =>
      state.context.history.filter(
        r => r.type === 'chooseQuestion'
      ) as ChooseQuestionEvent[]
  );

  return (
    <StyledThemeRow>
      <Label>
        <span>{theme.name}</span>

        {theme.description && (
          <span>
            <TipCircle message={theme.description} size="md" />
          </span>
        )}

        <DashLine />
      </Label>

      <Grid>
        {questions.map(question => (
          <QuestionCard
            key={question.uuid}
            question={question}
            isUsed={history.some(r => r.payload.questionUuid === question.uuid)}
          />
        ))}
      </Grid>
    </StyledThemeRow>
  );
};
