import {Contest} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export interface ContestListFilter {
  jury?: boolean;
}

/**
 *
 */
export const fetchContestList = async (filter: ContestListFilter) => {
  const {data} = await getPrivateAxios().get<Contest[]>('/workshop/contest', {
    params: {filter}
  });
  return data;
};
