import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface SurfaceProps {
  children: ReactNode;
}

const StyledSurface = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};

  @media only screen and (min-width: 320px) {
    border-radius: 0;
    margin: 0 -24px 24px -24px;
    padding: 24px;
  }

  @media only screen and (min-width: 1024px) {
    border-radius: 16px;
    margin: 0 0 24px 0;
    padding: 32px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Surface = ({children}: SurfaceProps) => {
  return <StyledSurface>{children}</StyledSurface>;
};
