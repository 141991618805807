import React from 'react';
import {generatePath, useLocation, useNavigate, useParams} from 'react-router-dom';
import {Surface} from '@openquiz/quiz-ui';
import {
  QuestionForm,
  QuestionFormDto,
  editorRoundPath,
  useEditorContext,
  useQuestionCreateMutate
} from '@/features/wshop-editor';
import {Page, PageTitle} from '@/components';

export const QuestionCreatePage = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const {themeUuid} = useParams();
  const {packageUuid, versionUuid, roundUuid} = useEditorContext();
  const {mutateAsync} = useQuestionCreateMutate();

  const backHref = generatePath(editorRoundPath, {packageUuid, versionUuid, roundUuid});

  const onSubmitForm = async (data: QuestionFormDto) => {
    if (!themeUuid) return;
    await mutateAsync({
      data: {...data, versionUuid, themeUuid},
      packageUuid
    });
    navigate(backHref);
  };

  return (
    <Page>
      <PageTitle to={{href: backHref}} title="Создание вопроса" />
      <Surface>
        <QuestionForm
          nextPosition={state.nextPosition ?? -1}
          nextPrice={state.nextPrice ?? 0}
          onSubmit={onSubmitForm}
        />
      </Surface>
    </Page>
  );
};
