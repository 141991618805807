import React from 'react';
import {Outlet} from 'react-router-dom';
import {EditorLayout, EditorProvider} from '@/features/wshop-editor';
import {Container} from '@/components';

export const EditorOutlet = () => (
  <EditorProvider>
    <EditorLayout>
      <Container>
        <Outlet />
      </Container>
    </EditorLayout>
  </EditorProvider>
);
