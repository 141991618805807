import React from 'react';
import styled from 'styled-components';

interface CircularProgressBarProps {
  value: number;
  size?: number;
}

const StyledCircularProgressBar = styled.svg`
  .circle-background,
  .circle-progress {
    fill: none;
  }

  .circle-background {
    stroke: ${p => p.theme.colors.border.subtle};
  }

  .circle-progress {
    stroke: ${p => p.theme.colors.accent.emphasis};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: all 500ms linear;
  }
`;

export const CircularProgressBar = ({value, size = 56}: CircularProgressBarProps) => {
  const radius = (size - 2) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * value) / 100;

  return (
    <StyledCircularProgressBar width={size} height={size} viewBox={viewBox}>
      <circle
        className="circle-background"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${2}px`}
      />
      <circle
        className="circle-progress"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${2}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset
        }}
      />
    </StyledCircularProgressBar>
  );
};
