import {create} from 'zustand';

interface BackState {
  href: string[];
  count: number;
  push: (href: string) => void;
  shift: () => void;
}

export const useBackStateStore = create<BackState>(set => ({
  href: [],
  count: 0,
  push: (href: string) => set(state => ({href: [...state.href, href]})),
  shift: () => set(state => ({href: state.href.slice(0, -1)}))
}));
