import {User} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';
import {PaginatedQuery} from '@/types';

interface FindAllUsersFilter {
  search?: string;
  withPublishedPackages?: boolean;
}

export class FindAllUsersDto {
  filter?: FindAllUsersFilter;
  take?: number;
}

/**
 * This function fetches a users list from the API.
 * @param findAllUsersDto
 */
export const fetchUsersList = async (findAllUsersDto: FindAllUsersDto) => {
  const {data} = await getPrivateAxios().get<PaginatedQuery<User>>('/users', {
    params: findAllUsersDto
  });
  return data;
};
