import React, {ReactNode} from 'react';
import {FormattedNumber} from 'react-intl';
import styled from 'styled-components';

interface ChartCardProps {
  /**
   * Contents of the card, preferably use graphs, a table there.
   */
  children?: ReactNode;
  /**
   * Name of the card.
   */
  label: ReactNode;
  /**
   * Total value of the card.
   */
  value: ReactNode;
  /**
   * Element to the right of the name and values.
   */
  extra?: ReactNode;
}

const StyledStatsCard = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding: 24px;
  border-radius: 16px;
`;

const Header = styled.div`
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }
`;

const Value = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;

  span {
    ${p => p.theme.typography.body.md};
    color: ${p => p.theme.colors.text.muted};
    line-height: normal;
    font-weight: normal;
  }
`;

const Label = styled.div`
  ${p => p.theme.typography.caption.md};
  color: ${p => p.theme.colors.text.subtle};
  font-weight: normal;
`;

const Extra = styled(Label)`
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1;

  @media only screen and (min-width: 320px) {
    opacity: 0;
  }

  @media only screen and (min-width: 1024px) {
    opacity: 1;
  }

  @media only screen and (min-width: 1440px) {
    opacity: 1;
  }
`;

export const StatsCard = ({children, label, value, extra}: ChartCardProps) => {
  const isValueNumber = typeof value === 'number';
  const isValueArray = Array.isArray(value);

  return (
    <StyledStatsCard>
      <Header>
        {extra && <Extra>{extra}</Extra>}
        <Value>
          {!isValueNumber && !isValueArray && value}
          {isValueNumber && <FormattedNumber value={value} />}
          {isValueArray && (
            <>
              <FormattedNumber value={value[0]} />
              <span>
                / <FormattedNumber value={value[1]} />
              </span>
            </>
          )}
        </Value>
        <Label>{label}</Label>
      </Header>
      {children}
    </StyledStatsCard>
  );
};
