import React, {ReactNode} from 'react';
import {Link, generatePath} from 'react-router-dom';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {DotsSix, PencilSimple, Trash} from '@phosphor-icons/react';
import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {Button, CollapsedSection, Tooltip} from '@openquiz/quiz-ui';
import {editorThemeEditPath} from '@/features/wshop-editor';
import {EditableTitle} from '@/components';

interface ThemeRowProps {
  children: ReactNode;
  highlighted: boolean;
  theme: PackageTheme;
  packageUuid: string;
  onChangeName: (name: string) => void;
  onDelete: () => Promise<void>;
}

export const ThemeRow = ({
  children,
  highlighted,
  theme,
  packageUuid,
  onChangeName,
  onDelete
}: ThemeRowProps) => {
  const {attributes, listeners, setNodeRef, isDragging, transition, transform} =
    useSortable({
      id: theme.uuid
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <CollapsedSection
      ref={setNodeRef}
      style={style}
      {...attributes}
      label={
        <EditableTitle
          size="h3"
          tabIndex={theme.position + 1}
          value={theme.name}
          onChange={onChangeName}
        />
      }
      highlighted={highlighted}
      extra={
        <>
          <div {...listeners}>
            <Tooltip message="Сортировать" enabled={!isDragging} placement="top">
              <Button variant="link2">
                <DotsSix size={20} weight="bold" />
              </Button>
            </Tooltip>
          </div>
          <Tooltip message="Редактировать" enabled={!isDragging} placement="top">
            <Link
              to={generatePath(editorThemeEditPath, {
                themeUuid: theme.uuid,
                packageUuid: packageUuid,
                roundUuid: theme.roundUuid,
                versionUuid: theme.versionUuid
              })}>
              <Button variant="link2">
                <PencilSimple size={20} weight="bold" />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip message="Удалить" enabled={!isDragging} placement="top">
            <Button variant="link2" onClick={onDelete}>
              <Trash size={20} weight="bold" />
            </Button>
          </Tooltip>
        </>
      }>
      {children}
    </CollapsedSection>
  );
};
