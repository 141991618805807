import {PackageVersion} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export type TemplateData = {
  rounds: number;
  themes: number;
  questions: number;
  finalRound: boolean;
};

export interface FetchFillVersion {
  data: TemplateData;
  packageUuid: string;
  versionUuid: string;
}

/**
 *
 * @param params
 */
export const fetchFillVersion = async (params: FetchFillVersion) => {
  const {data} = await getPrivateAxios().post<PackageVersion>(
    `/workshop/packages/${params.packageUuid}/versions/${params.versionUuid}/template`,
    params.data
  );
  return data;
};
