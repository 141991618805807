import {getRandomString} from '@openquiz/quiz-ui';
import {PackageQuestionStep} from '@opeq-dev/openquiz-schema';

/**
 *
 */
export const getDefaultStep = (
  initial: Pick<PackageQuestionStep, 'position' | 'isAnswer'>
): Omit<PackageQuestionStep, 'questionUuid' | 'versionUuid'> => ({
  ...initial,
  uuid: getRandomString(32),
  type: 'TEXT',
  payload: '',
  transcription: null,
  caption: null,
  variants: [],
  duration: -1,
  speech: null,
  speechUuid: null
});
