import React, {ReactNode} from 'react';
import {motion} from 'framer-motion';
import styled from 'styled-components';
import {ModalProvider, getAnimationProps} from '@openquiz/quiz-ui';
import {ControlBar} from '../../ControlBar';

export interface LayoutProps {
  children: ReactNode;
  sidebar?: ReactNode;
  aside?: ReactNode;
}

const StyledLayout = styled.div<{$hasSidebars: boolean}>`
  display: grid;
  grid-template-rows: 1fr;
  margin: 0 auto;
  max-width: 2040px;
  height: calc(100vh / var(--zoom));
  overflow: hidden;
  box-sizing: border-box;

  @media only screen and (min-width: 320px) {
    grid-template-columns: ${p => (p.$hasSidebars ? '190px 1fr 190px' : '1f')};
    padding: 16px;
    gap: 16px;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: ${p => (p.$hasSidebars ? '200px 1fr 200px' : '1f')};
    padding: 16px;
    gap: 16px;
  }

  @media only screen and (min-width: 1440px) {
    grid-template-columns: ${p => (p.$hasSidebars ? '240px 1fr 240px' : '1f')};
    padding: 24px;
    gap: 24px;
  }
`;

const Side = styled(motion.div)`
  height: 100%;
  overflow: hidden;
`;

const Entry = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
`;

const sideAnimationProps = getAnimationProps({
  initial: {x: 8, opacity: 0},
  animate: {x: 0, opacity: 1},
  exit: {x: 8, opacity: 0}
});

const entryAnimationProps = getAnimationProps({
  initial: {y: 4, opacity: 0},
  animate: {y: 0, opacity: 1},
  exit: {y: 0, opacity: 0}
});

export const Layout = ({children, sidebar, aside}: LayoutProps) => {
  return (
    <ModalProvider>
      <StyledLayout $hasSidebars={!!(sidebar && aside)}>
        {sidebar && <Side {...sideAnimationProps}>{sidebar}</Side>}
        <Entry {...entryAnimationProps}>
          <Content>{children}</Content>
          <div>
            <ControlBar />
          </div>
        </Entry>
        {aside && <Side {...sideAnimationProps}>{aside}</Side>}
      </StyledLayout>
    </ModalProvider>
  );
};
