import {create} from 'zustand';
import {persist} from 'zustand/middleware';

type UiSettingsSize = 'sm' | 'md' | 'lg' | 'xlg';

type UiSettingsTheme = 'auto' | 'light' | 'dark';

interface UiSettingsState {
  size: UiSettingsSize;
  theme: UiSettingsTheme;
  volume: number;
  sfx: boolean;
  hotkeys: boolean;
  updateSize: (size: UiSettingsSize) => void;
  updateTheme: (theme: UiSettingsTheme) => void;
  updateVolume: (volume: number) => void;
  updateSfx: (sfx: boolean) => void;
  updateHotkeys: (hotkeys: boolean) => void;
}

export const useUiSettingsStore = create(
  persist<UiSettingsState>(
    set => ({
      size: 'md',
      theme: 'auto',
      volume: 0.5,
      sfx: true,
      hotkeys: true,
      updateSize: size => set({size}),
      updateTheme: theme => set({theme}),
      updateVolume: volume => set({volume}),
      updateSfx: sfx => set({sfx}),
      updateHotkeys: hotkeys => set({hotkeys})
    }),
    {name: 'ui-settings-store'}
  )
);
