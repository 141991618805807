import {useCallback, useEffect, useState} from 'react';
import {nanoid} from 'nanoid';
import {Action} from '@opeq-dev/openquiz-machine';
import {useGameService} from '@/features/machine';
import {UserReaction} from '@/features/reactions';

export const useReactionsListener = () => {
  const {socket} = useGameService();
  const [stack, setStack] = useState<UserReaction[]>([]);

  const push = useCallback(
    (reaction: UserReaction) => {
      setStack(stack => [...stack, reaction]);
      setTimeout(() => {
        onClose(reaction);
      }, 2000);
    },
    [setStack]
  );

  const onClose = useCallback(
    (reaction: UserReaction) => {
      setStack(stack => {
        return stack.filter(
          r => !(r.id === reaction.id && r.userUuid === reaction.userUuid)
        );
      });
    },
    [setStack]
  );

  useEffect(() => {
    if (!socket) return;
    socket.on('event', (e: Action) => {
      if (e.type !== 'sendReaction') return;
      push({
        userUuid: e.self.userUuid,
        name: e.payload.reaction as UserReaction['name'],
        id: nanoid()
      });
    });
  }, [socket, push]);

  return {stack};
};
