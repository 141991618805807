import {ReactElement} from 'react';
import {User} from '@opeq-dev/openquiz-schema';
import {useCurrentUserQuery} from '@/features/user';

interface CurrentUserGuardProps {
  children: ReactElement;
  user: User;
}

export const CurrentUserGuard = ({
  children,
  user
}: CurrentUserGuardProps): JSX.Element | null => {
  const {data: currentUser, isLoading} = useCurrentUserQuery();

  if (!currentUser || isLoading) {
    return null;
  }

  if (currentUser.uuid !== user.uuid) {
    return null;
  }

  return children;
};
