import {useEffect, useMemo} from 'react';
import {useAudioPlayer} from 'react-use-audio-player';
import {Action} from '@opeq-dev/openquiz-machine';
import {useUiSettingsStore} from '@/stores';
import sprite from '@/assets/sfx/sprite.mp3';

interface SfxSprite {
  [name: Action['type'] | string]: [number, number];
}

export const sfxSprite: SfxSprite = {
  chooseQuestion: [50, 300],
  chooseVariant: [50, 300],
  basicTryAnswer: [50, 300],
  typeBet: [420, 888],
  typeFinalBet: [420, 888],
  countdown: [1318, 4000],
  acceptAnswer: [5638, 1160],
  rejectAnswer: [6810, 575],
  noRisk: [7507, 1970],
  secret: [9507, 3531],
  auction: [13744, 2040]
};

/**
 *
 * @returns
 */
export const useSfxPlayer = () => {
  const {volume, sfx} = useUiSettingsStore();

  const sfxVolume = useMemo(() => (sfx ? volume * 0.1 : 0), [volume, sfx]);

  const {
    play,
    stop,
    volume: setVolume
  } = useAudioPlayer({
    src: sprite,
    autoplay: false,
    volume: sfxVolume,
    sprite: sfxSprite
  });

  useEffect(() => {
    setVolume(sfxVolume);
  }, [sfxVolume]);

  return {play, stop};
};
