import styled, {css} from 'styled-components';
import {CalloutProps} from './types';

export const StyledCallout = styled.div<{
  $status: CalloutProps['status'];
  $size: CalloutProps['size'];
  $limited: CalloutProps['limited'];
}>`
  ${p => p.theme.typography.body.md};

  background-color: transparent;
  border: 1px solid transparent;
  color: transparent;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  transition: background-color 80ms ease-in-out;
  backdrop-filter: blur(4px);

  & > svg {
    display: flex;
    flex-shrink: 0;
    color: inherit;
    transition: color 80ms ease-in-out;
  }

  ${p =>
    p.$status === 'success' &&
    css`
      background-color: ${p => p.theme.colors.success.subtle};
      border-color: ${p => p.theme.colors.success.subtle};
      color: ${p => p.theme.colors.success.text};
    `}

  ${p =>
    p.$status === 'information' &&
    css`
      background-color: ${p => p.theme.colors.accent.subtle};
      border-color: ${p => p.theme.colors.accent.subtle};
      color: ${p => p.theme.colors.accent.text};
    `}

  ${p =>
    p.$status === 'attention' &&
    css`
      background-color: ${p => p.theme.colors.attention.subtle};
      border-color: ${p => p.theme.colors.attention.subtle};
      color: ${p => p.theme.colors.attention.text};
    `}

  ${p =>
    p.$status === 'danger' &&
    css`
      background-color: ${p => p.theme.colors.danger.subtle};
      border-color: ${p => p.theme.colors.danger.subtle};
      color: ${p => p.theme.colors.danger.text};
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      padding: 16px 20px;
      gap: 12px;
    `}

  ${p =>
    p.$size === 'sm' &&
    css`
      padding: 12px 16px;
      gap: 8px;
    `}

  ${p =>
    p.$limited &&
    css`
      min-width: 120px;
      max-width: 400px;
    `}
`;

export const Entry = styled.div`
  width: 100%;

  a {
    text-decoration: underline;
    font-weight: 500;
  }
`;
