import {css} from 'styled-components';

/**
 * Trims the text by the number of lines.
 * @param lines
 */
export const getLineClampCss = (lines: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  overflow: hidden;
`;
