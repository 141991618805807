import React from 'react';
import styled, {useTheme} from 'styled-components';
import {CheckCircle, CircleWavyWarning} from '@phosphor-icons/react';

interface ContestStatusPillProps {
  status: 'await' | 'reviewed';
}

const StyledContestStatusPill = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ContestStatusPill = ({status}: ContestStatusPillProps) => {
  const theme = useTheme();

  if (status === 'await') {
    return (
      <StyledContestStatusPill>
        <CircleWavyWarning
          color={theme.colors.attention.text}
          size={24}
          weight="duotone"
        />
        <span>Ожидает отзыв</span>
      </StyledContestStatusPill>
    );
  }

  return (
    <StyledContestStatusPill>
      <CheckCircle color={theme.colors.success.text} size={24} weight="duotone" />
      <span>Проверен</span>
    </StyledContestStatusPill>
  );
};
