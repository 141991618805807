import {getPrivateAxios} from '@/libs';

export interface FetchQuestionParams {
  packageUuid: string;
  questionUuid: string;
}

/**
 * Fetch a question.
 * @param params
 */
export const fetchQuestion = async (params: FetchQuestionParams) => {
  const {data} = await getPrivateAxios().get(
    `/workshop/packages/${params.packageUuid}/questions/${params.questionUuid}`
  );
  return data;
};
