import React, {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {State} from 'xstate';
import {PaperPlaneRight} from '@phosphor-icons/react';
import {
  Ctx,
  PassBetEvent,
  TypeBetEvent,
  TypeFinalBetEvent,
  getAuctionHighestBet
} from '@opeq-dev/openquiz-machine';
import {Button, FieldGroup, FieldRow, Input} from '@openquiz/quiz-ui';
import {
  UserChip,
  selectCtxCurrentQuestion,
  selectTeamScore,
  selectTimer,
  selectTurnTeam,
  useMachineEvent,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';

interface BetHintDto {
  bet: number;
}

const selectIsPreviousBetAllIn = (state: State<Ctx>) => {
  const previousBet = state.context.history
    .filter(
      record =>
        record.type === 'typeBet' &&
        record.payload.questionUuid === state.context.current.questionUuid
    )
    .pop() as TypeFinalBetEvent | undefined;

  if (!previousBet) return false;

  const previousTeamScore = state.context.teams.find(
    team => team.uuid === previousBet.self.teamUuid
  )?.score;

  return previousBet.payload.bet === previousTeamScore;
};

export const BetHint = () => {
  const self = useMachineSelf();
  const {event} = useMachineEvent();

  const turnTeam = useMachineSelector(selectTurnTeam);
  const question = useMachineSelector(selectCtxCurrentQuestion);
  const score = useMachineSelector(selectTeamScore(turnTeam?.uuid || ''));
  const auctionBetsTimer = useMachineSelector(selectTimer('auctionBets'));

  const highestBet = useMachineSelector(state => getAuctionHighestBet(state.context));
  const possibleBet = parseInt(highestBet.toFixed(0)) + 1;
  const isPreviousBetAllIn = useMachineSelector(selectIsPreviousBetAllIn);

  const {register, setValue, handleSubmit} = useForm<BetHintDto>({
    defaultValues: {
      bet: possibleBet
    }
  });

  useEffect(() => {
    setValue('bet', possibleBet);
  }, [possibleBet]);

  const isSelf = self?.teamUuid === turnTeam?.uuid;

  const onClickAllIn = useCallback(() => {
    if (!question || score <= highestBet) return;
    event<TypeBetEvent>('typeBet', {questionUuid: question.uuid, bet: score});
  }, [event, question, score, highestBet]);

  const onClickPass = useCallback(() => {
    if (!question) return;
    event<PassBetEvent>('passBet', {questionUuid: question.uuid});
  }, [event, question]);

  const onFormSubmit = (values: BetHintDto) => {
    const bet = Math.trunc(values.bet);
    if (!question || bet < possibleBet || bet > score) return;
    event<TypeBetEvent>('typeBet', {questionUuid: question.uuid, bet});
  };

  if (!question || !turnTeam) {
    return null;
  }

  if (!isSelf) {
    return (
      <UserChip
        userUuid={turnTeam.leaderUuid}
        message=", делает ставку"
        timer={auctionBetsTimer}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <FieldGroup>
        <FieldRow justify="center">
          <UserChip
            userUuid={turnTeam.leaderUuid}
            message=", делайте ставку"
            timer={auctionBetsTimer}
            isSelf={true}
          />
        </FieldRow>
      </FieldGroup>

      <FieldGroup>
        <FieldRow justify="center">
          {score >= possibleBet && !isPreviousBetAllIn && (
            <FieldRow size="sm">
              <div style={{width: 120}}>
                <Input
                  {...register('bet', {required: 'Пожалуйста введите ставку.'})}
                  placeholder="Ставка"
                  autoComplete="off"
                  autoFocus={true}
                  type="number"
                  size="sm"
                  min={possibleBet}
                  max={score}
                />
              </div>
              <Button size="sm" type="submit">
                <PaperPlaneRight size={16} weight="bold" />
              </Button>
            </FieldRow>
          )}

          <FieldRow size="sm">
            {score > highestBet && (
              <Button
                variant={!isPreviousBetAllIn ? 'secondary' : 'primary'}
                size="sm"
                onClick={onClickAllIn}>
                Ва-банк
              </Button>
            )}
            <Button variant="secondary" size="sm" onClick={onClickPass}>
              Пас
            </Button>
          </FieldRow>
        </FieldRow>
      </FieldGroup>
    </form>
  );
};
