import {useQuery} from '@tanstack/react-query';
import {FetchThemesParams, fetchThemes} from './fetchThemes';

/**
 *
 * @param params
 */
const getThemesQueryKey = (params: Pick<FetchThemesParams, 'roundUuid'>) => [
  'editor',
  'themes',
  {roundUuid: params.roundUuid}
];

/**
 *
 * @param params
 */
export const useThemesQuery = (params: FetchThemesParams) =>
  useQuery({
    queryKey: getThemesQueryKey(params),
    queryFn: () => fetchThemes(params)
  });
