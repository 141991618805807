import React, {ReactNode, createElement} from 'react';
import styled from 'styled-components';
import {PhosphorIcon} from '@openquiz/quiz-ui';

interface StatChipProps {
  children: ReactNode;
  icon: PhosphorIcon;
}

const StyledStatChip = styled.div`
  ${p => p.theme.typography.body.md};
  display: flex;
  align-items: center;
  gap: 4px;

  span[data-id='count'] {
    font-weight: bold;
  }

  span[data-id='value'] {
    opacity: 0.6;
  }
`;

const Entry = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StatChip = ({children, icon}: StatChipProps) => (
  <StyledStatChip>
    {createElement(icon, {size: 20, weight: 'bold'})}
    <Entry>{children}</Entry>
  </StyledStatChip>
);
