import {State} from 'xstate';
import {Ctx, MachineTimerKey} from '@opeq-dev/openquiz-machine';

/**
 * Select a timer.
 * @param timer
 * @returns
 */
export const selectTimer = (timer: MachineTimerKey) => (state: State<Ctx>) =>
  state.context.timers[timer];
