import React from 'react';
import {motion} from 'framer-motion';
import {useSelector} from '@xstate/react';
import styled from 'styled-components';
import {FieldRow, TipCircle, Tooltip, getAnimationProps} from '@openquiz/quiz-ui';
import {
  questionTypeLabelMap,
  selectCtxCurrentQuestion,
  selectCtxCurrentTheme,
  useGameService
} from '@/features/machine';

const StyledTopBar = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  @media only screen and (min-width: 320px) {
    margin-bottom: 16px;
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 16px;
  }

  @media only screen and (min-width: 1440px) {
    margin-bottom: 24px;
  }
`;

const Label = styled.div`
  ${p => p.theme.typography.label.lg};
  display: flex;
  align-items: center;
  text-align: center;
  height: 24px;
  margin-bottom: 2px;
`;

const ToolTip = styled.div`
  display: flex;
  margin: -2px 0 0 4px;
`;

const Type = styled.div`
  ${p => p.theme.typography.caption.sm};
  color: ${p => p.theme.colors.text.subtle};
`;

const Source = styled.div`
  ${p => p.theme.typography.caption.sm};
  color: ${p => p.theme.colors.text.subtle};
  border-bottom: 1px solid ${p => p.theme.colors.text.subtle};
`;

const topBarMotionProps = getAnimationProps(
  {
    initial: {y: 8, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: -8, opacity: 0}
  },
  {duration: 0.2}
);

export const TopBar = () => {
  const {service} = useGameService();
  const theme = useSelector(service, selectCtxCurrentTheme);
  const question = useSelector(service, selectCtxCurrentQuestion);

  if (!theme || !question) {
    return null;
  }

  return (
    <StyledTopBar>
      <motion.div {...topBarMotionProps}>
        <Label>
          <span>{theme?.name}</span>
          {theme.description && (
            <ToolTip>
              <TipCircle message={theme.description} size="md" />
            </ToolTip>
          )}
          , {question?.price}
        </Label>
        <FieldRow justify="center" size="sm">
          <Type>{questionTypeLabelMap.get(question.type)}</Type>
          {question.source && (
            <Tooltip message={question.source}>
              <Source>Источник</Source>
            </Tooltip>
          )}
        </FieldRow>
      </motion.div>
    </StyledTopBar>
  );
};
