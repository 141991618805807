import {ReactElement} from 'react';
import {create} from 'zustand';
import {ModalParams} from './types';

interface ModalStoreState {
  modals: {id: string; element: ReactElement; params: Partial<ModalParams>}[];
  push: (id: string, element: ReactElement, params: Partial<ModalParams>) => void;
  shift: (id: string) => void;
  reset: () => void;
}

export const modalStore = create<ModalStoreState>(set => {
  return {
    modals: [],
    push: (id, element, params = {}) => {
      set(s => ({...s, modals: [...s.modals, {id, element, params}]}));
    },
    shift: id => {
      set(s => ({
        ...s,
        modals: s.modals.filter(modal => modal.id !== id)
      }));
    },
    reset: () => {
      set(s => {
        return {...s, modals: s.modals.filter(modal => modal.params.persistent)};
      });
    }
  };
});
