import {PackageQuestion} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export interface QuestionCreateParams {
  data: PackageQuestion;
  packageUuid: string;
}

/**
 * Fetch create a question.
 * @param params
 */
export const fetchQuestionCreate = async (params: QuestionCreateParams) => {
  const {data} = await getPrivateAxios().post<PackageQuestion>(
    `/workshop/packages/${params.packageUuid}/questions`,
    params.data
  );
  return data;
};
