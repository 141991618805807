import {Callout} from '@openquiz/quiz-ui';
import {QuestionType} from '@opeq-dev/openquiz-schema';
import React from 'react';
import styled from 'styled-components';

interface VariantsHelpCalloutProps {
  type: QuestionType;
}

const StyledVariantsHelpCallout = styled.div`
  b {
    border-bottom: 2px solid ${p => p.theme.colors.accent.text};
    font-weight: 500;
  }
`;

export const VariantsHelpCallout = ({type}: VariantsHelpCalloutProps) => (
  <StyledVariantsHelpCallout>
    <Callout size="sm">
      {type === 'VARIANTS' && (
        <>
          <b>Один игрок</b> должен выбрать правильный вариант.
        </>
      )}

      {type === 'VARIANTS_EVERYONE' && (
        <>
          <b>Все игроки</b> должны выбрать правильный вариант.
        </>
      )}

      {type === 'VARIANTS_NORISK' && (
        <>
          <b>Один игрок</b> игрок должен выбрать правильный вариант, <b>без риска</b>{' '}
          потерять очки.
        </>
      )}

      {type === 'VARIANTS_EVERYONE_NORISK' && (
        <>
          <b>Все игроки</b> должны выбрать правильный вариант, <b>без риска</b> потерять
          очки.
        </>
      )}

      {type === 'VARIANTS_ONE_BY_ONE' && (
        <>
          <b>Все игроки</b> могут попытаться ответить на вопрос, игрок который{' '}
          <b>первый нажал кнопку</b>, должен выбрать правильный ответ.
        </>
      )}
    </Callout>
  </StyledVariantsHelpCallout>
);
