import React, {useEffect} from 'react';
import {SplashScreen, useSfxPlayer} from '@/features/machine';

export const VariantsOneByOneQuestionSplash = () => {
  const sfxPlayer = useSfxPlayer();

  useEffect(() => {
    sfxPlayer.play('secret');
  }, [sfxPlayer.play]);

  return (
    <SplashScreen
      icon={
        <picture>
          <source
            srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f43e/512.webp"
            type="image/webp"
          />
          <img
            src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f43e/512.gif"
            alt="🐾"
            width={96}
            height={96}
          />
        </picture>
      }>
      <h2>Вопрос с вариантами ответа</h2>
      <p>Во время ответа нужно выбрать один из предложенных вариантов.</p>
      <p>Могут ответить все игроки.</p>
    </SplashScreen>
  );
};
