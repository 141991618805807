import React from 'react';
import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {Spinner} from '@openquiz/quiz-ui';
import {ModalConfirm, useModalConfirm} from '@openquiz/quiz-modal';
import {SortableList} from '@/features/workshop';
import {
  QuestionsGrid,
  ThemeRow,
  useEditorContext,
  useThemeDeleteMutation,
  useThemeUpdateMutation,
  useThemesQuery
} from '@/features/wshop-editor';
import {useLocation} from 'react-router-dom';

export const ThemesGrid = () => {
  const {state} = useLocation();
  const {packageUuid, roundUuid, versionUuid} = useEditorContext();
  const {mutate: mutateUpdate} = useThemeUpdateMutation();
  const {mutateAsync: mutateDelete} = useThemeDeleteMutation();
  const {data: themes = [], isLoading} = useThemesQuery({roundUuid, packageUuid});

  const confirmDeleteTheme = useModalConfirm(props => (
    <ModalConfirm
      {...props}
      content={<>При удалении темы, все вопросы будут также удалены.</>}
      resolveLabel="Удалить"
    />
  ));

  const sortedThemes = themes.sort((a, b) => a.position - b.position);

  const onChangeName = (theme: PackageTheme) => (name: string) => {
    mutateUpdate({data: {...theme, name}, packageUuid});
  };

  const onClickDeleteTheme = async (theme: PackageTheme) => {
    try {
      await confirmDeleteTheme();
      await mutateDelete({uuid: theme.uuid, packageUuid});
    } catch (e) {
      /* empty */
    }
  };

  const onDragEnd = (items: string[]) => {
    items.forEach((uuid, position) => {
      const theme = themes.find(t => t.uuid === uuid);
      if (!theme) return;
      mutateUpdate({data: {...theme, position}, packageUuid});
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <SortableList
      items={sortedThemes.map(t => t.uuid)}
      strategy="vertical"
      onDragEnd={onDragEnd}>
      {sortedThemes.map(theme => (
        <ThemeRow
          key={`theme-row-${theme.uuid}`}
          highlighted={state?.highlights === theme.uuid}
          theme={theme}
          packageUuid={packageUuid}
          onChangeName={name => onChangeName(theme)(name)}
          onDelete={() => onClickDeleteTheme(theme)}>
          <QuestionsGrid
            themeUuid={theme.uuid}
            packageUuid={packageUuid}
            versionUuid={versionUuid}
          />
        </ThemeRow>
      ))}
    </SortableList>
  );
};
