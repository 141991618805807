import React, {ReactElement, cloneElement, useState} from 'react';
import {
  ConnectPlayerEvent,
  TeamRecord,
  changeTeamNameGuard,
  connectPlayerGuard,
  hostUniversalGuard
} from '@opeq-dev/openquiz-machine';
import {usePeerWebcam} from '@openquiz/peer-webcam';
import {
  DotsLoader,
  PlayerSlot,
  TeamSlot,
  TeamSlotPlaceholder,
  WebCamera
} from '@openquiz/quiz-ui';
import {
  selectGameSettings,
  selectTeamPlayers,
  selectTurnTeamUuid,
  useMachineEvent,
  useMachineGuard,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';
import {UserBanner, useUserQuery} from '@/features/user';
import {useIndicator} from './useIndicator';
import {PlayerCircle} from './PlayerCircle';
import {SlotTeamMenu} from './SlotTeamMenu';
import {SlotSingleMenu} from './SlotSingleMenu';
import {ActionButton} from '@/features/machine/widgets/Slot/ActionButton';

interface SlotProps {
  team: TeamRecord;
  hovered?: boolean;
}

const TeamModeSlot = ({team}: SlotProps) => {
  const {event} = useMachineEvent();
  const {guard} = useMachineGuard();

  const indicator = useIndicator(team);
  const users = useMachineSelector(selectTeamPlayers(team.uuid));

  const onClickConnect = () => {
    event<ConnectPlayerEvent>('connectPlayer', {exists: {uuid: team.uuid}});
  };

  return (
    <TeamSlot
      team={team}
      users={users}
      indicator={indicator}
      actionButton={<ActionButton team={team} />}
      menu={
        guard(changeTeamNameGuard)({teamUuid: team.uuid}) ? (
          <SlotTeamMenu team={team} />
        ) : undefined
      }
      renderUser={user => <PlayerCircle user={user} team={team} />}
      onClickConnect={
        guard(connectPlayerGuard)({exists: {uuid: team.uuid}})
          ? onClickConnect
          : undefined
      }
    />
  );
};

const SingleModeSlot = ({team, hovered}: SlotProps) => {
  const {data: player, isLoading} = useUserQuery(team.leaderUuid);
  const {streams, gameUuid} = usePeerWebcam();
  const {guard} = useMachineGuard();
  const indicator = useIndicator(team);
  const self = useMachineSelf();
  const turnTeamUuid = useMachineSelector(selectTurnTeamUuid);

  const isActive = self?.teamUuid === team?.uuid || turnTeamUuid === team.uuid || hovered;

  const stream = streams[`${gameUuid}-${player?.uuid}`];
  const hasStream = stream?.getAudioTracks().length === 0;

  if (isLoading || !player) {
    return (
      <TeamSlotPlaceholder>
        <DotsLoader />
      </TeamSlotPlaceholder>
    );
  }

  return (
    <PlayerSlot
      team={team}
      user={player}
      indicator={indicator}
      active={isActive}
      actionButton={<ActionButton team={team} />}
      menu={guard(hostUniversalGuard)({}) ? <SlotSingleMenu team={team} /> : undefined}
      renderBanner={() => <UserBanner user={player} />}
      renderStream={hasStream ? () => <WebCamera stream={stream} /> : undefined}
    />
  );
};

export const Slot = ({team}: SlotProps) => {
  const settings = useMachineSelector(selectGameSettings);

  if (settings.teamMode) {
    return <TeamModeSlot team={team} />;
  }

  return (
    <Hoverable>
      <SingleModeSlot team={team} />
    </Hoverable>
  );
};

const Hoverable = ({children}: {children: ReactElement}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{height: '100%', width: '100%'}}>
      {cloneElement(children, {hovered})}
    </div>
  );
};
