import React, {Fragment, createElement, useMemo} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {AsteriskSimple, ListNumbers} from '@phosphor-icons/react';
import {FieldRow, PhosphorIcon, Tooltip} from '@openquiz/quiz-ui';
import {CategoryChip} from '@/features/wshop-categories';
import {PackageFull, SizeBar} from '@/features/wshop-packages';
import {getSearchString} from '@/features/wshop-search';
import {DraftSticker} from '../DraftSticker';
import {IllegalSticker} from '../IllegalSticker';
import {VerifiedSticker} from '../VerifiedSticker';

interface MetaProps {
  pkg: PackageFull;
  hasSizeBar?: boolean;
  hasLinks?: boolean;
  hasTags?: boolean;
}

const difficultyMap = new Map<PackageFull['difficulty'], [color: string, label: string]>([
  ['easy', ['#01fc9b80', 'Легкий']],
  ['normal', ['#fcd90180', 'Обычный']],
  ['hard', ['#d301fc80', 'Сложный']]
]);

const typeMap = new Map<
  PackageFull['type'],
  [color: string, icon: PhosphorIcon, label: string]
>([['OnlyVariants', ['rgba(1,80,252,0.3)', ListNumbers, 'С вариантами ответов']]]);

const StyledMeta = styled.div``;

const Difficulty = styled.div<{$color: string}>`
  ${p => p.theme.typography.caption.md};
  background-color: ${p => p.$color};
  color: ${p => p.theme.colors.text.default};
  border-radius: 8px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Type = styled.div<{$color: string}>`
  ${p => p.theme.typography.caption.md};
  background-color: ${p => p.$color};
  color: ${p => p.theme.colors.text.default};
  border-radius: 8px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Size = styled.div`
  color: ${p => p.theme.colors.text.muted};
  margin-top: 5px;
  opacity: 0.6;
`;

const Tags = styled.div`
  color: ${p => p.theme.colors.text.muted};
  margin-top: 5px;
  font-size: 14px;
  line-height: 16px;
  max-width: 480px;
  text-transform: lowercase;
  opacity: 0.6;
  transition: opacity 80ms ease-in-out;

  & a {
    color: inherit;
    :hover span {
      color: ${p => p.theme.colors.accent.text};
    }
  }

  :hover {
    opacity: 1;
  }
`;

export const Meta = ({
  pkg,
  hasSizeBar = false,
  hasLinks = false,
  hasTags = false
}: MetaProps) => {
  const difficulty = useMemo(() => difficultyMap.get(pkg.difficulty), [pkg.difficulty]);
  const type = useMemo(() => typeMap.get(pkg.type), [pkg.type]);

  const isVerified = useMemo(() => pkg.isVerified, [pkg.isVerified]);
  const isIllegal = useMemo(() => pkg.tags.some(t => t.tag.name === '18+'), []);
  const isDraft = useMemo(
    () => pkg.versions.every(version => !version.published),
    [pkg.versions]
  );

  return (
    <StyledMeta>
      <FieldRow size="sm">
        <FieldRow size="xs">
          {difficulty && (
            <Tooltip message="Сложность пака">
              <Difficulty $color={difficulty[0]}>
                <AsteriskSimple size={20} weight="fill" />
                <span>{difficulty[1]}</span>
              </Difficulty>
            </Tooltip>
          )}
          {type && (
            <Type $color={type[0]}>
              {createElement(type[1], {weight: 'bold'})}
              <span>{type[2]}</span>
            </Type>
          )}
          {isVerified && <VerifiedSticker />}
          {isIllegal && <IllegalSticker />}
          {isDraft && <DraftSticker />}
        </FieldRow>
        <FieldRow size="xs">
          {pkg.categories.length === 0 && <CategoryChip />}
          {pkg.categories.map(category => (
            <CategoryChip
              key={`category-chip-${category.categoryUuid}`}
              category={category.category}
              hasLink={hasLinks}
            />
          ))}
        </FieldRow>
      </FieldRow>

      {hasSizeBar && !!pkg.meta.questions && (
        <Size>
          <SizeBar pkg={pkg} flatten={true} />
        </Size>
      )}

      {hasTags && !!pkg.tags.length && (
        <Tags>
          {pkg.tags.map((tag, index) => (
            <Fragment key={`tag-span-${tag.tagUuid}`}>
              {createElement(
                hasLinks ? Link : 'span',
                {
                  to: hasLinks
                    ? `/workshop/search?data=${getSearchString({
                        query: `#${tag.tag.name}`,
                        sort: 'created_at'
                      })}`
                    : ''
                },
                <>
                  <span>#{tag.tag.name}</span>
                  {pkg.tags.length - 1 > index && ', '}
                </>
              )}
            </Fragment>
          ))}
        </Tags>
      )}
    </StyledMeta>
  );
};
