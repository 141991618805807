import React, {ReactNode} from 'react';
import {Bug} from '@phosphor-icons/react';
import {isRouteErrorResponse, useRouteError} from 'react-router-dom';
import styled from 'styled-components';
import {OpenQuizProvider} from '@openquiz/quiz-ui';
import {useAppearanceTheme} from '@/hooks';

interface ErrorMessageProps {
  children: ReactNode;
  status: number | string;
}

const StyledErrorBoundary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: calc(100vh / var(--zoom));

  svg {
    color: ${p => p.theme.colors.danger.text};
    margin-bottom: 32px;
  }

  h1 {
    margin-bottom: 16px;
  }

  p {
    ${p => p.theme.typography.body.lg};
    max-width: 360px;
  }
`;

const ErrorMessage = ({children, status}: ErrorMessageProps) => {
  const theme = useAppearanceTheme();

  return (
    <OpenQuizProvider theme={theme}>
      <StyledErrorBoundary>
        <Bug size={96} weight="duotone" />
        <h1>{status}</h1>
        <p>{children}</p>
      </StyledErrorBoundary>
    </OpenQuizProvider>
  );
};

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <ErrorMessage status={error.status}>Эта страница не существует!</ErrorMessage>
      );
    }
  }

  return (
    <ErrorMessage status="Произошла ошибка">
      Что-то пошло не так, попробуйте перезагрузить страницу.
    </ErrorMessage>
  );
};
