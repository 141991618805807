import React, {MemoExoticComponent, createElement, useMemo} from 'react';
import {AudioPlayerProvider} from 'react-use-audio-player';
import {motion} from 'framer-motion';
import styled from 'styled-components';
import {useSelector} from '@xstate/react';
import {Asterisk} from '@phosphor-icons/react';
import {PackageQuestionStep, QuestionStepType} from '@opeq-dev/openquiz-schema';
import {QuestionStepStage} from '@opeq-dev/openquiz-machine';
import {getAnimationProps} from '@openquiz/quiz-ui';
import {selectCtxCurrentSteps, useGameService} from '@/features/machine';
import {AudioStep} from './AudioStep';
import {ImageStep} from './ImageStep';
import {TextStep} from './TextStep';
import {VideoStep} from './VideoStep';
import {Variants} from './Variants';
import {selectPlayStepIndex} from './selectPlayStepIndex';

interface RenderStepsProps {
  stage: QuestionStepStage;
}

interface StepComponentsProps {
  step: PackageQuestionStep;
  stage: QuestionStepStage;
}

type StepComponentType =
  | ((props: StepComponentsProps) => JSX.Element)
  | MemoExoticComponent<({stage, step}: StepComponentsProps) => JSX.Element>;

const StyledRenderSteps = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Caption = styled.div`
  ${p => p.theme.typography.caption.sm};
  color: ${p => p.theme.colors.text.muted};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
  text-align: center;
  max-width: 600px;
  white-space: pre-wrap;
`;

const stepByTypeMap = new Map<QuestionStepType, StepComponentType>([
  ['TEXT', TextStep],
  ['AUDIO', AudioStep],
  ['VIDEO', VideoStep],
  ['IMAGE', ImageStep]
]);

const renderStepsAnimationProps = getAnimationProps(
  {
    initial: {y: 4, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: -4, opacity: 0}
  },
  {duration: 0.2}
);

export const RenderSteps = ({stage}: RenderStepsProps) => {
  const {service} = useGameService();
  const steps = useSelector(service, selectCtxCurrentSteps);
  const playStepIndex = useSelector(service, selectPlayStepIndex);

  const currentStep = useMemo(() => {
    return stage.includes('play')
      ? steps
          .filter(s => !s.isAnswer)
          .sort((a, b) => a.position - b.position)
          .at(playStepIndex)
      : steps.filter(s => s.isAnswer).at(0);
  }, [stage, steps, playStepIndex]);

  const currentComponent = useMemo(
    () => (currentStep ? stepByTypeMap.get(currentStep.type) : null),
    [currentStep]
  );

  if (!currentStep) {
    return null;
  }

  return (
    <StyledRenderSteps
      {...renderStepsAnimationProps}
      key={`render-steps-${currentStep.uuid}`}>
      <AudioPlayerProvider>
        <Step>
          {currentComponent &&
            createElement(currentComponent, {
              step: currentStep,
              stage
            })}
        </Step>

        {currentStep.caption && (
          <Caption>
            <Asterisk size={16} weight="bold" />
            <span>{currentStep.caption}</span>
            <Asterisk size={16} weight="bold" />
          </Caption>
        )}

        {!!currentStep.variants.filter(Boolean).length && <Variants stage={stage} />}
      </AudioPlayerProvider>
    </StyledRenderSteps>
  );
};
