import React, {useMemo} from 'react';
import styled, {css} from 'styled-components';
import {AnimatePresence, motion} from 'framer-motion';
import {PlusCircle, SignIn} from '@phosphor-icons/react';
import {ConnectPlayerEvent, connectPlayerGuard} from '@opeq-dev/openquiz-machine';
import {
  TeamSlotPlaceholder,
  getAnimationProps,
  getRandomNumber,
  getSeedRandomNumber
} from '@openquiz/quiz-ui';
import {
  Slot,
  selectGameSettings,
  selectTeams,
  teamSlotStyles,
  useMachineEvent,
  useMachineGuard,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';
import {getUuid} from '@/libs';

interface LobbySlotsProps {
  direction: 'vertical' | 'horizontal';
}

const StyledLobbySlots = styled.div<{
  $direction: LobbySlotsProps['direction'];
  $mode: 'team' | 'single';
  $count: number;
}>`
  position: relative;
  display: grid;
  overflow: hidden;
  width: 100%;

  ${p =>
    p.$direction === 'vertical' &&
    css`
      ${p.$mode === 'team'
        ? css`
            grid-template-columns: repeat(4, 240px);
            grid-auto-rows: minmax(200px, 320px);
          `
        : css`
            grid-template-columns: repeat(4, 240px);
            grid-template-rows: repeat(2, 160px);
          `}

      justify-content: center;
      gap: 16px;
    `}

  ${p =>
    p.$direction === 'horizontal' &&
    css`
      ${p.$mode === 'team'
        ? css`
            grid-template-columns: repeat(1, 1fr);
            grid-auto-rows: minmax(180px, 260px);
          `
        : css`
            grid-template-columns: repeat(1, 1fr);
            grid-auto-rows: minmax(90px, 160px);
          `}

      gap: 8px;
      height: 100%;
    `}
`;

const MinSizeWrapper = styled(motion.div)<{$mode: 'team' | 'single'}>`
  display: flex;
  width: 100%;
  height: 100%;
`;

const slotMotionProps = getAnimationProps({
  initial: {x: 16, opacity: 0},
  animate: {x: 0, opacity: 1},
  exit: {x: -16, opacity: 0}
});

export const LobbySlots = ({direction}: LobbySlotsProps) => {
  const self = useMachineSelf();
  const {event} = useMachineEvent();
  const {guard} = useMachineGuard();

  const settings = useMachineSelector(selectGameSettings);
  const teams = useMachineSelector(selectTeams);

  const allowConnect = guard(connectPlayerGuard)({});
  const mode = settings.teamMode ? 'team' : 'single';

  const emptySlots = useMemo(
    () =>
      new Array((settings.teamMode ? 4 : settings.slots) - teams.length || 0)
        .fill('')
        .map((_, index) =>
          getSeedRandomNumber((teams.length + index).toString(), 1000, 100000)
        ),
    [settings, teams.length]
  );

  const onClickCreateTeam = () => {
    if (!self?.userUuid) return;

    if (mode === 'team') {
      const availableSlotStyles = teamSlotStyles.filter(slotStyle =>
        teams.every(team => team.style.color !== slotStyle.style.color)
      );

      const nextSlotStyle = availableSlotStyles.at(
        getRandomNumber(0, availableSlotStyles.length)
      );

      if (!nextSlotStyle) return;

      event<ConnectPlayerEvent>('connectPlayer', {
        create: {
          uuid: getUuid(),
          name: nextSlotStyle.label,
          style: nextSlotStyle.style
        }
      });
    } else {
      event<ConnectPlayerEvent>('connectPlayer', {
        create: {
          uuid: getUuid(),
          name: '',
          style: {color: ''}
        }
      });
    }
  };

  return (
    <StyledLobbySlots $direction={direction} $mode={mode} $count={teams.length + 1}>
      <AnimatePresence mode="popLayout">
        {teams.map(team => (
          <MinSizeWrapper
            key={`team-slot-widget-${team.uuid}`}
            $mode={settings.teamMode ? 'team' : 'single'}
            {...slotMotionProps}>
            <Slot team={team} />
          </MinSizeWrapper>
        ))}

        {direction === 'vertical' && (
          <>
            {emptySlots.map(num => (
              <MinSizeWrapper
                key={`team-slot-placeholder-${num}`}
                $mode={mode}
                {...slotMotionProps}>
                <TeamSlotPlaceholder pointer={allowConnect} onClick={onClickCreateTeam}>
                  {mode === 'team' ? (
                    <>
                      <PlusCircle size={32} />
                      <div>Создать команду</div>
                    </>
                  ) : (
                    <>
                      <SignIn size={32} />
                      <div>Присоединиться</div>
                    </>
                  )}
                </TeamSlotPlaceholder>
              </MinSizeWrapper>
            ))}
          </>
        )}

        {direction === 'horizontal' && (
          <>
            {!!emptySlots.length && (
              <MinSizeWrapper $mode={mode} {...slotMotionProps}>
                <TeamSlotPlaceholder pointer={allowConnect} onClick={onClickCreateTeam}>
                  {mode === 'team' ? (
                    <>
                      <PlusCircle size={32} />
                      <div>Создать команду</div>
                    </>
                  ) : (
                    <>
                      <SignIn size={32} />
                      <div>Присоединиться</div>
                    </>
                  )}
                </TeamSlotPlaceholder>
              </MinSizeWrapper>
            )}
          </>
        )}
      </AnimatePresence>
    </StyledLobbySlots>
  );
};
