import React from 'react';
import {SpeakerSimpleHigh, SpeakerSimpleSlash} from '@phosphor-icons/react';
import {PopperMenu, Tooltip} from '@openquiz/quiz-ui';
import {useUiSettingsStore} from '@/stores';
import {ControlBarButton} from '../ControlBarButton';
import {ControlBarVolumeMenu} from './ControlBarVolumeMenu';

export const ControlBarVolume = () => {
  const {volume} = useUiSettingsStore();

  return (
    <Tooltip message="Громкость" placement="top" closeOnReferencePress={true}>
      <div>
        <PopperMenu menu={<ControlBarVolumeMenu />} placement="top">
          <ControlBarButton variant={volume > 0 ? 'secondary' : 'danger'}>
            {volume > 0 ? (
              <SpeakerSimpleHigh size={24} weight="bold" />
            ) : (
              <SpeakerSimpleSlash size={24} weight="bold" />
            )}
          </ControlBarButton>
        </PopperMenu>
      </div>
    </Tooltip>
  );
};
