import {Circle, Coins, Lifebuoy, ListNumbers, Question} from '@phosphor-icons/react';
import {QuestionType} from '@opeq-dev/openquiz-schema';
import {PhosphorIcon} from '@openquiz/quiz-ui';

export const questionTypeIconMap = new Map<QuestionType, PhosphorIcon>([
  ['BASIC', Circle],
  ['SECRET', Question],
  ['NORISK', Lifebuoy],
  ['VARIANTS', ListNumbers],
  ['VARIANTS_EVERYONE', ListNumbers],
  ['VARIANTS_NORISK', ListNumbers],
  ['VARIANTS_EVERYONE_NORISK', ListNumbers],
  ['VARIANTS_ONE_BY_ONE', ListNumbers],
  ['AUCTION', Coins]
]);
