import {MouseEvent, useCallback, useRef} from 'react';

interface Options {
  timeout?: number;
}

/**
 *
 * @param doubleClick
 * @param click
 * @param _options
 */
export const useDoubleClick = (
  doubleClick: (e: MouseEvent) => void,
  click: (e: MouseEvent) => void,
  _options?: Options
) => {
  const options = {
    timeout: 200,
    ..._options
  };

  const clickTimeout = useRef<NodeJS.Timeout | null>(null);

  const clearClickTimeout = () => {
    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }
  };

  return useCallback(
    (e: MouseEvent) => {
      clearClickTimeout();
      if (click && e.detail === 1) {
        clickTimeout.current = setTimeout(() => {
          click(e);
        }, options.timeout);
      }
      if (e.detail % 2 === 0) {
        doubleClick(e);
      }
    },
    [click, doubleClick, options.timeout]
  );
};
