import {
  NumberCircleFour,
  NumberCircleOne,
  NumberCircleThree,
  NumberCircleTwo
} from '@phosphor-icons/react';
import {PhosphorIcon} from '@openquiz/quiz-ui';

export const iconNumberMap = new Map<number, PhosphorIcon>([
  [0, NumberCircleOne],
  [1, NumberCircleTwo],
  [2, NumberCircleThree],
  [3, NumberCircleFour]
]);
