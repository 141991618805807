import React from 'react';
import styled from 'styled-components';
import {Grid} from '@openquiz/quiz-ui';
import {useWorkshopCategories} from '../../api';
import {CategoryCard} from '../../components';

const StyledCategoriesCards = styled.div``;

export const CategoriesCards = () => {
  const {data: categories = []} = useWorkshopCategories();

  return (
    <StyledCategoriesCards>
      <Grid cols={{sm: 1, md: 3, lg: 5}} gap={16}>
        {categories.map(category => (
          <CategoryCard key={`category-card-${category.uuid}`} category={category} />
        ))}
      </Grid>
    </StyledCategoriesCards>
  );
};
