import React, {ReactNode, forwardRef} from 'react';
import styled from 'styled-components';

interface SidebarBadgeProps {
  children: ReactNode;
}

const StyledSidebarBadge = styled.div`
  ${p => p.theme.typography.label.sm};
  background-color: ${p => p.theme.colors.accent.emphasis};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 6px;
  padding: 2px 4px;
  text-align: center;
  min-width: 20px;
`;

export const SidebarBadge = forwardRef<HTMLDivElement, SidebarBadgeProps>(
  function SidebarBadge({children}, ref) {
    return <StyledSidebarBadge ref={ref}>{children}</StyledSidebarBadge>;
  }
);
