import React, {ReactNode} from 'react';
import {restrictToWindowEdges} from '@dnd-kit/modifiers';
import {DndContext, DragOverlay} from '@dnd-kit/core';
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import {useSortableList} from '../../hooks';

interface SortableListProps {
  children: ReactNode;
  items: string[];
  strategy?: 'vertical' | 'horizontal';
  delay?: number;
  renderDragOverlay?: (uuid: string) => ReactNode;
  onDragEnd: (items: string[]) => void;
}

export const SortableList = ({
  children,
  items,
  strategy = 'vertical',
  delay,
  onDragEnd
}: SortableListProps) => {
  const sortableProps = useSortableList(items, {delay, onDragEnd});

  return (
    <DndContext {...sortableProps} modifiers={[restrictToWindowEdges]}>
      <SortableContext
        items={items}
        strategy={
          strategy === 'vertical'
            ? verticalListSortingStrategy
            : horizontalListSortingStrategy
        }>
        {children}
      </SortableContext>
      <DragOverlay dropAnimation={null} />
    </DndContext>
  );
};
