import {useQuery} from '@tanstack/react-query';
import {FetchRoundsParams, fetchRounds} from './fetchRounds';

/**
 *
 * @param params
 */
export const useRoundsQuery = (params: FetchRoundsParams) =>
  useQuery({
    queryKey: [
      'editor',
      'rounds',
      {packageUuid: params.packageUuid, versionUuid: params.versionUuid}
    ],
    queryFn: () => fetchRounds(params)
  });
