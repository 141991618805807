import React, {useEffect} from 'react';
import {PlaceInitialBetHint, SplashScreen, useSfxPlayer} from '@/features/machine';

export const AuctionQuestionSplash = () => {
  const sfxPlayer = useSfxPlayer();

  useEffect(() => {
    sfxPlayer.play('auction');
  }, [sfxPlayer.play]);

  return (
    <SplashScreen
      icon={
        <picture>
          <source
            srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.webp"
            type="image/webp"
          />
          <img
            src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.gif"
            alt="💸"
            width={96}
            height={96}
          />
        </picture>
      }
      action={<PlaceInitialBetHint />}>
      <h2>Вопрос аукцион</h2>
      <p>
        Это вопрос аукцион, он будет передан игроку, который поставит наибольшую ставку.
      </p>
    </SplashScreen>
  );
};
