import React, {forwardRef} from 'react';
import styled from 'styled-components';
import RcSlider, {SliderProps as RcSliderProps} from 'rc-slider';

export type SliderProps = RcSliderProps;

const StyledSlider = styled.div`
  user-select: none;
  width: 100%;
  height: 32px;

  .rc-slider {
    position: relative;
    width: 100%;
    padding: 5px 0;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-rail {
    background-color: ${p => p.theme.colors.border.default};
    border-radius: 2px;
    position: absolute;
    width: 100%;
    height: 4px;
  }
  .rc-slider-track {
    background-color: ${p => p.theme.colors.accent.emphasis};
    border-radius: 2px;
    position: absolute;
    height: 4px;
  }
  .rc-slider-handle {
    position: absolute;
    background-color: ${p => p.theme.colors.accent.emphasis};
    border: 2px solid ${p => p.theme.colors.canvas.default};
    width: 16px;
    height: 16px;
    margin-top: -6px;
    box-sizing: border-box;
    border-radius: 50%;
    touch-action: pan-x;
    transition:
      margin ease-in-out 80ms,
      width ease-in-out 80ms,
      height ease-in-out 80ms;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    background-color: ${p => p.theme.colors.accent.emphasis};
    border-color: ${p => p.theme.colors.accent.emphasis};
  }

  .rc-slider-handle:focus {
    outline: none;
    box-shadow: none;
  }

  .rc-slider-handle:focus-visible {
    box-shadow: 0 0 0 3px ${p => p.theme.colors.accent.subtle};
  }
  .rc-slider-handle-click-focused:focus {
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    cursor: grab;
  }
  .rc-slider-handle:active {
    margin-top: 7px;
    width: 6px;
    height: 6px;
  }

  .rc-slider-mark {
    ${p => p.theme.typography.caption.sm};
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    color: ${p => p.theme.colors.text.subtle};
  }

  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;

    :first-of-type {
      margin-left: 8px;
    }

    :last-of-type {
      margin-left: -14px;
    }
  }

  .rc-slider-mark-text-active {
    color: ${p => p.theme.colors.text.default};
    font-weight: 500;
  }
  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
    pointer-events: none;
  }
  .rc-slider-dot {
    display: none;
  }
  .rc-slider-dot-active {
    border-color: #96dbfa;
  }
  .rc-slider-dot-reverse {
    margin-right: -4px;
  }
  .rc-slider-disabled {
    background-color: #e9e9e9;
  }
  .rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
  }
  .rc-slider-disabled .rc-slider-handle,
  .rc-slider-disabled .rc-slider-dot {
    background-color: #fff;
    border-color: #ccc;
    box-shadow: none;
    cursor: not-allowed;
  }
  .rc-slider-disabled .rc-slider-mark-text,
  .rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
  }
  .rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;
  }
  .rc-slider-vertical .rc-slider-rail {
    width: 4px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-track {
    bottom: 0;
    left: 5px;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-handle {
    margin-top: 0;
    margin-left: -5px;
    touch-action: pan-y;
  }
  .rc-slider-vertical .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-step {
    width: 4px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-dot {
    margin-left: -2px;
  }
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    display: block !important;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-leave {
    display: block !important;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
  .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    animation-name: rcSliderTooltipZoomDownIn;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    animation-name: rcSliderTooltipZoomDownOut;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    transform: scale(0, 0);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .rc-slider-tooltip-zoom-down-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @keyframes rcSliderTooltipZoomDownIn {
    0% {
      transform: scale(0, 0);
      transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      transform: scale(1, 1);
      transform-origin: 50% 100%;
    }
  }
  @keyframes rcSliderTooltipZoomDownOut {
    0% {
      transform: scale(1, 1);
      transform-origin: 50% 100%;
    }
    100% {
      transform: scale(0, 0);
      transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  .rc-slider-tooltip {
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip-hidden {
    display: none;
  }
  .rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
  }
  .rc-slider-tooltip-inner {
    min-width: 24px;
    height: 24px;
    padding: 6px 2px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    background-color: #6c6c6c;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
  }
  .rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    bottom: 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: #6c6c6c;
  }
`;

export const Slider = forwardRef<HTMLInputElement, SliderProps>(
  function Slider(props, ref) {
    return (
      <StyledSlider>
        <RcSlider ref={ref} {...props} />
      </StyledSlider>
    );
  }
);
