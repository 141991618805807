import React, {TextareaHTMLAttributes, forwardRef} from 'react';
import styled from 'styled-components';

type TextAreaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'size'>;

const StyledTextArea = styled.div`
  position: relative;
`;

const TextField = styled.textarea`
  ${p => p.theme.typography.body.md};
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.default};
  color: ${p => p.theme.colors.text.default};
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  resize: vertical;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px transparent;
  transition:
    background-color 80ms ease-in-out,
    border-color 80ms ease-in-out,
    color 80ms ease-in-out;

  /* TODO: Придумать что делать с этим */
  min-width: 120px;
  max-width: 400px;

  ::placeholder {
    font-weight: normal;
    color: ${p => p.theme.colors.text.subtle};
  }

  :focus {
    border-color: ${p => p.theme.colors.accent.emphasis};
    box-shadow: 0 0 0 2px ${p => p.theme.colors.accent.focus};
  }

  &[type='file'] {
    padding: 10px 16px;
  }

  &[disabled] {
    background-color: ${p => p.theme.colors.neutral.muted};
    border-color: ${p => p.theme.colors.neutral.muted};
    color: ${p => p.theme.colors.text.subtle};
  }
`;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, ref) {
    return (
      <StyledTextArea>
        <TextField {...props} ref={ref} />
      </StyledTextArea>
    );
  }
);
