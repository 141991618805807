import React, {ReactNode, useCallback, useState} from 'react';
import {Accept, useDropzone} from 'react-dropzone';
import {CloudArrowUp} from '@phosphor-icons/react';
import styled from 'styled-components';
import {File as FileType} from '@opeq-dev/openquiz-schema';

interface Callbacks {
  onProgress?: (percent: number) => void;
}

interface InputFileProps {
  type?: 'IMAGE' | 'VIDEO' | 'AUDIO';
  label?: ReactNode;
  fn: (file: File, callbacks?: Callbacks) => Promise<FileType>;
  onChange: (data: FileType) => void;
}

const StyledInputFile = styled.div`
  background-color: ${p => p.theme.colors.neutral.subtle};
  border: 1px solid ${p => p.theme.colors.border.default};
  display: inline-flex;
  align-items: center;
  border-radius: 12px;
  padding: 0 16px;
  gap: 8px;
  height: 40px;
  cursor: pointer;
  user-select: none;
  transition:
    background-color ease-in-out 80ms,
    border-color ease-in-out 80ms,
    color ease-in-out 80ms,
    transform ease-in-out 80ms;

  svg {
    flex-shrink: 0;
  }

  :hover {
    background-color: ${p => p.theme.colors.accent.subtle};
    border-color: ${p => p.theme.colors.accent.emphasis};
    color: ${p => p.theme.colors.accent.text};
  }

  :active {
    transform: scale(0.98);
  }
`;

const Label = styled.div`
  ${p => p.theme.typography.label.md};
`;

const acceptMap = new Map<InputFileProps['type'], Accept>([
  [undefined, {'image/*': [], 'video/*': [], 'audio/*': []}],
  ['IMAGE', {'image/*': []}],
  ['VIDEO', {'video/*': []}],
  ['AUDIO', {'audio/*': []}]
]);

export const InputFile = ({type, label = 'Загрузить', fn, onChange}: InputFileProps) => {
  const [progress, setProgress] = useState(0);

  const onDrop = useCallback(
    async (files: File[]) => {
      if (files.length === 0) return;
      const data = await fn(files[0], {onProgress: setProgress});
      onChange(data);
    },
    [setProgress, onChange, fn]
  );

  const {getRootProps, getInputProps} = useDropzone({
    accept: acceptMap.get(type),
    multiple: false,
    onDrop
  });

  return (
    <StyledInputFile {...getRootProps()}>
      <CloudArrowUp size={20} weight="bold" />
      <Label>
        {label}
        {progress > 0 && progress < 100 && <>&nbsp;({progress}%)</>}
      </Label>
      <input {...getInputProps()} />
    </StyledInputFile>
  );
};
