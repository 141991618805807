import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {getAnimationProps} from '@openquiz/quiz-ui';

interface RootProps {
  children: ReactNode;
}

const StyledRoot = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
`;

const Overlay = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  backdrop-filter: blur(16px);
`;

const Entry = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media only screen and (min-width: 320px) {
    padding: 8px;
  }

  @media only screen and (min-width: 1024px) {
    padding: 64px 40px;
  }
`;

const overlayMotionProps = getAnimationProps({
  initial: {opacity: 0},
  animate: {opacity: 1},
  exit: {opacity: 0}
});

const entryMotionProps = getAnimationProps({
  initial: {y: -4, scale: 0.98, opacity: 0},
  animate: {y: 0, scale: 1, opacity: 1},
  exit: {y: -4, scale: 0.98, opacity: 0}
});

export const Root = ({children}: RootProps) => (
  <StyledRoot>
    <Overlay {...overlayMotionProps} />
    <Entry {...entryMotionProps}>{children}</Entry>
  </StyledRoot>
);
