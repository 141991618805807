import React from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Button,
  FieldGroup,
  FieldRow,
  Title,
  TitleBackLink,
  useWizard
} from '@openquiz/quiz-ui';
import {getSearchString} from '@/features/wshop-search';

export const ChooseStep = () => {
  const navigate = useNavigate();
  const {onChangeStep} = useWizard();

  const onClickGo = () => {
    navigate(`/workshop/search?data=${getSearchString({sort: 'created_at'})}`);
  };

  const onClickDiscard = () => {
    onChangeStep('base');
  };

  return (
    <div>
      <TitleBackLink onClick={onClickDiscard}>Новая игра</TitleBackLink>
      <Title layoutId="title">OpenQuiz Workshop</Title>

      <FieldGroup size="lg">
        <p>
          Выбирайте любой пак из нашего каталога и нажимайте кнопку <b>Создать игру</b>.
        </p>
      </FieldGroup>

      <FieldGroup size="lg">
        <FieldRow>
          <Button onClick={onClickGo}>Выбрать пак</Button>
          <Button variant="secondary" onClick={onClickDiscard}>
            Назад
          </Button>
        </FieldRow>
      </FieldGroup>
    </div>
  );
};
