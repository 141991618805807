import React from 'react';
import styled from 'styled-components';
import {AnimatePresence, motion} from 'framer-motion';
import {User} from '@opeq-dev/openquiz-schema';
import {availablePatterns, getAnimationProps} from '@openquiz/quiz-ui';
import {useUserBanner} from '../../hooks';

interface UserBannerProps {
  user: User;
}

const StyledUserBanner = styled.div`
  position: relative;
  height: 100%;
  z-index: -1;
  pointer-events: none;
`;

const Pattern = styled(motion.div)<{$isReadable: boolean}>`
  width: 100%;
  height: 100%;
  filter: ${p => (p.$isReadable ? 'invert(0%)' : 'invert(100%)')};
`;

const patternBgAnimationProps = getAnimationProps({
  initial: {scale: 0.9, opacity: 0},
  animate: {scale: 1, opacity: 1},
  exit: {scale: 1.1, opacity: 0}
});

export const UserBanner = ({user}: UserBannerProps) => {
  const {patternKey, color, isReadable} = useUserBanner(user);
  const pattern = availablePatterns.get(patternKey);

  return (
    <StyledUserBanner style={{backgroundColor: color}}>
      <AnimatePresence initial={false} mode="popLayout">
        {pattern && (
          <Pattern
            {...patternBgAnimationProps}
            key={patternKey}
            $isReadable={isReadable}
            style={{
              backgroundImage: `url(${pattern.src})`,
              ...pattern.style
            }}
          />
        )}
      </AnimatePresence>
    </StyledUserBanner>
  );
};
