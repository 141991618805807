import React, {
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
  createElement
} from 'react';
import {IconProps} from '@phosphor-icons/react';
import styled, {css} from 'styled-components';

interface FieldSectionProps {
  children: ReactNode;
  label?: ReactNode;
  icon?: ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>;
  size?: 'sm' | 'md' | 'lg';
}

const StyledFieldSection = styled.div<{$size: FieldSectionProps['size']}>`
  ${p =>
    p.$size === 'sm' &&
    css`
      margin-bottom: 16px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      margin-bottom: 24px;
    `}

  ${p =>
    p.$size === 'lg' &&
    css`
      margin-bottom: 32px;
    `}

  :last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    display: flex;
    margin-right: 8px;
  }

  span {
    ${p => p.theme.typography.label.md};
    color: ${p => p.theme.colors.text.subtle};
    display: inline-flex;
    margin-right: 24px;
    flex-shrink: 0;
  }

  div {
    background-color: ${p => p.theme.colors.border.subtle};
    width: 100%;
    height: 1px;
    pointer-events: none;
  }
`;

export const FieldSection = ({children, label, icon, size = 'md'}: FieldSectionProps) => (
  <StyledFieldSection $size={size}>
    {label && (
      <Label>
        {icon && createElement(icon, {size: 20, weight: 'bold'})}
        <span>{label}</span>
        <div />
      </Label>
    )}

    <div>{children}</div>
  </StyledFieldSection>
);
