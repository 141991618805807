import React, {ReactElement} from 'react';
import {FormattedNumber} from 'react-intl';
import {AnimatePresence} from 'framer-motion';
import styled from 'styled-components';
import {CrownSimple, DotsThreeOutline} from '@phosphor-icons/react';
import {TeamRecord} from '@opeq-dev/openquiz-machine';
import {User} from '@opeq-dev/openquiz-schema';
import {
  Avatar,
  ButtonMore,
  Indicator,
  PopperMenu,
  SlotIndicator
} from '@openquiz/quiz-ui';
import {Entry} from './Entry';
import {Surface} from './Surface';

interface PlayerSlotProps {
  team: TeamRecord | undefined;
  user: User;
  indicator?: Indicator;
  active?: boolean;
  actionButton?: ReactElement;
  menu?: ReactElement;
  renderBanner: () => ReactElement;
  renderStream?: () => ReactElement;
}

const StyledPlayerSlot = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
`;

const IndicatorWrapper = styled.div`
  position: absolute;
  box-shadow: inset 0 0 0 5px ${p => p.theme.colors.canvas.default};
  border-radius: 12px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  pointer-events: none;
`;

const BannerWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
`;

const TopBar = styled.div`
  position: absolute;
  right: 8px;
  left: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`;

const Picture = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
`;

const Stream = styled(Picture)`
  bottom: 0;
`;

export const PlayerSlot = ({
  team,
  user,
  indicator,
  active = true,
  actionButton,
  menu,
  renderBanner,
  renderStream
}: PlayerSlotProps) => {
  return (
    <StyledPlayerSlot>
      <AnimatePresence>
        {indicator && (
          <IndicatorWrapper>
            <SlotIndicator key={indicator.type} indicator={indicator} />
          </IndicatorWrapper>
        )}
      </AnimatePresence>

      <BannerWrapper>{renderBanner()}</BannerWrapper>

      {renderStream ? (
        <Stream>{renderStream()}</Stream>
      ) : (
        <Picture>
          <Avatar user={user} size={80} stroked={true} />
        </Picture>
      )}

      <Entry>
        <Surface params={{hidden: !active}}>
          <span>{user.username}</span>
        </Surface>
        {team ? (
          <Surface params={{shrink: true}}>
            <FormattedNumber
              value={team.score}
              notation={team.score > 100000 ? 'compact' : 'standard'}
            />
          </Surface>
        ) : (
          <Surface>
            <CrownSimple size={20} weight="bold" />
          </Surface>
        )}
      </Entry>

      <TopBar>
        {actionButton}
        {menu && (
          <PopperMenu menu={menu} placement="bottom-end" distance={4}>
            <ButtonMore>
              <DotsThreeOutline size={20} weight="fill" />
            </ButtonMore>
          </PopperMenu>
        )}
      </TopBar>
    </StyledPlayerSlot>
  );
};
