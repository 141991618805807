import React from 'react';
import {GameSidebarLink, useAllGamesQuery} from '@/features/game';

export const GamesList = () => {
  const {data: games = []} = useAllGamesQuery({active: true});

  return (
    <>
      {games.map(game => (
        <GameSidebarLink key={`game-sidebar-link-${game.uuid}`} game={game} />
      ))}
    </>
  );
};
