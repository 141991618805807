import {useEffect} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useAuthStore} from '@/features/auth';

export const useAuthListener = () => {
  const ql = useQueryClient();
  const {token} = useAuthStore();
  useEffect(() => {
    if (!token) {
      ql.setQueryData(['profile'], () => null);
    } else {
      ql.refetchQueries({queryKey: ['profile']});
    }
  }, [token, ql]);
};
