import React, {ReactNode} from 'react';
import {motion} from 'framer-motion';
import styled from 'styled-components';
import {getAnimationProps} from '@openquiz/quiz-ui';

interface StepProps {
  children: ReactNode;
}

const StyledStep = styled(motion.div)`
  user-select: none;
`;

const stepMotionProps = getAnimationProps(
  {
    initial: {height: 0, y: -124, opacity: 0},
    animate: {height: 'auto', y: 0, opacity: 1},
    exit: {height: 0, y: -32, opacity: 0}
  },
  {duration: 0.2}
);

export const Step = ({children}: StepProps) => (
  <StyledStep {...stepMotionProps}>{children}</StyledStep>
);
