import {createQueryKeys} from '@lukemorales/query-key-factory';
import {
  ContestListFilter,
  fetchContestDetail,
  fetchContestList
} from '@/features/contest';

export const contestQueryKeys = createQueryKeys('contest', {
  list: (filter: ContestListFilter) => ({
    queryKey: [{filter}],
    queryFn: () => fetchContestList(filter)
  }),
  detail: (uuid: string) => ({
    queryKey: [uuid],
    queryFn: () => fetchContestDetail(uuid)
  })
});
