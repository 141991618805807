import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {List, X} from '@phosphor-icons/react';
import {Logotype} from '@openquiz/quiz-ui';

interface MobileHeaderProps {
  open: boolean;
  onToggle: () => void;
}

const StyledMobileHeader = styled.div`
  background-color: ${p => p.theme.colors.canvas.default};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  padding: 18px 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  pointer-events: all;
  z-index: 100;

  a {
    display: inline-flex;
  }
`;

export const MobileHeader = ({open, onToggle}: MobileHeaderProps) => (
  <StyledMobileHeader>
    {open ? (
      <X size={24} weight="bold" onClick={onToggle} />
    ) : (
      <List size={24} weight="bold" onClick={onToggle} />
    )}

    <Link to="/">
      <Logotype />
    </Link>
  </StyledMobileHeader>
);
