import React from 'react';
import {Modal, ModalProps} from '@openquiz/quiz-modal';
import {Title} from '@openquiz/quiz-ui';
import {Speech, fetchSpeechCreate} from '@/features/speech';
import {SpeechForm, SpeechFormData} from '@/features/wshop-editor';

interface StepSpeechModalProps extends ModalProps {
  data: SpeechFormData;
  onSpeechReady: (speech: Speech, transcription: string | null) => void;
}

export const StepSpeechModal = ({
  data,
  onSpeechReady,
  onCancel
}: StepSpeechModalProps) => {
  const onFormSubmit = async (data: SpeechFormData) => {
    const speech = await fetchSpeechCreate({text: data.transcription || data.text});
    onSpeechReady(speech, data.transcription);
    onCancel();
  };

  return (
    <Modal size="md" onCancel={onCancel}>
      <Title>Озвучить шаг</Title>
      <SpeechForm data={data} onSubmit={onFormSubmit} onCancel={onCancel} />
    </Modal>
  );
};
