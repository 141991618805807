import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Callout, Modal, ModalCloseCallback, Title} from '@openquiz/quiz-ui';
import {useCurrentUserQuery} from '@/features/user';
import {UploadFileField} from '@/features/game';
import {fetchPackage} from '@/features/wshop-packages';

interface UploadPackageModalProps {
  onClose: ModalCloseCallback;
}

export const UploadPackageModal = ({onClose}: UploadPackageModalProps) => {
  const navigate = useNavigate();
  const [isNotAuthor, setIsNotAuthor] = useState(true);
  const {data: user} = useCurrentUserQuery();

  const onCompleteUpload = async (value: string) => {
    const uploadedPkg = await fetchPackage(value.split('/')[0]);

    if (!uploadedPkg) return;

    if (uploadedPkg.userUuid === user?.uuid) {
      navigate(`/editor/${value.split('/')[0]}`);
    } else {
      setIsNotAuthor(false);
    }
  };

  return (
    <Modal onClose={onClose}>
      <Title>Загрузка пака</Title>

      {isNotAuthor && (
        <UploadFileField onComplete={onCompleteUpload} onDiscard={onClose} />
      )}

      {!isNotAuthor && (
        <Callout status="attention">
          <p>Этот пак уже был загружен другим пользователем.</p>
          <p>
            Если вы автор этого пака, напишите администрации&nbsp;
            <a
              href="https://discord.com/invite/k56easeP5H"
              target="_blank"
              rel="noreferrer">
              Discord сервера
            </a>
            .
          </p>
        </Callout>
      )}
    </Modal>
  );
};
