import React, {
  FunctionComponent,
  ReactNode,
  SVGProps,
  createElement,
  useMemo,
  useState
} from 'react';
import styled, {css} from 'styled-components';
import Discord from './discord.svg?react';
import Google from './google.svg?react';
import Twitch from './twitch.svg?react';
import Vk from './vk.svg?react';

type Provider = 'discord' | 'google' | 'twitch' | 'vk';

interface OAuthButtonProps {
  children?: ReactNode;
  provider: Provider;
  onClick: (provide: Provider) => void;
}

const StyledOAuthButton = styled.button<{$provider: Provider}>`
  ${p => p.theme.typography.label.md};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  transition:
    background-color 80ms ease-in-out,
    border-color 80ms ease-in-out,
    color 80ms ease-in-out,
    transform 80ms ease-in-out;

  ${p =>
    p.$provider === 'discord'
      ? css`
          background-color: #5865f2;
          color: ${p => p.theme.colors.text.onEmphasis};
          width: 100%;
          height: 44px;

          &:hover {
            transform: scale(1.02);
          }
        `
      : css`
          background-color: ${p => p.theme.colors.neutral.subtle};
          color: ${p => p.theme.colors.text.default};
          width: 50px;
          height: 50px;

          &:hover {
            background-color: ${p => p.theme.colors.neutral.emphasisPlus};
            color: ${p => p.theme.colors.text.onEmphasis};
          }
        `}

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }

  span {
    display: flex;
    margin-left: 10px;
  }

  svg {
    display: flex;
    width: 20px;
    height: 20px;
    color: inherit;
  }
`;

const providerMap = new Map<Provider, FunctionComponent<SVGProps<SVGSVGElement>>>([
  ['discord', Discord],
  ['vk', Vk],
  ['google', Google],
  ['twitch', Twitch]
]);

export const OAuthButton = ({children, provider, onClick}: OAuthButtonProps) => {
  const [idle, setIdle] = useState(false);
  const icon = useMemo(() => providerMap.get(provider), [provider]);

  const onClickButton = () => {
    setIdle(true);
    onClick(provider);
  };

  return (
    <StyledOAuthButton
      type="button"
      $provider={provider}
      disabled={idle}
      onClick={onClickButton}>
      {icon && createElement(icon)}
      {children && <span>{children}</span>}
    </StyledOAuthButton>
  );
};
