import React, {forwardRef} from 'react';
import {useIntl} from 'react-intl';
import {DayPicker, SelectSingleEventHandler} from 'react-day-picker';
import styled from 'styled-components';
import {CalendarBlank} from '@phosphor-icons/react';
import {PopperMenu} from '@openquiz/quiz-ui';
import {Input} from '../Input';
import {inputStyle} from './inputStyle';

interface InputDateProps {
  value: Date | string;
  onChange: (date: Date) => void;
}

const StyledDatePicker = styled(DayPicker)`
  ${inputStyle};
`;

const Overlay = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.floating}, ${p => p.theme.shadow.lg};
  border-radius: 12px;
`;

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(function InputDate(
  {value, onChange, ...rest},
  ref
) {
  const intl = useIntl();

  const onChangeDay: SelectSingleEventHandler = (day: Date | undefined) => {
    if (!day) return;
    onChange(day);
  };

  return (
    <PopperMenu
      placement="bottom-start"
      menu={
        <Overlay>
          <StyledDatePicker
            mode="single"
            selected={new Date(value)}
            defaultMonth={new Date(value)}
            weekStartsOn={1}
            onSelect={onChangeDay}
          />
        </Overlay>
      }>
      <Input
        {...rest}
        ref={ref}
        value={intl.formatDate(value, {dateStyle: 'short'})}
        extraLeft={<CalendarBlank size={20} weight="duotone" />}
        placeholder="Pick date"
        readOnly={true}
      />
    </PopperMenu>
  );
});
