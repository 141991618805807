import React, {useMemo} from 'react';
import {TooltipProps} from 'recharts';
import styled from 'styled-components';

type TooltipCustomProps = TooltipProps<number, string>;

const StyledTooltipCustom = styled.div`
  ${p => p.theme.typography.body.sm};
  background-color: ${p => p.theme.colors.tooltip.default};
  color: ${p => p.theme.colors.text.onEmphasis};
  box-shadow: ${p => p.theme.shadow.floating};
  border-radius: 8px;
  text-align: left;
  padding: 8px 12px;
  max-width: 320px;

  small {
    opacity: 0.5;
  }
`;

const Row = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;

  :last-child {
    margin-bottom: 0;
  }
`;

const Dot = styled.div`
  border-radius: 4px;
  width: 16px;
  height: 16px;
`;

export const TooltipCustom = ({payload}: TooltipCustomProps) => {
  const reversedPayload = useMemo(() => [...(payload || [])].reverse(), [payload]);
  return (
    <StyledTooltipCustom>
      {reversedPayload.map(line => (
        <Row key={`chat-tooltip-${line.dataKey}`}>
          <Dot style={{backgroundColor: line.stroke}} />
          <div>
            {line.name}: {line.value}
          </div>
        </Row>
      ))}
    </StyledTooltipCustom>
  );
};
