import React, {ReactNode, createElement, forwardRef} from 'react';
import {NavLink, NavLinkProps, useMatch} from 'react-router-dom';
import {motion} from 'framer-motion';
import styled from 'styled-components';
import {PhosphorIcon} from '../../../types';

interface TabProps extends NavLinkProps {
  children?: ReactNode;
  icon?: PhosphorIcon | string;
}

const StyledTab = styled(NavLink)`
  ${p => p.theme.typography.caption.md};
  color: ${p => p.theme.colors.text.subtle};
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 0 8px 0;
  text-align: center;
  cursor: pointer;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out;

  span {
    position: relative;
    z-index: 1;
  }

  &:hover,
  &.active {
    color: ${p => p.theme.colors.accent.text};
  }

  &.active {
    font-weight: 500;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const Icon = styled.div`
  display: flex;
  font-size: 20px;
  width: 20px;
  height: 20px;
`;

const Highlight = styled(motion.div)`
  background-color: ${p => p.theme.colors.accent.text};
  border-radius: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
`;

export const Tab = forwardRef<HTMLAnchorElement, TabProps>(function Tab(
  {children, icon, ...rest},
  ref
) {
  const match = useMatch(rest.end ? `${rest.to}` : (`${rest.to}*` as string));

  return (
    <StyledTab ref={ref} {...rest}>
      {icon && (
        <Icon>
          {typeof icon === 'string'
            ? icon
            : createElement(icon, {size: 20, weight: match ? 'fill' : 'regular'})}
        </Icon>
      )}
      {children && <span>{children}</span>}
      {match && <Highlight layoutId="tab-highlight" />}
    </StyledTab>
  );
});
