import React from 'react';
import {Link} from 'react-router-dom';
import {FormattedDate, FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {FieldRow, FieldSection} from '@openquiz/quiz-ui';
import {generateUserPath} from '@/features/user';
import {
  AuthorChip,
  DataGroup,
  Meta,
  PackageFull,
  Rating,
  RoundsList,
  SizeBar
} from '@/features/wshop-packages';
import {PageTitle} from '@/components';

interface PackageContentProps {
  pkg: PackageFull;
}

const StyledPackageContent = styled.div``;

const Description = styled.div`
  ${p => p.theme.typography.body.md};
  margin-top: -8px;
  max-width: 480px;
  white-space: pre-wrap;
`;

export const PackageContent = ({pkg}: PackageContentProps) => (
  <StyledPackageContent>
    <PageTitle to={{href: '/'}} title={pkg.name} />

    {pkg.description && (
      <FieldSection>
        <Description>{pkg.description}</Description>
      </FieldSection>
    )}

    <FieldSection>
      <FieldRow size="lg">
        <DataGroup
          label={
            <FormattedMessage
              id="workshop.rating.count"
              values={{count: pkg._count.ratings}}
            />
          }>
          <Rating rating={{value: pkg.rating, count: pkg._count.ratings}} />
        </DataGroup>
        <DataGroup label="Автор">
          <Link
            style={{color: 'inherit'}}
            to={generateUserPath(pkg.user.username, pkg.user.discriminator)}>
            <AuthorChip user={pkg.user} />
          </Link>
        </DataGroup>
        <DataGroup label="Дата публикации">
          <FormattedDate value={pkg.createdAt} dateStyle="long" />
        </DataGroup>
      </FieldRow>
    </FieldSection>

    <FieldSection>
      <Meta pkg={pkg} hasLinks={true} hasTags={true} />
    </FieldSection>

    <FieldSection>
      <FieldSection size="sm">
        <SizeBar pkg={pkg} />
      </FieldSection>
      <FieldSection>
        <RoundsList pkg={pkg} />
      </FieldSection>
    </FieldSection>
  </StyledPackageContent>
);
