import React, {useCallback, useEffect, useId, useMemo, useRef, useState} from 'react';
import {useInfiniteQuery} from '@tanstack/react-query';
import {ArrowLineDown} from '@phosphor-icons/react';
import {Button, FieldRow, Grid} from '@openquiz/quiz-ui';
import {PackageCard, PackageCardPlaceholder} from '@/features/workshop';
import {FetchPackagesParams, PackageFull, fetchPackages} from '@/features/wshop-packages';
import {PaginatedQuery} from '@/types';

interface InfinityPackagesGridProps {
  params: FetchPackagesParams;
  infinity?: boolean;
}

export const InfinityPackagesGrid = ({
  params,
  infinity = true
}: InfinityPackagesGridProps) => {
  const id = useId();
  const spinnerMoreRef = useRef<HTMLDivElement>(null);
  const [infinityMode, setInfinityMode] = useState(infinity);

  const {
    data: allPackages,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useInfiniteQuery<PaginatedQuery<PackageFull>>({
    queryKey: [{params}],
    queryFn: ({pageParam}) => fetchPackages({...params, cursor: pageParam as string}),
    initialPageParam: null,
    refetchOnMount: false,
    getNextPageParam: (lastPage: {nextCursor: number}) => lastPage.nextCursor
  });

  const count = useMemo(
    () => allPackages?.pages.map(p => p.data).flat().length,
    [allPackages?.pages]
  );

  const onNextPage = () => {
    setInfinityMode(true);
    fetchNextPage();
  };

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [target] = entries;
      if (!infinityMode) return;
      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        onNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  useEffect(() => {
    if (!spinnerMoreRef.current) return;

    const observer = new IntersectionObserver(handleObserver, {threshold: 0});
    observer.observe(spinnerMoreRef.current);

    return () => {
      if (!spinnerMoreRef.current) return;
      observer.unobserve(spinnerMoreRef.current);
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, spinnerMoreRef]);

  if (!count)
    return (
      <Grid cols={{sm: 1, md: 3, lg: 3}}>
        <PackageCardPlaceholder />
        <PackageCardPlaceholder />
        <PackageCardPlaceholder />
      </Grid>
    );

  return (
    <>
      {allPackages?.pages.map((page, i) => (
        <div
          key={`${id}-${page.nextCursor}`}
          style={{position: 'relative', marginBottom: 24}}>
          {hasNextPage && !isFetchingNextPage && allPackages?.pages?.length - 1 === i && (
            <div
              ref={spinnerMoreRef}
              style={{position: 'absolute', top: -64, height: 64, pointerEvents: 'none'}}
            />
          )}

          <Grid gap={24} cols={{sm: 1, md: 3, lg: 3}}>
            {page.data.map(pkg => (
              <PackageCard key={`pkg-${pkg.uuid}`} pkg={pkg} />
            ))}
          </Grid>
        </div>
      ))}

      {!infinityMode && hasNextPage && (
        <FieldRow justify="center">
          <Button variant="link" fetching={isFetchingNextPage} onClick={onNextPage}>
            <ArrowLineDown size={16} weight="bold" />
            <span>Загрузить еще</span>
          </Button>
        </FieldRow>
      )}
    </>
  );
};
