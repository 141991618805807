import React from 'react';
import styled from 'styled-components';
import {PackageFull} from '@/features/wshop-packages';

interface BetSnippetProps {
  pkg: PackageFull;
  match: string;
  payload: {[key: string]: string};
}

const StyledBetSnippet = styled.div`
  span {
    text-decoration: underline;
  }
`;

export const BetSnippet = ({match, payload}: BetSnippetProps) => (
  <StyledBetSnippet>
    <span>{payload[match]}</span>
  </StyledBetSnippet>
);
