import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {
  Contest,
  File as FileType,
  WorkshopCategory,
  WorkshopTag
} from '@opeq-dev/openquiz-schema';
import {
  Button,
  DropzoneFile,
  FieldGroup,
  FieldRow,
  FormSection,
  Input,
  PreviewFile,
  Select,
  Tag,
  TextArea,
  TipCircle,
  ErrorMessage as UiErrorMessage,
  requirements
} from '@openquiz/quiz-ui';
import {useTagsQuery} from '@/features/workshop';
import {useWorkshopCategories} from '@/features/wshop-categories';
import {
  PackageCreateData,
  PackageFull,
  PackageUpdateData
} from '@/features/wshop-packages';
import {fetchUploadFile} from '@/api/file';
import {formatError} from '@/libs/validation';
import {ContestCheckbox, useContestList} from '@/features/contest';

type PackageFormData = PackageCreateData | PackageUpdateData;

interface InformationFormProps {
  pkg?: PackageFull;
  onSubmit: (data: PackageFormData) => Promise<void>;
  onDelete?: () => Promise<void>;
}

export const InformationForm = ({pkg, onSubmit, onDelete}: InformationFormProps) => {
  const {data: contestsArr = []} = useContestList({jury: false});
  const {data: categoriesArr = []} = useWorkshopCategories();
  const {data: tagsArr = []} = useTagsQuery();

  const {
    formState: {isSubmitting, errors},
    handleSubmit,
    register,
    watch,
    setValue,
    setError
  } = useForm<PackageFormData>({
    defaultValues: {
      uuid: '',
      description: '',
      difficulty: null,
      files: [],
      categories: [],
      tags: [],
      contests: [],
      ...pkg
    }
  });

  const {files, categories, tags, contests} = watch();
  const cover = files[0] ? files[0].file : null;

  useEffect(() => {
    if (contestsArr.length && !contests.length && !pkg) {
      setValue('contests', [{contestUuid: contestsArr[0].uuid, packageUuid: ''}]);
    }
  }, [contestsArr, pkg]);

  const onFormSubmit = async (data: PackageFormData) => {
    try {
      await onSubmit(data);
    } catch (err) {
      formatError<PackageFormData>(err).forEach(error => {
        setError(error.key, {type: 'custom', message: error.message});
      });
    }
  };

  const onChangeFile = (file: FileType) => {
    setValue('files', [{file, fileUuid: file.uuid}]);
  };

  const onDeleteFile = () => {
    setValue('files', []);
  };

  const onChangeContest = (contest: Contest) => {
    if (contests.some(c => c.contestUuid === contest.uuid)) {
      setValue('contests', []);
    } else {
      setValue('contests', [{contestUuid: contest.uuid, packageUuid: ''}]);
    }
  };

  const onChangeCategory = (category: WorkshopCategory) => {
    if (categories.some(c => c.categoryUuid === category.uuid)) {
      setValue(
        'categories',
        categories.filter(c => c.categoryUuid !== category.uuid)
      );
      return;
    }

    setValue('categories', [
      ...categories,
      {
        categoryUuid: category.uuid,
        packageUuid: pkg?.uuid ?? '',
        category
      }
    ]);
  };

  const onChangeTag = (tag: WorkshopTag) => {
    if (tags.some(t => t.tagUuid === tag.uuid)) {
      setValue(
        'tags',
        tags.filter(t => t.tagUuid !== tag.uuid)
      );
      return;
    }

    setValue('tags', [
      ...tags,
      {
        tagUuid: tag.uuid,
        packageUuid: pkg?.uuid ?? '',
        tag
      }
    ]);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <FormSection label="Оформление">
        <FieldGroup
          label={
            <FieldRow size="xs">
              <span>Обложка</span>
              <TipCircle message={<p>Идеальный размер обложки 600x760.</p>} />
            </FieldRow>
          }>
          <FieldGroup size="sm">
            {cover ? (
              <PreviewFile type="IMAGE" src={cover?.src} onDelete={onDeleteFile} />
            ) : (
              <DropzoneFile
                requirements={requirements['IMAGE']}
                fn={async (file, onProgress) =>
                  await fetchUploadFile(file, {folder: 'workshop/covers'}, onProgress)
                }
                onChange={onChangeFile}
              />
            )}
          </FieldGroup>
        </FieldGroup>
      </FormSection>

      <FormSection label="Информация">
        <FieldGroup size="lg">
          <FieldGroup label="Название">
            <Input
              {...register('name', {
                required: 'Введите название',
                minLength: {value: 3, message: 'Минимальная длинна 3 символа.'},
                maxLength: {value: 64, message: 'Максимальная длинна 64 символа.'}
              })}
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label="Описание">
            <TextArea
              {...register('description', {
                maxLength: {value: 512, message: 'Максимальная длинна 512 символов.'}
              })}
              placeholder="Краткое описание пака."
              rows={3}
            />
          </FieldGroup>
        </FieldGroup>
      </FormSection>

      {!!contestsArr.length && (
        <FormSection label="Конкурсы">
          {contestsArr.map(contest => (
            <ContestCheckbox
              key={`contest-checkbox-${contest.uuid}`}
              contest={contest}
              selected={contests.some(c => c.contestUuid === contest.uuid)}
              onChange={onChangeContest}
            />
          ))}
        </FormSection>
      )}

      <FormSection label="Мета">
        <FieldGroup label="Сложность">
          <Select {...register('difficulty')}>
            <option value="">Не указанна</option>
            <option value="easy">Легко</option>
            <option value="normal">Нормально</option>
            <option value="hard">Сложно</option>
          </Select>
        </FieldGroup>

        <FieldGroup
          label={
            <FieldRow size="xs">
              <span>Категории</span>
              <TipCircle
                message={
                  <>
                    <p>Укажите до 3-ех основных категорий пака.</p>
                    <p>
                      Если вы не можете выбрать основные категории, то оставляйте без
                      категорий.
                    </p>
                  </>
                }
                size="lg"
              />
            </FieldRow>
          }>
          <FieldRow size="xs">
            {categoriesArr.map((category, index) => {
              const selected = categories.some(c => c.categoryUuid === category.uuid);
              return (
                <Tag
                  key={category.uuid}
                  index={index}
                  selected={selected}
                  disabled={!selected && categories.length >= 3}
                  onClick={() => onChangeCategory(category)}>
                  {category.emoji}&nbsp;
                  {category.name}
                </Tag>
              );
            })}
          </FieldRow>
        </FieldGroup>

        <FieldGroup label="Теги">
          <FieldRow size="xs">
            {tagsArr.map((tag, index) => {
              const selected = tags.some(t => t.tagUuid === tag.uuid);
              return (
                <Tag
                  key={tag.uuid}
                  index={index}
                  selected={selected}
                  disabled={!selected && tags.length >= 6}
                  onClick={() => onChangeTag(tag)}>
                  {tag.name}
                </Tag>
              );
            })}
          </FieldRow>
        </FieldGroup>
      </FormSection>

      <FormSection>
        <FieldRow size="md">
          <Button fetching={isSubmitting} type="submit">
            Сохранить
          </Button>
          {!!pkg && onDelete && (
            <Button fetching={isSubmitting} variant="danger" onClick={onDelete}>
              Удалить пак
            </Button>
          )}
        </FieldRow>
      </FormSection>
    </form>
  );
};
