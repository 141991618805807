import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {DotsLoader, Grid} from '@openquiz/quiz-ui';
import {SortableList} from '@/features/workshop';
import {
  QuestionCard,
  QuestionCreateButton,
  getLastItem,
  getPredictedPrice,
  useEditorContext,
  useQuestionUpdateMutate,
  useQuestionsQuery,
  useRoundQuery
} from '@/features/wshop-editor';

interface QuestionsGridProps {
  themeUuid: string;
  packageUuid: string;
  versionUuid: string;
}

const StyledQuestionsGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
`;

export const QuestionsGrid = ({themeUuid}: QuestionsGridProps) => {
  const {state} = useLocation();
  const {packageUuid, roundUuid, versionUuid} = useEditorContext();
  const {mutate} = useQuestionUpdateMutate();
  const {data: questions = [], isLoading} = useQuestionsQuery({
    themeUuid,
    packageUuid
  });

  const {data: round = {type: 'BASIC'}, isLoading: isLoadingRound} = useRoundQuery({
    uuid: roundUuid,
    packageUuid,
    versionUuid
  });

  const sortedQuestions = useMemo(
    () => questions.sort((a, b) => a.position - b.position),
    [questions]
  );

  const canAddQuestion =
    round.type === 'FINAL' ? questions.length === 0 : questions.length < 10;

  const nextPosition = getLastItem(sortedQuestions.map(q => q.position)) + 1;
  const nextPrice = getPredictedPrice(sortedQuestions.map(q => q.price));

  const onDragEnd = (items: string[]) => {
    items.forEach((uuid, position) => {
      const question = questions.find(q => q.uuid === uuid);
      if (!question) return;
      mutate({data: {...question, position}, packageUuid});
    });
  };

  if (isLoading) {
    return (
      <StyledQuestionsGrid>
        <DotsLoader />
      </StyledQuestionsGrid>
    );
  }

  return (
    <StyledQuestionsGrid>
      <Grid
        cols={{
          sm: sortedQuestions.length + (canAddQuestion ? 1 : 0),
          md: sortedQuestions.length + (canAddQuestion ? 1 : 0),
          lg: sortedQuestions.length + (canAddQuestion ? 1 : 0)
        }}
        gap={8}>
        <SortableList
          items={sortedQuestions.map(t => t.uuid)}
          strategy="horizontal"
          onDragEnd={onDragEnd}>
          {sortedQuestions.map(question => (
            <QuestionCard
              key={`question-card-${question.uuid}`}
              question={question}
              packageUuid={packageUuid}
              roundUuid={roundUuid}
              versionUuid={versionUuid}
              themeUuid={themeUuid}
              highlighted={state?.highlights === question.uuid}
            />
          ))}
        </SortableList>

        {!isLoadingRound && canAddQuestion && (
          <QuestionCreateButton
            nextPosition={nextPosition}
            nextPrice={nextPrice}
            packageUuid={packageUuid}
            versionUuid={versionUuid}
            roundUuid={roundUuid}
            themeUuid={themeUuid}
          />
        )}
      </Grid>
    </StyledQuestionsGrid>
  );
};
