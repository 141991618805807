import React, {memo, useEffect, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';
import {AnimatePresence, motion} from 'framer-motion';
import {ModalType} from './ModalContext';

interface ModalRootProps {
  /**
   * Map of modal instances associated by unique ids.
   */
  modals: Record<string, ModalType>;
}

const StyledModalRoot = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  overflow: auto;
  backdrop-filter: blur(64px);
`;

const Entry = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media only screen and (min-width: 320px) {
    padding: 64px 4px;
  }

  @media only screen and (min-width: 1024px) {
    padding: 64px 40px;
  }
`;

export const ModalRoot = memo(function memoModalRoot({modals}: ModalRootProps) {
  const [mountNode, setMountNode] = useState<Element | undefined>(undefined);

  useEffect(() => setMountNode(document.body), [setMountNode]);

  const visibleModalKey = useMemo(
    () => Object.keys(modals)[Object.keys(modals).length - 1],
    [modals]
  );

  if (!mountNode) return null;

  return createPortal(
    <AnimatePresence>
      {visibleModalKey && (
        <StyledModalRoot
          transition={{ease: 'linear', duration: 0.1}}
          initial={{opacity: 0, backgroundColor: 'rgba(0, 0, 0, 0)'}}
          animate={{opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
          exit={{opacity: 0, backgroundColor: 'rgba(0, 0, 0, 0)'}}>
          {Object.keys(modals)
            .filter(key => key.toString() === visibleModalKey)
            .map(key => (
              <Entry
                key={key}
                transition={{ease: 'linear', duration: 0.1}}
                animate={{opacity: 1, y: 0}}
                initial={{opacity: 0, y: -8}}
                exit={{opacity: 0, y: -8}}>
                {modals[key]({})}
              </Entry>
            ))}
        </StyledModalRoot>
      )}
    </AnimatePresence>,
    mountNode
  );
});
