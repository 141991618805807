import {getPercentFrom} from '@openquiz/quiz-ui';
import {MachineTimer} from '@opeq-dev/openquiz-machine';

/**
 *
 * @param timer
 * @returns
 */
export const useTimerProgress = (timer: MachineTimer | undefined): number => {
  if (!timer || timer.current >= timer.duration) {
    return 0;
  }

  return getPercentFrom(timer.current, timer.duration);
};
