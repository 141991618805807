import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';

interface SurfaceParams {
  shrink: boolean;
  hidden: boolean;
}

interface SurfaceProps {
  children: ReactNode;
  params?: Partial<SurfaceParams>;
}

const StyledSurface = styled.div<{$params: SurfaceProps['params']}>`
  ${p => p.theme.typography.body.sm};
  background-color: rgba(0, 0, 0, 0.3);
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 8px;
  padding: 4px 8px;
  display: inline-flex;
  overflow: hidden;
  backdrop-filter: blur(16px);
  transition:
    transform 80ms ease-in-out,
    opacity 80ms ease-in-out;

  ${p =>
    p.$params?.shrink &&
    css`
      flex-shrink: 0;
    `}

  ${p =>
    p.$params?.hidden &&
    css`
      transform: translateY(4px);
      opacity: 0;
    `}

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  & svg {
    display: flex;
  }
`;

const defaultParams: SurfaceParams = {
  shrink: false,
  hidden: false
};

export const Surface = ({children, params: _params}: SurfaceProps) => {
  const params = {defaultParams, ..._params};
  return (
    <StyledSurface $params={params}>
      <span>{children}</span>
    </StyledSurface>
  );
};
