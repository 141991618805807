import React, {useLayoutEffect, useMemo, useRef} from 'react';
import {FormattedDate} from 'react-intl';
import {Link} from 'react-router-dom';
import styled, {css, keyframes} from 'styled-components';
import {Contest} from '@opeq-dev/openquiz-schema';
import {genSpiralPattern, getSeedRandomNumber} from '@openquiz/quiz-ui';

interface ContestSlideProps {
  contest: Contest;
  to: string;
}

const patternAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledContestSlide = styled.div`
  color: ${p => p.theme.colors.text.onEmphasis};
  padding: 48px 64px;
  height: 320px;
  overflow: hidden;

  @media only screen and (min-width: 320px) {
    border-radius: 0;
    padding: 24px 24px 40px 24px;
  }

  @media only screen and (min-width: 1024px) {
    border-radius: 20px;
    padding: 40px 64px;
  }
`;

const Pattern = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 320px;
  z-index: 0;
  user-select: none;
  transition: background-color ease-in-out 120ms;
  animation: ${patternAnimation} ease-in-out 120ms forwards;

  svg {
    fill: rgba(255, 255, 255, 0.15);
  }
`;

const Name = styled.div`
  ${p => p.theme.typography.heading.h1};
`;

const ActiveAt = styled.div<{$hasEmoji: boolean}>`
  margin-bottom: 16px;
  opacity: 0.5;
  font-size: 16px;
  line-height: 24px;

  ${p =>
    p.$hasEmoji &&
    css`
      padding-left: 35px;
    `}

  &:last-child {
    margin-bottom: 0;
  }
`;

const Desc = styled.div`
  font-size: 16px;
  line-height: 24px;
  max-width: 480px;
  margin-bottom: 16px;
`;

const Prizes = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
  font-size: 16px;
  line-height: 24px;
  max-width: 480px;
`;

const PrizeLabel = styled.div`
  border-bottom: 2px solid red;
`;

const More = styled(Link)`
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 8px 16px;
  border-radius: 12px;
  display: inline-flex;
  cursor: pointer;
  backdrop-filter: blur(8px);

  &:hover {
    filter: invert(100%);
  }
`;

export const ContestSlide = ({contest, to}: ContestSlideProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const hasEmoji = useMemo(() => /\p{Emoji}/u.test(contest.name), [contest.name]);

  useLayoutEffect(() => {
    if (!ref.current) return;
    const rect = ref.current.getBoundingClientRect();
    const svg = genSpiralPattern(rect.width, 320, {
      center: {x: rect.width / 2, y: rect.height * -1},
      turnAngle: getSeedRandomNumber(contest.style.seed, 11, 14),
      turnRadius: getSeedRandomNumber(contest.style.seed, 2, 3),
      size: 1,
      seed: contest.style.seed
    });
    ref.current?.replaceChildren(svg);
  }, [contest]);

  return (
    <StyledContestSlide style={{backgroundColor: contest.style.color}}>
      <Pattern ref={ref} key={`pattern-${contest.style.seed}`} />
      <Name>{contest.name}</Name>
      <ActiveAt $hasEmoji={hasEmoji}>
        <FormattedDate value={contest.startAt} dateStyle="short" />
        <span>&nbsp;–&nbsp;</span>
        <FormattedDate value={contest.endAt} dateStyle="short" />
      </ActiveAt>
      <Desc>{contest.description}</Desc>
      <Prizes>
        <PrizeLabel>Призы:</PrizeLabel>
        {contest.prizes.split('\n').map((prize, index) => (
          <div key={`prize-${index}`}>{prize}</div>
        ))}
      </Prizes>
      <More to={to}>Читать подробнее</More>
    </StyledContestSlide>
  );
};
