import React from 'react';
import styled, {css, keyframes} from 'styled-components';

interface DotsLoaderProps {
  color?: 'neutral' | 'accent' | 'onEmphasis';
}

const getDotKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
`;

const StyledDotsLoader = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Dot = styled.div<{$index: number; $color: DotsLoaderProps['color']}>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: ${getDotKeyframes} ease-in-out 1s infinite ${p => p.$index * 250}ms;

  ${p =>
    p.$color === 'neutral' &&
    css`
      background-color: ${p.theme.colors.neutral.emphasis};
    `}

  ${p =>
    p.$color === 'accent' &&
    css`
      background-color: ${p.theme.colors.accent.emphasis};
    `}

  ${p =>
    p.$color === 'onEmphasis' &&
    css`
      background-color: ${p.theme.colors.text.onEmphasis};
    `}
`;

export const DotsLoader = ({color = 'neutral'}: DotsLoaderProps) => {
  return (
    <StyledDotsLoader>
      <Dot $index={0} $color={color} />
      <Dot $index={1} $color={color} />
      <Dot $index={2} $color={color} />
    </StyledDotsLoader>
  );
};
