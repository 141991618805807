import {PackageRound} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export interface FetchRoundUpdateParams {
  data: PackageRound;
  packageUuid: string;
  versionUuid: string;
}

/**
 *
 * @param params
 */
export const fetchRoundUpdate = async (params: FetchRoundUpdateParams) => {
  const {data} = await getPrivateAxios().patch<PackageRound>(
    `/workshop/packages/${params.packageUuid}/rounds/${params.data.uuid}`,
    params.data
  );
  return data;
};
