import React, {createElement, useMemo} from 'react';
import {CheckCircle, Circle, CircleDashed} from '@phosphor-icons/react';
import styled, {css} from 'styled-components';
import {PackageRound} from '@opeq-dev/openquiz-schema';
import {PhosphorIcon, getLineClampCss} from '@openquiz/quiz-ui';

interface RoundRowProps {
  round: PackageRound;
  status: 'not-started' | 'in-progress' | 'finished';
}

const StyledRoundRow = styled.div<{$status: RoundRowProps['status']}>`
  ${p => p.theme.typography.body.md};
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  gap: 6px;

  svg {
    flex-shrink: 0;
    margin: 2px 0;
  }

  :last-child {
    margin-bottom: 0;
  }

  ${p =>
    p.$status === 'not-started' &&
    css`
      color: ${p => p.theme.colors.text.default};
    `}

  ${p =>
    p.$status === 'in-progress' &&
    css`
      color: ${p => p.theme.colors.accent.text};
      font-weight: 500;
    `}

  ${p =>
    p.$status === 'finished' &&
    css`
      color: ${p => p.theme.colors.text.subtle};
      text-decoration: line-through;
    `}
`;

const Name = styled.div`
  ${getLineClampCss(1)}
  height: 24px;
`;

const statusIconMap = new Map<RoundRowProps['status'], PhosphorIcon>([
  ['not-started', CircleDashed],
  ['in-progress', Circle],
  ['finished', CheckCircle]
]);

export const RoundRow = ({round, status}: RoundRowProps) => {
  const icon = useMemo(() => statusIconMap.get(status), [status]);
  return (
    <StyledRoundRow $status={status}>
      {icon &&
        createElement(icon, {
          size: 20,
          weight: status === 'in-progress' ? 'fill' : 'bold'
        })}
      <Name>{round.name}</Name>
    </StyledRoundRow>
  );
};
