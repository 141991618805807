import React from 'react';
import {useForm} from 'react-hook-form';
import {PaperPlaneRight} from '@phosphor-icons/react';
import {Button, FieldGroup, FieldRow, Input} from '@openquiz/quiz-ui';
import {
  HintRow,
  TextChip,
  selectPlayerTeam,
  selectTimer,
  useMachineEvent,
  useMachineGuard,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';
import {TypeFinalAnswerEvent, typeFinalAnswerGuard} from '@opeq-dev/openquiz-machine';

interface AnswerDto {
  answer: string;
}

export const EnterAnswerHint = () => {
  const self = useMachineSelf();
  const {event} = useMachineEvent();
  const {guard} = useMachineGuard();

  const team = useMachineSelector(selectPlayerTeam(self.userUuid));
  const playTimer = useMachineSelector(selectTimer('play'));

  const {register, handleSubmit} = useForm<AnswerDto>({
    defaultValues: {
      answer: ''
    }
  });

  const onFormSubmit = (value: AnswerDto) => {
    if (!value.answer) return;
    event<TypeFinalAnswerEvent>('typeFinalAnswer', {answer: value.answer});
  };

  if (!team || !guard(typeFinalAnswerGuard)({})) {
    return (
      <TextChip>
        Ожидаем ответы игроков{' '}
        {Math.round((playTimer.duration - playTimer.current) / 1000)} сек.
      </TextChip>
    );
  }

  return (
    <form style={{width: '100%'}} onSubmit={handleSubmit(onFormSubmit)}>
      <FieldGroup>
        <FieldRow justify="center">
          <HintRow
            timer={playTimer}
            type="typeFinalAnswer"
            team={team}
            userUuid={team.leaderUuid}
            isSelf={true}
          />
        </FieldRow>
      </FieldGroup>

      <FieldGroup>
        <FieldRow justify="center" size="sm">
          <div>
            <Input
              {...register('answer', {required: 'Пожалуйста введите ответ.'})}
              style={{width: 200}}
              placeholder="Ответ"
              autoComplete="off"
              autoFocus={true}
              size="sm"
            />
          </div>
          <Button size="sm" type="submit">
            <PaperPlaneRight size={16} weight="bold" />
            <span>Отправить</span>
          </Button>
        </FieldRow>
      </FieldGroup>
    </form>
  );
};
