import {editorCreatePath} from '@/features/wshop-editor';
import React from 'react';
import {generatePath} from 'react-router-dom';
import {
  BookmarkSimple,
  FileDotted,
  GearSix,
  PlusCircle,
  SignOut,
  SketchLogo,
  UploadSimple,
  UserFocus
} from '@phosphor-icons/react';
import {User} from '@opeq-dev/openquiz-schema';
import {Menu, MenuGroup, MenuItem, MenuLink} from '@openquiz/quiz-ui';
import {useAuthStore} from '@/features/auth';
import {generateUserPath} from '@/features/user';
import {contestsJuryListPath, useContestList} from '@/features/contest';

interface UserMenuProps {
  user: User;
  onOpenSettings: () => void;
  onOpenUploadPackage: () => void;
  onClose?: () => void;
}

export const UserMenu = ({
  user,
  onOpenSettings,
  onOpenUploadPackage,
  onClose = () => void 0
}: UserMenuProps) => {
  const {logout} = useAuthStore();
  const {data: contestsForJury = []} = useContestList({jury: true});

  const onClickLink = () => {
    onClose();
  };

  const onClickSettings = () => {
    onClose();
    onOpenSettings();
  };

  const onClickUploadPackage = () => {
    onClose();
    onOpenUploadPackage();
  };

  const onLogout = () => {
    onClose();
    logout();
  };

  return (
    <Menu>
      <MenuGroup>
        <MenuLink
          to={generateUserPath(user.username, user.discriminator)}
          icon={UserFocus}
          onClick={onClickLink}>
          Профиль
        </MenuLink>
        <MenuItem icon={GearSix} onClick={onClickSettings}>
          Настройки
        </MenuItem>
      </MenuGroup>

      <MenuGroup label="Мастерская">
        <MenuLink
          to={generatePath(editorCreatePath)}
          icon={PlusCircle}
          onClick={onClickLink}>
          Создать пак
        </MenuLink>

        <MenuItem icon={UploadSimple} onClick={onClickUploadPackage}>
          Загрузить пак
        </MenuItem>

        <MenuLink to="/workshop/favorites" icon={BookmarkSimple} onClick={onClickLink}>
          Закладки
        </MenuLink>

        <MenuLink to="/workshop/drafts" icon={FileDotted} onClick={onClickLink}>
          Черновики
        </MenuLink>

        {!!contestsForJury.length && (
          <MenuLink
            to={contestsJuryListPath}
            icon={SketchLogo}
            color="accent"
            onClick={onClickLink}>
            Конкурсы
          </MenuLink>
        )}
      </MenuGroup>

      <MenuGroup>
        <MenuItem color="danger" icon={SignOut} onClick={onLogout}>
          Выйти
        </MenuItem>
      </MenuGroup>
    </Menu>
  );
};
