import styled, {css} from 'styled-components';
import {ModalProps} from './types';

export const StyledModal = styled.div<{$size: ModalProps['size']}>`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  position: relative;
  width: 100%;
  overflow: hidden;

  border-radius: 20px;
  padding: 32px;
  height: auto;

  ${p =>
    p.$size === 'sm' &&
    css`
      max-width: 380px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      max-width: 460px;
    `}

  ${p =>
    p.$size === 'lg' &&
    css`
      max-width: 620px;
    `}
`;

export const Close = styled.button`
  background-color: ${p => p.theme.colors.neutral.emphasisPlus};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 12px;
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: background-color ease-in-out 80ms;
  z-index: 111;

  svg {
    display: flex;
  }

  :hover {
    background-color: ${p => p.theme.colors.accent.emphasis};
  }
`;
