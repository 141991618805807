import {Ctx} from '@opeq-dev/openquiz-machine';
import {State} from 'xstate';

/**
 * Select the turn team uuid.
 * @deprecated use `selectTurnTeam`
 * @param state
 * @returns
 */
export const selectTurnTeamUuid = (state: State<Ctx>) =>
  state.context.current.turnTeamUuid;
