import React from 'react';
import {useNavigate} from 'react-router-dom';
import {SignOut} from '@phosphor-icons/react';
import {ControlBarButton} from '../ControlBarButton';
import {ControlBarContainer} from '../ControlBarContainer';
import {ControlBarGroup} from '../ControlBarGroup';
import {ControlBarVolume} from '../ControlBarVolume';
import {ControlBarMore} from '../ControlBarMore';

export const SpectatorBar = () => {
  const navigate = useNavigate();

  const onClickExit = () => {
    navigate('/');
  };

  return (
    <ControlBarContainer>
      <ControlBarGroup>
        <ControlBarVolume />
      </ControlBarGroup>
      <ControlBarGroup>
        <ControlBarMore />
      </ControlBarGroup>
      <ControlBarButton variant="danger" onClick={onClickExit}>
        <SignOut size={24} weight="bold" />
        <span>Выйти</span>
      </ControlBarButton>
    </ControlBarContainer>
  );
};
