import React, {forwardRef, useCallback, useId} from 'react';
import {useDropzone} from 'react-dropzone';
import {AnimatePresence, motion} from 'framer-motion';
import {formatDistance} from 'date-fns';
import ru from 'date-fns/locale/ru';
import styled from 'styled-components';
import {UploadSimple} from '@phosphor-icons/react';
import {File as FileType} from '@opeq-dev/openquiz-schema';
import {CircularProgressBar, getAnimationProps, useProgress} from '@openquiz/quiz-ui';

export type UploadFnCallback = (
  file: File,
  onProgress: (value: number) => void
) => Promise<FileType>;

export interface DropzoneFileProps {
  requirements: {accept: {[key: string]: string[]}; limit: number};
  fn: UploadFnCallback;
  onChange: (data: FileType) => void;
}

const StyledDropzoneFile = styled.div`
  border: 1px dashed ${p => p.theme.colors.border.default};
  color: ${p => p.theme.colors.text.default};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  min-width: 120px;
  max-width: 400px;
  cursor: pointer;
  user-select: none;
  transition:
    background-color 80ms ease-in-out,
    border-color 80ms ease-in-out,
    transform 80ms ease-in-out;

  &[data-accepted='true'],
  :hover {
    background-color: ${p => p.theme.colors.accent.subtle};
    border-color: ${p => p.theme.colors.accent.emphasis};
  }

  &[data-rejected='true'] {
    background-color: ${p => p.theme.colors.danger.subtle};
    border-color: ${p => p.theme.colors.danger.emphasis};
  }

  &[data-disabled='true'] {
    pointer-events: none;
  }

  &:active {
    transform: scale(0.99);
  }
`;

const Indication = styled.div`
  background-color: ${p => p.theme.colors.border.subtle};
  color: ${p => p.theme.colors.text.muted};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const Icon = styled.div`
  display: flex;
`;

const Progress = styled.div`
  position: absolute;
  left: -4px;
  top: -4px;
`;

const Entry = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Label = styled.div`
  ${p => p.theme.typography.label.md};
  margin-bottom: 4px;
`;

const Requirements = styled(motion.div)`
  ${p => p.theme.typography.body.sm};
  color: ${p => p.theme.colors.text.subtle};
  text-align: center;
  width: 100%;
`;

const requirementsMotionProps = getAnimationProps({
  initial: {y: 4, opacity: 0},
  animate: {y: 0, opacity: 1},
  exit: {y: -4, opacity: 0}
});

export const DropzoneFile = forwardRef<HTMLDivElement, DropzoneFileProps>(
  function DropzoneFile({requirements, fn, onChange}, ref) {
    const id = useId();
    const {progress, reset, tick} = useProgress();

    const onDrop = useCallback(
      async (files: File[]) => {
        if (files.length === 0) return;
        reset();
        try {
          const result = await fn(files[0], tick);
          onChange(result);
        } catch (err) {
          // TODO: add toast
        }
      },
      [fn, reset, tick]
    );

    const {getRootProps, getInputProps, isDragAccept, isDragReject} = useDropzone({
      accept: requirements.accept,
      maxSize: requirements.limit,
      multiple: false,
      onDrop
    });

    return (
      <StyledDropzoneFile
        ref={ref}
        data-accepted={isDragAccept}
        data-rejected={isDragReject}
        data-disabled={!!progress.percent}
        {...getRootProps()}>
        <Indication>
          <Icon>
            {progress.percent ? (
              <>{progress.percent}%</>
            ) : (
              <UploadSimple size={24} weight="bold" />
            )}
          </Icon>
          <Progress>
            <CircularProgressBar value={progress.percent} />
          </Progress>
        </Indication>

        <Entry>
          <AnimatePresence initial={false} mode="popLayout">
            {progress.percent ? (
              <>
                <Label>Оптимизация</Label>
                <Requirements key={`requirements-${id}-eta`} {...requirementsMotionProps}>
                  Осталось{' '}
                  {formatDistance(0, progress.eta, {includeSeconds: true, locale: ru})}.
                </Requirements>
              </>
            ) : (
              <>
                <Label>Загрузите файл</Label>
                <Requirements
                  key={`requirements-${id}-${JSON.stringify(requirements.accept)}`}
                  {...requirementsMotionProps}>
                  {Object.values(requirements.accept)[0]
                    .map(e => e.replace('.', ''))
                    .join(', ')}
                  , до {requirements.limit / 1048576} мб.
                </Requirements>
              </>
            )}
          </AnimatePresence>
        </Entry>

        <input {...getInputProps()} />
      </StyledDropzoneFile>
    );
  }
);
