import React from 'react';
import {
  HintRow,
  selectTimer,
  selectTurnTeam,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';

interface AlreadyAnswerHintProps {
  /**
   * Player answer immediately.
   */
  immediately?: boolean;
}

export const AlreadyAnswerHint = ({immediately = false}: AlreadyAnswerHintProps) => {
  const self = useMachineSelf();
  const turnTeam = useMachineSelector(selectTurnTeam);
  const timer = useMachineSelector(
    immediately ? selectTimer('play') : selectTimer('try')
  );

  if (!turnTeam) {
    return null;
  }

  return (
    <HintRow
      timer={timer}
      type="alreadyTryAnswer"
      team={turnTeam}
      userUuid={turnTeam.leaderUuid}
      isSelf={turnTeam.leaderUuid === self?.userUuid}
    />
  );
};
