import React, {MouseEvent} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {
  FileArrowUp,
  MedalMilitary,
  Package,
  Play,
  Plus,
  Trash
} from '@phosphor-icons/react';
import {ModalConfirm, useModalConfirm} from '@openquiz/quiz-modal';
import {
  DiscordLogotype,
  Sidebar,
  SidebarFooter,
  SidebarGroup,
  SidebarLink,
  SidebarLogotype,
  Spinner
} from '@openquiz/quiz-ui';
import {UserBar} from '@/features/user';
import {
  editorInfoPath,
  editorPublishPath,
  editorStatisticsPath,
  useEditorContext,
  useVersionCreateMutation,
  useVersionDeleteMutation,
  useVersionsQuery
} from '@/features/wshop-editor';
import {RoundsList} from './RoundsList';
import {usePackageQuery, usePackageRun} from '@/features/wshop-packages';

export const EditorSidebar = () => {
  const navigate = useNavigate();
  const {packageUuid} = useEditorContext();
  const {data: pkg, isLoading} = usePackageQuery(packageUuid);
  const {data: versions = []} = useVersionsQuery({packageUuid});
  const {mutateAsync: createMutateAsync} = useVersionCreateMutation();
  const {mutateAsync} = useVersionDeleteMutation();

  const draftVersion = versions.find(v => !v.published);
  const runPackage = usePackageRun({packageUuid, versionUuid: draftVersion?.uuid ?? ''});

  const confirmDeleteDraft = useModalConfirm(props => (
    <ModalConfirm
      {...props}
      content={<>При удалении черновика, содержимое будет потеряно.</>}
      resolveLabel="Удалить"
    />
  ));

  const onClickRunDraft = (e: MouseEvent) => {
    e.preventDefault();
    runPackage();
  };

  const onClickCreateDraft = async (e: MouseEvent) => {
    e.preventDefault();
    await createMutateAsync({packageUuid});
  };

  const onClickDeleteDraft = async (e: MouseEvent) => {
    e.preventDefault();
    try {
      if (!draftVersion) return;
      await confirmDeleteDraft();
      await mutateAsync({versionUuid: draftVersion.uuid, packageUuid});
      navigate(generatePath(editorInfoPath, {packageUuid}));
    } catch (err) {
      /* empty */
    }
  };

  if (!pkg || isLoading) {
    return (
      <Sidebar>
        <Spinner />
      </Sidebar>
    );
  }

  return (
    <Sidebar>
      <SidebarGroup>
        <SidebarLogotype />
      </SidebarGroup>

      <SidebarGroup label="Управление">
        <SidebarLink icon={Package} to={generatePath(editorInfoPath, {packageUuid})}>
          Информация
        </SidebarLink>
        <SidebarLink
          icon={MedalMilitary}
          to={generatePath(editorStatisticsPath, {packageUuid})}>
          Статистика
        </SidebarLink>
      </SidebarGroup>

      {draftVersion ? (
        <SidebarGroup label="Черновик">
          <SidebarLink
            icon={FileArrowUp}
            variant="attention"
            to={generatePath(editorPublishPath, {
              packageUuid,
              versionUuid: draftVersion.uuid
            })}>
            Опубликовать
          </SidebarLink>
          <SidebarLink icon={Play} variant="attention" to="#" onClick={onClickRunDraft}>
            Запустить
          </SidebarLink>
          {versions.length > 1 && (
            <SidebarLink
              icon={Trash}
              variant="secondary"
              to="/"
              onClick={onClickDeleteDraft}>
              Удалить
            </SidebarLink>
          )}
        </SidebarGroup>
      ) : (
        <SidebarGroup label="Черновик">
          <SidebarLink
            icon={Plus}
            variant="attention"
            to="/"
            onClick={onClickCreateDraft}>
            Создать черновик
          </SidebarLink>
        </SidebarGroup>
      )}

      {draftVersion && (
        <SidebarGroup label="Раунды">
          <RoundsList versionUuid={draftVersion.uuid} />
        </SidebarGroup>
      )}

      <SidebarFooter>
        <SidebarGroup>
          <SidebarLink icon={DiscordLogotype} to="https://discord.gg/k56easeP5H">
            Discord сервер
          </SidebarLink>
        </SidebarGroup>
        <SidebarGroup>
          <UserBar onClickSign={() => void 0} />
        </SidebarGroup>
      </SidebarFooter>
    </Sidebar>
  );
};
