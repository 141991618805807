import React, {forwardRef} from 'react';
import styled from 'styled-components';
import {UserStats} from '@opeq-dev/openquiz-schema';
import {Achievement} from '@/features/achievement';

interface AchievementPictureProps {
  achievement: Achievement;
  stat: UserStats;
}

const StyledAchievementPicture = styled.img`
  display: flex;
  width: 100%;
`;

export const AchievementPicture = forwardRef<HTMLImageElement, AchievementPictureProps>(
  ({achievement}, ref) => (
    <StyledAchievementPicture ref={ref} src={achievement.frame} alt={achievement.id} />
  )
);
