import React, {HTMLAttributes, ReactNode} from 'react';
import styled, {css} from 'styled-components';

interface TeamSlotPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  pointer?: boolean;
}

const StyledTeamSlotPlaceholder = styled.div<{
  $pointer: TeamSlotPlaceholderProps['pointer'];
}>`
  background-color: transparent;
  border: 2px dashed ${p => p.theme.colors.border.default};
  color: ${p => p.theme.colors.text.subtle};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 4px;
  opacity: 0.25;
  user-select: none;
  transition:
    background-color ease-in-out 80ms,
    border-color ease-in-out 80ms,
    transform ease-in-out 80ms,
    opacity ease-in-out 80ms,
    color ease-in-out 80ms;

  ${p =>
    p.$pointer &&
    css`
      cursor: pointer;
      opacity: 1;

      &:hover {
        background-color: ${p => p.theme.colors.accent.subtle};
        border-color: ${p => p.theme.colors.accent.muted};
        color: ${p => p.theme.colors.accent.text};
      }

      &:active {
        transform: scale(0.98);
      }
    `}
`;

export const TeamSlotPlaceholder = ({
  children,
  pointer,
  ...rest
}: TeamSlotPlaceholderProps) => (
  <StyledTeamSlotPlaceholder {...rest} $pointer={pointer}>
    {children}
  </StyledTeamSlotPlaceholder>
);
