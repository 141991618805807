import React, {useMemo} from 'react';
import styled from 'styled-components';
import {PackageFull} from '@/features/wshop-packages';

interface QuestionSnippetProps {
  pkg: PackageFull;
  match: string;
  payload: {[key: string]: string};
}

const StyledQuestionSnippet = styled.div`
  text-decoration: underline;
`;

export const QuestionSnippet = ({pkg, match, payload}: QuestionSnippetProps) => {
  const question = useMemo(
    () => pkg.versions.at(0)?.questions.find(q => q.uuid === payload[match]),
    [pkg, match, payload]
  );

  const theme = useMemo(
    () => pkg.versions.at(0)?.themes.find(t => t.uuid === question?.themeUuid),
    [pkg, match, payload]
  );

  if (!question || !theme) {
    return <div>...</div>;
  }

  return (
    <StyledQuestionSnippet>
      <span>
        {theme.name}, {question.price}
      </span>
    </StyledQuestionSnippet>
  );
};
