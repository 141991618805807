import React, {useMemo} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import styled, {css, useTheme} from 'styled-components';
import {PatternKey, Pattern as PatternValue, getIsReadable} from '@openquiz/quiz-ui';

interface PatternPickerProps {
  patterns: Map<PatternKey, PatternValue>;
  currentColor: string;
  value: string;
  disabled?: boolean;
  onChange: (pattern: string) => void;
}

const StyledPatternPicker = styled.div<{$disabled: boolean}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  ${p =>
    p.$disabled &&
    css`
      pointer-events: none;
      filter: blur(4px);
    `}
`;

const Item = styled.button<{$active: boolean; $color: string}>`
  background-color: ${p => p.$color};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  border: none;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 64px;
  cursor: pointer;
  transition: opacity 160ms ease-in-out;

  ${p =>
    p.$active &&
    css`
      pointer-events: none;
    `}

  :focus-visible {
    box-shadow: 0 0 0 4px ${p => p.theme.colors.accent.focus};
  }

  &:hover {
    opacity: 0.6;
  }
`;

const CurrentIndicator = styled(motion.div)<{$color: string}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  box-shadow:
    inset 0 0 0 2px ${p => p.$color},
    inset 0 0 0 4px ${p => p.theme.colors.canvas.overlay};
  pointer-events: none;
  z-index: 1;
`;

const Pattern = styled.div<{$isReadable: boolean}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
  pointer-events: none;
  filter: ${p => (p.$isReadable ? 'invert(0%)' : 'invert(100%)')};
  z-index: 0;
`;

export const PatternPicker = ({
  patterns,
  currentColor,
  value,
  disabled = false,
  onChange
}: PatternPickerProps) => {
  const theme = useTheme();

  const isReadable = useMemo(
    () => getIsReadable(currentColor, theme.colors.text.onEmphasis),
    [currentColor, theme]
  );

  const patternsArr = Array.from(patterns);

  return (
    <StyledPatternPicker $disabled={disabled}>
      <AnimatePresence>
        {patternsArr.map(([key, pattern]) => (
          <Item
            key={`pattern-${key}`}
            type="button"
            $active={key === value}
            $color={currentColor}
            onClick={() => onChange(key)}>
            <Pattern
              $isReadable={isReadable}
              style={{
                backgroundImage: `url(${pattern.src})`,
                ...pattern.style
              }}
            />
            {key === value && (
              <CurrentIndicator layoutId="current" $color={currentColor} />
            )}
          </Item>
        ))}
      </AnimatePresence>
    </StyledPatternPicker>
  );
};
