import React, {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {interpret} from 'xstate';
import io from 'socket.io-client';
import {AudioPlayerProvider} from 'react-use-audio-player';
import {PeerProvider} from '@openquiz/peer-webcam';
import {Action, gameMachine} from '@opeq-dev/openquiz-machine';
import {
  AchievementsListener,
  AudioListener,
  ErrorScreen,
  LoadingScreen,
  MobileFlipScreen,
  useWindowFocus
} from '@/features/machine';
import {useAuthStore} from '@/features/auth';
import {GameMachineContext} from './GameMachineContext';
import {ReactionsListener} from '@/features/reactions';
import {useAutoPlayService} from './useAutoPlayService';

interface GameMachineProviderProps {
  children: ReactNode;
  userUuid: string;
  gameUuid: string;
}

export const GameMachineProvider = ({
  children,
  userUuid,
  gameUuid
}: GameMachineProviderProps) => {
  const {token} = useAuthStore();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const isMobilePortrait = useMediaQuery({query: 'only screen and (max-width: 480px)'});

  const {ref, status, grant} = useAutoPlayService();

  const [socket] = useState(() =>
    io(import.meta.env.VITE_MACHINE_RUNNER_END_POINT, {
      auth: {token, userUuid, gameUuid}
    })
  );

  const serviceRef = useRef(
    interpret(gameMachine, {
      clock: {
        setTimeout: () => void 0,
        clearTimeout: () => void 0
      }
    })
  );

  useEffect(() => {
    const socketInstance = socket;

    socketInstance.on('system', e => {
      if (e.type === 'init' && e.payload?.context?.game) {
        serviceRef.current.start(e.payload);
        socketInstance.emit('ready');
        setIsLoading(false);
        setIsError(false);
      }
    });

    socketInstance.on('event', e => {
      serviceRef.current.send(e);
    });

    socketInstance.on('connect_error', () => {
      setIsLoading(false);
      setIsError(true);
    });

    socketInstance.on('disconnect', () => {
      setIsLoading(false);
      setIsError(true);
    });

    return () => {
      socketInstance?.close();
    };
  }, [socket]);

  useWindowFocus(
    useCallback(() => {
      socket.emit('sync');
    }, [socket])
  );

  const emit = useCallback(
    (action: Action) => {
      socket.emit('event', action);
    },
    [socket]
  );

  if (isError) {
    return <ErrorScreen />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isMobilePortrait) {
    return <MobileFlipScreen />;
  }

  return (
    <GameMachineContext.Provider
      value={{
        service: serviceRef.current,
        autoplay: {status, grant},
        socket,
        emit
      }}>
      <PeerProvider socket={socket} gameUuid={gameUuid} userUuid={userUuid}>
        <AudioPlayerProvider>
          <ReactionsListener />
          <AchievementsListener />
          <AudioListener />
          <video
            ref={ref}
            src="https://raw.githubusercontent.com/esc0rtd3w/blank-intro-videos/master/blank.mp4"
            autoPlay={true}
            controls={true}
            height={0}
            style={{display: 'none'}}
          />
          {children}
        </AudioPlayerProvider>
      </PeerProvider>
    </GameMachineContext.Provider>
  );
};
