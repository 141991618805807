import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export interface FetchThemesParams {
  roundUuid: string;
  packageUuid: string;
}

/**
 *
 * @param params
 */
export const fetchThemes = async (params: FetchThemesParams) => {
  const {data} = await getPrivateAxios().get<PackageTheme[]>(
    `/workshop/packages/${params.packageUuid}/themes`,
    {params: {roundUuid: params.roundUuid}}
  );
  return data;
};
