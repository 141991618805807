import React from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Spinner} from '@openquiz/quiz-ui';
import {PackageContent, PackageSidebar, usePackageQuery} from '@/features/wshop-packages';
import {Container, Page} from '@/components';

const StyledPackagePage = styled.div`
  display: grid;

  @media only screen and (min-width: 320px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 40px;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 328px 1fr;
    grid-template-rows: 1fr;
    gap: 24px;
  }

  @media only screen and (min-width: 1440px) {
    grid-template-columns: 328px 1fr;
    grid-template-rows: 1fr;
    gap: 40px;
  }
`;

export const PackagePage = () => {
  const {packageUuid} = useParams();
  const {data: pkg, isLoading} = usePackageQuery(packageUuid as string);

  if (!pkg || isLoading) {
    return (
      <Page>
        <Container>
          <Spinner />
        </Container>
      </Page>
    );
  }

  return (
    <Page title={pkg.name}>
      <Container>
        <StyledPackagePage>
          <PackageSidebar pkg={pkg} />
          <PackageContent pkg={pkg} />
        </StyledPackagePage>
      </Container>
    </Page>
  );
};
