import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface FormSectionProps {
  children: ReactNode;
  label?: ReactNode;
}

const StyledFormSection = styled.div`
  display: flex;
  align-items: flex-start;

  @media only screen and (min-width: 320px) {
    border-bottom: none;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 0;
    margin-bottom: 24px;
  }

  @media only screen and (min-width: 768px) {
    border-bottom: 1px solid ${p => p.theme.colors.border.default};
    flex-direction: row;
    gap: 0;
    padding-bottom: 32px;
    margin-bottom: 32px;
  }

  @media only screen and (min-width: 1440px) {
    border-bottom: 1px solid ${p => p.theme.colors.border.default};
    flex-direction: row;
    gap: 0;
    padding-bottom: 32px;
    margin-bottom: 32px;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const Label = styled.div`
  ${p => p.theme.typography.label.md};
  color: ${p => p.theme.colors.text.subtle};
  width: 30%;
  max-width: 400px;
  flex-shrink: 0;

  @media only screen and (min-width: 320px) {
    &:empty {
      display: none;
    }
  }

  @media only screen and (min-width: 768px) {
    &:empty {
      display: block;
    }
  }

  @media only screen and (min-width: 1024px) {
    &:empty {
      display: block;
    }
  }
`;

export const Entry = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const FormSection = ({children, label}: FormSectionProps) => (
  <StyledFormSection>
    <Label>{label}</Label>
    <Entry>{children}</Entry>
  </StyledFormSection>
);
