import React, {createElement} from 'react';
import toast from 'react-hot-toast';
import {FormattedMessage} from 'react-intl';
import {Star, StarHalf} from '@phosphor-icons/react';
import styled from 'styled-components';
import {FieldRow, Tooltip} from '@openquiz/quiz-ui';

interface RatingProps {
  rating: {
    value: number;
    count: number;
  };
  layout?: 'stars' | 'value';
}

const StyledRating = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
`;

const Value = styled.div`
  ${p => p.theme.typography.caption.md};
`;

export const Rating = ({rating, layout = 'stars'}: RatingProps) => {
  const integer = Math.trunc(rating.value);
  const empty = Math.trunc(5 - rating.value);
  const float = rating.value - integer;

  const fullStartArray = new Array(integer).fill(Star);
  const emptyStartArray = new Array(empty).fill(Star);

  const onClickStars = () => {
    toast.error('Поставить оценку можно после игры');
  };

  if (layout === 'stars') {
    return (
      <StyledRating onClick={onClickStars}>
        <Tooltip message={rating.value.toFixed(1)}>
          <FieldRow size="xs">
            {fullStartArray.map((star, key) =>
              createElement(star, {
                key: `full-star-${key}`,
                size: 20,
                weight: 'fill',
                color: 'orange'
              })
            )}

            {float && <StarHalf size={20} weight="fill" color="orange" />}

            {emptyStartArray.map((star, key) =>
              createElement(star, {
                key: `empty-star-${key}`,
                size: 20,
                weight: 'bold',
                color: 'orange'
              })
            )}
          </FieldRow>
        </Tooltip>
      </StyledRating>
    );
  }

  return (
    <StyledRating>
      <Tooltip
        message={
          <FormattedMessage id="workshop.rating.count" values={{count: rating.count}} />
        }>
        <FieldRow size="xs">
          <Star size={20} weight="fill" color="orange" />
          <Value>{rating.value.toFixed(1)}</Value>
        </FieldRow>
      </Tooltip>
    </StyledRating>
  );
};
