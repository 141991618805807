import React, {ReactElement} from 'react';
import {User} from '@opeq-dev/openquiz-schema';
import {PopperMenu} from '@openquiz/quiz-ui';
import {UserFloatingID} from '@/features/user';

interface UserProfileTooltipProps {
  children: ReactElement;
  user: User;
}

export const UserProfileTooltip = ({children, user}: UserProfileTooltipProps) => (
  <PopperMenu
    menu={
      <div onClick={e => e.stopPropagation()}>
        <UserFloatingID user={user} />
      </div>
    }
    distance={16}>
    {children}
  </PopperMenu>
);
