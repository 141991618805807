import React, {
  ReactElement,
  ReactNode,
  cloneElement,
  createElement,
  useState
} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';
import {PhosphorIcon, getAnimationProps} from '@openquiz/quiz-ui';

interface WizardScreenProps {
  title: ReactNode;
  steps: {title: ReactNode; icon: PhosphorIcon; element: ReactElement}[];
}

const StyledWizardScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  flex-shrink: 0;

  @media only screen and (min-width: 320px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 26px;
    line-height: 32px;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 26px;
    line-height: 32px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  margin: auto 0;
  padding: 24px 0;
  width: 100%;
  overflow: auto;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;

  @media only screen and (min-width: 320px) {
    margin-top: 8px;
  }

  @media only screen and (min-width: 1024px) {
    margin-top: 16px;
  }

  @media only screen and (min-width: 1440px) {
    margin-top: 24px;
  }
`;

const PaginationItem = styled.div`
  ${p => p.theme.typography.label.md};
  color: ${p => p.theme.colors.text.subtle};
  display: flex;
  align-items: center;
  gap: 5px;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out;

  &[data-active='true'] {
    color: ${p => p.theme.colors.accent.text};
  }
`;

const contentAnimationProps = getAnimationProps(
  {
    initial: {x: 40, scale: 0.95, opacity: 0},
    animate: {x: 0, scale: 1, opacity: 1},
    exit: {x: -40, scale: 0.95, opacity: 0}
  },
  {duration: 0.2}
);

export const WizardScreen = ({title, steps}: WizardScreenProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const currentStepElement = steps[currentStep]?.element;

  const onClickNext = () => {
    setCurrentStep(s => s + 1);
  };

  return (
    <StyledWizardScreen>
      <Title>{title}</Title>

      <Pagination>
        {steps.map((step, index) => (
          <PaginationItem
            key={`pagination-item-${index}`}
            data-active={index === currentStep}>
            {createElement(step.icon, {size: 20, weight: 'fill'})}
            <span>{step.title}</span>
          </PaginationItem>
        ))}
      </Pagination>

      <Content>
        <AnimatePresence mode="wait">
          <motion.div key={currentStep} {...contentAnimationProps}>
            {cloneElement(currentStepElement, {onClickNext})}
          </motion.div>
        </AnimatePresence>
      </Content>
    </StyledWizardScreen>
  );
};
