import React, {ReactNode} from 'react';
import {getAnimationProps} from '@openquiz/quiz-ui';
import styled from 'styled-components';
import {motion} from 'framer-motion';

interface SplashScreenProps {
  children: ReactNode;
  icon?: ReactNode;
  action?: ReactNode;
}

const StyledSplashScreen = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  height: 100%;
`;

const Icon = styled.div`
  display: flex;
  width: 96px;
  height: 96px;
`;

const Message = styled.div`
  max-width: 400px;

  p {
    ${p => p.theme.typography.body.lg};
    margin-top: 24px;
  }
`;

const Action = styled.div``;

const splashScreenMotionProps = getAnimationProps(
  {
    initial: {y: 4, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: -4, opacity: 0}
  },
  {duration: 0.3}
);

export const SplashScreen = ({children, icon, action}: SplashScreenProps) => (
  <StyledSplashScreen {...splashScreenMotionProps}>
    {icon && <Icon>{icon}</Icon>}
    <Message>{children}</Message>
    {action && <Action>{action}</Action>}
  </StyledSplashScreen>
);
