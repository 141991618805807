import React from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {Surface} from '@openquiz/quiz-ui';
import {Page, PageTitle} from '@/components';
import {
  ThemeCreateData,
  ThemeForm,
  editorRoundPath,
  useEditorContext,
  useThemeCreateMutation
} from '@/features/wshop-editor';

export const ThemeCreatePage = () => {
  const navigate = useNavigate();
  const {packageUuid, roundUuid, versionUuid} = useEditorContext();
  const {mutateAsync} = useThemeCreateMutation();

  const backHref = generatePath(editorRoundPath, {
    packageUuid,
    roundUuid,
    versionUuid
  });

  const onSubmitForm = async (data: ThemeCreateData) => {
    await mutateAsync({data: {...data, versionUuid}, packageUuid});
    navigate(backHref, {replace: true});
  };

  return (
    <Page>
      <PageTitle to={{href: backHref}} title="Создание темы" />
      <Surface>
        <ThemeForm roundUuid={roundUuid} onSubmit={onSubmitForm} />
      </Surface>
    </Page>
  );
};
