import React from 'react';
import {createPortal} from 'react-dom';
import styled, {keyframes} from 'styled-components';
import {File} from '@opeq-dev/openquiz-schema';

interface CoverScribbleProps {
  file: File | undefined;
}

const coverScribbleKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.1;
  }
`;

const StyledCoverScribble = styled.div`
  position: fixed;
  left: -64px;
  right: -64px;
  top: -64px;
  bottom: -64px;
  background-position: center;
  background-size: cover;
  z-index: -1;
  pointer-events: none;
  filter: blur(128px);
  animation: ${coverScribbleKeyframes} 240ms ease-in-out forwards;
`;

export const CoverScribble = ({file}: CoverScribbleProps) => {
  if (!file) {
    return null;
  }

  return createPortal(
    <StyledCoverScribble style={{backgroundImage: `url(${file.src})`}} />,
    document.getElementById('root')!
  );
};
