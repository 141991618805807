import {useMemo} from 'react';
import uniqolor from 'uniqolor';
import {useTheme} from 'styled-components';
import {User} from '@opeq-dev/openquiz-schema';
import {PatternKey, getIsReadable} from '@openquiz/quiz-ui';

/**
 *
 * @param user
 */
export const useUserBanner = (user: User) => {
  const theme = useTheme();

  const isDiscordMember = useMemo(() => user.membership?.discord, [user.membership]);

  const patternKey = useMemo(
    () => (isDiscordMember && (user.profile.pattern as PatternKey)) || 'basic',
    [isDiscordMember, user]
  );

  const color = useMemo(
    () =>
      (isDiscordMember && user.profile.color) ||
      uniqolor(user.uuid.slice(0, 9), {saturation: [40, 50]}).color,
    [isDiscordMember, user]
  );

  const isReadable = useMemo(
    () => getIsReadable(color, theme.colors.text.onEmphasis),
    [color, theme]
  );

  return {
    patternKey,
    color,
    isReadable
  };
};
