import {useMemo} from 'react';
import {useMediaQuery} from 'react-responsive';

export const useMediaDevice = (): 'mobile' | 'table' | 'desktop' => {
  const isMobile = useMediaQuery({query: '(min-width: 320px)'});
  const isTablet = useMediaQuery({query: '(min-width: 1024px)'});
  const isDesktop = useMediaQuery({query: '(min-width: 1440px)'});

  const device = useMemo(
    () =>
      [
        ['mobile', isMobile],
        ['tablet', isTablet],
        ['desktop', isDesktop]
      ]
        .reverse()
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .find(([_, value]) => value),
    [isMobile, isTablet, isDesktop]
  );

  return device ? (device[0] as 'mobile' | 'table' | 'desktop') : 'desktop';
};
