import {createContext} from 'react';

export interface WizardContextType {
  key: string | undefined;
  data: unknown | undefined;
  onChangeStep(key: string, data?: unknown): void;
}

/**
 * Throw error when WizardContext is used outside of context provider
 */
const invariantViolation = () => {
  throw new Error(
    'Attempted to call useWizard outside of wizard context. Make sure your app is rendered inside WizardProvider.'
  );
};

/**
 * Modal Context Object
 */
export const WizardContext = createContext<WizardContextType>({
  key: undefined,
  data: undefined,
  onChangeStep: invariantViolation
});
