import {useEffect, useState} from 'react';

interface Device {
  id: string;
  label: string;
  kind: 'videoinput' | 'audioinput' | 'audiooutput';
}

export const useDevicesList = () => {
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [devices, setDevices] = useState<Device[]>([]);

  const getDevices = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({video: true});
      setDevices(
        (await navigator.mediaDevices.enumerateDevices())
          .map(device => ({
            id: device.deviceId,
            label: device.label,
            kind: device.kind
          }))
          .filter(device => device.kind === 'videoinput')
      );
      setStatus('success');
    } catch (e) {
      setStatus('error');
    }
  };

  useEffect(() => {
    getDevices();
  }, []);

  return {status, devices};
};
