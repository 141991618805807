import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {Tooltip} from '@openquiz/quiz-ui';

interface BetaBadgeProps {
  message: ReactNode;
}

const StyledBetaBadge = styled.div`
  ${p => p.theme.typography.label.sm};
  background-color: ${p => p.theme.colors.danger.emphasis};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 8px;
  display: inline-flex;
  padding: 4px 6px;
`;

export const BetaBadge = ({message}: BetaBadgeProps) => {
  return (
    <Tooltip message={message}>
      <StyledBetaBadge>BETA</StyledBetaBadge>
    </Tooltip>
  );
};
