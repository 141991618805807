import {getPrivateAxios} from '@/libs';

export interface FetchRoundDeleteParams {
  packageUuid: string;
  versionUuid: string;
  roundUuid: string;
}

/**
 *
 * @param params
 */
export const fetchRoundDelete = async (params: FetchRoundDeleteParams) => {
  await getPrivateAxios().delete<void>(
    `/workshop/packages/${params.packageUuid}/rounds/${params.roundUuid}`
  );
};
