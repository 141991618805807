import {getPrivateAxios} from '@/libs';

export interface FetchQuestionDeleteParams {
  packageUuid: string;
  questionUuid: string;
}

/**
 * Fetch delete a question.
 * @param params
 */
export const fetchQuestionDelete = async (params: FetchQuestionDeleteParams) => {
  const {data} = await getPrivateAxios().delete<void>(
    `/workshop/packages/${params.packageUuid}/questions/${params.questionUuid}`
  );
  return data;
};
