import React, {forwardRef, useCallback} from 'react';
import {Minus, Plus} from '@phosphor-icons/react';
import styled from 'styled-components';

interface InputNumberProps {
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
}

const StyledInputNumber = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.default};
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 10px 16px;
  gap: 10px;

  /* TODO: Придумать что делать с этим */
  min-width: 120px;
  max-width: 400px;
`;

const Label = styled.div`
  margin-right: auto;
  display: flex;
`;

const Input = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
`;

const Button = styled.button`
  background-color: ${p => p.theme.colors.border.default};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out,
    transform 80ms ease-in-out;

  svg {
    display: flex;
  }

  :hover {
    background-color: ${p => p.theme.colors.accent.emphasis};
  }

  :focus-visible {
    box-shadow: 0 0 0 2px ${p => p.theme.colors.accent.focus};
  }

  :active {
    transform: scale(0.98);
  }
`;

const Value = styled.div`
  border: 1px solid ${p => p.theme.colors.border.default};
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  width: 32px;
  line-height: 24px;
`;

export const InputNumber = forwardRef<HTMLDivElement, InputNumberProps>(
  function InputNumber({value, min, max, onChange}, ref) {
    const onClickIncrease = useCallback(() => {
      if (value >= max) return;
      onChange(value + 1);
    }, [onChange, value, max]);

    const onClickDecrease = useCallback(() => {
      if (value <= min) return;
      onChange(value - 1);
    }, [onChange, value, min]);

    return (
      <StyledInputNumber ref={ref}>
        <Label>Количество слотов</Label>
        <Input>
          <Button type="button" onClick={onClickDecrease}>
            <Minus weight="bold" size={20} />
          </Button>
          <Value>{value}</Value>
          <Button type="button" onClick={onClickIncrease}>
            <Plus weight="bold" size={20} />
          </Button>
        </Input>
      </StyledInputNumber>
    );
  }
);
