import React from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {colord} from 'colord';
import {WorkshopCategory} from '@opeq-dev/openquiz-schema';
import {getSearchString} from '@/features/wshop-search';

interface CategoryChipProps {
  category?: WorkshopCategory;
  hasLink?: boolean;
}

const StyledCategoryChip = styled.div<{$color?: string}>`
  ${p => p.theme.typography.caption.md};
  background-color: ${p => p.theme.colors.neutral.muted};
  color: ${p => p.theme.colors.text.default};
  border-radius: 8px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out;

  ${p =>
    p.$color &&
    css`
      background-color: ${colord(p.$color).alpha(0.25).toHex()};
    `}

  &[href] {
    :hover {
      background-color: ${p => p.$color};
      color: ${p => p.theme.colors.text.onEmphasis};
    }
  }
`;

export const CategoryChip = ({category, hasLink = false}: CategoryChipProps) => {
  if (category) {
    return (
      <StyledCategoryChip
        as={hasLink ? Link : 'div'}
        to={
          hasLink
            ? `/workshop/search?data=${getSearchString({
                query: `%${category.name}`,
                sort: 'created_at'
              })}`
            : ''
        }
        $color={category.color}>
        <span>{category.emoji}</span>
        <span>{category.name}</span>
      </StyledCategoryChip>
    );
  }

  return <StyledCategoryChip>Без категории</StyledCategoryChip>;
};
