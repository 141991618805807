import React from 'react';
import styled from 'styled-components';
import useEmblaCarousel from 'embla-carousel-react';
import {ContestSlide} from '@openquiz/quiz-ui';
import {useContestList} from '@/features/contest';
import {UpdateSlide} from '@/features/home';
import {slides} from './slides';

const StyledUpdatesSlider = styled.div`
  overflow: hidden;

  @media only screen and (min-width: 320px) {
    border-radius: 0;
    margin: -24px -24px 0 -24px;
  }

  @media only screen and (min-width: 1024px) {
    border-radius: 20px;
    margin: 0;
  }
`;

const Placeholder = styled.div`
  background-color: ${p => p.theme.colors.neutral.subtle};
  height: 320px;

  @media only screen and (min-width: 320px) {
    border-radius: 0;
    padding: 24px 24px 40px 24px;
  }

  @media only screen and (min-width: 1024px) {
    border-radius: 20px;
    padding: 40px 64px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  grid-column-gap: 16px;
`;

export const UpdatesSlider = () => {
  const [emblaRef] = useEmblaCarousel();
  const {data: contests = [], isLoading} = useContestList({jury: false});

  if (isLoading) {
    return <Placeholder />;
  }

  return (
    <StyledUpdatesSlider ref={emblaRef}>
      <Container>
        {contests.map(contest => (
          <ContestSlide
            key={`contest-slide-${contest.uuid}`}
            contest={contest}
            to="https://wiki.opeq.dev/contests/new-year-2024"
          />
        ))}
        {slides.map(slide => (
          <UpdateSlide key={`update-slide-${slide.id}`} slide={slide} />
        ))}
      </Container>
    </StyledUpdatesSlider>
  );
};
