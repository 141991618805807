import React from 'react';
import {
  FinalBetHint,
  FinalTopBar,
  Screen,
  ScreenContent,
  ScreenFooter
} from '@/features/machine';

export const FinalBetsScreen = () => {
  return (
    <Screen>
      <FinalTopBar />
      <ScreenContent />
      <ScreenFooter>
        <FinalBetHint />
      </ScreenFooter>
    </Screen>
  );
};
