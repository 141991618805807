import React, {SelectHTMLAttributes, forwardRef} from 'react';
import {CaretDown} from '@phosphor-icons/react';
import styled, {css} from 'styled-components';

interface SelectProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
  size?: 'sm' | 'md';
  placeholder?: string;
}

const StyledSelect = styled.div`
  position: relative;
  display: inline-flex;
`;

const SelectField = styled.select<{$size: SelectProps['size']}>`
  ${p => p.theme.typography.body.md};
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.default};
  color: ${p => p.theme.colors.text.default};
  display: flex;
  align-items: center;
  width: 100%;
  resize: vertical;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px transparent;
  transition:
    background-color 80ms ease-in-out,
    border-color 80ms ease-in-out,
    color 80ms ease-in-out;
  appearance: none;

  /* TODO: Придумать что делать с этим */
  min-width: 120px;
  max-width: 400px;

  ${p =>
    p.$size === 'sm' &&
    css`
      border-radius: 8px;
      padding: 0 36px 0 16px;
      height: 32px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      border-radius: 12px;
      padding: 0 36px 0 16px;
      height: 44px;
    `}

  ::placeholder {
    font-weight: normal;
    color: ${p => p.theme.colors.text.subtle};
  }

  :focus {
    border-color: ${p => p.theme.colors.accent.emphasis};
    box-shadow: 0 0 0 2px ${p => p.theme.colors.accent.focus};
  }

  &[disabled] {
    background-color: ${p => p.theme.colors.neutral.muted};
    border-color: ${p => p.theme.colors.neutral.muted};
    color: ${p => p.theme.colors.text.subtle};
  }
`;

const Caret = styled(CaretDown)`
  position: absolute;
  right: 12px;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  pointer-events: none;
`;

export const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(
  {size = 'md', ...props},
  ref
) {
  return (
    <StyledSelect>
      <SelectField ref={ref} {...props} $size={size} />
      <Caret size={16} weight="bold" />
    </StyledSelect>
  );
});
