import {State} from 'xstate';
import {useTheme} from 'styled-components';
import {useSelector} from '@xstate/react';
import {
  AcceptAnswerEvent,
  Ctx,
  MachineTimer,
  PassTryAnswerEvent,
  RejectAnswerEvent,
  SystemSetTryAnswerTimeout,
  TeamRecord
} from '@opeq-dev/openquiz-machine';
import {
  getChooseIndicator,
  getErrorIndicator,
  getPassIndicator,
  getProgressAttentionIndicator,
  getProgressIndicator,
  getSuccessIndicator
} from '@openquiz/quiz-ui';
import {
  selectCtxCurrentQuestion,
  selectTurnTeamUuid,
  useGameService,
  useMachineSelector
} from '@/features/machine';
import {getPercentFrom} from '@/libs';

const selectAnswerStatus =
  (
    team: TeamRecord,
    type: (
      | AcceptAnswerEvent
      | RejectAnswerEvent
      | PassTryAnswerEvent
      | SystemSetTryAnswerTimeout
    )['type']
  ) =>
  ({context}: State<Ctx>) => {
    return (
      context.current.questionUuid &&
      context.history.some(r => {
        if (type === 'passTryAnswer') {
          return (
            r.type === type &&
            r.self.teamUuid === team.uuid &&
            r.payload.questionUuid === context.current.questionUuid
          );
        }

        return (
          r.type === type &&
          r.payload.teamUuid === team.uuid &&
          r.payload.questionUuid === context.current.questionUuid
        );
      })
    );
  };

const useTeamState = (team: TeamRecord) => {
  const {service, isMatch} = useGameService();
  const turnTeamUuid = useSelector(service, selectTurnTeamUuid);

  const isChooseQuestion =
    isMatch([{game: {basicRound: 'choose'}}]) && team.uuid === turnTeamUuid;

  const isChooseResponder =
    isMatch([{game: {basicRound: {secretQuestion: 'splash'}}}]) &&
    team.uuid === turnTeamUuid;

  const isBasicTryAnswer =
    isMatch([
      {game: {basicRound: {basicQuestion: 'tryAnswer'}}},
      {game: {basicRound: {variantsOneByOneQuestion: 'tryAnswer'}}}
    ]) && team.uuid === turnTeamUuid;

  const isCustomTryAnswer =
    isMatch([
      // {game: {basicRound: {variantsQuestion: 'play'}}},
      {game: {basicRound: {noRiskQuestion: 'play'}}},
      {game: {basicRound: {secretQuestion: 'play'}}},
      {game: {basicRound: {auctionQuestion: 'play'}}}
    ]) && team.uuid === turnTeamUuid;

  const isAuctionRoundBet =
    isMatch([{game: {basicRound: {auctionQuestion: 'bets'}}}]) &&
    team.uuid === turnTeamUuid;

  const isFinalRoundBan =
    isMatch([{game: {finalRound: 'bans'}}]) && team.uuid === turnTeamUuid;

  const isVariantTryAnswer = useMachineSelector(state => {
    const question = selectCtxCurrentQuestion(state);
    return (
      isMatch([{game: {basicRound: {variantsQuestion: 'play'}}}]) &&
      (question?.type === 'VARIANTS' ||
        question?.type === 'VARIANTS_NORISK' ||
        question?.type === 'VARIANTS_ONE_BY_ONE') &&
      team.uuid === turnTeamUuid
    );
  });

  const isSuccessAnswer = useSelector(service, selectAnswerStatus(team, 'acceptAnswer'));

  const isWrongAnswer = useSelector(service, selectAnswerStatus(team, 'rejectAnswer'));

  const isTimeoutAnswer = useSelector(
    service,
    selectAnswerStatus(team, '#system/setTryAnswerTimeout')
  );

  const isPassedAnswer = useSelector(service, selectAnswerStatus(team, 'passTryAnswer'));

  return (
    Object.entries({
      isChooseQuestion,
      isChooseResponder,
      isBasicTryAnswer,
      isCustomTryAnswer,
      isAuctionRoundBet,
      isFinalRoundBan,
      isVariantTryAnswer,
      isSuccessAnswer,
      isWrongAnswer,
      isTimeoutAnswer,
      isPassedAnswer
    })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => value)
      .at(0)
      ?.at(0)
  );
};

const getTimerPercent = (timer: MachineTimer) => {
  return getPercentFrom(timer.current, timer.duration);
};

export const useIndicator = (team: TeamRecord) => {
  const theme = useTheme();
  const teamState = useTeamState(team);
  const {service} = useGameService();

  return useSelector(service, ({context}) => {
    switch (teamState) {
      case 'isChooseQuestion':
        return getChooseIndicator(theme);
      case 'isChooseResponder':
        return getProgressAttentionIndicator(
          theme,
          getTimerPercent(context.timers.splash)
        );
      case 'isBasicTryAnswer':
        return getProgressIndicator(theme, getTimerPercent(context.timers.try));
      case 'isCustomTryAnswer':
        return getProgressIndicator(theme, getTimerPercent(context.timers.play));
      case 'isAuctionRoundBet':
        return getProgressIndicator(theme, getTimerPercent(context.timers.auctionBets));
      case 'isFinalRoundBan':
        return getProgressIndicator(theme, getTimerPercent(context.timers.finalBans));
      case 'isVariantTryAnswer':
        return getProgressIndicator(theme, getTimerPercent(context.timers.play));
      case 'isSuccessAnswer':
        return getSuccessIndicator(theme);
      case 'isWrongAnswer':
      case 'isTimeoutAnswer':
        return getErrorIndicator(theme);
      case 'isPassedAnswer':
        return getPassIndicator(theme);
    }
  });
};
