import React from 'react';
import {DisconnectUserEvent} from '@opeq-dev/openquiz-machine';
import {usePeerMediaStream} from '@openquiz/peer-webcam';
import {Button, FieldGroup, FieldRow, Modal, Title} from '@openquiz/quiz-ui';
import {useMachineEvent} from '@/features/machine';

interface ConfirmDisconnectModalProps {
  onClose: () => void;
}

export const ConfirmDisconnectModal = ({onClose}: ConfirmDisconnectModalProps) => {
  const {stop} = usePeerMediaStream();
  const {event} = useMachineEvent();

  const onClickConfirm = async () => {
    await stop();
    event<DisconnectUserEvent>('disconnectUser', undefined);
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Title>Подтвердите отключение</Title>

      <FieldGroup size="lg">
        <p>Если вы отключаетесь от игры, то теряете свои очки.</p>
        <p>
          Кроме того, если кто-то займет ваше место, то вы не сможете подключиться обратно
          в игру.
        </p>
      </FieldGroup>

      <FieldGroup>
        <FieldRow>
          <Button variant="danger" onClick={onClickConfirm}>
            Отключиться
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Отмена
          </Button>
        </FieldRow>
      </FieldGroup>
    </Modal>
  );
};
