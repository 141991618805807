/**
 *
 * @param stream
 */
export const stopMediaStream = (stream: MediaStream) => {
  stream.getTracks().forEach(track => {
    track.stop();
    stream.removeTrack(track);
  });
};
