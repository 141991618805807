import {colord, extend} from 'colord';
import a11yPlugin from 'colord/plugins/a11y';
extend([a11yPlugin]);

/**
 *
 * @param color
 * @param to
 */
export const getIsReadable = (color: string, to: string) => {
  return colord(color).contrast(to) >= 3;
};
