import React from 'react';
import {FormattedDate} from 'react-intl';
import {Link, generatePath} from 'react-router-dom';
import styled from 'styled-components';
import {ChatTeardropText, HashStraight, LinkBreak} from '@phosphor-icons/react';
import {Contest, ContestPackage} from '@opeq-dev/openquiz-schema';
import {FieldRow, TableColumn, TableRow} from '@openquiz/quiz-ui';
import {useModal} from '@openquiz/quiz-modal';
import {ContestFeedbackModal, ContestStatusPill} from '@/features/contest';
import {packagePath} from '@/features/wshop-packages';

interface ContestPkgRowProps {
  contest: Contest;
  pkg: ContestPackage;
}

const WorkshopLink = styled(Link)`
  ${p => p.theme.typography.caption.sm};
  color: ${p => p.theme.colors.text.subtle};
  display: flex;
  align-items: center;
  height: 16px;
  gap: 5px;

  &:hover {
    color: ${p => p.theme.colors.accent.text};
  }
`;

const FeedbackLink = styled(WorkshopLink)`
  color: ${p => p.theme.colors.attention.text};
  cursor: pointer;
`;

const FeedbackId = styled(WorkshopLink)`
  pointer-events: none;
`;

export const ContestPkgRow = ({contest, pkg}: ContestPkgRowProps) => {
  const openContestFeedbackModal = useModal(props => (
    <ContestFeedbackModal contest={contest} packageUuid={pkg.uuid} {...props} />
  ));

  return (
    <TableRow key={`pkg-row-${pkg.user}`}>
      <TableColumn width="30%">
        <div style={{marginBottom: 5}}>{pkg.name}</div>
        <FieldRow>
          {!pkg.feedbacks.length ? (
            <FeedbackLink as="div" onClick={openContestFeedbackModal}>
              <ChatTeardropText size={16} weight="duotone" />
              <span>Проверить</span>
            </FeedbackLink>
          ) : (
            <FeedbackId as="div">
              <HashStraight size={16} weight="duotone" />
              <span>
                {pkg.feedbacks
                  .map(f => f.uuid)
                  .at(0)
                  ?.slice(0, 8)}
              </span>
            </FeedbackId>
          )}

          <WorkshopLink
            to={generatePath(packagePath, {packageUuid: pkg.uuid})}
            target="_blank">
            <LinkBreak size={16} weight="duotone" />
            <span>Открыть</span>
          </WorkshopLink>
        </FieldRow>
      </TableColumn>
      <TableColumn width="15%">{pkg.user.username}</TableColumn>
      <TableColumn width="20%">
        <FormattedDate value={pkg.createdAt} dateStyle="short" />
      </TableColumn>
      <TableColumn width={240}>
        <ContestStatusPill status={pkg.feedbacks.length ? 'reviewed' : 'await'} />
      </TableColumn>
    </TableRow>
  );
};
