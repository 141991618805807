import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useSelector} from '@xstate/react';
import {QuestionStepStage} from '@opeq-dev/openquiz-machine';
import {Grid} from '@openquiz/quiz-ui';
import {selectCtxCurrentSteps, useGameService} from '@/features/machine';
import {VariantItem} from './VariantItem';

interface VariantsProps {
  stage: QuestionStepStage;
}

const StyledVariants = styled.div`
  max-width: 480px;
  width: 100%;

  @media only screen and (min-width: 320px) {
    margin-top: 16px;
  }

  @media only screen and (min-width: 1024px) {
    margin-top: 24px;
  }

  @media only screen and (min-width: 1440px) {
    margin-top: 32px;
  }
`;

export const Variants = ({stage}: VariantsProps) => {
  const {service} = useGameService();
  const steps = useSelector(service, selectCtxCurrentSteps);

  const variants = useMemo(
    () => steps.find(step => !step.isAnswer)?.variants.filter(Boolean) || [],
    [steps]
  );

  const rightVariant = useMemo(
    () => steps.find(step => step.isAnswer)?.variants.at(0),
    [steps]
  );

  return (
    <StyledVariants>
      <Grid cols={{sm: 2, md: 2, lg: 2}} gap={16}>
        {variants.map((label, index) => (
          <VariantItem
            key={`variant-item-${index}`}
            variant={{index: index.toString(), label}}
            isCorrect={stage === 'answer' ? rightVariant === index.toString() : undefined}
          />
        ))}
      </Grid>
    </StyledVariants>
  );
};
