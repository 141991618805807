import {useEffect} from 'react';
import {useLocalStorage} from 'react-use';
import {useFeatureFlagEnabled} from 'posthog-js/react';

/**
 *
 */
export const useSpeechGuard = () => {
  const tts = useFeatureFlagEnabled('text-to-speech');
  const [localTts, setLocalTts] = useLocalStorage('text-to-speech', false);
  useEffect(() => {
    setLocalTts(tts);
  }, [tts]);
  return localTts;
};
