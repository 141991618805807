import React, {ReactNode, createElement} from 'react';
import styled from 'styled-components';
import {PhosphorIcon} from '../../../types';

export interface MenuCustomItemProps {
  children: ReactNode;
  icon?: PhosphorIcon;
}

const StyledMenuCustomItem = styled.div`
  ${p => p.theme.typography.caption.md};
  color: ${p => p.theme.colors.text.default};
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin: 0 -8px 1px -8px;
  padding: 8px 12px;
  border-radius: 8px;
  user-select: none;
  transition:
    background-color 40ms ease-in-out,
    color 40ms ease-in-out;

  & svg {
    display: inline-flex;
    transition:
      background-color 40ms ease-in-out,
      color 40ms ease-in-out;
  }

  & > svg {
    color: ${p => p.theme.colors.text.subtle};
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const MenuCustomItem = ({children, icon}: MenuCustomItemProps) => (
  <StyledMenuCustomItem>
    {icon && createElement(icon, {size: 20, weight: 'bold'})}
    <span>{children}</span>
  </StyledMenuCustomItem>
);
