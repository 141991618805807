import React, {
  ButtonHTMLAttributes,
  MouseEvent,
  ReactNode,
  forwardRef,
  useEffect,
  useState
} from 'react';
import styled, {css} from 'styled-components';
import {Spinner} from '@openquiz/quiz-ui';
import {colord} from 'colord';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'danger-filled'
  | 'link'
  | 'link2';

export type ButtonSize = 'sm' | 'md' | 'lg';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Content of the button.
   */
  children: ReactNode;
  /**
   * Button size.
   */
  size?: ButtonSize;
  /**
   * Button design option.
   */
  variant?: ButtonVariant;
  /**
   * Custom button color.
   */
  color?: string;
  /**
   * Button will be disabled.
   */
  disabled?: boolean;
  /**
   * Button will stretch to the full width of the parent container.
   */
  stretch?: boolean;
  /**
   * Button will display a loading indicator.
   */
  fetching?: boolean;
  /**
   * Button will be marked as active.
   */
  pressed?: boolean;
}

const StyledButton = styled.button<{
  $variant: ButtonVariant;
  $color: string | undefined;
  $size: ButtonSize;
  $stretch: boolean;
  $fetching: boolean;
}>`
  position: relative;
  background-color: transparent;
  border: 1px solid transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
  cursor: pointer;
  user-select: none;
  transition:
    background-color 80ms ease-in-out,
    border-color 80ms ease-in-out,
    color 80ms ease-in-out,
    opacity 80ms ease-in-out,
    transform 80ms ease-in-out;

  &:active {
    transform: scale(0.98);
  }

  ${p =>
    p.$size === 'sm' &&
    css`
      ${p.theme.typography.label.md};
      font-weight: normal;
      border-radius: 8px;
      padding: 0 16px;
      min-width: 36px;
      height: 36px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      ${p.theme.typography.label.md};
      border-radius: 12px;
      padding: 0 20px;
      min-width: 40px;
      height: 40px;
    `}

  ${p =>
    p.$size === 'lg' &&
    css`
      ${p.theme.typography.label.md};
      border-radius: 12px;
      padding: 0 20px;
      min-width: 44px;
      height: 44px;
    `}

  ${p =>
    p.$variant === 'primary' &&
    css`
      background-color: ${p.$color ?? p.theme.colors.accent.emphasis};
      border-color: ${p.$color ?? p.theme.colors.accent.emphasis};
      color: ${p.theme.colors.text.onEmphasis};
    `}

  ${p =>
    p.$variant === 'secondary' &&
    css`
      background-color: transparent;
      border-color: ${p.$color ?? p.theme.colors.border.default};
      color: ${p.$color ?? p.theme.colors.text.muted};

      &:hover {
        background-color: ${p.theme.colors.canvas.subtle};
      }
    `}

  ${p =>
    p.$variant === 'danger' &&
    css`
      background-color: transparent;
      border-color: ${p.theme.colors.danger.emphasis};
      color: ${p.theme.colors.danger.text};

      &:hover {
        background-color: ${p.theme.colors.danger.subtle};
      }
    `}

  ${p =>
    p.$variant === 'danger-filled' &&
    css`
      background-color: ${p.theme.colors.danger.emphasis};
      border-color: ${p.theme.colors.danger.emphasis};
      color: ${p.theme.colors.text.onEmphasis};

      :hover {
        opacity: 0.6;
      }
    `}

  ${p =>
    p.$variant === 'link' &&
    css`
      background-color: transparent;
      border-color: transparent;
      color: ${p.$color ?? p.theme.colors.accent.text};
      padding: 0;
      height: auto;

      &[disabled] {
        background-color: transparent !important;
        border-color: transparent !important;
        color: ${p.theme.colors.text.subtle};
        pointer-events: none;
      }
    `}

  ${p =>
    p.$variant === 'link2' &&
    css`
      background-color: ${p.$color
        ? colord(p.$color).alpha(0.1).toRgbString()
        : p.theme.colors.neutral.subtle};
      color: ${p.$color ?? p.theme.colors.text.muted};
      border-radius: 10px;
      border-color: transparent;
      padding: 4px 8px;
      min-width: auto;
      height: auto;
      font-weight: normal;

      &:hover {
        background-color: ${p.$color
          ? colord(p.$color).alpha(0.15).toRgbString()
          : p.theme.colors.neutral.subtle};
        color: ${p.$color ?? p.theme.colors.accent.text};
      }

      &:active {
        background-color: ${p.$color ?? p.theme.colors.accent.emphasis};
        color: ${p.theme.colors.text.onEmphasis};
      }

      &[disabled] {
        background-color: ${p => p.theme.colors.accent.subtle};
        border-color: transparent !important;
        color: ${p.theme.colors.text.subtle};
        pointer-events: none;
      }
    `}

  ${p =>
    p.$stretch &&
    css`
      text-align: center;
      justify-content: center;
      width: 100%;
    `}

  ${p =>
    p.$fetching &&
    css`
      ${Label} {
        filter: blur(8px);
        opacity: 0.5;
      }
      pointer-events: none;
    `}

  &:focus-visible {
    box-shadow: 0 0 0 2px ${p => p.theme.colors.accent.focus};
  }

  &[disabled] {
    background-color: ${p => p.theme.colors.neutral.muted};
    border-color: ${p => p.theme.colors.neutral.muted};
    color: ${p => p.theme.colors.text.subtle};
    pointer-events: none;
  }
`;

const FetchingIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 5px;
`;

/**
 * Button, used in forms, etc.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    children,
    variant = 'primary',
    color,
    size = 'md',
    stretch = false,
    fetching = false,
    pressed = false,
    type = 'button',
    onClick,
    ...rest
  },
  ref
) {
  const [forceFetching, setForceFetching] = useState(fetching);

  useEffect(() => {
    setForceFetching(fetching);
  }, [fetching]);

  const onButtonClick = async (e: MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return;
    if (onClick.constructor.name === 'AsyncFunction') {
      try {
        setForceFetching(true);
        await onClick(e);
      } finally {
        setForceFetching(false);
      }
    } else {
      onClick(e);
    }
  };

  return (
    <StyledButton
      {...rest}
      ref={ref}
      type={type}
      data-pressed={pressed}
      onClick={onButtonClick}
      $variant={variant}
      $color={color}
      $size={size}
      $stretch={stretch}
      $fetching={forceFetching}>
      {forceFetching && (
        <FetchingIndicator>
          <Spinner size={20} />
        </FetchingIndicator>
      )}
      <Label>{children}</Label>
    </StyledButton>
  );
});
