import React from 'react';
import {DotsThreeOutline} from '@phosphor-icons/react';
import {PopperMenu, Tooltip} from '@openquiz/quiz-ui';
import {ControlBarButton} from '../ControlBarButton';
import {ControlBarMoreMenu} from './ControlBarMoreMenu';

export const ControlBarMore = () => (
  <Tooltip message="Еще" placement="top" closeOnReferencePress={true}>
    <div>
      <PopperMenu menu={<ControlBarMoreMenu />} placement="top">
        <ControlBarButton variant="secondary">
          <DotsThreeOutline size={24} weight="fill" />
        </ControlBarButton>
      </PopperMenu>
    </div>
  </Tooltip>
);
