import {Ctx} from '@opeq-dev/openquiz-machine';
import {State} from 'xstate';

/**
 *
 * @returns
 * @param playerUuid
 */
export const selectPlayerTeam = (playerUuid: string) => (state: State<Ctx>) => {
  const player = state.context.users.find(user => user.uuid === playerUuid);
  return state.context.teams.find(team => team.uuid === player?.teamUuid);
};
