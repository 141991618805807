import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Logotype} from '@openquiz/quiz-ui';

const StyledSidebarHamburger = styled.div`
  display: flex;

  a {
    display: flex;
  }

  @media only screen and (min-width: 320px) {
    margin-bottom: 16px;
    opacity: 0;
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 0;
    opacity: 1;
  }
`;

export const SidebarLogotype = () => (
  <StyledSidebarHamburger>
    <Link to="/">
      <Logotype />
    </Link>
  </StyledSidebarHamburger>
);
