import React from 'react';
import {Play} from '@phosphor-icons/react';
import {StartEvent} from '@opeq-dev/openquiz-machine';
import {useGameService, useMachineEvent} from '@/features/machine';
import {ControlBarButton} from '../ControlBarButton';

export const LeaderStartButton = () => {
  const {isMatch} = useGameService();
  const {event} = useMachineEvent();

  const onClickStart = () => {
    event<StartEvent>('start', undefined);
  };

  if (!isMatch([{game: 'idle'}])) {
    return null;
  }

  return (
    <ControlBarButton onClick={onClickStart}>
      <Play size={24} weight="bold" />
      <span>Запустить игру</span>
    </ControlBarButton>
  );
};
