import {WifiX} from '@phosphor-icons/react';
import React from 'react';
import {NavLink} from 'react-router-dom';
import styled, {useTheme} from 'styled-components';
import {DotsLoader, Logotype} from '@openquiz/quiz-ui';

const StyledErrorScreen = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  backdrop-filter: blur(24px);
  z-index: 100;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: auto;
  padding-top: 24px;
  max-width: 600px;
  width: 100%;
`;

const Footer = styled.div`
  padding-top: 24px;
  margin-top: auto;
`;

const Message = styled.div`
  ${p => p.theme.typography.body.lg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorScreen = () => {
  const theme = useTheme();
  return (
    <StyledErrorScreen>
      <Content>
        <WifiX size={64} weight="bold" color={theme.colors.danger.text} />
        <Message>Потеряно соединение с сервером игры</Message>
        <DotsLoader color="accent" />
      </Content>
      <Footer>
        <NavLink to="/">
          <Logotype />
        </NavLink>
      </Footer>
    </StyledErrorScreen>
  );
};
