import {State} from 'xstate';
import {Ctx, getPkgVersion} from '@opeq-dev/openquiz-machine';

/**
 *
 * @param state
 * @returns
 */
export const selectCtxCurrentRound = (state: State<Ctx>) =>
  getPkgVersion(state.context)?.rounds.find(
    round => round.uuid === state.context.current.roundUuid
  );
