import React, {HTMLAttributes, ReactNode, forwardRef} from 'react';
import {motion} from 'framer-motion';
import styled, {css} from 'styled-components';

interface TitleProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  layoutId?: string;
}

const StyledTitle = styled.div<{
  $size: TitleProps['size'];
}>`
  ${p =>
    p.$size === 'sm' &&
    css`
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
    `}

  ${p =>
    p.$size === 'lg' &&
    css`
      margin-bottom: 24px;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
    `}


  :last-child {
    margin-bottom: 0;
  }
`;

export const Title = forwardRef<HTMLDivElement, TitleProps>(function Title(
  {children, size = 'md', layoutId, ...rest},
  ref
) {
  return (
    <StyledTitle ref={ref} $size={size} {...rest}>
      <motion.span layoutId={layoutId}>{children}</motion.span>
    </StyledTitle>
  );
});
