import React from 'react';
import {
  EnterAnswerHint,
  FinalTopBar,
  LeaderReviewHint,
  RenderSteps,
  Screen,
  ScreenContent,
  ScreenFooter,
  useGameService
} from '@/features/machine';

export const FinalQuestionScreen = () => {
  const {isMatch} = useGameService();

  return (
    <Screen>
      <FinalTopBar />
      <ScreenContent>
        {isMatch([
          {game: {finalRound: {question: 'play'}}},
          {game: {finalRound: {question: 'review'}}}
        ]) && <RenderSteps stage="play" />}

        {isMatch([{game: {finalRound: {question: 'answer'}}}]) && (
          <RenderSteps stage="answer" />
        )}
      </ScreenContent>

      <ScreenFooter>
        {isMatch([{game: {finalRound: {question: 'play'}}}]) && <EnterAnswerHint />}
        {isMatch([{game: {finalRound: {question: 'review'}}}]) && <LeaderReviewHint />}
      </ScreenFooter>
    </Screen>
  );
};
