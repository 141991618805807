import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {CloudArrowUp} from '@phosphor-icons/react';
import {Button, Callout, FieldGroup, FieldRow, FieldSection} from '@openquiz/quiz-ui';
import {fetchConvertPackage, fetchConvertStatus} from '@/features/workshop';

interface UploadFileFieldProps {
  onComplete: (value: string) => void;
  onDiscard: () => void;
}

const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export const UploadFileField = ({onComplete, onDiscard}: UploadFileFieldProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState({
    upload: 0,
    convert: 0
  });

  const [error, setError] = useState<string | null>(null);

  const poolingJobStatus = async (id: number) => {
    await sleep(300);

    const {
      progress: convertProgress,
      value,
      error: convertError
    } = await fetchConvertStatus(id);

    setProgress(p => ({...p, convert: convertProgress}));

    if (convertError) {
      setError(
        convertError === 'Version 5 is not supported.'
          ? 'Пакеты версии 5 не поддерживаются.'
          : 'В паке имеется ошибка.'
      );
      return;
    }

    if (convertProgress < 100) {
      await poolingJobStatus(id);
    } else {
      if (value) {
        onComplete(value);
      }
    }
  };

  const onDrop = async (files: File[]) => {
    if (!files.length) return;
    setFile(files[0]);

    const {id} = await fetchConvertPackage(files[0], value =>
      setProgress(p => ({...p, upload: value}))
    );

    await poolingJobStatus(id);
  };

  const {getRootProps, getInputProps} = useDropzone({
    accept: {'application/zip': ['.zip', '.siq']},
    multiple: false,
    onDrop
  });

  if (error) {
    return (
      <FieldSection label="Ошибка при конвертации">
        <FieldGroup>
          <Callout status="danger">
            <p>{error}</p>
          </Callout>
        </FieldGroup>

        <FieldGroup>
          <Button variant="secondary" onClick={onDiscard}>
            Закрыть
          </Button>
        </FieldGroup>
      </FieldSection>
    );
  }

  if (file) {
    return (
      <FieldSection label="Прогресс загрузки">
        <ol>
          <li>Загрузка {progress.upload > 0 && `${progress.upload}%`}</li>
          <li>Оптимизация файлов {progress.convert > 0 && `${progress.convert}%`}</li>
        </ol>
      </FieldSection>
    );
  }

  return (
    <FieldGroup size="xlg">
      <FieldRow>
        <Button {...getRootProps()}>
          <input {...getInputProps()} />
          <CloudArrowUp size={20} weight="bold" />
          <span>Выбрать файл</span>
        </Button>
        <Button variant="secondary" onClick={onDiscard}>
          Назад
        </Button>
      </FieldRow>
    </FieldGroup>
  );
};
