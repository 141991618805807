import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';

interface ContainerProps {
  children: ReactNode;
  size?: 'md' | 'lg';
}

const StyledContainer = styled.div<{$size: ContainerProps['size']}>`
  margin: 0 auto;
  width: 100%;

  ${p =>
    p.$size === 'md' &&
    css`
      max-width: 1024px;
    `}

  ${p =>
    p.$size === 'lg' &&
    css`
      max-width: 1668px;
    `}
`;

export const Container = ({children, size = 'md'}: ContainerProps) => (
  <StyledContainer $size={size}>{children}</StyledContainer>
);
