import useResizeObserver from 'use-resize-observer';

/**
 *
 * @returns
 */
export const useLogotypeObserver = () => {
  const {ref, height = 0} = useResizeObserver<HTMLDivElement>();

  const circleHeight = Math.min(height / 2 - 2, 96);

  return {
    ref,
    height: circleHeight
  };
};
