import React from 'react';
import {motion} from 'framer-motion';
import styled from 'styled-components';
import {OpenQuizIcon, getAnimationProps} from '@openquiz/quiz-ui';

const StyledOpenQuizGif = styled.div`
  position: relative;
  margin: -64px -64px 0 -64px;
  padding: 64px 0 0 0;
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
`;

const motionProps = getAnimationProps(
  {
    initial: {rotate: 180, scale: 0},
    animate: {rotate: 0, scale: 1, transition: {delay: 0.4}},
    exit: {y: 32, rotate: 32, scale: 0, opacity: 0}
  },
  {duration: 0.2}
);

export const OpenQuizGif = () => (
  <StyledOpenQuizGif>
    <motion.div {...motionProps}>
      <OpenQuizIcon size={80} />
    </motion.div>
  </StyledOpenQuizGif>
);
