/**
 *
 * @param length
 */
export const getSliderMarks = (length: number) =>
  Array.from({length}, (_, i) => i + 1).reduce(
    (acc, i) => ({
      ...acc,
      [i]: i
    }),
    {}
  );
