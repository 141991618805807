import React from 'react';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';
import {ErrorMessage} from '@hookform/error-message';
import {
  Button,
  FieldGroup,
  FieldRow,
  TextArea,
  TipCircle,
  ErrorMessage as UiErrorMessage
} from '@openquiz/quiz-ui';

export interface SpeechFormData {
  text: string;
  transcription: string | null;
}

interface SpeechFormProps {
  data: SpeechFormData;
  onSubmit: (data: SpeechFormData) => void;
  onCancel: () => void;
}

const Text = styled.div`
  ${p => p.theme.typography.body.sm};
  background-color: ${p => p.theme.colors.canvas.default};
  border-radius: 12px;
  padding: 12px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export const SpeechForm = ({data, onSubmit, onCancel}: SpeechFormProps) => {
  const {
    formState: {isSubmitting, errors},
    register,
    handleSubmit
  } = useForm({defaultValues: data});

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldGroup>
        <Text>{data.text}</Text>
      </FieldGroup>

      <FieldGroup
        label={
          <FieldRow size="xs">
            <span>SSML Разметка</span>
            <TipCircle message="SSML (Speech Synthesis Markup Language) — язык разметки синтеза речи. Позволяет настраивать преобразование текста в речь и делает речь более естественной и выразительной." />
          </FieldRow>
        }>
        <p>
          Инструкция:{' '}
          <a
            href="https://cloud.yandex.ru/ru/docs/speechkit/tts/markup/ssml"
            target="_blank"
            rel="noreferrer">
            Yandex Cloud SSML
          </a>
        </p>
        <TextArea
          {...register('transcription', {
            maxLength: {value: 600, message: 'Максимальная длинна 600 символов.'}
          })}
          style={{fontSize: 14, lineHeight: 'normal'}}
          placeholder="Оставьте пустым, если нужен оригинальный текст."
          rows={8}
        />
        <ErrorMessage
          errors={errors}
          name="transcription"
          render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
        />
      </FieldGroup>

      <FieldGroup>
        <FieldRow justify="flex-end">
          <Button variant="secondary" onClick={onCancel}>
            Отмена
          </Button>
          <Button type="submit" fetching={isSubmitting}>
            Озвучить
          </Button>
        </FieldRow>
      </FieldGroup>
    </form>
  );
};
