import React from 'react';
import styled from 'styled-components';
import {User} from '@opeq-dev/openquiz-schema';
import {Avatar} from '@openquiz/quiz-ui';

interface AuthorChipProps {
  user: User;
}

const StyledAuthorChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 5px;
  color: inherit;
`;

const Name = styled.div`
  ${p => p.theme.typography.caption.md};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
`;

export const AuthorChip = ({user}: AuthorChipProps) => (
  <StyledAuthorChip>
    <Avatar user={user} size={20} />
    <Name>{user.username}</Name>
  </StyledAuthorChip>
);
