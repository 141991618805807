import React, {ReactNode, forwardRef} from 'react';
import styled, {css} from 'styled-components';

interface SegmentProps {
  label: ReactNode;
  value: number | string | null;
}

interface SegmentedProps {
  segments: SegmentProps[];
  disabled?: boolean;
  value: SegmentProps['value'];
  onChange: (segment: SegmentProps['value']) => void;
}

const StyledSegmented = styled.div`
  background-color: ${p => p.theme.colors.neutral.subtle};
  border-radius: 12px;
  padding: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  max-width: 400px;
  user-select: none;
`;

const Segment = styled.div<{$active?: boolean}>`
  color: ${p => p.theme.colors.text.muted};
  position: relative;
  border-radius: 8px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 4px;
  width: 100%;
  cursor: pointer;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out,
    box-shadow 80ms ease-in-out,
    transform 80ms ease-in-out;

  &:active {
    transform: scale(0.98);
  }

  ${p =>
    p.$active
      ? css`
          background-color: ${p => p.theme.colors.accent.emphasis};
          box-shadow: ${p => p.theme.shadow.sm};
          color: ${p => p.theme.colors.text.onEmphasis};
          pointer-events: none;
        `
      : css`
          :hover {
            background-color: ${p => p.theme.colors.canvas.overlay};
          }
        `}
`;

export const Segmented = forwardRef<HTMLDivElement, SegmentedProps>(function Segmented(
  {value, segments, onChange},
  ref
) {
  return (
    <StyledSegmented ref={ref}>
      {segments.map(segment => (
        <Segment
          key={`segment-${segment.value}`}
          $active={value === segment.value}
          onClick={() => onChange(segment.value)}>
          <span>{segment.label}</span>
        </Segment>
      ))}
    </StyledSegmented>
  );
});
