import {State} from 'xstate';
import {Ctx, UserRecord} from '@opeq-dev/openquiz-machine';

/**
 *
 * @param state
 * @returns
 */
export const selectHost = (state: State<Ctx>): UserRecord | undefined =>
  state.context.users.filter(user => user.role === 'LEADER')[0] ?? undefined;
