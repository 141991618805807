import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface MenuGroupProps {
  children: ReactNode;
  label?: string;
}

const StyledMenuGroup = styled.div`
  border-bottom: 1px solid ${p => p.theme.colors.border.subtle};
  padding-bottom: 4px;
  margin-bottom: 4px;

  :last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  :empty {
    display: none;
  }
`;

const Label = styled.div`
  ${p => p.theme.typography.caption.md};
  color: ${p => p.theme.colors.text.subtle};
  margin: 8px -8px 4px -8px;
  padding: 0 12px;
`;

export const MenuGroup = ({children, label}: MenuGroupProps) => (
  <StyledMenuGroup>
    {label && <Label>{label}</Label>}
    {children}
  </StyledMenuGroup>
);
