import React from 'react';
import {InfinityPackagesGrid} from '@/features/workshop';
import {Container, Page, PageTitle} from '@/components';

export const FavoritesPage = () => (
  <Page title="Закладки – Мастерская">
    <Container>
      <PageTitle to={{href: '/'}} title="Закладки" />
      <InfinityPackagesGrid params={{filter: {ownedFavorites: true}, take: 6}} />
    </Container>
  </Page>
);
