import React, {FunctionComponent, createElement} from 'react';
import styled from 'styled-components';
import {PlaceholderLine, PlaceholderLineProps} from './PlaceholderLine';

type Placeholders = 'line';

interface PlaceholderProps {
  config?: [Placeholders, PlaceholderLineProps][];
}

const placeholderMap = new Map<Placeholders, FunctionComponent<PlaceholderLineProps>>([
  ['line', PlaceholderLine]
]);

const defaultConfig: PlaceholderProps['config'] = [
  ['line', {}],
  ['line', {}],
  ['line', {}]
];

const StyledPlaceholder = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  :last-child {
    margin-bottom: 0;
  }
`;

export const Placeholder = ({config = defaultConfig}: PlaceholderProps) => {
  return (
    <StyledPlaceholder>
      {config.map((placeholder, index) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        createElement(placeholderMap.get(placeholder[0])!, {
          ...placeholder[1],
          key: `placeholder-${index}`
        })
      )}
    </StyledPlaceholder>
  );
};
