import React, {ReactNode} from 'react';
import {AnimatePresence} from 'framer-motion';
import styled from 'styled-components';

interface TabsProps {
  children: ReactNode;
}

const StyledTabs = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

export const Tabs = ({children}: TabsProps) => (
  <StyledTabs>
    <AnimatePresence>{children}</AnimatePresence>
  </StyledTabs>
);
