import {FunctionComponent, ReactElement, useCallback, useId} from 'react';
import {modalStore} from '../modalStore';
import {ModalParams, ModalProps} from '../types';

/**
 * This hook is used to show modal windows.
 * @param component
 * @param params
 */
export const useModal = (
  component: FunctionComponent<ModalProps>,
  params: Partial<ModalParams> = {}
) => {
  const id = useId();
  const {push, shift} = modalStore();

  const closeModal = useCallback(() => {
    shift(id);
  }, [id, shift]);

  return useCallback(() => {
    push(id, component({onCancel: closeModal}) as ReactElement, params);
  }, [push, id, component, params, closeModal]);
};
