import React, {FunctionComponent} from 'react';

/**
 * Modals are represented as react components
 * This is what gets passed to useModal as the first argument.
 */
export type ModalType = FunctionComponent<unknown>;

export type ModalOpenCallback = () => void;

export type ModalCloseCallback = (options?: {closeAll?: boolean}) => void;

/**
 * The shape of the modal context
 */
export interface ModalContextType {
  onOpenModal(key: string, component: ModalType): void;
  onCloseModal(key: string): void;
  onCloseAllModals(): void;
}

/**
 * Throw error when ModalContext is used outside of context provider
 */
const invariantViolation = () => {
  throw new Error(
    'Attempted to call useModal outside of modal context. Make sure your app is rendered inside ModalProvider.'
  );
};

/**
 * Modal Context Object
 */
export const ModalContext = React.createContext<ModalContextType>({
  onOpenModal: invariantViolation,
  onCloseModal: invariantViolation,
  onCloseAllModals: invariantViolation
});
