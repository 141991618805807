import { fetchUploadFile } from "@/api/file";
import { Speech, useSpeechGuard } from "@/features/speech";
import { QuestionFormDto, StepSpeechModal } from "@/features/wshop-editor";
import { ErrorMessage } from "@hookform/error-message";
import { ModalConfirm, useModal, useModalConfirm } from "@openquiz/quiz-modal";
import {
  Button,
  DropzoneFile,
  FieldGroup,
  FieldRow,
  Grid,
  Input,
  PreviewFile,
  Segmented,
  Select,
  TextArea,
  TipCircle,
  ErrorMessage as UiErrorMessage,
  requirements,
} from "@openquiz/quiz-ui";
import { textReadDuration } from "@opeq-dev/openquiz-machine";
import { File as FileType, QuestionStepType } from "@opeq-dev/openquiz-schema";
import {
  MagicWand,
  NumberSquareFour,
  NumberSquareOne,
  NumberSquareThree,
  NumberSquareTwo,
  Trash,
} from "@phosphor-icons/react";
import React, { createElement } from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  UseFormRegister,
  UseFormSetValue,
  useFormState,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";

interface StepFormProps {
  control: Control<QuestionFormDto>;
  register: UseFormRegister<QuestionFormDto>;
  setValue: UseFormSetValue<QuestionFormDto>;
  index: number;
}

const Text = styled.div`
  background-color: ${(p) => p.theme.colors.canvas.default};
  border-radius: 12px;
  padding: 16px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Transcription = styled.div`
  ${(p) => p.theme.typography.body.sm};
  color: ${(p) => p.theme.colors.text.subtle};
  margin-top: 16px;
  opacity: 0.5;
`;

const Duration = styled.div`
  ${(p) => p.theme.typography.body.sm};
  color: ${(p) => p.theme.colors.text.subtle};
  margin-top: 4px;
  opacity: 0.5;
`;

export const StepForm = ({
  control,
  register,
  setValue,
  index,
}: StepFormProps) => {
  const formKey: `steps.${number}` = `steps.${index}`;

  const speechGuard = useSpeechGuard();
  const { errors } = useFormState({ control });
  const { type: questionType, steps: questionSteps = [] } = useWatch({
    control,
  });
  const { uuid, type, payload, transcription, speech, isAnswer } = useWatch({
    control,
    name: formKey,
  });

  const confirmChangeType = useModalConfirm((props) => (
    <ModalConfirm
      {...props}
      content={<>При смене типа контента, содержимое шага будет очищено.</>}
      resolveLabel="Сменить тип"
    />
  ));

  const onChangeType = (
    type: QuestionStepType,
    field: ControllerRenderProps<QuestionFormDto, `steps.${number}.type`>,
  ) => {
    if (payload) {
      confirmChangeType()
        .then(() => {
          setValue(`${formKey}.questionStepFile`, []);
          setValue(`${formKey}.payload`, "");
          setValue(`${formKey}.duration`, -1);
          field.onChange(type);
        })
        .catch(() => void 0);
    } else {
      field.onChange(type);
      setValue(`${formKey}.duration`, -1);
    }
  };

  const onChangeFile = (file: FileType) => {
    setValue(`${formKey}.questionStepFile`, [{ fileUuid: file.uuid }]);
    setValue(`${formKey}.payload`, file.src);
    setValue(`${formKey}.duration`, file.duration);
  };

  const onDeleteFile = () => {
    setValue(`${formKey}.questionStepFile`, []);
    setValue(`${formKey}.payload`, "");
    setValue(`${formKey}.duration`, -1);
  };

  const onSpeechReady = (speech: Speech, transcription: string | null) => {
    onClickDeleteSpeech();

    setTimeout(() => {
      setValue(`${formKey}.transcription`, transcription);
      setValue(`${formKey}.speech`, speech);
      setValue(`${formKey}.speechUuid`, speech.uuid);
      setValue(`${formKey}.duration`, speech.duration);
    });
  };

  const onClickDeleteSpeech = () => {
    setValue(`${formKey}.speech`, null);
    setValue(`${formKey}.speechUuid`, null);
  };

  const openSpeechModal = useModal((props) =>
    createElement(StepSpeechModal, {
      ...props,
      data: { text: payload, transcription },
      onSpeechReady,
    }),
  );

  if (speech) {
    return (
      <FieldGroup>
        <FieldGroup size="sm">
          <FieldRow size="sm">
            {speechGuard && (
              <Button variant="link2" onClick={openSpeechModal}>
                <MagicWand size={20} weight="duotone" />
                <span>Переозвучить шаг</span>
              </Button>
            )}
            <Button variant="link2" onClick={onClickDeleteSpeech}>
              <Trash size={20} weight="duotone" />
              <span>Удалить озвучку</span>
            </Button>
          </FieldRow>
        </FieldGroup>

        <FieldGroup size="sm">
          <audio controls={true}>
            <source src={speech.src} />
          </audio>
        </FieldGroup>

        <FieldGroup size="sm">
          <Text>{payload}</Text>
          {transcription && <Transcription>[T] {transcription}</Transcription>}
        </FieldGroup>
      </FieldGroup>
    );
  }

  return (
    <>
      {speechGuard && type === "TEXT" && payload && (
        <FieldGroup size="sm">
          <Button variant="link2" onClick={openSpeechModal}>
            <MagicWand size={20} weight="duotone" />
            <span>Озвучить шаг</span>
          </Button>
        </FieldGroup>
      )}

      <FieldGroup size="sm">
        <Controller
          control={control}
          name={`${formKey}.type`}
          render={({ field }) => (
            <Segmented
              {...field}
              onChange={(type) => onChangeType(type as QuestionStepType, field)}
              segments={[
                { label: "Текст", value: "TEXT" },
                { label: "Изображение", value: "IMAGE" },
                { label: "Видео", value: "VIDEO" },
                { label: "Аудио", value: "AUDIO" },
              ]}
            />
          )}
        />
      </FieldGroup>

      {type === "TEXT" && (
        <FieldGroup>
          <TextArea
            {...register(`${formKey}.payload`, {
              required: "Обязательное поле",
              maxLength: {
                value: 600,
                message: "Максимальная длинна 600 символов.",
              },
            })}
            placeholder="Текст вопроса"
            rows={5}
          />
          <ErrorMessage
            errors={errors}
            name={`${formKey}.payload`}
            render={({ message }) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>
      )}

      {type !== "TEXT" && (
        <FieldGroup>
          {payload ? (
            <PreviewFile type={type} src={payload} onDelete={onDeleteFile} />
          ) : (
            <DropzoneFile
              requirements={requirements[type]}
              fn={(file, onProgress) =>
                fetchUploadFile(
                  file,
                  { folder: "workshop/media", stepUuid: uuid },
                  onProgress,
                )
              }
              onChange={onChangeFile}
            />
          )}
        </FieldGroup>
      )}

      <FieldGroup label="Подпись">
        <TextArea
          {...register(`${formKey}.caption`, {
            maxLength: {
              value: 128,
              message: "Максимальная длинна 128 символов.",
            },
          })}
          rows={2}
        />
        <ErrorMessage
          errors={errors}
          name={`${formKey}.caption`}
          render={({ message }) => <UiErrorMessage>{message}</UiErrorMessage>}
        />
      </FieldGroup>

      {questionType?.includes("VARIANTS") && (
        <>
          {isAnswer ? (
            <FieldGroup label="Правильный вариант">
              <Select
                {...register(`${formKey}.variants.0`, {
                  required: "Обязательное поле",
                })}
              >
                {(questionSteps?.find((s) => !s.isAnswer)?.variants || []).map(
                  (variant, index) => (
                    <option key={`variant-option-${index}`} value={index}>
                      {index + 1}. {variant}
                    </option>
                  ),
                )}
              </Select>
              <ErrorMessage
                errors={errors}
                name={`${formKey}.variants.0`}
                render={({ message }) => (
                  <UiErrorMessage>{message}</UiErrorMessage>
                )}
              />
            </FieldGroup>
          ) : (
            <FieldGroup label="Варианты">
              <Grid cols={{ sm: 1, md: 2, lg: 2 }} gap={8}>
                <div>
                  <Input
                    {...register(`${formKey}.variants.0`, {
                      required: "Обязательное поле",
                      maxLength: {
                        value: 32,
                        message: "Максимальная длинна 32 символа.",
                      },
                    })}
                    placeholder="Вариант ответа"
                    extraLeft={<NumberSquareOne size={20} weight="bold" />}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`${formKey}.variants.0`}
                    render={({ message }) => (
                      <UiErrorMessage>{message}</UiErrorMessage>
                    )}
                  />
                </div>
                <div>
                  <Input
                    {...register(`${formKey}.variants.1`, {
                      required: "Обязательное поле",
                      maxLength: {
                        value: 32,
                        message: "Максимальная длинна 32 символа.",
                      },
                    })}
                    placeholder="Вариант ответа"
                    extraLeft={<NumberSquareTwo size={20} weight="bold" />}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`${formKey}.variants.1`}
                    render={({ message }) => (
                      <UiErrorMessage>{message}</UiErrorMessage>
                    )}
                  />
                </div>
                <div>
                  <Input
                    {...register(`${formKey}.variants.2`, {
                      maxLength: {
                        value: 32,
                        message: "Максимальная длинна 32 символа.",
                      },
                    })}
                    placeholder="Вариант ответа"
                    extraLeft={<NumberSquareThree size={20} weight="bold" />}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`${formKey}.variants.2`}
                    render={({ message }) => (
                      <UiErrorMessage>{message}</UiErrorMessage>
                    )}
                  />
                </div>
                <div>
                  <Input
                    {...register(`${formKey}.variants.3`, {
                      maxLength: {
                        value: 32,
                        message: "Максимальная длинна 32 символа.",
                      },
                    })}
                    placeholder="Вариант ответа"
                    extraLeft={<NumberSquareFour size={20} weight="bold" />}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`${formKey}.variants.3`}
                    render={({ message }) => (
                      <UiErrorMessage>{message}</UiErrorMessage>
                    )}
                  />
                </div>
              </Grid>
            </FieldGroup>
          )}
        </>
      )}

      <FieldGroup
        label={
          <FieldRow size="xs">
            <span>Длительность</span>
            <TipCircle
              message={
                <>
                  <p>
                    Время воспроизведения шага. Длительность шагов суммируется.
                  </p>
                  <p>
                    Мин: 1 сек.
                    <br />
                    Макс: 120 сек.
                  </p>
                  <p>Значение по умолчанию: -1.</p>
                </>
              }
              size="lg"
            />
          </FieldRow>
        }
      >
        <FieldRow>
          <div style={{ width: 120 }}>
            <Input
              {...register(`${formKey}.duration`, {
                required: "Обязательное поле",
                min: { value: -1, message: "Минимальное значение -1 секунда." },
                max: {
                  value: 120,
                  message: "Максимальное значение 120 секунд.",
                },
                validate: (value) =>
                  value === 0 ? "Некорректное значение." : undefined,
                valueAsNumber: true,
              })}
              step="any"
              extraRight="сек."
              type="number"
            />
          </div>
        </FieldRow>

        <ErrorMessage
          errors={errors}
          name={`${formKey}.duration`}
          render={({ message }) => <UiErrorMessage>{message}</UiErrorMessage>}
        />
      </FieldGroup>
    </>
  );
};
