import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export type ThemeCreateData = Omit<PackageTheme, 'uuid'>;

export interface FetchThemeDeleteParams {
  uuid: string;
  packageUuid: string;
}

/**
 *
 * @param params
 */
export const fetchThemeDelete = async (params: FetchThemeDeleteParams) => {
  const {data} = await getPrivateAxios().delete<void>(
    `/workshop/packages/${params.packageUuid}/themes/${params.uuid}`
  );
  return data;
};
