import React from 'react';
import styled from 'styled-components';
import {PackageQuestion, PackageQuestionStep} from '@opeq-dev/openquiz-schema';

interface TooltipPreviewProps {
  question: PackageQuestion & {steps: PackageQuestionStep[]};
}

const StyledTooltipPreview = styled.div`
  position: relative;
  width: 100%;
  min-width: 120px;

  img,
  video {
    border-radius: 16px;
    display: flex;
    margin: -16px;
    width: 320px;
    max-height: 240px;
    object-fit: cover;
  }

  audio {
    background-color: transparent;
    height: 64px;
    width: 240px;
  }
`;

export const TooltipPreview = ({question}: TooltipPreviewProps) => {
  const questionsStep = question.steps.find(s => !s.isAnswer);

  return (
    <StyledTooltipPreview>
      {questionsStep?.type === 'TEXT' && <div>{questionsStep.payload}</div>}
      {questionsStep?.type === 'IMAGE' && <img src={questionsStep.payload} alt="" />}
      {questionsStep?.type === 'VIDEO' && (
        <video src={questionsStep.payload} autoPlay={true} muted={true} />
      )}
      {questionsStep?.type === 'AUDIO' && (
        <video
          src={questionsStep.payload}
          autoPlay={false}
          muted={false}
          controls={true}
        />
      )}
    </StyledTooltipPreview>
  );
};
