import React, {ReactNode, createElement} from 'react';
import {Link} from 'react-router-dom';
import {ArrowRight} from '@phosphor-icons/react';
import styled, {css} from 'styled-components';
import {PhosphorIcon} from '../../../types';

interface SectionProps {
  children: ReactNode;
  label?: {
    children: ReactNode;
    icon?: PhosphorIcon;
    to?: string;
  };
}

const StyledSection = styled.div`
  margin-bottom: 48px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  gap: 24px;

  > svg {
    flex-shrink: 0;
    margin-right: -16px;
    color: ${p => p.theme.colors.text.subtle};
  }
`;

const LabelDivider = styled.div`
  border-bottom: 2px solid ${p => p.theme.colors.border.subtle};
  width: 100%;
  height: 0;
`;

const LabelLink = styled(Link)<{$hasLink: boolean}>`
  ${p => p.theme.typography.label.md};
  color: ${p => p.theme.colors.text.muted};
  border-radius: 4px;
  display: inline-flex;
  flex-shrink: 0;
  margin: 0 auto;
  text-align: center;
  gap: 8px;
  cursor: pointer;
  backdrop-filter: blur(4px);
  pointer-events: none;
  transition:
    background-color ease-in-out 80ms,
    color ease-in-out 80ms;

  font-size: 16px;
  line-height: 20px;

  ${p =>
    p.$hasLink &&
    css`
      pointer-events: all;
    `}

  :hover {
    background-color: ${p => p.theme.colors.accent.emphasis};
    color: ${p => p.theme.colors.text.onEmphasis};
  }
`;

export const Section = ({children, label}: SectionProps) => (
  <StyledSection>
    {label && (
      <Label>
        <LabelLink to={label.to ?? '#'} $hasLink={!!label.to}>
          {label.icon && createElement(label.icon, {size: 16, weight: 'fill'})}
          <span>{label.children}</span>
          {label.to && <ArrowRight size={20} weight="bold" />}
        </LabelLink>
        <LabelDivider />
      </Label>
    )}
    <div>{children}</div>
  </StyledSection>
);
