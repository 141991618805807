import {getPrivateAxios} from '@/libs';

export interface EvaluateRatingData {
  packageUuid: string;
  value: number;
  feedback: string;
}

/**
 * Submit evaluate rating.
 * @param params
 */
export const fetchEvaluateRating = async ({
  packageUuid,
  value,
  feedback
}: EvaluateRatingData) => {
  await getPrivateAxios().post<void>(
    `/workshop/packages/${packageUuid}/evaluate-rating`,
    {value, feedback}
  );
};
