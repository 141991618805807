import React from 'react';
import {FinalRoundSplash} from '@/features/machine/components/@splash-screens';
import {useGameService} from '@/features/machine';
import {FinalBansScreen} from './FinalBansScreen';
import {FinalBetsScreen} from './FinalBetsScreen';
import {FinalQuestionScreen} from './FinalQuestionScreen';

export const FinalRoundScreen = () => {
  const {isMatch} = useGameService();

  const screens = {
    splash: isMatch([{game: {finalRound: 'idle'}}, {game: {finalRound: 'splash'}}]),
    bans: isMatch([{game: {finalRound: 'bans'}}]),
    bets: isMatch([{game: {finalRound: 'bets'}}]),
    question: isMatch([
      {game: {finalRound: {question: 'review'}}},
      {game: {finalRound: {question: 'play'}}},
      {game: {finalRound: {question: 'answer'}}}
    ])
  };

  return (
    <>
      {screens.splash && <FinalRoundSplash />}
      {screens.bans && <FinalBansScreen />}
      {screens.bets && <FinalBetsScreen />}
      {screens.question && <FinalQuestionScreen />}
    </>
  );
};
