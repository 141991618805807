import React from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {colord} from 'colord';
import {ArrowSquareOut, Check} from '@phosphor-icons/react';
import {Contest} from '@opeq-dev/openquiz-schema';

interface ContestCheckboxProps {
  contest: Contest;
  selected: boolean;
  onChange: (contest: Contest) => void;
}

const StyledContestCheckbox = styled.div<{$color: string}>`
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.default};
  margin-bottom: 5px;
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  transition:
    background-color ease-in-out 80ms,
    border-color ease-in-out 80ms;

  &:hover {
    background-color: ${p => colord(p.$color).alpha(0.05).toRgbString()};
    border-color: ${p => p.$color};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.div<{$color: string; $selected: boolean}>`
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.default};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  transition:
    background-color ease-in-out 80ms,
    border-color ease-in-out 80ms;

  ${p =>
    p.$selected &&
    css`
      background-color: ${p.$color};
      border-color: ${p.$color};
      color: ${p => p.theme.colors.text.onEmphasis};
    `}
`;

const Name = styled.div`
  ${p => p.theme.typography.label.md};
  margin-bottom: 5px;
`;

const Caption = styled.div`
  ${p => p.theme.typography.caption.sm};
  margin-bottom: 5px;
  opacity: 0.6;
`;

const MoreLink = styled(Link)<{$color: string}>`
  ${p => p.theme.typography.caption.sm};
  color: ${p => p.$color};
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;

export const ContestCheckbox = ({contest, selected, onChange}: ContestCheckboxProps) => {
  const onClick = () => {
    onChange(contest);
  };

  return (
    <StyledContestCheckbox
      $color={contest.style.color}
      data-selected={selected}
      onClick={onClick}>
      <Icon $color={contest.style.color} $selected={selected}>
        {selected && <Check size={14} weight="bold" />}
      </Icon>
      <div>
        <Name>{contest.name}</Name>
        <Caption>{contest.description}</Caption>
        <MoreLink
          $color={contest.style.color}
          target="_blank"
          to="https://wiki.opeq.dev/contests/new-year-2024">
          <ArrowSquareOut size={14} weight="duotone" />
          <span>Подробнее</span>
        </MoreLink>
      </div>
    </StyledContestCheckbox>
  );
};
