import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface ScreenFooterProps {
  children: ReactNode;
}

const StyledScreenFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 16px;
  min-height: 20px;
  flex-shrink: 0;
`;

export const ScreenFooter = ({children}: ScreenFooterProps) => (
  <StyledScreenFooter>{children}</StyledScreenFooter>
);
