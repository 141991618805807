import {Reaction} from '../types';

export const reactionList: Reaction[] = [
  {name: 'thumbsUp', icon: '👍'},
  {name: 'poop', icon: '💩'},
  {name: 'confetti', icon: '🎉'},
  {name: 'laugh', icon: '😂'},
  {name: 'cool', icon: '😎'},
  {name: 'sad', icon: '😢'},
  {name: 'angry', icon: '😡'},
  {name: 'clap', icon: '👏'},
  {name: 'thumbsDown', icon: '👎'}
];
