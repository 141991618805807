import React from 'react';
import {useSelector} from '@xstate/react';
import styled from 'styled-components';
import {TypeFinalAnswerEvent} from '@opeq-dev/openquiz-machine';
import {FinalAnswerCard} from '@/features/machine/components/@layouts/LeftSidebar/FinalAnswerCard';
import {useGameService} from '@/features/machine';

const StyledPlayerFinalAnswers = styled.div`
  margin: 0 auto 16px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
`;

export const PlayerFinalAnswers = () => {
  const {service} = useGameService();

  const finalAnswers = useSelector(service, state => {
    const answers = ((
      state.context.history.filter(
        r => r.type === 'typeFinalAnswer'
      ) as TypeFinalAnswerEvent[]
    ).filter(record => {
      return state.context.history.some(
        r =>
          (r.type === 'acceptFinalAnswer' || r.type === 'rejectFinalAnswer') &&
          r.payload.teamUuid === record.self.teamUuid
      );
    }) ?? []) as TypeFinalAnswerEvent[];

    return answers.map(answer => ({
      answer,
      decision: state.context.history.some(
        r => r.type === 'acceptFinalAnswer' && r.payload.teamUuid === answer.self.teamUuid
      )
    }));
  });

  if (!finalAnswers.length) {
    return null;
  }

  return (
    <StyledPlayerFinalAnswers>
      {finalAnswers.map(({answer, decision}) => (
        <FinalAnswerCard
          key={`final-answer-${answer.self.teamUuid}`}
          action={answer}
          decision={decision ? 'accept' : 'reject'}
        />
      ))}
    </StyledPlayerFinalAnswers>
  );
};
