import {useMutation, useQueryClient} from '@tanstack/react-query';
import {fetchGameStop} from '../raw';
import {Game} from '@/features/game';

export const useGameStopMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchGameStop,
    onSuccess: (_data, uuid) => {
      queryClient.setQueryData<Game[]>(['games', {filter: {ownedGames: true}}], games => {
        if (!games) return [];
        return games.filter(game => game.uuid !== uuid);
      });
    }
  });
};
