export const requirements: Record<
  'IMAGE' | 'VIDEO' | 'AUDIO',
  {accept: {[key: string]: string[]}; limit: number}
> = {
  IMAGE: {
    accept: {'image/*': ['.jpeg', '.png', '.gif', '.webp']},
    limit: 5 * 1048576
  },
  VIDEO: {
    accept: {'video/*': ['.mp4', '.mov', '.webm', '.avi']},
    limit: 16 * 1048576
  },
  AUDIO: {
    accept: {'audio/*': ['.mp3', '.wav', '.ogg']},
    limit: 5 * 1048576
  }
};
