import styled from 'styled-components';

export const StyledWebCamera = styled.div`
  background-color: #000;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: opacity 160ms ease-in-out;

  video {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
