import React, {ReactElement, useMemo} from 'react';
import {FormattedNumber} from 'react-intl';
import {colord} from 'colord';
import {AnimatePresence} from 'framer-motion';
import styled, {css, keyframes} from 'styled-components';
import {CirclesThreePlus, DotsThreeOutline} from '@phosphor-icons/react';
import {TeamRecord, UserRecord} from '@opeq-dev/openquiz-machine';
import {
  ButtonMore,
  Indicator,
  PopperMenu,
  SlotIndicator,
  getLineClampCss
} from '@openquiz/quiz-ui';
import {useLogotypeObserver} from './useLogotypeObserver';

interface StyleObject {
  colors: {background: string; border: string; text: string};
}

interface TeamSlotProps {
  team: TeamRecord;
  users: UserRecord[];
  indicator?: Indicator;
  actionButton?: ReactElement;
  menu?: ReactElement;
  renderUser: (user: UserRecord) => ReactElement;
  onClickConnect?: () => void;
}

const usersAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledTeamSlot = styled.div<{$style: StyleObject}>`
  ${p =>
    p.$style &&
    css`
      background-color: ${p.$style.colors.background};
      border: 2px solid ${p.$style.colors.border};
      color: ${p.$style.colors.text};
    `}

  position: relative;
  padding: 20px 16px 16px 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  width: 100%;
  height: 100%;
`;

const IndicatorWrapper = styled.div`
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  pointer-events: none;
  border-radius: 12px;
  z-index: 10;
  overflow: hidden;
  display: flex;
`;

const TopBar = styled.div`
  position: absolute;
  right: 8px;
  left: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`;

const Logotype = styled.div`
  position: relative;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Users = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 4px;
  animation: ${usersAnimation} 240ms ease-in-out forwards;
`;

const UserCircle = styled.div<{$style: StyleObject; $clickable: boolean}>`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${p =>
    p.$clickable &&
    css`
      background-color: ${p.$style.colors.background};
      color: ${p.$style.colors.text};
      border: none;
      cursor: pointer;
      transition:
        background-color 80ms ease-in-out,
        color 80ms ease-in-out,
        transform 80ms ease-in-out;

      &:hover {
        background-color: ${p.$style.colors.text};
        color: ${p.theme.colors.text.onEmphasis};
      }

      &:active {
        transform: scale(0.95);
      }
    `}

  &:empty {
    border: 2px dashed ${p => p.$style.colors.border};
  }
`;

const Name = styled.div`
  ${p => p.theme.typography.label.md};
  ${getLineClampCss(1)};
  flex-shrink: 0;
  text-align: center;
  margin-top: auto;
  margin-bottom: 8px;
`;

const Score = styled.div`
  opacity: 0.5;
`;

export const TeamSlot = ({
  team,
  users,
  indicator,
  actionButton,
  menu,
  onClickConnect,
  renderUser
}: TeamSlotProps) => {
  const {ref, height} = useLogotypeObserver();

  // TODO: move to function
  const styleObject: StyleObject = useMemo(
    () => ({
      colors: {
        background: colord(team.style.color).alpha(0.1).toRgbString(),
        border: colord(team.style.color).alpha(0.25).toRgbString(),
        text: team.style.color
      }
    }),
    [team.style.color]
  );

  const emptyUsers = useMemo(
    () => new Array(4 - users.length || 0).fill(''),
    [users.length]
  );

  return (
    <StyledTeamSlot $style={styleObject}>
      <AnimatePresence>
        {indicator && (
          <IndicatorWrapper>
            <SlotIndicator key={indicator.type} indicator={indicator} />
          </IndicatorWrapper>
        )}
      </AnimatePresence>

      <Logotype ref={ref}>
        <Users>
          {users.map(user => (
            <UserCircle
              key={`user-circle-${user.uuid}`}
              $style={styleObject}
              $clickable={false}
              style={{width: height, height: height}}>
              {renderUser(user)}
            </UserCircle>
          ))}

          {emptyUsers.map((_u, key) => (
            <UserCircle
              key={`empty-user-circle-${team.uuid}-${key}`}
              $style={styleObject}
              $clickable={!!onClickConnect}
              style={{width: height, height: height}}
              onClick={onClickConnect}>
              {onClickConnect && <CirclesThreePlus size={24} weight="bold" />}
            </UserCircle>
          ))}
        </Users>
      </Logotype>

      <Name>{team.name}</Name>
      <Score>
        <FormattedNumber
          value={team.score}
          notation={team.score > 100000 ? 'compact' : 'standard'}
        />
      </Score>

      <TopBar>
        {actionButton}
        {menu && (
          <PopperMenu menu={menu} placement="bottom-end" distance={4}>
            <ButtonMore styleObject={styleObject}>
              <DotsThreeOutline size={20} weight="fill" />
            </ButtonMore>
          </PopperMenu>
        )}
      </TopBar>
    </StyledTeamSlot>
  );
};
