import React, {HTMLAttributes, ReactNode, forwardRef} from 'react';
import styled from 'styled-components';

interface MenuProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const StyledMenu = styled.div`
  position: relative;
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.floating};
  border-radius: 12px;
  padding: 4px 12px;
  width: 100%;
  min-width: 120px;
  box-sizing: border-box;
`;

export const Menu = forwardRef<HTMLDivElement, MenuProps>(function Menu(
  {children, ...rest},
  ref
) {
  return (
    <StyledMenu ref={ref} {...rest}>
      {children}
    </StyledMenu>
  );
});
