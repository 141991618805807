import React from 'react';
import {Grid, Section} from '@openquiz/quiz-ui';
import {UserCard, useUsersListQuery} from '@/features/user';
import {Container, Page, PageTitle} from '@/components';

export const AuthorsListPage = () => {
  const {data: users = {data: [], count: 0}} = useUsersListQuery({
    filter: {withPublishedPackages: true}
  });

  return (
    <Page title="Авторы – Мастерская">
      <Container>
        <PageTitle to={{href: '/'}} title="Авторы" />

        <Section>
          <Grid cols={{sm: 1, md: 3, lg: 4}}>
            {users.data
              .filter(user => user.verified)
              .map(user => (
                <UserCard key={`user-${user.uuid}`} user={user} />
              ))}
          </Grid>
        </Section>

        <Section>
          <Grid cols={{sm: 1, md: 3, lg: 4}}>
            {users.data
              .filter(user => !user.verified)
              .map(user => (
                <UserCard key={`user-${user.uuid}`} user={user} />
              ))}
          </Grid>
        </Section>
      </Container>
    </Page>
  );
};
