import React from 'react';
import {
  ChooseVariantHint,
  PrePlayHint,
  RenderSteps,
  Screen,
  ScreenContent,
  ScreenFooter,
  TopBar,
  TryAnswerHint,
  VariantsOneByOneQuestionSplash,
  useGameService
} from '@/features/machine';

export const VariantsOneByOneQuestionScreen = () => {
  const {isMatch} = useGameService();

  return (
    <Screen>
      <div>
        {!isMatch([{game: {basicRound: {variantsOneByOneQuestion: 'splash'}}}]) && (
          <TopBar />
        )}
      </div>

      <ScreenContent>
        {isMatch([{game: {basicRound: {variantsOneByOneQuestion: 'splash'}}}]) && (
          <VariantsOneByOneQuestionSplash />
        )}

        {isMatch([{game: {basicRound: {variantsOneByOneQuestion: 'prePlay'}}}]) && (
          <RenderSteps key="render-steps" stage="pre-play" />
        )}

        {isMatch([
          {game: {basicRound: {variantsOneByOneQuestion: 'play'}}},
          {game: {basicRound: {variantsOneByOneQuestion: 'tryAnswerBuffer'}}},
          {game: {basicRound: {variantsOneByOneQuestion: 'tryAnswer'}}}
        ]) && <RenderSteps key="render-steps" stage="play" />}

        {isMatch([{game: {basicRound: {variantsOneByOneQuestion: 'answer'}}}]) && (
          <RenderSteps stage="answer" />
        )}
      </ScreenContent>

      <ScreenFooter>
        {isMatch([{game: {basicRound: {variantsOneByOneQuestion: 'prePlay'}}}]) && (
          <PrePlayHint />
        )}

        {isMatch([
          {game: {basicRound: {variantsOneByOneQuestion: 'play'}}},
          {game: {basicRound: {variantsOneByOneQuestion: 'tryAnswerBuffer'}}}
        ]) && <TryAnswerHint />}

        {isMatch([{game: {basicRound: {variantsOneByOneQuestion: 'tryAnswer'}}}]) && (
          <ChooseVariantHint />
        )}
      </ScreenFooter>
    </Screen>
  );
};
