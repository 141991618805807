import {getPrivateAxios} from '@/libs';

export interface FetchVersionPublishParams {
  packageUuid: string;
  versionUuid: string;
}

/**
 *
 * @param uuid
 */
export const fetchVersionPublish = async ({
  packageUuid,
  versionUuid
}: FetchVersionPublishParams) => {
  const {data} = await getPrivateAxios().patch<void>(
    `/workshop/packages/${packageUuid}/versions/${versionUuid}`
  );
  return data;
};
