import styled, {css} from 'styled-components';
import {CreatorLabelProps} from './types';

export const StyledCreatorLabel = styled.div<{$size: CreatorLabelProps['size']}>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  & svg {
    flex-shrink: 0;
  }

  ${p =>
    p.$size === 'sm' &&
    css`
      ${p => p.theme.typography.label.sm};
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      ${p => p.theme.typography.label.md};
    `}
`;
