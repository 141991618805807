import React, {useEffect} from 'react';
import {subWeeks} from 'date-fns';
import {Grid, Section} from '@openquiz/quiz-ui';
import {Container, Page} from '@/components';
import {UpdatesSlider} from '@/features/home';
import {UserCard, useUsersListQuery} from '@/features/user';
import {PackageCard} from '@/features/workshop';
import {CategoriesCards} from '@/features/wshop-categories';
import {usePackagesQuery} from '@/features/wshop-packages';
import {getSearchString} from '@/features/wshop-search';

export const HomePage = () => {
  useEffect(() => {}, []);

  const {data: users = {data: []}} = useUsersListQuery({
    filter: {withPublishedPackages: true},
    take: 4
  });

  const {data: verifiedPackages = {data: []}} = usePackagesQuery({
    filter: {
      createdAt: subWeeks(new Date(), 2).toDateString()
    },
    sort: 'rating',
    take: 3
  });

  return (
    <Page>
      <Container>
        <Section>
          <UpdatesSlider />
        </Section>

        <Section
          label={{
            children: 'Лучшие паки за неделю',
            to: `/workshop/search?data=${getSearchString({
              createdAt: subWeeks(new Date(), 2).toDateString(),
              sort: 'rating'
            })}`
          }}>
          <Container>
            <Grid cols={{sm: 1, md: 3, lg: 3}}>
              {verifiedPackages.data.map(pkg => (
                <PackageCard key={`verified-packages-card-${pkg.uuid}`} pkg={pkg} />
              ))}
            </Grid>
          </Container>
        </Section>

        <Section label={{children: 'Категории'}}>
          <Container>
            <CategoriesCards />
          </Container>
        </Section>

        <Section label={{children: 'Авторы', to: '/workshop/authors'}}>
          <Container>
            <Grid cols={{sm: 1, md: 4, lg: 4}}>
              {users.data.map(user => (
                <UserCard key={`user-${user.uuid}`} user={user} />
              ))}
            </Grid>
          </Container>
        </Section>
      </Container>
    </Page>
  );
};
