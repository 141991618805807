import {ContestDetail} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

/**
 *
 */
export const fetchContestDetail = async (uuid: string) => {
  const {data} = await getPrivateAxios().get<ContestDetail>(`/workshop/contest/${uuid}`);
  return data;
};
