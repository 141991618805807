import {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useBackStateStore} from '@/stores';

export const useBackStateObserver = () => {
  const location = useLocation();
  const {href, push} = useBackStateStore();

  const getLastHref = useCallback(() => {
    return href[href.length - 1];
  }, [href]);

  useEffect(() => {
    const newHref = window.location.href
      .replace(window.location.origin, '')
      .replace(window.location.search, '');

    if (getLastHref() !== newHref) {
      push(newHref);
    }
  }, [location.pathname]);
};
