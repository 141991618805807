import React, {ReactElement, ReactNode, cloneElement, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {
  FloatingArrow,
  FloatingPortal,
  Placement,
  arrow,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions
} from '@floating-ui/react';
import styled, {css, useTheme} from 'styled-components';
import {getAnimationProps} from '@openquiz/quiz-ui';

interface TooltipProps {
  children: ReactElement;
  message: ReactNode;
  enabled?: boolean;
  placement?: Placement;
  distance?: number;
  delay?:
    | number
    | Partial<{
        open: number;
        close: number;
      }>;
  size?: 'md' | 'lg';
  closeOnReferencePress?: boolean;
}

const Floating = styled.div`
  z-index: 2900;
`;

const Message = styled(motion.div)<{$size: TooltipProps['size']}>`
  background-color: ${p => p.theme.colors.tooltip.default};
  color: ${p => p.theme.colors.text.onEmphasis};
  box-shadow: ${p => p.theme.shadow.floating}, ${p => p.theme.shadow.lg};
  width: max-content;
  word-break: break-word;

  ${p =>
    p.$size === 'md' &&
    css`
      ${p => p.theme.typography.body.sm};
      border-radius: 12px;
      padding: 8px 12px;
      text-align: center;
      max-width: 240px;
    `}

  ${p =>
    p.$size === 'lg' &&
    css`
      ${p => p.theme.typography.body.md};
      border-radius: 16px;
      padding: 16px;
      max-width: 320px;
    `};
`;

const getFloatingMotionProps = (placement: Placement | undefined) => {
  const y = placement?.includes('top') ? 4 : -4;
  return getAnimationProps({
    initial: {y, scale: 0.98, opacity: 0},
    animate: {y: 0, scale: 1, opacity: 1},
    exit: {y, scale: 0.98, opacity: 0}
  });
};

export const Tooltip = ({
  children,
  message,
  enabled = true,
  placement,
  distance = 14,
  delay = 0,
  size = 'md',
  closeOnReferencePress = false
}: TooltipProps) => {
  const theme = useTheme();
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const {
    refs,
    floatingStyles,
    placement: floatingPlacement,
    context
  } = useFloating({
    open: isOpen,
    placement,
    middleware: [
      offset(distance),
      flip(),
      shift({padding: 8}),
      arrow({
        element: arrowRef
      })
    ],
    onOpenChange: setIsOpen
  });

  const {getReferenceProps, getFloatingProps} = useInteractions([
    useHover(context, {
      delay,
      enabled,
      handleClose: safePolygon({
        requireIntent: true
      })
    }),
    useDismiss(context, {
      referencePress: closeOnReferencePress
    })
  ]);

  return (
    <>
      {cloneElement(children, {
        ref: refs.setReference,
        ...getReferenceProps(),
        'data-open': enabled && isOpen
      })}

      <AnimatePresence>
        {enabled && isOpen && (
          <FloatingPortal>
            <Floating
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}>
              <Message {...getFloatingMotionProps(floatingPlacement)} $size={size}>
                <FloatingArrow
                  ref={arrowRef}
                  context={context}
                  tipRadius={3}
                  width={16}
                  height={8}
                  fill={theme.colors.tooltip.default}
                />
                {message}
              </Message>
            </Floating>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </>
  );
};
