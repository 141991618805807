import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export type ThemeCreateData = Omit<PackageTheme, 'uuid'>;

export interface FetchThemeCreateParams {
  data: ThemeCreateData;
  packageUuid: string;
}

/**
 *
 * @param params
 */
export const fetchThemeCreate = async (params: FetchThemeCreateParams) => {
  const {data} = await getPrivateAxios().post<PackageTheme>(
    `/workshop/packages/${params.packageUuid}/themes`,
    params.data
  );
  return data;
};
