import {useQuery} from '@tanstack/react-query';
import {FetchQuestionParams, fetchQuestion} from './fetchQuestion';

/**
 * Query for a question.
 * @param params
 */
export const useQuestionQuery = (params: FetchQuestionParams) => {
  return useQuery({
    queryKey: ['editor', 'questions', params.questionUuid],
    queryFn: () => fetchQuestion(params)
  });
};
