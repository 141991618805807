import {ActorRef, State, Subscribable} from 'xstate';
import {useSelector} from '@xstate/react';
import {Action, Ctx} from '@opeq-dev/openquiz-machine';
import {useGameService} from './useGameService';

export const useMachineSelector = <
  TActor extends ActorRef<Action, State<Ctx>>,
  T,
  TEmitted = TActor extends Subscribable<infer Emitted> ? Emitted : never
>(
  selector: (emitted: TEmitted) => T
) => {
  const {service} = useGameService();
  return useSelector(service, selector);
};
