import React from 'react';
import {Modal, Title} from '@openquiz/quiz-ui';
import {RenderSteps} from '@/features/machine';

interface AnswerModalModalProps {
  onClose: () => void;
}

export const AnswerModal = ({onClose}: AnswerModalModalProps) => (
  <Modal size="lg" closeOutside={true} onClose={onClose}>
    <Title>Ответ</Title>
    <RenderSteps stage="answer" />
  </Modal>
);
