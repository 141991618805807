export interface CreateMediaStreamParams {
  shape: 'rectangle' | 'square';
}

const shapeSettingsMap = new Map<
  CreateMediaStreamParams['shape'],
  MediaTrackConstraintSet
>([
  ['rectangle', {width: 240 * 1.5, height: 160 * 1.5, aspectRatio: {ideal: 1.5}}],
  ['square', {aspectRatio: {ideal: 1}}]
]);

/**
 * The function will create a media stream with the specified device id and shape.
 * @param deviceId
 * @param shape
 */
export const createMediaStream = async (
  deviceId: string | null,
  {shape}: CreateMediaStreamParams
) => {
  const stream = await navigator.mediaDevices.getUserMedia({
    video: {
      deviceId: deviceId ? {exact: deviceId} : deviceId || undefined,
      facingMode: 'user',
      frameRate: 30,
      ...(shapeSettingsMap.get(shape) || {})
    }
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.currentMediaStream = stream;
  return stream;
};
