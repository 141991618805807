import {getPrivateAxios} from '@/libs';

/**
 *
 * @returns
 */
export const fetchGameRandomName = async () => {
  const {data} = await getPrivateAxios().get<string>('/game/random-name');
  return data;
};
