import React from 'react';
import styled, {css} from 'styled-components';
import {UserSwitch} from '@phosphor-icons/react';
import {Avatar, DotsLoader, PopperMenu, useModal} from '@openquiz/quiz-ui';
import {SettingsModal, UserBanner, UserMenu, useCurrentUserQuery} from '@/features/user';
import {UploadPackageModal} from '@/features/workshop';

interface UserBarProps {
  onClickSign: () => void;
}

const StyledUserBar = styled.div<{$center?: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  height: 56px;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;

  ${p =>
    p.$center &&
    css`
      justify-content: center;
    `}

  &[data-open='true'] {
    & [data-id='caret'] {
      transform: rotate(180deg);
    }
  }

  & [data-id='caret'] {
    transition: transform 80ms ease-in-out;
  }
`;

const Username = styled.div`
  ${p => p.theme.typography.label.lg};
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
  overflow: hidden;
`;

const BannerWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  z-index: -1;
  opacity: 0.25;
`;

const Placeholder = styled.div`
  background-color: ${p => p.theme.colors.neutral.emphasis};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  * {
    color: ${p => p.theme.colors.accent.emphasis};
    opacity: 1;
  }
`;

export const UserBar = ({onClickSign}: UserBarProps) => {
  const {data: user, isFetching} = useCurrentUserQuery();

  const [openSettingModal, closeSettingModal] = useModal(
    () => <SettingsModal onClose={closeSettingModal} />,
    []
  );

  const [openUploadPackageModal, closeUploadPackageModal] = useModal(
    () => <UploadPackageModal onClose={closeUploadPackageModal} />,
    []
  );

  if (isFetching) {
    return (
      <StyledUserBar $center={true}>
        <BannerWrapper>
          <Placeholder>
            <DotsLoader color="onEmphasis" />
          </Placeholder>
        </BannerWrapper>
      </StyledUserBar>
    );
  }

  if (!user) {
    return (
      <StyledUserBar $center={true} onClick={onClickSign}>
        <BannerWrapper>
          <Placeholder />
        </BannerWrapper>
        <UserSwitch size={24} weight="bold" />
        <Username>Войти</Username>
      </StyledUserBar>
    );
  }

  return (
    <PopperMenu
      menu={
        <UserMenu
          user={user}
          onOpenSettings={openSettingModal}
          onOpenUploadPackage={openUploadPackageModal}
        />
      }
      placement="bottom-start">
      <StyledUserBar>
        <BannerWrapper>
          <UserBanner user={user} />
        </BannerWrapper>
        <Avatar size={32} user={user} />
        <Username>{user.username}</Username>
      </StyledUserBar>
    </PopperMenu>
  );
};
