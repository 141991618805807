import React, {ReactElement, cloneElement, useState} from 'react';
import {
  FloatingPortal,
  Placement,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react';
import styled from 'styled-components';
import {AnimatePresence, motion} from 'framer-motion';
import {getAnimationProps} from '@openquiz/quiz-ui';

interface PopperMenuProps {
  children: ReactElement;
  menu: ReactElement;
  initialOpen?: boolean;
  placement?: Placement;
  distance?: number;
}

const Floating = styled.div`
  z-index: 3000;
`;

const getFloatingMotionProps = (placement: Placement | undefined) => {
  const y = placement?.includes('top') ? 4 : -4;

  return getAnimationProps({
    initial: {y, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y, opacity: 0}
  });
};

export const PopperMenu = ({
  children,
  menu,
  initialOpen = false,
  placement,
  distance = 8
}: PopperMenuProps) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const {
    refs,
    floatingStyles,
    placement: floatingPlacement,
    context
  } = useFloating({
    open: isOpen,
    placement,
    middleware: [
      offset(distance),
      flip(),
      shift({
        padding: 8
      })
    ],
    onOpenChange: setIsOpen
  });

  const {getReferenceProps, getFloatingProps} = useInteractions([
    useClick(context),
    useDismiss(context)
  ]);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {cloneElement(children, {
        ref: refs.setReference,
        ...getReferenceProps(),
        'data-open': isOpen
      })}

      <AnimatePresence>
        {isOpen && (
          <FloatingPortal>
            <Floating
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}>
              <motion.div {...getFloatingMotionProps(floatingPlacement)}>
                {cloneElement(menu, {onClose})}
              </motion.div>
            </Floating>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </>
  );
};
