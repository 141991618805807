const START_PRICE = 100;

/**
 *
 * @param prices
 */
export const getPredictedPrice = (prices: number[]) => {
  if (prices.length === 0) {
    return START_PRICE;
  }

  if (prices.length === 1) {
    return prices[0] * 2;
  }

  const step = prices[prices.length - 1] - prices[prices.length - 2];
  const price = step * (prices.length + 1);

  return price > 0 ? price : START_PRICE;
};
