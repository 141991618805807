import {getValidationMessage} from './getValidationMessage';
import {messages} from './messages';

type MessageKeys = keyof typeof messages;

/**
 *
 * @param error
 */
export const formatError = <DTO>(error: unknown) => {
  const stack: {key: string; message: string}[] = [];

  const formattedError = error as {
    response: {
      data: {
        message: {
          [key in keyof DTO]: [{[key in MessageKeys]: string}];
        }[];
      };
    };
  };

  formattedError.response.data.message.forEach(message => {
    Object.entries(message).map(([key, value]) => {
      Object.keys(value as string[]).forEach(valueKey => {
        stack.push({key, message: getValidationMessage(valueKey as MessageKeys)});
      });
    });
  });

  return stack as {key: keyof DTO; message: string}[];
};
