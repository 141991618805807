import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {FormattedDate, FormattedTime} from 'react-intl';
import {addHours} from 'date-fns';
import {ChangeSettingsEvent} from '@opeq-dev/openquiz-machine';
import {GameSettings} from '@opeq-dev/openquiz-schema';
import {
  BetaBadge,
  Button,
  Callout,
  FieldGroup,
  FieldRow,
  FieldSection,
  Modal,
  TipCircle,
  Title,
  Toggle
} from '@openquiz/quiz-ui';
import {useMachineEvent, useMachineSelector} from '../../hooks';

interface MachineSettingsModalProps {
  onClose: () => void;
}

export const MachineSettingsModal = ({onClose}: MachineSettingsModalProps) => {
  const {event} = useMachineEvent();
  const settings = useMachineSelector(state => state.context.game?.settings);

  const {handleSubmit, control} = useForm<GameSettings>({
    defaultValues: {
      prePlay: false,
      slots: 8,
      ...(settings ?? {})
    }
  });

  const onSubmit = (data: GameSettings) => {
    event<ChangeSettingsEvent>('changeSettings', {settings: data});
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Title>Параметры игры</Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldGroup size="lg">
          <Callout status="attention">
            Параметры игры нельзя менять во время активного вопроса.
          </Callout>
        </FieldGroup>

        <FieldSection label="Игровое время">
          <FieldGroup label="Активна до" labelWidth="45%" inline={true}>
            <FormattedDate value={addHours(new Date(), 5)} dateStyle="long" />
            &nbsp;
            <FormattedTime value={addHours(new Date(), 5)} />
          </FieldGroup>
        </FieldSection>

        <FieldSection label="Параметры">
          <FieldGroup size="sm">
            <Controller
              control={control}
              name="prePlay"
              render={({field: {onChange, onBlur, value}}) => (
                <Toggle
                  value={value}
                  label={
                    <FieldRow size="xs">
                      <span>Режим чтения вопросов</span>
                      <TipCircle message="Нельзя ответить пока воспроизводится вопрос." />
                    </FieldRow>
                  }
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FieldGroup>

          <FieldGroup size="sm">
            <Controller
              control={control}
              name="teamMode"
              render={({field: {onChange, onBlur, value}}) => (
                <Toggle
                  value={value}
                  disabled={true}
                  label={
                    <FieldRow size="sm">
                      <FieldRow size="xs">
                        <span>Командный режим</span>
                      </FieldRow>
                      <BetaBadge message="Протестируйте новый игровой режим! Максимум 4 команды, по 4 игрока." />
                    </FieldRow>
                  }
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FieldGroup>
        </FieldSection>

        <FieldGroup>
          <FieldRow>
            <Button type="submit">Сохранить</Button>
            <Button variant="secondary" onClick={onClose}>
              Отмена
            </Button>
          </FieldRow>
        </FieldGroup>
      </form>
    </Modal>
  );
};
