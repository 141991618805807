import {State} from 'xstate';
import {Ctx} from '@opeq-dev/openquiz-machine';
import {GameSettings} from '@opeq-dev/openquiz-schema';

/**
 *
 * @param state
 * @returns
 */
export const selectGameSettings = (state: State<Ctx>) =>
  state.context.game?.settings as GameSettings;
