import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface ControlBarGroupProps {
  children: ReactNode;
}

const StyledControlBarGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  ::after {
    background-color: ${p => p.theme.colors.border.default};
    position: absolute;
    border-radius: 4px;
    right: -13px;
    top: 50%;
    margin-top: -12px;
    width: 1px;
    height: 24px;
    content: '';
  }

  :empty {
    display: none;
  }

  :last-child {
    margin-right: 0;
    ::after {
      display: none;
    }
  }
`;

export const ControlBarGroup = ({children}: ControlBarGroupProps) => (
  <StyledControlBarGroup>{children}</StyledControlBarGroup>
);
