import {FunctionComponent, createElement} from 'react';
import reactStringReplace from 'react-string-replace';
import {Action} from '@opeq-dev/openquiz-machine';
import {Theme} from '@openquiz/quiz-ui';
import {PackageFull} from '@/features/wshop-packages';
import {formattedActionPropsMap} from './formattedActionPropsMap';
import {FormattedAction} from '../types';

interface Params {
  pkg: PackageFull;
  colors: Theme['colors'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  snippetsMap: Map<string, FunctionComponent<any>>;
}

/**
 *
 * @param action
 * @param params
 */
export const getFormattedAction = (
  action: Action,
  {pkg, colors, snippetsMap}: Params
): FormattedAction | null => {
  const actionProps = formattedActionPropsMap[action.type];

  if (!actionProps) {
    return null;
  }

  return {
    icon: actionProps.icon,
    color: actionProps.color(colors),
    message: reactStringReplace(actionProps.message, /:(\w+)/g, (match, i) => {
      const snippet = snippetsMap.get(match);
      return snippet
        ? createElement(snippet, {
            key: `snippet-${i}-${action.type}`,
            pkg,
            match,
            self: action.self,
            payload: action.payload
          })
        : match;
    })
  };
};
