import {State} from 'xstate';
import {Ctx, getStepDuration} from '@opeq-dev/openquiz-machine';
import {selectCtxCurrentSteps, selectTimer} from '@/features/machine';

/**
 * Селектор вернет индекс текущего шага (только для стадий `pre-play` и `play`).
 * @param state
 */
export const selectPlayStepIndex = (state: State<Ctx>) => {
  const prePlayStates = [
    {game: {basicRound: 'basicQuestion'}},
    {game: {basicRound: 'variantsOneByOneQuestion'}}
  ].some(state.matches);

  const hasPrePlay = prePlayStates ? state.context.game?.settings.prePlay : false;

  const timer = selectTimer(hasPrePlay ? 'prePlay' : 'play')(state);
  const steps = selectCtxCurrentSteps(state)
    .filter(s => !s.isAnswer)
    .sort((a, b) => a.position - b.position);

  const durationMap = steps
    .map(step =>
      getStepDuration(step, {
        stage: hasPrePlay ? 'pre-play' : 'play',
        count: steps.length
      })
    )
    .map((duration, index, arr) => duration + (arr[index - 1] || 0));

  return durationMap.findIndex(value => timer.current <= value);
};
