import React from 'react';
import {NavLink} from 'react-router-dom';
import {motion} from 'framer-motion';
import styled from 'styled-components';
import {DotsLoader, Logotype, getAnimationProps} from '@openquiz/quiz-ui';
import FaviconDarkIcon from '@/assets/favicon-dark.svg?react';
import FaviconLightIcon from '@/assets/favicon-light.svg?react';
import {useAppearance} from '@/hooks';

const loadingScreenAnimationProps = getAnimationProps(
  {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0}
  },
  {duration: 0.5}
);

const StyledLoadingScreen = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  backdrop-filter: blur(24px);
  z-index: 100;
`;

const BrandLightIcon = styled(FaviconLightIcon)`
  width: 64px;
  height: 64px;
`;

const BrandDarkIcon = styled(FaviconDarkIcon)`
  width: 64px;
  height: 64px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: auto;
  padding-top: 24px;
  max-width: 600px;
  width: 100%;
`;

const Footer = styled.div`
  padding-top: 24px;
  margin-top: auto;
`;

const Message = styled.div`
  ${p => p.theme.typography.body.lg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingScreen = () => {
  const appearance = useAppearance();

  return (
    <StyledLoadingScreen {...loadingScreenAnimationProps}>
      <Content>
        {appearance === 'light' ? <BrandLightIcon /> : <BrandDarkIcon />}
        <Message>Получение данных игры</Message>
        <DotsLoader color="accent" />
      </Content>

      <Footer>
        <NavLink to="/">
          <Logotype />
        </NavLink>
      </Footer>
    </StyledLoadingScreen>
  );
};
