import React from 'react';
import {FormattedMessage} from 'react-intl';
import {CircleWavyCheck, Package} from '@phosphor-icons/react';
import styled, {useTheme} from 'styled-components';
import {User} from '@opeq-dev/openquiz-schema';
import {
  Avatar,
  Card,
  Placeholder,
  Stat,
  Tooltip,
  getLineClampCss
} from '@openquiz/quiz-ui';
import {generateUserPath} from '@/features/user';

interface UserCardProps {
  user: User & {verified?: boolean};
}

const AvatarWrapper = styled.div`
  margin-bottom: 16px;
`;

const Verified = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const Username = styled.div`
  ${p => p.theme.typography.label.lg};
  margin-bottom: 8px;
`;

const Desc = styled.div`
  ${p => p.theme.typography.body.md};
  ${getLineClampCss(3)};
  color: ${p => p.theme.colors.text.muted};
  margin-bottom: 12px;
  white-space: pre-wrap;
  height: calc(24px * 3);
`;

const Stats = styled.div`
  margin-top: auto;
  color: ${p => p.theme.colors.text.subtle};
`;

export const UserCard = ({user}: UserCardProps) => {
  const theme = useTheme();

  return (
    <Card to={generateUserPath(user.username, user.discriminator)}>
      <AvatarWrapper>
        <Avatar user={user} size={80} />
      </AvatarWrapper>

      {user.verified && (
        <Tooltip message="Проверенный автор">
          <Verified>
            <CircleWavyCheck size={20} weight="bold" color={theme.colors.accent.text} />
          </Verified>
        </Tooltip>
      )}

      <Username>{user.username}</Username>

      {user.profile.about ? (
        <Desc>{user.profile.about}</Desc>
      ) : (
        <Placeholder
          config={[
            ['line', {height: [14, 4]}],
            ['line', {height: [14, 4]}],
            ['line', {height: [14, 4]}]
          ]}
        />
      )}

      <Stats>
        <Stat icon={Package}>
          <FormattedMessage
            id="user.package.count"
            values={{count: user._count.packages}}
          />
        </Stat>
      </Stats>
    </Card>
  );
};
