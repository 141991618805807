import React, {ReactNode, useContext} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';
import {SpeakerHigh, SpeakerSlash} from '@phosphor-icons/react';
import {Button, getAnimationProps} from '@openquiz/quiz-ui';
import {GameMachineContext} from '@/features/machine/providers';
import {colord} from 'colord';

interface MediaPlayerProps {
  children: ({muted}: {muted: boolean}) => ReactNode;
}

const autoPlayRenderMotionProps = getAnimationProps(
  {
    initial: {y: 4, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: -4, opacity: 0}
  },
  {duration: 0.16}
);

const StyledRenderAutoplay = styled(motion.div)`
  background-color: ${p => colord(p.theme.colors.canvas.default).alpha(0.8).toHex()};
  color: ${p => p.theme.colors.text.default};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;
`;

const Message = styled.div`
  max-width: 440px;
`;

export const RenderAutoplay = ({children}: MediaPlayerProps) => {
  const {
    autoplay: {status, grant}
  } = useContext(GameMachineContext);

  return (
    <>
      {children({muted: status !== 'allow'})}
      <AnimatePresence>
        {status !== 'allow' && (
          <StyledRenderAutoplay {...autoPlayRenderMotionProps}>
            <SpeakerSlash size={32} />
            <Message>
              Ваш браузер запрещает воспроизведение звука, пожалуйста нажмите на кнопку
              ниже, чтобы разрешить воспроизведение.
            </Message>
            <Button onClick={grant}>
              <SpeakerHigh size={20} weight="bold" />
              <span>Разрешить звук</span>
            </Button>
          </StyledRenderAutoplay>
        )}
      </AnimatePresence>
    </>
  );
};
