import {createContext} from 'react';
import {
  BaseActionObject,
  Interpreter,
  ResolveTypegenMeta,
  ServiceMap,
  StateSchema,
  TypegenDisabled
} from 'xstate';
import {Action, Ctx} from '@opeq-dev/openquiz-machine';
import {Socket} from 'socket.io-client';

export type ServiceInterpretType = Interpreter<
  Ctx,
  StateSchema,
  Action,
  {value: string; context: Ctx},
  ResolveTypegenMeta<TypegenDisabled, Action, BaseActionObject, ServiceMap>
>;

export interface GameMachineContextValue {
  service: ServiceInterpretType | undefined;
  autoplay: {status: 'idle' | 'allow' | 'disallow'; grant: () => void};
  socket: Socket | undefined;
  emit: (action: Action) => void;
}

export const GameMachineContext = createContext<GameMachineContextValue>({
  service: undefined,
  autoplay: {status: 'idle', grant: () => void 0},
  socket: undefined,
  emit: () => void 0
});
