import {create} from 'zustand';
import Peer from 'peerjs';
import {stopMediaStream} from '@openquiz/peer-webcam';

interface PeerStore {
  peer: Peer | undefined;
  gameUuid: string | undefined;
  userUuid: string | undefined;
  streams: Record<string, MediaStream>;
  initPeer: (gameUuid: string, userUuid: string) => void;
  addStream: (peerId: string, stream: MediaStream) => void;
  removeStream: (peerId: string) => void;
}

const config = {
  iceServers: [
    {urls: 'stun:stun.l.google.com:19302'},
    {urls: 'turn:0.peerjs.com:3478', username: 'peerjs', credential: 'peerjsp'}
  ]
  // sdpSemantics: 'unified-plan'
};

export const usePeerWebcam = create<PeerStore>(set => ({
  peer: undefined,
  gameUuid: undefined,
  userUuid: undefined,
  streams: {},
  initPeer: (gameUuid, userUuid) =>
    set({
      peer: new Peer(`${gameUuid}-${userUuid}`, {config, secure: false, debug: 2}),
      gameUuid,
      userUuid,
      streams: {}
    }),
  addStream: (peerId, stream) =>
    set(state => ({streams: {...state.streams, [peerId]: stream}})),
  removeStream: peerId =>
    set(state => {
      const stream = state.streams[peerId];

      if (stream) {
        stopMediaStream(stream);
        delete state.streams[peerId];
      }

      return {streams: state.streams};
    })
}));
