import {FunctionComponent, ReactElement, useCallback, useId} from 'react';
import {modalStore} from '../modalStore';
import {ModalConfirmProps} from '../types';

/**
 * This hook is used to show modal windows.
 * @param modal
 */
export const useModalConfirm = (modal: FunctionComponent<ModalConfirmProps>) => {
  const id = useId();
  const {push, shift} = modalStore();

  const closeModal = useCallback(() => {
    shift(id);
  }, [id, shift]);

  return async () => {
    await new Promise<void>((resolve, reject) => {
      const component = modal({
        onConfirm: () => {
          resolve();
          closeModal();
        },
        onCancel: () => {
          reject();
          closeModal();
        }
      }) as ReactElement;
      push(id, component, {});
    });
  };
};
