import {useState} from 'react';

const matchPrefersColorScheme = window.matchMedia('(prefers-color-scheme: dark)');
const getColorScheme = (matches: boolean) => (matches ? 'dark' : 'light');

/**
 * Hook to get the system color scheme.
 */
export const useSystemColorScheme = () => {
  const [colorScheme, setColorScheme] = useState<'light' | 'dark'>(
    getColorScheme(matchPrefersColorScheme.matches)
  );

  matchPrefersColorScheme.addEventListener('change', e => {
    setColorScheme(getColorScheme(e.matches));
  });

  return colorScheme;
};
