import React, {ReactNode, forwardRef} from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {getAnimationProps} from '../../libs';

interface AnimatedStepProps {
  children: ReactNode;
}

const animationProps = getAnimationProps({
  initial: {x: 24, opacity: 0},
  animate: {x: 0, opacity: 1},
  exit: {x: -0, opacity: 0}
});

const StyledAnimatedStep = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`;

export const AnimatedStep = forwardRef<HTMLDivElement, AnimatedStepProps>(
  function AnimatedStep({children}, ref) {
    return (
      <StyledAnimatedStep ref={ref} {...animationProps}>
        {children}
      </StyledAnimatedStep>
    );
  }
);
