import React from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {DotsLoader, getAnimationProps} from '@openquiz/quiz-ui';

const StyledPageFallback = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const pageFallbackAnimationProps = getAnimationProps({
  initial: {opacity: 0},
  animate: {opacity: 1},
  exit: {opacity: 0}
});

export const PageFallback = () => (
  <StyledPageFallback {...pageFallbackAnimationProps}>
    <DotsLoader />
  </StyledPageFallback>
);
