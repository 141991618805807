import {
  darkTheme,
  // halloweenTheme,
  lightTheme,
  useSystemColorScheme
} from '@openquiz/quiz-ui';
import {useUiSettingsStore} from '@/stores';

/**
 *
 */
export const useAppearance = () => {
  const {theme} = useUiSettingsStore();
  const systemColorScheme = useSystemColorScheme();
  return theme === 'auto' ? systemColorScheme : theme;
};

/**
 *
 */
export const useAppearanceTheme = () => {
  const appearance = useAppearance();
  return appearance === 'dark' ? darkTheme : lightTheme;
};
