import React from 'react';
import {ModalProvider} from '@openquiz/quiz-ui';
import {ControlBarContainer} from '../ControlBarContainer';
import {ControlBarGroup} from '../ControlBarGroup';
import {ControlBarMore} from '../ControlBarMore';
import {ControlBarVolume} from '../ControlBarVolume';
import {LeaderDecisionButton} from '../LeaderDecisionButton';
import {LeaderDecisionFinal} from '../LeaderDecisionFinal';
import {LeaderRightAnswer} from '../LeaderRightAnswer';
import {LeaderStartButton} from '../LeaderStartButton';
import {WebCameraButton} from '../WebCameraButton';
import {ReactionButton} from '../ReactionButton';

export const LeaderBar = () => (
  <>
    <LeaderDecisionFinal />

    {/* НЕ УБИРАТЬ */}
    <ModalProvider>
      <LeaderRightAnswer />
    </ModalProvider>

    <ControlBarContainer>
      <ControlBarGroup>
        <LeaderStartButton />
      </ControlBarGroup>

      <ControlBarGroup>
        <LeaderDecisionButton />
      </ControlBarGroup>

      <ControlBarGroup>
        <ReactionButton />
        <WebCameraButton />
        <ControlBarVolume />
      </ControlBarGroup>

      <ControlBarGroup>
        <ControlBarMore />
      </ControlBarGroup>
    </ControlBarContainer>
  </>
);
