import React from 'react';
import styled from 'styled-components';
import {PackageFull} from '@/features/wshop-packages';

interface RoleSnippetProps {
  pkg: PackageFull;
  match: string;
  payload: {[key: string]: string};
}

const StyledRoleSnippet = styled.div``;

export const RoleSnippet = ({match, payload}: RoleSnippetProps) => (
  <StyledRoleSnippet>
    {payload[match] === 'PLAYER' ? 'Игрок' : 'Ведущий'}
  </StyledRoleSnippet>
);
