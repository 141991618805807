import {createElement} from 'react';
import {useModal} from '@openquiz/quiz-ui';
import {CreateGameForm} from '@/features/game';

interface PackageRunParams {
  packageUuid: string;
  versionUuid: string;
}

/**
 * @deprecated TODO: сделать нормальную функцию запуска
 * @param params
 */
export const usePackageRun = ({packageUuid, versionUuid}: PackageRunParams) => {
  const [openRunModal, closeRunModal] = useModal(
    () =>
      createElement(CreateGameForm, {
        packageUuid,
        versionUuid,
        onClose: closeRunModal
      }),
    [packageUuid, versionUuid]
  );
  return openRunModal;
};
