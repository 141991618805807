import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PackageRound} from '@opeq-dev/openquiz-schema';
import {fetchRoundDelete} from './fetchRoundDelete';

/**
 *
 */
export const useRoundDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchRoundDelete,
    onSuccess: (data, variables) => {
      queryClient.setQueryData<PackageRound[]>(
        [
          'editor',
          'rounds',
          {packageUuid: variables.packageUuid, versionUuid: variables.versionUuid}
        ],
        (rounds = []) => rounds.filter(r => r.uuid !== variables.roundUuid)
      );
    }
  });
};
