import React from 'react';
import styled from 'styled-components';
import {Crown, DotsThreeOutline} from '@phosphor-icons/react';
import {TeamRecord, UserRecord, changeTeamNameGuard} from '@opeq-dev/openquiz-machine';
import {Avatar, PopperMenu, Tooltip, WebCamera} from '@openquiz/quiz-ui';
import {FetchedUser} from '@/features/user';
import {PlayerMenu} from '../PlayerMenu';
import {useMachineGuard, useMachineSelf} from '../../../hooks';
import {usePeerWebcam} from '@openquiz/peer-webcam';

interface PlayerCircleProps {
  team: TeamRecord;
  user: UserRecord;
}

const StyledPlayerCircle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const WebCameraWrapper = styled.div`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & video {
    transform: scale(1.8);
  }
`;

const TeamLeaderIcon = styled.div`
  background-color: ${p => p.theme.colors.canvas.default};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  position: absolute;
  left: -4px;
  top: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: 2;
`;

const MoreButton = styled.button`
  background-color: rgba(0, 0, 0, 0.5);
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  transition:
    background-color 80ms ease-in-out,
    opacity 80ms ease-in-out;

  &[data-open='true'],
  :hover {
    opacity: 1;
  }

  &[data-open='true'],
  :active {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

export const PlayerCircle = ({team, user}: PlayerCircleProps) => {
  const self = useMachineSelf();
  const {guard} = useMachineGuard();

  const isTeamLeader = team.leaderUuid === user.uuid;

  const {streams, gameUuid} = usePeerWebcam();
  const stream = streams[`${gameUuid}-${user?.uuid}`];
  const hasStream = stream?.getAudioTracks().length === 0;

  return (
    <StyledPlayerCircle>
      <FetchedUser uuid={user.uuid}>
        {user =>
          hasStream ? (
            <WebCameraWrapper>
              <WebCamera stream={stream} />
            </WebCameraWrapper>
          ) : (
            <Avatar user={user} size="100%" />
          )
        }
      </FetchedUser>

      {isTeamLeader && (
        <Tooltip message="Лидер команды" placement="top" distance={8}>
          <TeamLeaderIcon>
            <Crown size={16} weight="bold" />
          </TeamLeaderIcon>
        </Tooltip>
      )}

      {guard(changeTeamNameGuard)({teamUuid: team.uuid}) &&
        self.userUuid !== user.uuid && (
          <PopperMenu
            menu={<PlayerMenu team={team} user={user} />}
            placement="bottom"
            distance={-12}>
            <MoreButton>
              <DotsThreeOutline size={24} weight="fill" />
            </MoreButton>
          </PopperMenu>
        )}
    </StyledPlayerCircle>
  );
};
