import React, {useCallback, useState} from 'react';
import {Button, Callout, FieldGroup, FieldRow, Modal, Title} from '@openquiz/quiz-ui';
import {Game, useGameStopMutation} from '../../api';

interface StopGameModalProp {
  game: Game;
  onClose: () => void;
}

export const StopGameModal = ({game, onClose}: StopGameModalProp) => {
  const [throttleError, setThrottleError] = useState(false);
  const {mutateAsync} = useGameStopMutation();

  const onClickStop = useCallback(async () => {
    try {
      await mutateAsync(game.uuid);
      onClose();
    } catch (error) {
      setThrottleError(true);
    }
  }, [game.uuid, mutateAsync, onClose]);

  return (
    <Modal onClose={onClose}>
      <Title>Остановить игру</Title>

      <FieldGroup size="lg">
        <FieldGroup>
          <p>
            После остановки игры, ее нельзя будет продолжить. Вы уверены, что хотите
            остановить?
          </p>
        </FieldGroup>

        {throttleError && (
          <FieldGroup>
            <Callout status="danger">
              <p>Вы уже остановили 3 игры подряд. Подождите минуту.</p>
            </Callout>
          </FieldGroup>
        )}
      </FieldGroup>

      <FieldGroup>
        <FieldRow>
          <Button variant="danger" onClick={onClickStop}>
            Остановить
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Отмена
          </Button>
        </FieldRow>
      </FieldGroup>
    </Modal>
  );
};
