import {WorkshopTag} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

/**
 *
 * @returns
 */
export const fetchTags = async () => {
  const {data} = await getPrivateAxios().get<WorkshopTag[]>('/workshop/tag');
  return data;
};
