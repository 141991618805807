import {css} from 'styled-components';

export const inputStyle = css`
  --rdp-cell-size: 32px; /* Size of the day cells. */
  --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
  --rdp-accent-color: ${p => p.theme.colors.accent.emphasis};
  --rdp-background-color: ${p => p.theme.colors.neutral.muted};
  --rdp-accent-color-dark: #3003e1; /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #180270; /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #fff; /* Color of selected day text */

  ${p => p.theme.typography.body.md};

  padding: 8px 12px;

  /* Hide elements for devices that are not screen readers */
  .rdp-vhidden {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    appearance: none;
    position: absolute !important;
    top: 0;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
  }

  /* Buttons */
  .rdp-button_reset {
    appearance: none;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: default;
    color: inherit;
    background: none;
    font: inherit;
  }

  .rdp-button_reset:focus-visible {
    /* Make sure to reset outline only when :focus-visible is supported */
    outline: none;
  }

  .rdp-button {
    border: 2px solid transparent;
  }

  .rdp-button[disabled]:not(.rdp-day_selected) {
    opacity: 0.25;
  }

  .rdp-button:not([disabled]) {
    cursor: pointer;
  }

  .rdp-button:focus-visible:not([disabled]) {
    color: inherit;
    background-color: var(--rdp-background-color);
    border: var(--rdp-outline);
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: var(--rdp-background-color);
  }

  .rdp-months {
    display: flex;
  }

  .rdp-month {
    margin: 0 1em;
  }

  .rdp-month:first-child {
    margin-left: 0;
  }

  .rdp-month:last-child {
    margin-right: 0;
  }

  .rdp-table {
    margin: 0;
    max-width: calc(var(--rdp-cell-size) * 7);
    border-collapse: collapse;
  }

  .rdp-with_weeknumber .rdp-table {
    max-width: calc(var(--rdp-cell-size) * 8);
    border-collapse: collapse;
  }

  .rdp-caption {
    ${p => p.theme.typography.body.md};
    color: ${p => p.theme.colors.text.subtle};
    border-bottom: 1px solid ${p => p.theme.colors.border.default};
    padding-bottom: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rdp-multiple_months .rdp-caption {
    position: relative;
    display: block;
    text-align: center;
  }

  .rdp-caption_dropdowns {
    position: relative;
    display: inline-flex;
  }

  .rdp-caption_label {
  }

  .rdp-nav {
    white-space: nowrap;
  }

  .rdp-multiple_months .rdp-caption_start .rdp-nav {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .rdp-multiple_months .rdp-caption_end .rdp-nav {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .rdp-nav_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 100%;
  }

  /* ---------- */
  /* Dropdowns  */
  /* ---------- */

  .rdp-dropdown_year,
  .rdp-dropdown_month {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  .rdp-dropdown {
    appearance: none;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: inherit;
    opacity: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .rdp-dropdown[disabled] {
    opacity: unset;
    color: unset;
  }

  .rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
    background-color: var(--rdp-background-color);
    border: var(--rdp-outline);
    border-radius: 6px;
  }

  .rdp-dropdown_icon {
    margin: 0 0 0 5px;
  }

  .rdp-head {
    border: 0;
  }

  .rdp-head_row,
  .rdp-row {
    height: 100%;
  }

  .rdp-head_cell {
    ${p => p.theme.typography.body.sm};
    color: ${p => p.theme.colors.text.subtle};
    font-weight: normal;
    vertical-align: middle;
    text-align: center;
    height: var(--rdp-cell-size);
    padding: 0;
    text-transform: uppercase;
  }

  .rdp-tbody {
    border: 0;
  }

  .rdp-tfoot {
    margin: 0.5em;
  }

  .rdp-cell {
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0;
    border: 4px solid transparent;
    text-align: center;
  }

  .rdp-weeknumber {
    font-size: 0.75em;
  }

  .rdp-weeknumber,
  .rdp-day {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: var(--rdp-cell-size);
    max-width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    margin: 0;
    border: 2px solid transparent;
    border-radius: 100%;
    transition:
      background-color ease-in-out 80ms,
      color ease-in-out 80ms,
      transform ease-in-out 80ms;

    &:active {
      transform: scale(0.95);
    }
  }

  .rdp-day_today:not(.rdp-day_outside):not(.rdp-day_selected) {
    color: ${p => p.theme.colors.accent.text};
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    background-color: ${p => p.theme.colors.accent.emphasis};
    color: ${p => p.theme.colors.text.onEmphasis};
    opacity: 1;
  }

  .rdp-day_outside {
    opacity: 0.4;
  }

  .rdp-day_selected:focus-visible {
    /* Since the background is the same use again the outline */
    outline: var(--rdp-outline);
    outline-offset: 2px;
    z-index: 1;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rdp-day_range_end.rdp-day_range_start {
    border-radius: 100%;
  }

  .rdp-day_range_middle {
    border-radius: 0;
  }

  /*# sourceMappingURL=style.css.map */
`;
