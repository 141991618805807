import React from 'react';
import {AnimatePresence} from 'framer-motion';
import {
  ChooseVariantHint,
  RenderSteps,
  Screen,
  ScreenContent,
  ScreenFooter,
  TopBar,
  VariantsQuestionSplash,
  useGameService
} from '@/features/machine';

export const VariantsQuestionScreen = () => {
  const {isMatch} = useGameService();

  return (
    <Screen>
      <div>
        {!isMatch([{game: {basicRound: {variantsQuestion: 'splash'}}}]) && <TopBar />}
      </div>

      <ScreenContent>
        <AnimatePresence>
          {isMatch([{game: {basicRound: {variantsQuestion: 'splash'}}}]) && (
            <VariantsQuestionSplash />
          )}

          {isMatch([{game: {basicRound: {variantsQuestion: 'play'}}}]) && (
            <RenderSteps stage="play" />
          )}

          {isMatch([{game: {basicRound: {variantsQuestion: 'answer'}}}]) && (
            <RenderSteps stage="answer" />
          )}
        </AnimatePresence>
      </ScreenContent>

      <ScreenFooter>
        {isMatch([{game: {basicRound: {variantsQuestion: 'play'}}}]) && (
          <ChooseVariantHint />
        )}
      </ScreenFooter>
    </Screen>
  );
};
