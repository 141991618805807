import {create} from 'zustand';
import {persist} from 'zustand/middleware';

interface ReferrerStoreState {
  referrer: string | undefined;
  updateReferrer: (referrer: string) => void;
}

export const referrerStore = create(
  persist<ReferrerStoreState>(
    set => ({
      referrer: undefined,
      updateReferrer: referrer => set({referrer})
    }),
    {name: 'referrerStore'}
  )
);
