import React from 'react';
import {
  HintRow,
  UserChip,
  selectCtxCurrentQuestion,
  selectTimer,
  selectTurnTeam,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';

export const ChooseVariantHint = () => {
  const self = useMachineSelf();
  const turnTeam = useMachineSelector(selectTurnTeam);
  const question = useMachineSelector(selectCtxCurrentQuestion);
  const playTimer = useMachineSelector(selectTimer('play'));

  if (!self || !question) {
    return null;
  }

  if (question.type === 'VARIANTS_EVERYONE') {
    return (
      <UserChip
        userUuid={self.userUuid}
        message=", выбирайте вариант ответа"
        timer={playTimer}
        isSelf={true}
      />
    );
  }

  if (turnTeam) {
    return (
      <HintRow
        timer={playTimer}
        type="chooseVariant"
        team={turnTeam}
        userUuid={turnTeam.leaderUuid}
        isSelf={turnTeam.leaderUuid === self.userUuid}
      />
    );
  }

  return null;
};
