import {
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {arrayMove, sortableKeyboardCoordinates} from '@dnd-kit/sortable';

interface UseSortableListOptions {
  delay?: number;
  onDragEnd: (items: string[]) => void;
}

export const useSortableList = (
  items: string[],
  {delay, onDragEnd}: UseSortableListOptions
) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {delay: delay || 0, tolerance: 0}
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const onListDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.indexOf(active.id as string);
      const newIndex = items.indexOf(over.id as string);
      onDragEnd(arrayMove(items, oldIndex, newIndex));
    }
  };

  return {
    sensors,
    collisionDetection: closestCenter,
    onDragEnd: onListDragEnd
  };
};
