import React, {useMemo} from 'react';
import {PanInfo, motion, useDragControls} from 'framer-motion';
import {colord} from 'colord';
import styled from 'styled-components';
import {UserLabel, UserLabelPlaceholder, getAnimationProps} from '@openquiz/quiz-ui';
import {
  AchievementPicture,
  AchievementStat,
  achievementsList
} from '@/features/achievement';
import {FetchedUser} from '@/features/user';

interface AchievementToastProps {
  stat: AchievementStat;
  userUuid: string;
  onClose: (stat: AchievementStat) => void;
}

const StyledAchievementToast = styled(motion.div)`
  margin-bottom: 8px;
  border-radius: 24px;
  overflow: hidden;
  pointer-events: all;
  :last-child {
    margin-bottom: 0;
  }
`;

const Toast = styled(motion.div)`
  background-color: ${p => p.theme.colors.neutral.emphasisPlus};
  color: ${p => p.theme.colors.text.onEmphasis};
  box-shadow: ${p => p.theme.shadow.floating};
  border-radius: 24px;
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 12px;
  transition: opacity ease-in-out 80ms;
`;

const Preview = styled.div`
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 50%;
  margin-right: -22px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  pointer-events: none;

  img {
    transform: scale(1.5);
  }
`;

const Entry = styled.div`
  pointer-events: none;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    ${p => p.theme.typography.caption.md};
  }
`;

const Text = styled.div`
  ${p => p.theme.typography.label.md};
  padding-left: 24px;
`;

const achievementToastAnimationProps = getAnimationProps(
  {
    initial: {y: -8, height: 0, opacity: 0},
    animate: {y: 0, height: 'auto', opacity: 1},
    exit: {y: 0, height: 0, opacity: 0}
  },
  {duration: 0.2}
);

const DRAG_OFFSET = 128;

export const AchievementToast = ({stat, userUuid, onClose}: AchievementToastProps) => {
  const controls = useDragControls();

  const achievement = useMemo(
    () => achievementsList.find(a => a.id === stat.name),
    [stat]
  );

  const onDragEnd = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const offset = Math.abs(info.offset.x);
    if (offset >= DRAG_OFFSET) {
      onClose(stat);
    }
  };

  const color = useMemo(
    () =>
      colord(achievement?.color ?? '')
        .darken(0.12)
        .toRgbString(),
    [achievement?.color]
  );

  if (!achievement) {
    return null;
  }

  return (
    <StyledAchievementToast {...achievementToastAnimationProps}>
      <Toast
        style={{backgroundColor: color}}
        drag="x"
        dragControls={controls}
        dragConstraints={{left: 0, right: 0}}
        onDragEnd={onDragEnd}>
        <Preview>
          <AchievementPicture achievement={achievement} stat={stat} />
        </Preview>
        <Entry>
          <Title>
            <FetchedUser uuid={userUuid} fallback={<UserLabelPlaceholder />}>
              {user => <UserLabel user={user} />}
            </FetchedUser>
            <span>получает достижение</span>
          </Title>
          <Text>{achievement.label}</Text>
        </Entry>
      </Toast>
    </StyledAchievementToast>
  );
};
