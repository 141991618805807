import React from 'react';
import {Callout, Spinner} from '@openquiz/quiz-ui';
import {ListRow, PackageFull, useMemoryCollapsedList} from '@/features/wshop-packages';
import {useRoundsQuery} from '@/features/wshop-editor';
import {ThemesEntry} from './ThemesEntry';

interface RoundsListProps {
  pkg: PackageFull;
}

export const RoundsList = ({pkg}: RoundsListProps) => {
  const {data: rounds = [], isLoading} = useRoundsQuery({
    packageUuid: pkg.uuid,
    versionUuid: pkg.versions[0]?.uuid || ''
  });

  const {getItemProps} = useMemoryCollapsedList({initial: [0], mode: 'multiple'});

  if (isLoading) {
    return <Spinner />;
  }

  if (!rounds.length) {
    return <Callout status="attention">Опубликуйте первую версию.</Callout>;
  }

  return (
    <>
      {rounds.map((round, index) => (
        <ListRow
          key={`round-row-${round.uuid}`}
          label={round.name}
          {...getItemProps(index)}>
          <ThemesEntry packageUuid={pkg.uuid} roundUuid={round.uuid} />
        </ListRow>
      ))}
    </>
  );
};
