import React, {ReactNode, forwardRef} from 'react';
import {motion} from 'framer-motion';
import styled from 'styled-components';
import {getAnimationProps} from '../../../libs';

interface WizardStepProps {
  children: ReactNode;
}

const StyledWizardStep = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`;

const wizardAnimationProps = getAnimationProps({
  initial: {x: 24, opacity: 0},
  animate: {x: 0, opacity: 1},
  exit: {x: 0, opacity: 0}
});

export const WizardStep = forwardRef<HTMLDivElement, WizardStepProps>(function WizardStep(
  {children},
  ref
) {
  return (
    <StyledWizardStep ref={ref} {...wizardAnimationProps}>
      {children}
    </StyledWizardStep>
  );
});
