import React from 'react';
import {
  HintRow,
  selectTimer,
  selectTurnTeam,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';

export const ChooseResponderHint = () => {
  const self = useMachineSelf();
  const turnTeam = useMachineSelector(selectTurnTeam);
  const splashTimer = useMachineSelector(selectTimer('splash'));

  if (!turnTeam) {
    return null;
  }

  return (
    <HintRow
      timer={splashTimer}
      type="chooseResponder"
      team={turnTeam}
      userUuid={turnTeam.leaderUuid}
      isSelf={turnTeam.leaderUuid === self.userUuid}
    />
  );
};
