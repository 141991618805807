import {
  FavoritePackage,
  Package,
  PackageVersionFull,
  WorkshopCategoryOnPackage
} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';
import {PaginatedQuery} from '@/types';
import {useQuery} from '@tanstack/react-query';

export type PackageFull = Package & {
  /**
   * TODO: remove this.
   */
  type: 'OnlyVariants' | null;
  difficulty: string | null;
  versions: PackageVersionFull[];
  categories: WorkshopCategoryOnPackage[];
  favorites: FavoritePackage[];
};

export interface FetchPackagesParams {
  filter?: {
    search?: string;
    ownedDrafts?: boolean;
    ownedFavorites?: boolean;
    userUuid?: string;
    illegal?: boolean;
    verified?: boolean;
    createdAt?: string;
  };
  sort?: 'created_at' | 'rating' | 'name';
  cursor?: string;
  take: number;
}

/**
 * Fetch packages with pagination.
 * @param params
 */
export const fetchPackages = async (params: FetchPackagesParams) => {
  const {data} = await getPrivateAxios().get<PaginatedQuery<PackageFull>>(
    '/workshop/packages',
    {params}
  );
  return data;
};

/**
 *
 * @param params
 */
export const usePackagesQuery = (params: FetchPackagesParams) =>
  useQuery({
    queryKey: ['packages', params],
    queryFn: () => fetchPackages(params)
  });
