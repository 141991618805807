import React from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {PackageRound} from '@opeq-dev/openquiz-schema';
import {Surface} from '@openquiz/quiz-ui';
import {Page, PageFallback, PageTitle} from '@/components';
import {
  RoundForm,
  editorRoundPath,
  useEditorContext,
  useRoundQuery,
  useRoundUpdateMutation
} from '@/features/wshop-editor';

export const RoundEditPage = () => {
  const navigate = useNavigate();
  const {packageUuid, roundUuid, versionUuid} = useEditorContext();
  const {data: round, isLoading} = useRoundQuery({
    uuid: roundUuid,
    packageUuid,
    versionUuid
  });
  const {mutateAsync} = useRoundUpdateMutation();

  const backHref = generatePath(editorRoundPath, {packageUuid, roundUuid, versionUuid});

  const onSubmitForm = async (data: PackageRound) => {
    await mutateAsync({data, packageUuid, versionUuid});
    navigate(backHref, {replace: true});
  };

  if (!round || isLoading) {
    return <PageFallback />;
  }

  return (
    <Page>
      <PageTitle to={{href: backHref}} title={round.name} />
      <Surface>
        <RoundForm round={round} onSubmit={onSubmitForm} />
      </Surface>
    </Page>
  );
};
