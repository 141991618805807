import React from 'react';
import styled from 'styled-components';
import {PackageFull} from '@/features/wshop-packages';

interface DecisionSnippetProps {
  pkg: PackageFull;
  match: string;
  payload: {[key: string]: string};
}

const StyledDecisionSnippet = styled.div`
  span {
    text-decoration: underline;
  }
`;

export const DecisionSnippet = ({match, payload}: DecisionSnippetProps) => (
  <StyledDecisionSnippet>
    <span>{payload[match] ? 'правильный' : 'неправильный'}</span>&nbsp; вариант
  </StyledDecisionSnippet>
);
