import React, {useEffect} from 'react';
import styled from 'styled-components';
import {LayoutGroup, motion, useAnimation} from 'framer-motion';

interface AnimatedTitleProps {
  text: string;
  delay: number;
  speed: number;
}

const StyledAnimatedText = styled.div``;

const Word = styled(motion.span)`
  display: inline-block;
  margin-right: 0.25em;
  white-space: nowrap;
`;

const Character = styled(motion.span)`
  display: inline-block;
`;

export const AnimatedText = ({text, delay, speed}: AnimatedTitleProps) => {
  const controls = useAnimation();

  useEffect(() => {
    setTimeout(() => {
      controls.start('visible');
    }, delay);
  }, [controls, delay]);

  const wordAnimation = {
    hidden: {},
    visible: {}
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,
      x: '-4px'
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: speed ? 1 : 0,
        ease: [0.2, 0.65, 0.3, 0.9]
      }
    }
  };

  return (
    <StyledAnimatedText>
      <LayoutGroup>
        {text.split(' ').map((word, index) => {
          return (
            <Word
              aria-hidden="true"
              key={index}
              initial="hidden"
              animate={controls}
              variants={wordAnimation}
              transition={{
                delayChildren: index * speed,
                staggerChildren: speed / word.length
              }}>
              {word.split('').map((character, index) => {
                return (
                  <Character aria-hidden="true" key={index} variants={characterAnimation}>
                    {character}
                  </Character>
                );
              })}
            </Word>
          );
        })}
      </LayoutGroup>
    </StyledAnimatedText>
  );
};
