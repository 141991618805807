import React, {useEffect, useMemo, useRef} from 'react';
import {useLocation} from 'react-use';
import {useModal} from '@openquiz/quiz-modal';
import * as scenarios from './scenarios';
import {ScenarioPlay} from './widgets';

export const OnboardingRoot = () => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const scenario = useMemo(
    () => Object.values(scenarios).find(scenario => scenario.conditions(location)),
    [scenarios, location]
  );

  const openModal = useModal(props =>
    scenario ? <ScenarioPlay {...props} scenario={scenario} /> : null
  );

  useEffect(() => {
    setTimeout(() => {
      if (!scenario || !ref.current) return;
      ref.current.click();
    });
  }, [ref, scenario]);

  return <div ref={ref} onClick={openModal} />;
};
