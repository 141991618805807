import {useCallback, useEffect} from 'react';
import {useMachineUser, useSmartTryAnswer} from '@/features/machine';
import {useUiSettingsStore} from '@/stores';

export const useTryAnswerListener = () => {
  const machineUser = useMachineUser();
  const {onSmartTryAnswer} = useSmartTryAnswer();
  const {hotkeys} = useUiSettingsStore();

  const handleSpaceDown = useCallback(
    (e: KeyboardEvent) => {
      if (!hotkeys || machineUser?.role !== 'PLAYER' || e.code !== 'Space' || e.repeat)
        return;
      onSmartTryAnswer();
    },
    [machineUser?.role, onSmartTryAnswer]
  );

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (machineUser?.role !== 'PLAYER' || e.button !== 2) return;
      onSmartTryAnswer();
    },
    [machineUser?.role, onSmartTryAnswer]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleSpaceDown);
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('keydown', handleSpaceDown);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleSpaceDown, handleMouseDown]);
};
