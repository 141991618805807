import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {Button, DotsLoader, Logotype} from '@openquiz/quiz-ui';
import {fetchDiscordConnect} from '@/features/auth';

const StyledCallbackPage = styled.div`
  background-color: ${p => p.theme.colors.canvas.default};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  text-align: center;
`;

export const DiscordConnectPage = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    const code = searchParams.get('code');

    if (!code) {
      setError(true);
      return;
    }

    fetchDiscordConnect(code)
      .then(() => {
        window.close();
      })
      .catch(() => {
        setError(true);
      });
  }, [searchParams]);

  const onClose = () => {
    window.close();
  };

  return (
    <StyledCallbackPage>
      <Logotype />
      {!error ? (
        <DotsLoader />
      ) : (
        <>
          <div>При связывании профиля Discord произошла ошибка.</div>
          <Button size="sm" onClick={onClose}>
            Закрыть окно
          </Button>
        </>
      )}
    </StyledCallbackPage>
  );
};
