import React from 'react';
import {useFormContext} from 'react-hook-form';
import {
  FieldGroup,
  FieldSection,
  Title,
  TitleBackLink,
  useWizard
} from '@openquiz/quiz-ui';
import {UploadFileField} from './UploadFileField';

export const UploadStep = () => {
  const {onChangeStep} = useWizard();
  const {setValue} = useFormContext();

  const onCompleteUpload = (value: string) => {
    setValue('packageUuid', value.split('/')[0]);
    setValue('versionUuid', value.split('/')[1]);
    onChangeStep('base');
  };

  return (
    <div>
      <TitleBackLink onClick={() => onChangeStep('base')}>Новая игра</TitleBackLink>
      <Title layoutId="title">Загрузка пака</Title>

      <FieldGroup size="xlg">
        <p>Поддерживаются паки из игры SIGame.</p>
      </FieldGroup>

      <FieldSection label="Требования к файлу">
        <p>
          Расширение: siq, zip.
          <br />
          Максимальный размер: 200мб.
        </p>
      </FieldSection>

      <UploadFileField
        onComplete={onCompleteUpload}
        onDiscard={() => onChangeStep('base')}
      />
    </div>
  );
};
