import React from 'react';
import {useSelector} from '@xstate/react';
import styled from 'styled-components';
import {
  AcceptFinalAnswerEvent,
  RejectFinalAnswerEvent,
  TypeFinalAnswerEvent
} from '@opeq-dev/openquiz-machine';
import {useGameService, useMachineEvent} from '@/features/machine';
import {FinalAnswerCard} from '../../@layouts/LeftSidebar/FinalAnswerCard';

const StyledLeaderDecisionFinal = styled.div`
  margin: 0 auto 16px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
`;

export const LeaderDecisionFinal = () => {
  const {service, isMatch} = useGameService();
  const {event} = useMachineEvent();

  const allowFinalDecision = isMatch([{game: {finalRound: {question: 'review'}}}]);

  const finalAnswers = useSelector(
    service,
    state =>
      ((
        state.context.history.filter(
          r => r.type === 'typeFinalAnswer'
        ) as TypeFinalAnswerEvent[]
      ).filter(record => {
        return !state.context.history.some(
          r =>
            (r.type === 'acceptFinalAnswer' || r.type === 'rejectFinalAnswer') &&
            r.payload.teamUuid === record.self.teamUuid
        );
      }) ?? []) as TypeFinalAnswerEvent[]
  );

  const onClickAccent = (teamUuid: string) => {
    event<AcceptFinalAnswerEvent>('acceptFinalAnswer', {teamUuid, multiplier: 1});
  };

  const onClickReject = (teamUuid: string) => {
    event<RejectFinalAnswerEvent>('rejectFinalAnswer', {teamUuid});
  };

  if (!allowFinalDecision) {
    return null;
  }

  return (
    <StyledLeaderDecisionFinal>
      {finalAnswers.map(answer => (
        <FinalAnswerCard
          key={`final-answer-${answer.self.teamUuid}`}
          action={answer}
          onAccept={onClickAccent}
          onReject={onClickReject}
        />
      ))}
    </StyledLeaderDecisionFinal>
  );
};
