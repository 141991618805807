import React, {HTMLAttributes, ReactNode, forwardRef} from 'react';
import {useToggle} from 'react-use';
import {AnimatePresence, motion} from 'framer-motion';
import styled, {css, keyframes} from 'styled-components';
import {CaretRight} from '@phosphor-icons/react';
import {getAnimationProps} from '@openquiz/quiz-ui';

interface CollapsedSectionProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  label: ReactNode;
  extra?: ReactNode;
  highlighted?: boolean;
}

const highlightedAnimation = keyframes`
  from {
    border-color: orange;
  }
  to {
    border-color: transparent;
  }
`;

const StyledCollapsedSection = styled.div<{$highlighted: boolean}>`
  position: relative;
  margin-bottom: 24px;

  ${p =>
    p.$highlighted &&
    css`
      ::after {
        position: absolute;
        left: -12px;
        top: -12px;
        right: -12px;
        bottom: -12px;
        border-radius: 12px;
        border: 2px solid transparent;
        animation: ${highlightedAnimation} ease-in-out 3000ms;
        pointer-events: none;
        content: '';
      }
    `}

  :last-child {
    margin-bottom: 0;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
  user-select: none;
  transition: margin-bottom 80ms ease-in-out;

  :last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.div<{$expanded: boolean}>`
  ${p => p.theme.typography.label.md};
  color: ${p => p.theme.colors.text.subtle};
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 8px;
  cursor: pointer;
  transition: color 80ms ease-in-out;

  svg {
    color: ${p => p.theme.colors.text.subtle};
    flex-shrink: 0;
    transition:
      color 80ms ease-in-out,
      transform 80ms ease-in-out;

    :hover {
      color: ${p => p.theme.colors.accent.emphasis};
    }
  }

  ${p =>
    p.$expanded &&
    css`
      color: ${p => p.theme.colors.text.default};
      svg {
        transform: rotate(45deg);
      }
    `}
`;

const Divider = styled.div`
  border-bottom: 1px dashed ${p => p.theme.colors.border.muted};
  width: 100%;
`;

const Extra = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: -5px 0;

  & > * {
    display: flex;
  }
`;

const Entry = styled(motion.div)``;

const entryMotionProps = getAnimationProps({
  initial: {height: 0, opacity: 0},
  animate: {height: 'auto', opacity: 1},
  exit: {height: 0, opacity: 0}
});

export const CollapsedSection = forwardRef<HTMLDivElement, CollapsedSectionProps>(
  function CollapsedSection({children, label, extra, highlighted = false, ...rest}, ref) {
    const [expanded, setExpanded] = useToggle(true);

    return (
      <StyledCollapsedSection ref={ref} {...rest} $highlighted={highlighted}>
        <Head>
          <Label $expanded={expanded}>
            <CaretRight size={20} weight="bold" onClick={setExpanded} />
            <span>{label}</span>
          </Label>
          <Divider />
          {extra && <Extra>{extra}</Extra>}
        </Head>

        <AnimatePresence initial={false} mode="wait">
          {expanded && <Entry {...entryMotionProps}>{children}</Entry>}
        </AnimatePresence>
      </StyledCollapsedSection>
    );
  }
);
