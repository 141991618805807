import React from 'react';
import {Placeholder} from '@phosphor-icons/react';
import styled from 'styled-components';

const StyledEmptyMessage = styled.div`
  border: 1px dashed ${p => p.theme.colors.border.default};
  border-radius: 16px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const Title = styled.div`
  ${p => p.theme.typography.label.md};
  margin-bottom: 2px;
`;

const Desc = styled.div`
  color: ${p => p.theme.colors.text.muted};
`;

export const EmptyMessage = () => (
  <StyledEmptyMessage>
    <Placeholder size={20} weight="bold" />
    <div>
      <Title>К сожалению, по вашему запросу ничего не найдено.</Title>
      <Desc>Попробуйте изменить запрос или проверьте правильность его написания.</Desc>
    </div>
  </StyledEmptyMessage>
);
