import React, {ReactNode, createElement, forwardRef} from 'react';
import styled from 'styled-components';
import {PhosphorIcon} from '../../../types';

interface StatProps {
  children: ReactNode;
  icon?: PhosphorIcon;
}

const StyledStat = styled.div`
  ${p => p.theme.typography.caption.md};
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Stat = forwardRef<HTMLDivElement, StatProps>(function Stat(
  {children, icon},
  ref
) {
  return (
    <StyledStat ref={ref}>
      {icon && createElement(icon, {size: 20, weight: 'bold'})}
      <span>{children}</span>
    </StyledStat>
  );
});
