import React, {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {
  Button,
  Callout,
  Check,
  FieldGroup,
  FormSection,
  Spinner
} from '@openquiz/quiz-ui';
import {ValidationCheck, usePackageValidateQuery} from '@/features/wshop-editor';
import {JoinDiscordCallout, useCurrentUserQuery} from '@/features/user';

interface PublishFormProps {
  onSubmit: () => Promise<void>;
  packageUuid: string;
  versionUuid: string;
}

export const PublishForm = ({packageUuid, onSubmit}: PublishFormProps) => {
  const {data: messages = [], isFetching} = usePackageValidateQuery(packageUuid);
  const {data: user, isLoading} = useCurrentUserQuery();

  const {
    formState: {isSubmitting},
    register,
    handleSubmit,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      hasErrors: true,
      acceptPolicy: false
    }
  });

  useEffect(() => {
    setValue('hasErrors', !!messages.find(m => m.message.includes('#error')));
  }, [setValue, messages]);

  const hasErrors = watch('hasErrors');
  const acceptPolicy = watch('acceptPolicy');

  const hasConnectedDiscord = useMemo(
    () => user?.oAuths.some(auth => auth.provider === 'discord') || false,
    [user?.oAuths]
  );

  const isDiscordMember = useMemo(
    () => user?.membership?.discord || false,
    [user?.membership]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSection label="Проверки">
        <FieldGroup>
          <FieldGroup>
            <ValidationCheck
              isLoading={isFetching}
              label="Пакет"
              messages={messages.filter(m => m.message.includes('.pkg'))}
            />
          </FieldGroup>
          <FieldGroup>
            <ValidationCheck
              isLoading={isFetching}
              label="Раунды"
              messages={messages.filter(m => m.message.includes('.rounds'))}
            />
          </FieldGroup>
          <FieldGroup>
            <ValidationCheck
              isLoading={isFetching}
              label="Темы"
              messages={messages.filter(m => m.message.includes('.themes'))}
            />
          </FieldGroup>
          <FieldGroup>
            <ValidationCheck
              isLoading={isFetching}
              label="Вопросы"
              messages={messages.filter(m => m.message.includes('.questions'))}
            />
          </FieldGroup>
        </FieldGroup>
      </FormSection>

      <FormSection label="Правила">
        <FieldGroup>
          <Check {...register('acceptPolicy', {required: true})}>
            <span>Почитал</span>
            <a
              href="https://wiki.opeq.dev/workshop/publish"
              target="_blank"
              rel="noreferrer">
              правила публикации
            </a>
          </Check>
        </FieldGroup>
      </FormSection>

      <FormSection>
        {hasErrors && (
          <FieldGroup>
            <Callout status="danger" size="sm">
              В паке обнаружены ошибки, они подсвечены красным цветом, пожалуйста
              исправьте ошибки перед публикацией.
            </Callout>
          </FieldGroup>
        )}
        {!user ? (
          <FieldGroup>
            <Spinner />
          </FieldGroup>
        ) : (
          <FieldGroup>
            {(!hasConnectedDiscord || !isDiscordMember) && (
              <JoinDiscordCallout hasConnectedDiscord={hasConnectedDiscord}>
                Чтобы опубликовать пак, привяжите discord аккаунт и присоединяйтесь к
                нашему discord серверу.
              </JoinDiscordCallout>
            )}
          </FieldGroup>
        )}
        <FieldGroup>
          <Button
            type="submit"
            fetching={isSubmitting || isFetching || isLoading}
            disabled={
              !acceptPolicy || hasErrors || !hasConnectedDiscord || !isDiscordMember
            }>
            Опубликовать
          </Button>
        </FieldGroup>
      </FormSection>
    </form>
  );
};
