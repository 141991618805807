import React from 'react';
import styled from 'styled-components';
import {PackageQuestionStep} from '@opeq-dev/openquiz-schema';
import {useMediaPlay, useMediaProgress} from '../../../hooks';
import {RenderAutoplay} from '@/features/machine';

interface AudioStepProps {
  step: PackageQuestionStep;
}

const StyledAudioStep = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const AudioStep = ({step}: AudioStepProps) => {
  const {progress} = useMediaProgress();
  const {mediaRef} = useMediaPlay();

  return (
    <StyledAudioStep>
      <RenderAutoplay>
        {({muted}) => (
          <>
            {!muted && (
              <picture>
                <source
                  srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f3b6/512.webp"
                  type="image/webp"
                />
                <img
                  src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f3b6/512.gif"
                  alt="🎶"
                  width={128}
                  height={128}
                />
              </picture>
            )}
            <video
              ref={mediaRef}
              src={`${step.payload}#t=${progress}`}
              controls={false}
              playsInline={true}
              muted={muted}
              style={{display: 'none'}}
            />
          </>
        )}
      </RenderAutoplay>
    </StyledAudioStep>
  );
};
