import React, {
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';

export interface InputAutosizeProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  autoSelect?: boolean;
}

const StyledInputAutosize = styled.input`
  background-color: transparent;
  display: flex;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  max-width: 100%;
  min-width: 16px;
  border-radius: 4px;
  transition: background-color ease-in-out 80ms;

  &:hover {
    background-color: ${p => p.theme.colors.neutral.subtle};
  }

  &:focus {
    background-color: ${p => p.theme.colors.neutral.muted};
  }
`;

const FakeRender = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  visibility: hidden;
  overflow: scroll;
  white-space: pre;
`;

export const InputAutosize = forwardRef<HTMLInputElement, InputAutosizeProps>(
  function InputAutosize({autoSelect, ...rest}, ref) {
    const inputRef = useRef<HTMLInputElement>(null);
    const fakeRenderRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const [memoryValue, setMemoryValue] = useState<string | undefined>(
      rest.value?.toString() || rest.defaultValue?.toString() || undefined
    );

    const fakeRenderValue = useMemo(
      () =>
        [memoryValue, rest.value, rest.defaultValue, rest.placeholder, ''].reduce(
          (previousValue, currentValue) => {
            if (previousValue !== null && previousValue !== undefined) {
              return previousValue;
            }
            return currentValue;
          }
        ),
      [memoryValue]
    );

    const updateWidth = useCallback(() => {
      if (!inputRef.current || !fakeRenderRef.current) return;

      const computedStyle = window.getComputedStyle(inputRef.current);

      fakeRenderRef.current.style.fontSize = computedStyle.fontSize;
      fakeRenderRef.current.style.fontWeight = computedStyle.fontWeight;
      fakeRenderRef.current.style.lineHeight = computedStyle.lineHeight;
      fakeRenderRef.current.style.padding = computedStyle.padding;
      fakeRenderRef.current.style.margin = computedStyle.margin;

      inputRef.current.style.width = `${
        fakeRenderRef.current?.getBoundingClientRect().width
      }px`;
    }, [inputRef, fakeRenderRef]);

    useEffect(() => {
      if (!autoSelect || !inputRef.current) return;
      inputRef.current.select();
    }, [autoSelect, inputRef]);

    useLayoutEffect(() => {
      if (!inputRef.current) return;
      setMemoryValue(inputRef.current.value);
    }, [inputRef, rest]);

    useLayoutEffect(() => {
      updateWidth();
    }, [memoryValue, updateWidth]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (rest.onChange) rest.onChange(e);
      setMemoryValue(e.target.value);
    };

    const onFocus = (e: FocusEvent<HTMLInputElement>) => {
      if (rest.onFocus) rest.onFocus(e);
      setMemoryValue(e.target.value);
    };

    return (
      <>
        <StyledInputAutosize
          {...rest}
          ref={inputRef}
          autoComplete="off"
          placeholder={rest.placeholder}
          onChange={onChange}
          onFocus={onFocus}
        />
        <FakeRender ref={fakeRenderRef} style={rest.style}>
          {fakeRenderValue?.toString()}
        </FakeRender>
      </>
    );
  }
);
