import React from 'react';
import SimpleBar from 'simplebar-react';
import styled, {css, useTheme} from 'styled-components';
import {SmileySad, ThumbsDown, ThumbsUp} from '@phosphor-icons/react';
import {Button, FieldRow, UserLabel, UserLabelPlaceholder} from '@openquiz/quiz-ui';
import {TypeFinalAnswerEvent} from '@opeq-dev/openquiz-machine';
import {FetchedUser} from '@/features/user';
import 'simplebar-react/dist/simplebar.min.css';

interface FinalAnswerCardProps {
  action: TypeFinalAnswerEvent;
  decision?: 'accept' | 'reject';
  onAccept?: (teamUuid: string) => void;
  onReject?: (teamUuid: string) => void;
}

const StyledFinalAnswerCard = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  display: inline-flex;
  flex-direction: column;
  border-radius: 12px;
  width: 200px;
  padding: 16px;
  box-shadow: 0 1px 0 rgba(27, 31, 36, 0.04);
`;

const Text = styled.div<{$missingAnswer?: boolean}>`
  color: ${p => p.theme.colors.text.default};
  display: flex;
  align-items: center;
  gap: 4px;
  word-break: break-word;
  margin: 10px 0;
  text-overflow: ellipsis;
  ${p =>
    p.$missingAnswer &&
    css`
      color: ${p => p.theme.colors.text.muted};
    `}
`;

const Footer = styled.div`
  margin-top: auto;
`;

const Decision = styled.div<{$decision: FinalAnswerCardProps['decision']}>`
  ${p => p.theme.typography.label.md};
  display: flex;
  align-items: center;
  gap: 4px;

  ${p =>
    p.$decision === 'accept' &&
    css`
      color: ${p.theme.colors.success.text};
    `}

  ${p =>
    p.$decision === 'reject' &&
    css`
      color: ${p.theme.colors.danger.text};
    `}
`;

export const FinalAnswerCard = ({
  action,
  decision,
  onAccept,
  onReject
}: FinalAnswerCardProps) => {
  const theme = useTheme();
  return (
    <StyledFinalAnswerCard>
      <FetchedUser uuid={action.self.userUuid} fallback={<UserLabelPlaceholder />}>
        {user => <UserLabel user={user} />}
      </FetchedUser>
      {action.payload.answer ? (
        <SimpleBar style={{maxHeight: 80}}>
          <Text>{action.payload.answer}</Text>
        </SimpleBar>
      ) : (
        <Text $missingAnswer={true}>
          <SmileySad size={16} weight="bold" />
          Не успел ответить
        </Text>
      )}
      <Footer>
        {onAccept && onReject && (
          <FieldRow size="sm">
            <Button variant="link" onClick={() => onAccept(action.self.teamUuid)}>
              Принять
            </Button>
            <Button
              variant="link"
              color={theme.colors.danger.text}
              onClick={() => onReject(action.self.teamUuid)}>
              Отклонить
            </Button>
          </FieldRow>
        )}
        {decision && (
          <Decision $decision={decision}>
            {decision === 'accept' && (
              <>
                <ThumbsUp size={16} weight="bold" />
                <span>Правильно</span>
              </>
            )}
            {decision === 'reject' && (
              <>
                <ThumbsDown size={16} weight="bold" />
                <span>Неправильно</span>
              </>
            )}
          </Decision>
        )}
      </Footer>
    </StyledFinalAnswerCard>
  );
};
