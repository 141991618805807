import {PackageQuestion} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export interface QuestionUpdateParams {
  data: PackageQuestion;
  packageUuid: string;
}

/**
 * Fetch update a question.
 * @param params
 */
export const fetchQuestionUpdate = async (params: QuestionUpdateParams) => {
  const {data} = await getPrivateAxios().patch<PackageQuestion>(
    `/workshop/packages/${params.packageUuid}/questions/${params.data.uuid}`,
    params.data
  );
  return data;
};
