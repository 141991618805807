import {motion} from 'framer-motion';
import React, {createElement, useMemo} from 'react';
import styled, {css} from 'styled-components';
import {ChooseQuestionEvent} from '@opeq-dev/openquiz-machine';
import {PackageQuestion} from '@opeq-dev/openquiz-schema';
import {useMachineEvent} from '../../hooks';
import {questionTypeIconMap, questionTypeLabelMap} from '../../libs';

interface QuestionCardProps {
  question: PackageQuestion;
  isUsed: boolean;
}

const StyledQuestionCard = styled.div<{$isUsed: boolean}>`
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 2px solid ${p => p.theme.colors.border.default};
  position: relative;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: none;
  transition:
    background-color ease-in-out 80ms,
    color ease-in-out 80ms,
    box-shadow ease-in-out 80ms;

  @media only screen and (min-width: 320px) {
    ${p => p.theme.typography.body.sm};
    font-weight: bold;
  }

  @media only screen and (min-width: 1024px) {
    ${p => p.theme.typography.body.md};
    font-weight: bold;
  }

  @media only screen and (min-width: 1440px) {
    ${p => p.theme.typography.body.lg};
    font-weight: bold;
  }

  ${p =>
    p.$isUsed &&
    css`
      background-color: ${p => p.theme.colors.canvas.default};
      border: 1px solid ${p => p.theme.colors.border.muted};
      color: transparent;
      box-shadow: none;
      pointer-events: none;
    `}

  :hover {
    border-color: ${p => p.theme.colors.accent.emphasis};

    & [data-id='hint'] span {
      max-width: 100px;
      opacity: 1;
      transform: translateX(0);
    }
  }

  :active {
    background-color: ${p => p.theme.colors.accent.emphasis};
    color: ${p => p.theme.colors.text.onEmphasis};
    box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
    transform: translateY(0px);

    & [data-id='hint'] {
      color: ${p => p.theme.colors.text.onEmphasis};
    }
  }
`;

const Hint = styled.div`
  color: ${p => p.theme.colors.text.subtle};
  position: absolute;
  left: 4px;
  bottom: 4px;
  display: flex;
  align-items: center;
  gap: 2px;

  span {
    display: flex;
    font-size: 11px;
    font-weight: normal;
    line-height: 14px;
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    transform: translateX(-4px);
    transition:
      max-width 80ms ease-in-out,
      opacity 80ms ease-in-out,
      max-width 80ms ease-in-out,
      transform 80ms ease-in-out;
  }
`;

export const QuestionCard = ({question, isUsed = false}: QuestionCardProps) => {
  const {role, event} = useMachineEvent();

  const icon = useMemo(() => questionTypeIconMap.get(question.type), [question.type]);
  const label = useMemo(() => questionTypeLabelMap.get(question.type), [question.type]);

  const onClickQuestion = () => {
    event<ChooseQuestionEvent>('chooseQuestion', {questionUuid: question.uuid});
  };

  return (
    <StyledQuestionCard $isUsed={isUsed} onClick={onClickQuestion}>
      <motion.div layoutId={question.uuid}>{question.price}</motion.div>
      {role === 'LEADER' && (
        <Hint data-id="hint">
          {icon && createElement(icon, {size: 14})}
          {label && <span>{label}</span>}
        </Hint>
      )}
    </StyledQuestionCard>
  );
};
