import React, {MouseEvent} from 'react';
import {Stop} from '@phosphor-icons/react';
import {Menu, MenuGroup, MenuItem, useModal} from '@openquiz/quiz-ui';
import {Game, StopGameModal} from '@/features/game';

interface GameCardMenuProps {
  game: Game;
  onClose?: () => void;
}

export const GameCardMenu = ({game, onClose = () => void 0}: GameCardMenuProps) => {
  const [openCreateModal, closeCreateModal] = useModal(
    () => <StopGameModal game={game} onClose={closeCreateModal} />,
    [game]
  );

  const onClickStop = (e: MouseEvent) => {
    e.preventDefault();
    onClose();
    openCreateModal();
  };

  return (
    <Menu>
      <MenuGroup>
        <MenuItem color="danger" icon={Stop} onClick={onClickStop}>
          Остановить
        </MenuItem>
      </MenuGroup>
    </Menu>
  );
};
