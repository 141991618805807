import styled, {css} from 'styled-components';

export const StyledAvatar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Image = styled.img<{$stroked: boolean}>`
  background-color: ${p => p.theme.colors.canvas.overlay};
  display: flex;
  border-radius: 50%;

  ${p =>
    p.$stroked
      ? css`
          box-shadow:
            0 0 0 2px rgba(255, 255, 255, 0.5),
            ${p => p.theme.shadow.sm},
            ${p => p.theme.shadow.md};
        `
      : css`
          box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
        `}
`;

export const Empty = styled.div<{$stroked: boolean}>`
  background-color: ${p => p.theme.colors.neutral.muted};
  color: ${p => p.theme.colors.text.onEmphasis};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-sizing: border-box;
  transition: border-color ease-in-out 80ms;

  ${p =>
    p.$stroked
      ? css`
          box-shadow:
            0 0 0 2px rgba(255, 255, 255, 0.5),
            ${p => p.theme.shadow.sm},
            ${p => p.theme.shadow.md};
        `
      : css`
          box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
        `}
`;
