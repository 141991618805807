import React, {ReactNode} from 'react';
import {StyledErrorMessage} from './styled';

interface ErrorMessageProps {
  children: ReactNode;
}

export const ErrorMessage = ({children}: ErrorMessageProps) => (
  <StyledErrorMessage>{children}</StyledErrorMessage>
);
