import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface SidebarGroupProps {
  children: ReactNode;
  label?: ReactNode;
}

const StyledSidebarGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (min-width: 320px) {
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 24px;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  ${p => p.theme.typography.caption.md};
  color: ${p => p.theme.colors.text.subtle};
  margin-bottom: 12px;
  font-weight: 300;
  opacity: 0.8;
`;

export const SidebarGroup = ({children, label}: SidebarGroupProps) => (
  <StyledSidebarGroup>
    {label && <Label>{label}</Label>}
    {children}
  </StyledSidebarGroup>
);
