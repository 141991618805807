import React, {createElement, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import styled, {keyframes} from 'styled-components';
import {Star} from '@phosphor-icons/react';
import {Button, FieldGroup, Spinner, TextArea} from '@openquiz/quiz-ui';
import {PackageCard} from '@/features/workshop';
import {
  EvaluateRatingData,
  PackageFull,
  fetchEvaluateRating,
  usePackageEvaluatedRating
} from '@/features/wshop-packages';

interface GameEvaluationStepProps {
  pkg: PackageFull;
  onClickNext?: () => void;
}

const starIconKeyframes = keyframes`
  from {
    transform: scale(1.2) rotate(5deg);
  }
  to {
    transform: scale(1) rotate(0);
  }
`;

const StyledGameEvaluationStep = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;
`;

const CardWrapper = styled.div`
  pointer-events: none;
  margin-bottom: 32px;
  width: 300px;
`;

const Stars = styled.div`
  display: flex;
  gap: 16px;

  path {
    transition: all ease-in-out 80ms;
  }
`;

const StarIcon = styled(Star)`
  cursor: pointer;

  &[data-selected='true'] {
    animation: ${starIconKeyframes} 160ms ease-in-out;
  }

  path {
    transition: all ease-in-out 80ms;
  }
`;

const FeedbackCaption = styled.div`
  ${p => p.theme.typography.body.sm};
  color: ${p => p.theme.colors.text.subtle};
  margin: 8px auto 0 auto;
  text-align: center;
  max-width: 240px;
`;

export const GameEvaluationStep = ({
  pkg,
  onClickNext = () => void 0
}: GameEvaluationStepProps) => {
  const {data: evaluatedRating, isLoading} = usePackageEvaluatedRating(pkg.uuid);

  const [highlight, setHighlight] = useState(0);
  const stars = useMemo(() => new Array(5).fill(StarIcon), []);

  const {
    formState: {isSubmitting},
    register,
    watch,
    setValue,
    handleSubmit
  } = useForm<EvaluateRatingData>({
    defaultValues: {
      packageUuid: pkg.uuid,
      value: 0,
      feedback: ''
    }
  });

  useEffect(() => {
    if (!evaluatedRating) return;
    onClickNext();
  }, [evaluatedRating]);

  const value = watch('value');
  const isFeedbackRequired = !!value && value <= 2;

  const onSubmit = async (data: EvaluateRatingData) => {
    await fetchEvaluateRating(data);
    onClickNext();
  };

  const onClickStar = (index: number) => {
    setValue('value', index);
  };

  const onMouseEnter = (index: number) => {
    setHighlight(index);
  };

  const onMouseLeave = () => {
    setHighlight(0);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <StyledGameEvaluationStep onSubmit={handleSubmit(onSubmit)}>
      <CardWrapper>
        <PackageCard pkg={pkg} />
      </CardWrapper>

      <FieldGroup size="lg">
        <Stars onMouseLeave={onMouseLeave}>
          {stars.map((star, index) =>
            createElement(star, {
              key: `full-star-${index}`,
              size: 44,
              weight: index < (highlight || value) ? 'fill' : 'duotone',
              color: 'orange',
              'data-selected': index < (highlight || value),
              onMouseDown: () => onClickStar(index + 1),
              onMouseEnter: () => onMouseEnter(index + 1)
            })
          )}
        </Stars>
      </FieldGroup>

      <FieldGroup size="lg">
        <TextArea
          {...register('feedback', {
            required: isFeedbackRequired
              ? 'Вы оценили низко, пожалуйста напиши комментарий.'
              : undefined,
            maxLength: {value: 64, message: 'Максимальная длинна 64 символа.'}
          })}
          style={{width: 300}}
          placeholder={isFeedbackRequired ? 'Комментарий*' : 'Комментарий'}
        />
        <FeedbackCaption>Комментарий будет виден только автору пакета.</FeedbackCaption>
      </FieldGroup>

      <FieldGroup size="sm">
        <Button type="submit" fetching={isSubmitting} disabled={!value}>
          Отправить оценку
        </Button>
      </FieldGroup>

      <FieldGroup size="sm">
        <Button variant="link" onClick={onClickNext}>
          Пропустить
        </Button>
      </FieldGroup>
    </StyledGameEvaluationStep>
  );
};
