import React, {MouseEvent, useMemo} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {isAfter, subMinutes} from 'date-fns';
import {DotsThreeOutline} from '@phosphor-icons/react';
import {
  Avatar,
  ButtonToolbar,
  PopperMenu,
  Spinner,
  getLineClampCss
} from '@openquiz/quiz-ui';
import {UserGame} from '../../api';
import {FetchedUser, UserProfileTooltip} from '@/features/user';
import {animalEmojiMap} from '../../libs';
import {GameCardMenu} from '../GameCardMenu';

interface GameSidebarLinkProps {
  game: UserGame;
}

const StyledGameSidebarLink = styled(Link)`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  color: ${p => p.theme.colors.text.default};
  position: relative;
  border-radius: 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 12px 16px;
  width: 100%;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out;

  :hover {
    background-color: ${p => p.theme.colors.accent.emphasis};
    color: ${p => p.theme.colors.text.onEmphasis};

    button {
      color: ${p => p.theme.colors.text.onEmphasis};
    }

    & [data-id='users'] {
      & > div {
        box-shadow: 0 0 0 2px ${p => p.theme.colors.accent.emphasis};
      }
    }

    & [data-id='estimate'] {
      color: ${p => p.theme.colors.text.onEmphasis};
    }
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  display: flex;
  flex-shrink: 0;
  text-align: center;
  font-size: 16px;
  width: 20px;
  line-height: 20px;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.2);
`;

const Entry = styled.div`
  margin-right: auto;
`;

const Name = styled.div`
  ${p => p.theme.typography.label.md};
  text-transform: capitalize;
  margin-bottom: 4px;
  max-width: 92px;
  overflow: hidden;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Caption = styled.div`
  ${p => p.theme.typography.caption.sm};
  ${getLineClampCss(1)}
  opacity: 0.5;
`;

const Estimate = styled.div`
  ${p => p.theme.typography.caption.sm};
  color: ${p => p.theme.colors.danger.text};
  transition: color 80ms ease-in-out;
`;

const Users = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  & > div {
    margin-right: -5px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px ${p => p.theme.colors.canvas.overlay};
    transition: box-shadow 80ms ease-in-out;
  }
`;

const Toolbar = styled.div`
  flex-shrink: 0;
`;

export const GameSidebarLink = ({game}: GameSidebarLinkProps) => {
  const [_, emoji] =
    Object.entries(animalEmojiMap).find(([key]) => game.name.includes(key)) ?? [];

  const users = useMemo(() => game.ctx?.users ?? [], [game.ctx]);

  const isEndSoon = useMemo(
    () =>
      game.expiredAt
        ? isAfter(new Date(), subMinutes(new Date(game.expiredAt), 15))
        : false,
    [game.expiredAt]
  );

  const onClickToolbar = (e: MouseEvent) => {
    e.preventDefault();
  };

  return (
    <StyledGameSidebarLink to={`/game/${game.uuid}`}>
      <Icon>{emoji}</Icon>

      <Entry>
        <Name>
          <div>{game.name}</div>
        </Name>
        <Caption>{game.package.name}</Caption>
        {isEndSoon && <Estimate data-id="estimate">Скоро закончится</Estimate>}
        <Users data-id="users">
          {users.map(user => (
            <FetchedUser
              key={`user-${game.uuid}-${user.uuid}`}
              uuid={user.uuid}
              fallback={<Spinner size={24} />}>
              {user => (
                <UserProfileTooltip user={user}>
                  <Avatar user={user} size={24} />
                </UserProfileTooltip>
              )}
            </FetchedUser>
          ))}
        </Users>
      </Entry>

      <Toolbar onClick={onClickToolbar}>
        <PopperMenu menu={<GameCardMenu game={game} />}>
          <ButtonToolbar>
            <DotsThreeOutline size={16} weight="fill" />
          </ButtonToolbar>
        </PopperMenu>
      </Toolbar>
    </StyledGameSidebarLink>
  );
};
