import React from 'react';
import styled from 'styled-components';
import {Placeholder} from '@openquiz/quiz-ui';

const StyledPackageCardPlaceholder = styled.div`
  background: ${p => p.theme.colors.canvas.overlay};
  border-radius: 20px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;
  width: 100%;
  height: 380px;
  box-sizing: border-box;
`;

export const PackageCardPlaceholder = () => {
  return (
    <StyledPackageCardPlaceholder>
      <Placeholder config={[['line', {height: [24, 0]}]]} />
      <Placeholder config={[['line', {height: [16, 0]}]]} />
      <Placeholder config={[['line', {height: [16, 0]}]]} />
    </StyledPackageCardPlaceholder>
  );
};
