import React from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {Contest} from '@opeq-dev/openquiz-schema';
import {
  Button,
  Callout,
  FieldGroup,
  FieldRow,
  Slider,
  TextArea,
  ErrorMessage as UiErrorMessage,
  getSliderMarks
} from '@openquiz/quiz-ui';
import {ContestFeedbackData} from '@/features/contest';

interface ContestFeedbackFormProps {
  contest: Contest;
  packageUuid: string;
  onSubmit: (data: ContestFeedbackData) => Promise<void>;
  onCancel: () => void;
}

export const ContestFeedbackForm = ({
  contest,
  packageUuid,
  onSubmit,
  onCancel
}: ContestFeedbackFormProps) => {
  const {
    formState: {isSubmitting, errors},
    register,
    handleSubmit,
    setValue
  } = useForm<ContestFeedbackData>({
    defaultValues: {
      contestUuid: contest.uuid,
      packageUuid,
      score: 1,
      message: ''
    }
  });

  const onSubmitForm = async (data: ContestFeedbackData) => {
    await onSubmit(data);
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <FieldGroup size="lg">
        <Callout status="attention" size="sm">
          Важно проводить проверку всесторонне и беспристрастно, закладывая основу для
          честной и справедливой оценки каждого пака.
        </Callout>
      </FieldGroup>

      <FieldGroup size="lg">
        <FieldGroup label="Оценка" size="lg">
          <Slider
            {...register('score', {required: 'Обязательное поле'})}
            marks={getSliderMarks(10)}
            min={1}
            max={10}
            defaultValue={1}
            onChange={value => setValue('score', Number(value))}
          />
          <ErrorMessage
            name="score"
            errors={errors}
            render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup label="Комментарий">
          <TextArea
            rows={3}
            {...register('message', {
              required: 'Обязательное поле',
              maxLength: {value: 512, message: 'Максимальная длинна 512 символов'}
            })}
          />
          <ErrorMessage
            name="message"
            errors={errors}
            render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>
      </FieldGroup>

      <FieldGroup>
        <FieldRow justify="flex-end">
          <Button variant="secondary" onClick={onCancel}>
            Отмена
          </Button>
          <Button type="submit" fetching={isSubmitting}>
            Отправить
          </Button>
        </FieldRow>
      </FieldGroup>
    </form>
  );
};
