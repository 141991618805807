import {useMutation, useQueryClient} from '@tanstack/react-query';
import {GameSettings} from '@opeq-dev/openquiz-schema';
import {Game} from '@/features/game';
import {getPrivateAxios} from '@/libs';

export interface CreateGameDto {
  name: string;
  settings: Omit<GameSettings, 'gameUuid'>;
  packageUuid: string;
  versionUuid: string;
}

/**
 *
 * @param createGameDto
 */
const fetchCreateGame = async (createGameDto: CreateGameDto): Promise<Game> => {
  const {data} = await getPrivateAxios().post<Game>('/game', createGameDto);
  return data;
};

/**
 *
 */
export const useCreateGameMutation = () => {
  const cache = useQueryClient();
  return useMutation({
    mutationFn: fetchCreateGame,
    onSuccess: async () => await cache.refetchQueries({queryKey: ['games']})
  });
};
