import React from 'react';
import {AnimatePresence} from 'framer-motion';
import {
  AlreadyAnswerHint,
  PrePlayHint,
  RenderSteps,
  Screen,
  ScreenContent,
  ScreenFooter,
  TopBar,
  TryAnswerHint,
  useGameService
} from '@/features/machine';
import {LoadingStage} from './LoadingStage';

export const BasicQuestionScreen = () => {
  const {isMatch} = useGameService();

  return (
    <Screen>
      <div>
        {!isMatch([
          {game: {basicRound: {basicQuestion: 'splash'}}},
          {game: {basicRound: {basicQuestion: 'loading'}}}
        ]) && <TopBar />}
      </div>

      <ScreenContent>
        <AnimatePresence mode="wait">
          {isMatch([
            {game: {basicRound: {basicQuestion: 'splash'}}},
            {game: {basicRound: {basicQuestion: 'loading'}}}
          ]) && <LoadingStage />}

          {isMatch([{game: {basicRound: {basicQuestion: 'prePlay'}}}]) && (
            <RenderSteps key="render-steps" stage="pre-play" />
          )}

          {isMatch([
            {game: {basicRound: {basicQuestion: 'play'}}},
            {game: {basicRound: {basicQuestion: 'tryAnswerBuffer'}}},
            {game: {basicRound: {basicQuestion: 'tryAnswer'}}}
          ]) && <RenderSteps key="render-steps" stage="play" />}

          {isMatch([{game: {basicRound: {basicQuestion: 'answer'}}}]) && (
            <RenderSteps stage="answer" />
          )}
        </AnimatePresence>
      </ScreenContent>

      <ScreenFooter>
        {isMatch([{game: {basicRound: {basicQuestion: 'prePlay'}}}]) && <PrePlayHint />}

        {isMatch([
          {game: {basicRound: {basicQuestion: 'play'}}},
          {game: {basicRound: {basicQuestion: 'tryAnswerBuffer'}}}
        ]) && <TryAnswerHint />}

        {isMatch([{game: {basicRound: {basicQuestion: 'tryAnswer'}}}]) && (
          <AlreadyAnswerHint />
        )}
      </ScreenFooter>
    </Screen>
  );
};
