import {getPrivateAxios} from '@/libs';
import {useQuery} from '@tanstack/react-query';

export interface PackageStats {
  chart: {date: string; value: {all: number}}[];
  starts: number;
  percents: number;
}

/**
 * Fetch package stats.
 * @param uuid
 */
const fetchPackageStats = async (uuid: string) => {
  const {data} = await getPrivateAxios().get<PackageStats>(
    `/workshop/packages/${uuid}/stats`
  );
  return data;
};

/**
 *
 * @param uuid
 */
export const usePackageStatsQuery = (uuid: string) =>
  useQuery({
    queryKey: ['packages', 'stats', {uuid}],
    queryFn: () => fetchPackageStats(uuid)
  });
