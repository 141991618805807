import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PackageFull, fetchPackageFavorite} from '../api';

export const usePackageFavorite = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchPackageFavorite,
    onMutate: uuid => {
      queryClient.setQueryData<PackageFull>(['packages', {uuid}], data => {
        if (!data) return;
        return {
          ...data,
          favorites: !data.favorites.length
            ? [{uuid: '', packageUuid: '', userUuid: ''}]
            : []
        };
      });
    }
  });
};
