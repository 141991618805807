import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {generatePath} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';
import {debounce} from 'throttle-debounce';
import {List, PencilSimple} from '@phosphor-icons/react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {PackageRound} from '@opeq-dev/openquiz-schema';
import {InputAutosize, SidebarLink, useDoubleClick} from '@openquiz/quiz-ui';
import {editorRoundPath} from '@/features/wshop-editor';

interface RoundSidebarRowProps {
  round: PackageRound;
  packageUuid: string;
  onChange: (round: PackageRound) => void;
}

const StyledRoundSidebarRow = styled.div`
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: 12px;
  gap: 10px;
  overflow: hidden;
  width: 100%;

  :last-child {
    margin-bottom: 0;
  }

  svg {
    flex-shrink: 0;
  }
`;

const DragHandleIcon = styled(List)`
  transition: color 80ms ease-in-out;
  cursor: grab;
  :hover {
    color: ${p => p.theme.colors.accent.text};
  }
`;

const Label = styled.div`
  ${p => p.theme.typography.caption.md};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const EditForm = styled.form`
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  width: 100%;

  svg {
    color: ${p => p.theme.colors.text.subtle};
  }
`;

export const RoundSidebarRow = ({round, packageUuid, onChange}: RoundSidebarRowProps) => {
  const [inlineEdit, setInlineEdit] = useState(false);
  const {attributes, listeners, setNodeRef, transform} = useSortable({
    id: round.uuid
  });

  const {register, setValue, handleSubmit} = useForm<PackageRound>({
    defaultValues: round,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  useEffect(() => {
    setValue('name', round.name);
  }, [round, setValue]);

  const debouncedChange = useCallback(debounce(300, onChange), [onChange]);

  const hybridClick = useDoubleClick(
    () => {
      setInlineEdit(true);
    },
    () => {},
    {}
  );

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedChange({...round, name: e.target.value});
  };

  const onInputBlur = () => {
    setInlineEdit(false);
  };

  const style = {
    transform: CSS.Transform.toString(transform)
  };

  return (
    <StyledRoundSidebarRow ref={setNodeRef} style={style} {...attributes}>
      {inlineEdit ? (
        <EditForm onSubmit={handleSubmit(onChange)}>
          <PencilSimple size={20} weight="bold" />
          <InputAutosize
            {...register('name', {
              required: 'Обязательное поле',
              minLength: {value: 3, message: 'Минимальная длинна 3 символа.'},
              maxLength: {value: 32, message: 'Максимальная длинна 32 символа.'},
              onChange: onInputChange,
              onBlur: onInputBlur
            })}
            autoSelect={true}
          />
        </EditForm>
      ) : (
        <SidebarLink
          to={generatePath(editorRoundPath, {
            packageUuid,
            roundUuid: round.uuid,
            versionUuid: round.versionUuid
          })}
          icon={() => <DragHandleIcon {...listeners} size={20} weight="bold" />}
          onClick={hybridClick}>
          <Label>{round.name}</Label>
        </SidebarLink>
      )}
    </StyledRoundSidebarRow>
  );
};
