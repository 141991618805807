import React, {ButtonHTMLAttributes, ReactNode, forwardRef} from 'react';
import styled from 'styled-components';

interface ButtonToolbarProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The content of the button, supports icons inside, in size 16px.
   */
  children: ReactNode;
}

const StyledButtonToolbar = styled.button`
  ${p => p.theme.typography.caption.sm};
  background-color: ${p => p.theme.colors.neutral.muted};
  color: ${p => p.theme.colors.text.muted};
  border-radius: 8px;
  border: none;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  cursor: pointer;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out,
    transform 80ms ease-in-out;
  z-index: 1;

  &[data-open='true'],
  :hover,
  :focus-visible {
    background-color: ${p => p.theme.colors.accent.emphasis};
    color: ${p => p.theme.colors.text.onEmphasis};
  }

  :focus-visible {
    box-shadow: 0 0 0 2px ${p => p.theme.colors.accent.focus};
  }

  &:active {
    transform: scale(0.98);
  }
`;

/**
 * Button to display in toolbars, on cards as a button with actions.
 */
export const ButtonToolbar = forwardRef<HTMLButtonElement, ButtonToolbarProps>(
  function ButtonToolbar({children, ...rest}, ref) {
    return (
      <StyledButtonToolbar ref={ref} type="button" {...rest}>
        {children}
      </StyledButtonToolbar>
    );
  }
);
