import React from 'react';
import {DotsLoader, FieldRow} from '@openquiz/quiz-ui';

export const PrePlayHint = () => (
  <FieldRow size="sm">
    <div style={{height: 20}}>
      <DotsLoader />
    </div>
  </FieldRow>
);
