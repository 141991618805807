import React from 'react';
import styled from 'styled-components';
import {StyledUserLabel} from './styled';

export interface UserLabelPlaceholderProps {
  layoutId?: string;
  hasCaption?: boolean;
}

const Circle = styled.div`
  background-color: ${p => p.theme.colors.neutral.muted};
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

const Line = styled.div`
  background-color: ${p => p.theme.colors.neutral.muted};
  border-radius: 4px;
  width: 100px;
  height: 16px;
`;

export const UserLabelPlaceholder = ({
  layoutId,
  hasCaption = false
}: UserLabelPlaceholderProps) => (
  <StyledUserLabel layoutId={layoutId} $hasCaption={hasCaption}>
    <Circle />
    <Line />
  </StyledUserLabel>
);
