import React, {FunctionComponent, ReactNode, createElement, useId, useMemo} from 'react';
import {Link, generatePath} from 'react-router-dom';
import {AnimatePresence, motion} from 'framer-motion';
import styled, {useTheme} from 'styled-components';
import {CheckCircle, CircleDashed, WarningCircle, XCircle} from '@phosphor-icons/react';
import {
  PackageQuestion,
  PackageQuestionStep,
  PackageRound,
  PackageTheme
} from '@opeq-dev/openquiz-schema';
import {getAnimationProps} from '@openquiz/quiz-ui';
import {
  ValidateMessage,
  ValidationMessage,
  editorRoundPath,
  useEditorContext
} from '@/features/wshop-editor';

interface ValidationCheckProps {
  isLoading: boolean;
  label: ReactNode;
  messages: ValidateMessage[];
}

const StyledValidationCheck = styled.div``;

const Label = styled.div`
  ${p => p.theme.typography.label.md};
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const Result = styled(motion.div)`
  color: ${p => p.theme.colors.text.muted};
  padding-left: 2px;
  margin-top: 8px;

  ol {
    padding: 0 56px;
    margin: 0 0 8px 0;
  }

  ol:last-child {
    margin-bottom: 0;
  }
`;

const resultMotionProps = getAnimationProps(
  {
    initial: {height: 0, opacity: 0},
    animate: {height: 'auto', opacity: 1},
    exit: {height: 0, opacity: 0}
  },
  {duration: 0.2}
);

interface RoundsEmptyErrorProps {
  data: Pick<PackageRound, 'uuid' | 'name' | 'versionUuid'>[];
}

interface ThemesEmptyErrorProps {
  data: Pick<PackageTheme, 'uuid' | 'name' | 'roundUuid' | 'versionUuid'>[];
}

interface QuestionsEmptyErrorProps {
  data: {
    question: Pick<PackageQuestion, 'uuid' | 'price' | 'themeUuid' | 'versionUuid'>;
    theme: Pick<PackageTheme, 'uuid' | 'name' | 'roundUuid' | 'versionUuid'>;
  }[];
}
interface StepDuplicateFileProps {
  data: {
    step: Pick<
      PackageQuestionStep,
      'uuid' | 'position' | 'isAnswer' | 'questionUuid' | 'versionUuid'
    > & {hash: string};
    question: Pick<
      PackageQuestion,
      'uuid' | 'price' | 'themeUuid' | 'versionUuid' | 'position'
    >;
    theme: Pick<PackageTheme, 'uuid' | 'name' | 'roundUuid' | 'versionUuid' | 'position'>;
  }[];
}

const PkgEmptyMessage = () => {
  return <ValidationMessage label="В паке нет раундов." level="danger" />;
};

const PkgMissingCoverMessage = () => {
  return <ValidationMessage label="Не загружена обложка." level="danger" />;
};

const PkgMissingDescriptionMessage = () => {
  return <ValidationMessage label="Не указано описание." level="attention" />;
};

const PkgMissingCategoriesMessage = () => {
  return <ValidationMessage label="Не указаны категории." level="attention" />;
};

const PkgMissingTagsMessage = () => {
  return <ValidationMessage label="Не указаны теги." level="attention" />;
};

const PkgMissingDifficultyMessage = () => {
  return <ValidationMessage label="Не указана сложность." level="attention" />;
};

const RoundsEmptyMessage = ({data}: RoundsEmptyErrorProps) => {
  const id = useId();
  const {packageUuid, versionUuid} = useEditorContext();
  return (
    <ValidationMessage label="Пустые раунды:" level="danger">
      <ol>
        {data.map(round => (
          <li key={`round-error-${id}-${round.uuid}`}>
            <Link
              to={generatePath(editorRoundPath, {
                packageUuid,
                versionUuid,
                roundUuid: round.uuid
              })}>
              {round.name}
            </Link>
          </li>
        ))}
      </ol>
    </ValidationMessage>
  );
};

const RoundsToManyThemesMessage = ({data}: RoundsEmptyErrorProps) => {
  const id = useId();
  const {packageUuid, versionUuid} = useEditorContext();
  return (
    <ValidationMessage label="В этих раундах, слишком много тем:" level="attention">
      <ol>
        {data.map(round => (
          <li key={`round-${id}-${round.uuid}`}>
            <Link
              to={generatePath(editorRoundPath, {
                packageUuid,
                versionUuid,
                roundUuid: round.uuid
              })}>
              {round.name}
            </Link>
          </li>
        ))}
      </ol>
    </ValidationMessage>
  );
};

const RoundsSimilarNameMessage = ({data}: RoundsEmptyErrorProps) => {
  const id = useId();
  const {packageUuid, versionUuid} = useEditorContext();
  return (
    <ValidationMessage label="Одинаковые названия раундов:" level="attention">
      <ol>
        {data.map(round => (
          <li key={`round-error-${id}-${round.uuid}`}>
            <Link
              to={generatePath(editorRoundPath, {
                packageUuid,
                versionUuid,
                roundUuid: round.uuid
              })}>
              {round.name}
            </Link>
          </li>
        ))}
      </ol>
    </ValidationMessage>
  );
};

const ThemesEmptyMessage = ({data}: ThemesEmptyErrorProps) => {
  const id = useId();
  const {packageUuid, versionUuid} = useEditorContext();
  return (
    <ValidationMessage label="Пустые темы:" level="danger">
      <ol>
        {data.map(theme => (
          <li key={`theme-error-${id}-${theme.uuid}`}>
            <Link
              to={generatePath(editorRoundPath, {
                packageUuid,
                versionUuid,
                roundUuid: theme.roundUuid
              })}
              state={{highlights: theme.uuid}}>
              {theme.name}
            </Link>
          </li>
        ))}
      </ol>
    </ValidationMessage>
  );
};

const QuestionsPriceMessage = ({data}: ThemesEmptyErrorProps) => {
  const id = useId();
  const {packageUuid, versionUuid} = useEditorContext();
  return (
    <ValidationMessage label="Неправильный порядок вопросов:" level="attention">
      <ol>
        {data.map(theme => (
          <li key={`theme-error-${id}-${theme.uuid}`}>
            <Link
              to={generatePath(editorRoundPath, {
                packageUuid,
                versionUuid,
                roundUuid: theme.roundUuid
              })}
              state={{highlights: theme.uuid}}>
              {theme.name}
            </Link>
          </li>
        ))}
      </ol>
    </ValidationMessage>
  );
};

const QuestionsEmptyStepsMessage = ({data}: QuestionsEmptyErrorProps) => {
  const id = useId();
  const {packageUuid, versionUuid} = useEditorContext();
  return (
    <ValidationMessage label="Пустой вопрос:" level="danger">
      <ol>
        {data.map(item => (
          <li key={`question-error-${id}-${item.question.uuid}`}>
            <Link
              to={generatePath(editorRoundPath, {
                packageUuid,
                versionUuid,
                roundUuid: item.theme.roundUuid
              })}
              state={{highlights: item.question.uuid}}>
              {item.theme.name} - {item.question.price}
            </Link>
          </li>
        ))}
      </ol>
    </ValidationMessage>
  );
};

const stepSort = (
  a: StepDuplicateFileProps['data'][0],
  b: StepDuplicateFileProps['data'][0]
) => {
  if (a.theme.position === b.theme.position) {
    if (a.question.position === b.question.position) {
      if (a.step.isAnswer && !b.step.isAnswer) {
        return 1;
      } else if (!a.step.isAnswer && b.step.isAnswer) {
        return -1;
      } else {
        return a.step.position - b.step.position;
      }
    }
    return a.question.position - b.question.position;
  }
  return a.theme.position - b.theme.position;
};

const stepGroupReducer = (
  group: Record<string, StepDuplicateFileProps['data']>,
  item: StepDuplicateFileProps['data'][0]
) => {
  const key = item.step.hash;
  if (!group[key]) {
    group[key] = [];
  }
  group[key].push(item);
  return group;
};

const StepDuplicateFileMessage = ({data}: StepDuplicateFileProps) => {
  const id = useId();
  const {packageUuid, versionUuid} = useEditorContext();

  const groups = useMemo(() => data.sort(stepSort).reduce(stepGroupReducer, {}), [data]);

  return (
    <ValidationMessage label="Дубликат файлов:" level="attention">
      <ol>
        {Object.values(groups).map((group, key) => (
          <li key={`step-error-${id}-${key}`}>
            {group.map(item => (
              <div key={`step-error-${id}-${item.step.uuid}`}>
                <Link
                  key={`step-error-${id}-${item.step.uuid}`}
                  to={generatePath(editorRoundPath, {
                    packageUuid,
                    versionUuid,
                    roundUuid: item.theme.roundUuid
                  })}
                  state={{highlights: item.question.uuid}}>
                  {item.theme.name} - {item.question.price}{' '}
                  {item.step.isAnswer ? '(Ответ)' : `(Шаг: ${item.step.position + 1})`}
                </Link>
                <br />
              </div>
            ))}
          </li>
        ))}
      </ol>
    </ValidationMessage>
  );
};

const errorComponentMap = new Map<string, FunctionComponent<never>>([
  ['#error.pkg.empty', PkgEmptyMessage],
  ['#error.pkg.missing-cover', PkgMissingCoverMessage],
  ['#warning.pkg.missing-description', PkgMissingDescriptionMessage],
  ['#warning.pkg.missing-categories', PkgMissingCategoriesMessage],
  ['#warning.pkg.missing-tags', PkgMissingTagsMessage],
  ['#warning.pkg.missing-difficulty', PkgMissingDifficultyMessage],
  ['#error.rounds.empty', RoundsEmptyMessage],
  ['#warning.rounds.to-many-themes', RoundsToManyThemesMessage],
  ['#warning.rounds.similar-name', RoundsSimilarNameMessage],
  ['#error.themes.empty', ThemesEmptyMessage],
  ['#warning.questions.price', QuestionsPriceMessage],
  ['#error.questions.empty-steps', QuestionsEmptyStepsMessage],
  ['#warning.questions.duplicate-file', StepDuplicateFileMessage]
]);

export const ValidationCheck = ({isLoading, label, messages}: ValidationCheckProps) => {
  const id = useId();
  const theme = useTheme();

  const isError = !!messages.find(m => m.message.includes('#error'));
  const isWarning = !isError && !!messages.find(m => m.message.includes('#warning'));
  const isSuccess = !isError && !isWarning;

  return (
    <StyledValidationCheck>
      <Label>
        {isLoading ? (
          <CircleDashed size={20} weight="bold" color={theme.colors.text.muted} />
        ) : (
          <>
            {isError && (
              <XCircle size={20} weight="bold" color={theme.colors.danger.text} />
            )}
            {isWarning && (
              <WarningCircle
                size={20}
                weight="bold"
                color={theme.colors.attention.text}
              />
            )}
            {isSuccess && (
              <CheckCircle size={20} weight="bold" color={theme.colors.success.text} />
            )}
          </>
        )}

        <span>{label}</span>
      </Label>

      <AnimatePresence initial={false}>
        {!!messages.length && (
          <Result {...resultMotionProps}>
            {messages.map((message, key) =>
              createElement(
                errorComponentMap.get(message.message) as FunctionComponent<{
                  data: ValidateMessage['data'];
                }>,
                {
                  key: `${id}-${key}`,
                  data: message.data
                }
              )
            )}
          </Result>
        )}
      </AnimatePresence>
    </StyledValidationCheck>
  );
};
