import {FindAllUsersDto} from '@/features/user';

/**
 * This function returns a query key for a user.
 * @param uuidOrUsername
 * @param discriminator
 */
export const getUserQueryKey = (uuidOrUsername: string, discriminator?: string) => {
  return ['users', {uuidOrUsername, discriminator}];
};

/**
 * This function returns a query key for a users list.
 * @param findAllUsersDto
 */
export const getUsersListKey = (findAllUsersDto: FindAllUsersDto) => {
  return ['users', findAllUsersDto];
};
