import {createElement} from 'react';
import {useModal} from '@openquiz/quiz-modal';
import {SignModal, useAuthStore} from '@/features/auth';

type Callback = () => void;

export const useAuthGuardCallback = (callback: Callback) => {
  const {token} = useAuthStore();
  const openSignModal = useModal(props => createElement(SignModal, props));

  return () => {
    if (!token) {
      openSignModal();
      return;
    }
    callback();
  };
};
