import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';

interface TableColumnProps {
  children?: ReactNode;
  width?: string | number;
}

const StyledTableColumn = styled.div<{
  $isNonShrink: boolean;
}>`
  padding: 20px 24px;

  ${p =>
    p.$isNonShrink &&
    css`
      flex-shrink: 0;
    `}
`;

export const TableColumn = ({children, width}: TableColumnProps) => (
  <StyledTableColumn $isNonShrink={typeof width === 'number'} style={{width}}>
    {children}
  </StyledTableColumn>
);
