import React from 'react';
import {IntlProvider} from 'react-intl';
import {RouterProvider} from 'react-router-dom';
import {PostHogProvider} from 'posthog-js/react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {router} from './router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: true
    }
  }
});

const messages = {
  'user.package.count':
    '{count, plural, =0 {# паков} one {# пак} few {# пака} many {# паков} other {# паков}}',
  'user.game.count':
    '{count, plural, =0 {# игр} one {# игра} few {# игры} many {# игр} other {# игр}}',
  'user.win.count':
    '{count, plural, =0 {# побед} one {# победа} few {# победы} many {# побед} other {# победы}}',
  'workshop.recommended-label': 'Рекомендуемый',
  'workshop.draft-label': 'Черновик',
  'workshop.rating.count':
    '{count, plural, =0 {# оценок} one {# оценка} few {# оценки} many {# оценок} other {# оценок}}',
  'workshop.packages.count': '',
  'workshop.packages.runs':
    '{count, plural, =0 {# запусков} one {# запуск} two {# запуска} few {# запуска} many {# запусков} other {# запусков}}',
  'workshop.questions.count':
    '{count, plural, =0 {# вопросов} one {# вопрос} two {# вопроса} few {# вопроса} many {# вопросов} other {# вопросов}}',
  'workshop.steps.image.count':
    '{count, plural, =0 {# изображений} one {# изображение} two {# изображения} few {# изображения} many {# изображений} other {# изображений}}',
  'workshop.steps.audio.count':
    '{count, plural, =0 {# аудио} one {# аудио} two {# аудио} few {# аудио} many {# аудио} other {# аудио}}',
  'workshop.steps.video.count':
    '{count, plural, =0 {# видео} one {# видео} two {# видео} few {# видео} many {# видео} other {# видео}}'
};

export const App = () => (
  <PostHogProvider
    apiKey={import.meta.env.VITE_POSTHOG_KEY}
    options={{api_host: import.meta.env.VITE_POSTHOG_HOST}}>
    <IntlProvider messages={messages} locale="ru" defaultLocale="ru">
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </IntlProvider>
  </PostHogProvider>
);
