import React from 'react';
import {
  HintRow,
  selectTimer,
  selectTurnTeam,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';

export const BanThemeHint = () => {
  const self = useMachineSelf();

  const turnTeam = useMachineSelector(selectTurnTeam);
  const finalBansTimer = useMachineSelector(selectTimer('finalBans'));

  if (!turnTeam) {
    return null;
  }

  return (
    <HintRow
      timer={finalBansTimer}
      type="chooseBanTheme"
      team={turnTeam}
      userUuid={turnTeam.leaderUuid}
      isSelf={turnTeam.leaderUuid === self?.userUuid}
    />
  );
};
