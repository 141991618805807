import React from 'react';
import styled, {css, keyframes} from 'styled-components';
import {HandPalm, HandWaving, XCircle} from '@phosphor-icons/react';
import {Tooltip} from '@openquiz/quiz-ui';
import {
  selectCtxCurrentQuestion,
  useGameService,
  useMachineEvent,
  useMachineGuard,
  useMachineSelector,
  useSmartTryAnswer
} from '@/features/machine';
import {ControlBarButton} from '../ControlBarButton';
import {PassTryAnswerEvent, basicTryAnswerGuard} from '@opeq-dev/openquiz-machine';

const dashOffsetAnimation = keyframes`
  0% {
    stroke-dashoffset: 24;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const StyledPlayerAnswerButton = styled.div`
  position: relative;
`;

const BlockedIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;

  rect {
    stroke: ${p => p.theme.colors.neutral.emphasis};
    animation: ${dashOffsetAnimation} 500ms linear infinite;
  }
`;

const StyledControlBarButton = styled(ControlBarButton)<{$disabled?: boolean}>`
  ${p =>
    p.$disabled &&
    css`
      background-color: ${p => p.theme.colors.border.subtle};
      border-color: ${p => p.theme.colors.border.subtle};
      color: ${p => p.theme.colors.text.default};
      opacity: 0.5;
    `}
`;

export const PlayerAnswerButton = () => {
  const {isMatch} = useGameService();
  const {event} = useMachineEvent();
  const {guard} = useMachineGuard();
  const {isBlocked, onSmartTryAnswer} = useSmartTryAnswer();

  const question = useMachineSelector(selectCtxCurrentQuestion);

  const allowAnswer =
    isMatch([
      {game: {basicRound: {basicQuestion: 'play'}}},
      {game: {basicRound: {basicQuestion: 'tryAnswerBuffer'}}},
      {game: {basicRound: {variantsOneByOneQuestion: 'play'}}},
      {game: {basicRound: {variantsOneByOneQuestion: 'tryAnswerBuffer'}}}
    ]) &&
    question &&
    guard(basicTryAnswerGuard)({questionUuid: question.uuid});

  const computedAllowAnswer = allowAnswer && !isBlocked;

  const onClickPass = () => {
    if (!question) return;
    event<PassTryAnswerEvent>('passTryAnswer', {questionUuid: question.uuid});
  };

  return (
    <>
      <StyledPlayerAnswerButton>
        {isBlocked && (
          <BlockedIndicator>
            <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
              <rect
                width="100%"
                height="100%"
                fill="none"
                rx={16}
                ry={16}
                strokeWidth={2}
                strokeDasharray="16,8"
                strokeDashoffset={0}
                strokeLinecap="butt"
              />
            </svg>
          </BlockedIndicator>
        )}

        {computedAllowAnswer ? (
          <ControlBarButton onClick={onSmartTryAnswer}>
            <HandWaving size={24} weight="bold" />
            <span style={{width: 100}}>Ответить</span>
          </ControlBarButton>
        ) : (
          <StyledControlBarButton $disabled={true} onClick={onSmartTryAnswer}>
            <HandPalm size={24} weight="bold" />
            <span style={{width: 100}}>Подождите</span>
          </StyledControlBarButton>
        )}
      </StyledPlayerAnswerButton>

      <Tooltip message="Пас" placement="top" closeOnReferencePress={true}>
        <ControlBarButton
          variant="secondary"
          disabled={!computedAllowAnswer}
          onClick={onClickPass}>
          <XCircle size={24} weight="bold" />
        </ControlBarButton>
      </Tooltip>
    </>
  );
};
