import React, {ReactNode, useEffect} from 'react';
import {useLocation, useToggle} from 'react-use';
import styled, {css} from 'styled-components';
import {useMediaDevice} from '@openquiz/quiz-ui';
import {MobileHeader} from './MobileHeader';

interface ShellProps {
  children: ReactNode;
  sidebar: ReactNode;
}

const StyledShell = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: 1440px;
  margin: 0 auto;
`;

const MobileWrapper = styled.div`
  overflow: hidden;
  width: 100%;
`;

const Sidebar = styled.aside`
  position: sticky;
  left: 0;
  top: 0;
  padding: 24px 0 24px 24px;
  width: 240px;
  height: calc(100vh / var(--zoom));
  flex-shrink: 0;
`;

const MobileSidebar = styled.aside<{$open: boolean}>`
  position: fixed;
  left: 0;
  top: 0;
  padding: 24px;
  width: 240px;
  height: calc(100vh / var(--zoom));
  flex-shrink: 0;
  transition:
    opacity 160ms ease-in-out,
    visibility 160ms ease-in-out,
    transform 160ms ease-in-out;
  transform: translateX(-240px) scale(1.1);
  opacity: 0;
  z-index: 10;
  pointer-events: none;

  ${p =>
    p.$open &&
    css`
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
      transform: translateX(0) scale(1);
    `}
`;

const Content = styled.main`
  width: 100%;
  padding: 24px;
  overflow: hidden;
`;

const MobileContent = styled.div<{$open: boolean}>`
  padding-top: 64px;
  width: 100%;
  transition:
    transform 160ms ease-in-out,
    filter 160ms ease-in-out;

  ${p =>
    p.$open &&
    css`
      transform: translateX(240px) scale(0.98);
      pointer-events: none;
      filter: blur(16px);
    `}
`;

export const Shell = ({children, sidebar}: ShellProps) => {
  const location = useLocation();
  const device = useMediaDevice();
  const [open, toggleOpen] = useToggle(false);

  useEffect(() => {
    toggleOpen(false);
  }, [device, location]);

  return (
    <StyledShell>
      {device !== 'mobile' && (
        <>
          <Sidebar>{sidebar}</Sidebar>
          <Content>{children}</Content>
        </>
      )}

      {device === 'mobile' && (
        <MobileWrapper>
          <MobileHeader open={open} onToggle={toggleOpen} />
          <MobileSidebar $open={open}>{sidebar}</MobileSidebar>
          <MobileContent $open={open}>
            <Content>{children}</Content>
          </MobileContent>
        </MobileWrapper>
      )}
    </StyledShell>
  );
};
