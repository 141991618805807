import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {fetchThemeCreate} from './fetchThemeCreate';

/**
 *
 */
export const useThemeCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchThemeCreate,
    onSuccess: data => {
      if (!data) return;
      queryClient.setQueriesData<PackageTheme[]>(
        {queryKey: ['editor', 'themes']},
        (themes = []) => [...themes, data]
      );
    }
  });
};
