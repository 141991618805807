import React from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {
  Button,
  FieldGroup,
  FieldRow,
  FormSection,
  Input,
  TextArea,
  TipCircle,
  ErrorMessage as UiErrorMessage
} from '@openquiz/quiz-ui';
import {ThemeCreateData, ThemeUpdateData} from '@/features/wshop-editor';

export type ThemeFormData = ThemeCreateData | ThemeUpdateData;

interface ThemeFormProps {
  theme?: PackageTheme;
  roundUuid: string;
  onSubmit: (data: ThemeFormData) => Promise<void>;
}

export const ThemeForm = ({theme, roundUuid, onSubmit}: ThemeFormProps) => {
  const {
    register,
    formState: {isSubmitting, errors},
    handleSubmit
  } = useForm<ThemeCreateData>({
    defaultValues: {
      name: '',
      description: '',
      position: -1,
      roundUuid: roundUuid,
      ...theme
    }
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSection label="Информация">
        <FieldGroup label="Название">
          <Input
            {...register('name', {
              required: 'Обязательное поле',
              minLength: {value: 3, message: 'Минимальная длинна 3 символа.'},
              maxLength: {value: 64, message: 'Максимальная длинна 64 символа.'}
            })}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup
          label={
            <FieldRow size="sm">
              <span>Описание</span>
              <TipCircle message="Описание темы, чтобы игроки понимали, что их ждет." />
            </FieldRow>
          }>
          <TextArea
            {...register('description', {
              maxLength: {value: 256, message: 'Максимальная длинна 256 символов.'}
            })}
            rows={3}
          />
          <ErrorMessage
            errors={errors}
            name="description"
            render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>
      </FormSection>

      <FormSection>
        <Button fetching={isSubmitting} type="submit">
          {theme ? 'Сохранить' : 'Создать'}
        </Button>
      </FormSection>
    </form>
  );
};
