import {motion} from 'framer-motion';
import styled from 'styled-components';

export const StyledUserLabel = styled(motion.div)<{$hasCaption: boolean}>`
  display: flex;
  align-items: center;
  gap: ${p => (p.$hasCaption ? '8px' : '4px')};
`;

export const Entry = styled.div``;

export const Username = styled.div`
  ${p => p.theme.typography.label.md}
`;

export const Caption = styled.div`
  ${p => p.theme.typography.caption.sm}
  opacity: 0.7;
`;
