import React, {ReactNode, createElement, useCallback} from 'react';
import {PanInfo, motion} from 'framer-motion';
import styled, {css} from 'styled-components';
import {PhosphorIcon} from '@openquiz/quiz-ui';

interface ToggleProps {
  value: boolean;
  label: ReactNode;
  icon?: PhosphorIcon;
  variants?: 'default' | 'highlighted';
  disabled?: boolean;
  onChange: (value: boolean) => void;
  onBlur: () => void;
}

const StyledToggle = styled.div<{
  $disabled: boolean;
  $variants: 'default' | 'highlighted';
}>`
  background-color: ${p => p.theme.colors.canvas.overlay};

  display: flex;
  align-items: center;
  padding: 10px 16px;
  gap: 10px;

  /* TODO: Придумать что делать с этим */
  min-width: 120px;
  max-width: 400px;

  ${p =>
    p.$disabled &&
    css`
      background-color: ${p => p.theme.colors.canvas.default};
      color: ${p => p.theme.colors.text.muted};
      pointer-events: none;
    `}

  ${p =>
    p.$variants === 'default' &&
    css`
      border: 1px solid ${p => p.theme.colors.border.default};
      border-radius: 12px;
    `}

    ${p =>
    p.$variants === 'highlighted' &&
    css`
      border: 1px solid ${p => p.theme.colors.accent.emphasis};
      border-radius: 12px;
      background-color: ${p => p.theme.colors.accent.subtle};
    `}
`;

const Input = styled.button`
  width: 48px;
  height: 24px;
  border-radius: 14px;
  border: none;
  padding: 0 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 80ms ease-in-out;
  overflow: hidden;

  &[data-checked='true'] {
    background-color: ${p => p.theme.colors.accent.emphasis};

    :focus {
      box-shadow: 0 0 0 2px ${p => p.theme.colors.accent.focus};
    }
  }

  &[data-checked='false'] {
    background-color: ${p => p.theme.colors.border.default};

    :focus {
      box-shadow: 0 0 0 2px ${p => p.theme.colors.border.subtle};
    }
  }

  div {
    width: 16px;
    height: 16px;
    background-color: ${p => p.theme.colors.text.onEmphasis};
    border-radius: 10px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.02);
  }
`;

const Icon = styled.div`
  color: ${p => p.theme.colors.text.muted};
  width: 20px;
  height: 20px;
  display: flex;
  flex-shrink: 0;
`;

const Label = styled.div`
  margin-right: auto;
  display: flex;
`;

export const Toggle = ({
  value,
  label,
  icon,
  disabled = false,
  variants = 'default',
  onChange,
  onBlur
}: ToggleProps) => {
  const onClickToggle = useCallback(() => {
    onChange(!value);
  }, [onChange, value]);

  const onDragEnd = useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      if (info.offset.x > 50) {
        onChange(true);
      } else if (info.offset.x < -50) {
        onChange(false);
      }
    },
    [onChange]
  );

  return (
    <StyledToggle $disabled={disabled} $variants={variants}>
      {icon && <Icon>{createElement(icon, {size: 20, weight: 'fill'})}</Icon>}
      <Label>{label}</Label>
      <Input
        data-checked={value}
        type="button"
        style={{justifyContent: value ? 'flex-end' : 'flex-start'}}
        onClick={onClickToggle}
        onBlur={onBlur}>
        <motion.div
          layout={true}
          whileTap={{width: 24}}
          transition={{duration: 0.15}}
          drag="x"
          dragConstraints={{left: 0, right: 0}}
          dragElastic={value ? {left: 0.05, right: 0} : {left: 0, right: 0.05}}
          onDragEnd={onDragEnd}
        />
      </Input>
    </StyledToggle>
  );
};
