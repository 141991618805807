import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface ControlBarContainerProps {
  children?: ReactNode;
}

const StyledControlBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px 24px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

export const ControlBarContainer = ({children}: ControlBarContainerProps) => (
  <StyledControlBarContainer>{children}</StyledControlBarContainer>
);
