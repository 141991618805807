import React from 'react';
import styled from 'styled-components';
import {Contest} from '@opeq-dev/openquiz-schema';
import {Spinner, Table, TableColumn, TableRow} from '@openquiz/quiz-ui';
import {ContestPkgRow, useContestDetail} from '@/features/contest';

interface ContestJuryTableProps {
  contest: Contest;
}

const StyledContestJuryTable = styled.div`
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ContestJuryTable = ({contest}: ContestJuryTableProps) => {
  const {data: contestDetail, isLoading} = useContestDetail(contest.uuid);

  if (isLoading || !contestDetail) {
    return <Spinner />;
  }

  return (
    <StyledContestJuryTable>
      <Table label={contest.name}>
        <TableRow isHeader={true}>
          <TableColumn width="30%">Название</TableColumn>
          <TableColumn width="15%">Автор</TableColumn>
          <TableColumn width="20%">Дата создания</TableColumn>
          <TableColumn width={240}>Статус</TableColumn>
        </TableRow>
        {contestDetail.packages.map(pkg => (
          <ContestPkgRow
            key={`pkg-row-${pkg.package.user}`}
            contest={contest}
            pkg={pkg.package}
          />
        ))}
      </Table>
    </StyledContestJuryTable>
  );
};
