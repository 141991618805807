import React, {ReactNode} from 'react';
import {Plus} from '@phosphor-icons/react';
import styled from 'styled-components';

interface TagProps {
  children: ReactNode;
  index?: number;
  selected?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const StyledTag = styled.button<{$index: number}>`
  ${p => p.theme.typography.caption.md};
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.default};
  color: ${p => p.theme.colors.text.default};
  border-radius: 10px;
  padding: 8px 10px;
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  gap: 4px;
  cursor: pointer;
  transition:
    background-color 80ms ease-in-out,
    border-color 80ms ease-in-out,
    color 80ms ease-in-out;
  transition-delay: ${p => p.$index * 2}ms;

  & > svg {
    opacity: 0.5;
    transition: transform 80ms ease-in-out;
  }

  &[data-selected='true'],
  &:hover {
    background-color: ${p => p.theme.colors.accent.subtle};
    border-color: ${p => p.theme.colors.accent.muted};
    color: ${p => p.theme.colors.accent.text};
  }

  &[data-selected='true'] {
    background-color: ${p => p.theme.colors.accent.emphasis};
    border-color: ${p => p.theme.colors.accent.emphasis};
    color: ${p => p.theme.colors.text.onEmphasis};

    & > svg {
      transform: rotate(45deg);
    }
  }

  &[disabled] {
    background-color: ${p => p.theme.colors.border.default};
    pointer-events: none;
  }
`;

export const Tag = ({
  children,
  index = 0,
  selected = false,
  disabled = false,
  onClick
}: TagProps) => (
  <StyledTag
    $index={index}
    type="button"
    data-selected={selected}
    disabled={disabled}
    onClick={onClick}>
    <Plus size={16} weight="bold" />
    <span>{children}</span>
  </StyledTag>
);
