import {ModalConfirm, useModalConfirm} from '@openquiz/quiz-modal';
import React from 'react';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {Surface} from '@openquiz/quiz-ui';
import {
  QuestionForm,
  QuestionFormDto,
  editorRoundPath,
  useEditorContext,
  useQuestionDeleteMutate,
  useQuestionQuery,
  useQuestionUpdateMutate
} from '@/features/wshop-editor';
import {Page, PageFallback, PageTitle} from '@/components';

export const QuestionEditPage = () => {
  const navigate = useNavigate();
  const {questionUuid} = useParams();
  const {packageUuid, versionUuid, roundUuid} = useEditorContext();
  const {mutateAsync} = useQuestionUpdateMutate();
  const {mutateAsync: deleteMutateAsync} = useQuestionDeleteMutate();
  const {data: question, isLoading} = useQuestionQuery({
    packageUuid: packageUuid,
    questionUuid: questionUuid as string
  });

  const confirmDelete = useModalConfirm(props => (
    <ModalConfirm
      {...props}
      content={
        <>
          При удалении вопроса, содержимое будет потерянно, это действие нельзя отменить.
        </>
      }
      resolveLabel="Удалить"
    />
  ));

  const backHref = generatePath(editorRoundPath, {packageUuid, versionUuid, roundUuid});

  const onSubmitForm = async (data: QuestionFormDto) => {
    if (!packageUuid) return;
    await mutateAsync({data, packageUuid});
    navigate(backHref);
  };

  const onDelete = async () => {
    try {
      await confirmDelete();
      await deleteMutateAsync({
        packageUuid: packageUuid,
        questionUuid: questionUuid as string
      });
      navigate(backHref);
    } catch (e) {
      /* empty */
    }
  };

  if (isLoading || !question) {
    return <PageFallback />;
  }

  return (
    <Page>
      <PageTitle to={{href: backHref}} title="Редактирование вопроса" />
      <Surface>
        <QuestionForm question={question} onSubmit={onSubmitForm} onDelete={onDelete} />
      </Surface>
    </Page>
  );
};
