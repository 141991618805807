import React, {ReactNode, createElement} from 'react';
import styled, {css} from 'styled-components';
import {PhosphorIcon} from '@openquiz/quiz-ui';

interface StickerLabelProps {
  children: ReactNode;
  color: string;
  icon: PhosphorIcon;
  size?: 'sm' | 'md';
}

const StyledStickerLabel = styled.div<{
  $color: StickerLabelProps['color'];
  $size: StickerLabelProps['size'];
}>`
  color: ${p => p.$color};
  display: inline-flex;
  align-items: center;
  gap: 4px;

  & svg {
    flex-shrink: 0;
  }

  ${p =>
    p.$size === 'sm' &&
    css`
      ${p => p.theme.typography.label.sm};
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      ${p => p.theme.typography.label.md};
    `}
`;

const iconSizeMap = new Map([
  ['sm', 14],
  ['md', 16]
]);

export const StickerLabel = ({children, color, icon, size = 'md'}: StickerLabelProps) => (
  <StyledStickerLabel $color={color} $size={size}>
    {createElement(icon, {size: iconSizeMap.get(size), weight: 'bold'})}
    {children}
  </StyledStickerLabel>
);
