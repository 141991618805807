import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';

interface FieldGroupProps {
  children: ReactNode;
  label?: ReactNode;
  labelWidth?: string | number;
  labelHeight?: string | number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xlg';
  inline?: boolean;
}

const StyledFieldGroup = styled.div<{
  $size: FieldGroupProps['size'];
  $inline: FieldGroupProps['inline'];
}>`
  ${p =>
    p.$size === 'xs' &&
    css`
      margin-bottom: 4px;
    `}

  ${p =>
    p.$size === 'sm' &&
    css`
      margin-bottom: 8px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      margin-bottom: 16px;
    `}

  ${p =>
    p.$size === 'lg' &&
    css`
      margin-bottom: 24px;
    `}

  ${p =>
    p.$size === 'xlg' &&
    css`
      margin-bottom: 32px;
    `}

    ${p =>
    p.$inline &&
    css`
      display: flex;
      & > ${Label} {
        margin-bottom: 0;
        width: 50%;
      }
    `}

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  ${p => p.theme.typography.caption.md};
  color: ${p => p.theme.colors.text.subtle};
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  flex-shrink: 0;
`;

const Entry = styled.div`
  width: 100%;
`;

export const FieldGroup = ({
  children,
  label,
  labelWidth = '100%',
  labelHeight,
  size = 'md',
  inline = false
}: FieldGroupProps) => (
  <StyledFieldGroup $size={size} $inline={inline}>
    {label && <Label style={{width: labelWidth, height: labelHeight}}>{label}</Label>}
    <Entry>{children}</Entry>
  </StyledFieldGroup>
);
