import React, {useMemo} from 'react';
import {motion} from 'framer-motion';
import {colord} from 'colord';
import styled from 'styled-components';
import {Action, MachineTimer, TeamRecord} from '@opeq-dev/openquiz-machine';
import {getPercentFrom} from '@openquiz/quiz-ui';
import {TeamPill} from '@/features/machine/components';

interface HintRowProps {
  timer?: MachineTimer;
  type: Action['type'] | string;
  team: TeamRecord;
  userUuid: string;
  isSelf: boolean;
}

const StyledHintRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Message = styled.div`
  position: relative;

  [data-positive='false'] {
    opacity: 0.2;
  }
`;

const ProgressMessage = styled(motion.div)<{$color: string | undefined}>`
  position: absolute;
  left: 0;
  top: 0;
  color: ${p => (p.$color ? p.$color : p.theme.colors.text.muted)};
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  pointer-events: none;
`;

const messageTypeMap = new Map<Action['type'] | string, [self: string, other: string]>([
  ['chooseQuestion', ['выбирайте вопрос', 'выбирает вопрос']],
  ['chooseResponder', ['выбирайте, кто будет отвечать', 'выбирает, кто будет отвечать']],
  ['chooseVariant', ['выбирайте вариант ответа', 'выбирает вариант ответа']],
  ['chooseBanTheme', ['выберите какую тему забанить', 'выбирает какую тему забанить']],
  ['basicTryAnswer', ['попробуйте ответить', '']],
  ['alreadyTryAnswer', ['отвечайте на вопрос', 'отвечает на вопрос']],
  ['typeFinalAnswer', ['введите ответ', 'вводит ответ']]
]);

export const HintRow = ({timer, team, type, userUuid, isSelf}: HintRowProps) => {
  const styleObject = useMemo(
    () => ({
      colors: {
        background: colord(team.style.color).alpha(0.1).toRgbString(),
        border: colord(team.style.color).alpha(0.25).toRgbString(),
        text: team.style.color
      }
    }),
    [team.style.color]
  );

  const message = (messageTypeMap.get(type) || ['', ''])[isSelf ? 0 : 1];

  return (
    <StyledHintRow>
      <TeamPill team={team} userUuid={userUuid} />
      <Message>
        {timer ? (
          <>
            <span data-positive="false">{message}</span>
            <ProgressMessage
              $color={isSelf ? styleObject.colors.text : undefined}
              animate={{
                width: `${(100 - getPercentFrom(timer.current, timer.duration)) * 1.01}%`
              }}
              transition={{duration: 0.5, ease: 'linear'}}>
              {message}
            </ProgressMessage>
          </>
        ) : (
          message
        )}
      </Message>
    </StyledHintRow>
  );
};
