import {useMemo} from 'react';
import uniqolor from 'uniqolor';

/**
 * This hook will return a random color for a specific seed.
 * @param seed
 */
export const useRandomColor = (seed: string) => {
  return useMemo(() => uniqolor(seed, {saturation: [40, 50]}).color, [seed]);
};
