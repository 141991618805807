import React, {useMemo, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';
import {UserStats} from '@opeq-dev/openquiz-schema';
import {
  AchievementPicture,
  AchievementPreview,
  achievementsList
} from '@/features/achievement';

interface AchievementCardProps {
  stat: UserStats;
}

const StyledAchievementCard = styled.div`
  user-select: none;
  cursor: pointer;
  transition: transform 160ms ease-in-out;

  :hover {
    transform: scale(1.1);
  }

  :active {
    transform: scale(1);
  }
`;

export const AchievementCard = ({stat}: AchievementCardProps) => {
  const [preview, setPreview] = useState(false);

  const achievement = useMemo(
    () => achievementsList.find(a => a.id === stat.name),
    [stat]
  );

  const onAchievementClick = () => {
    setPreview(true);
  };

  const onPreviewClose = () => {
    setPreview(false);
  };

  if (!achievement) {
    return null;
  }

  return (
    <>
      <AnimatePresence>
        {preview && (
          <AchievementPreview
            achievement={achievement}
            stat={stat}
            onClose={onPreviewClose}
          />
        )}
      </AnimatePresence>

      <StyledAchievementCard onClick={onAchievementClick}>
        <motion.div layoutId={stat.name}>
          <AchievementPicture achievement={achievement} stat={stat} />
        </motion.div>
      </StyledAchievementCard>
    </>
  );
};
