import {xorwow} from 'seedrandom';

/**
 * This function returns a random number between min and max.
 * @param seed
 * @param min
 * @param max
 */
export const getSeedRandomNumber = (seed: string, min: number, max: number): number =>
  Math.floor(xorwow(seed).quick() * (max - min + 1)) + min;
