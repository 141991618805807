import React from 'react';
import {useForm} from 'react-hook-form';
import {AnimatePresence, motion} from 'framer-motion';
import {useLocation, useNavigate} from 'react-router-dom';
import {MagnifyingGlass} from '@phosphor-icons/react';
import styled from 'styled-components';
import {getAnimationProps} from '@openquiz/quiz-ui';
import {SearchDto} from '@/features/workshop';
import {getSearchString} from '@/features/wshop-search';

const StyledSearchBar = styled.form`
  position: relative;
  white-space: nowrap;
  margin-bottom: 16px;
`;

const Icon = styled(MagnifyingGlass)`
  position: absolute;
  left: 14px;
  top: 12px;
  color: ${p => p.theme.colors.text.subtle};
  pointer-events: none;
  flex-shrink: 0;
`;

const Input = styled.input`
  ${p => p.theme.typography.body.md};
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.md};
  color: ${p => p.theme.colors.text.default};
  text-overflow: ellipsis;
  border: none;
  border-radius: 12px;
  display: block;
  white-space: nowrap;
  width: 100%;
  padding: 10px 24px 10px 44px;
  box-sizing: border-box;
  transition:
    width ease-in-out 80ms,
    border-radius ease-in-out 80ms;

  &:focus {
    box-shadow: ${p => p.theme.shadow.floating};
    text-overflow: initial;
  }
`;

const searchBarAnimationProps = getAnimationProps(
  {
    initial: {y: -4, height: 0, opacity: 0},
    animate: {y: 0, height: 'auto', opacity: 1},
    exit: {y: 4, height: 0, opacity: 0}
  },
  {duration: 0.2}
);

export const SearchBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {handleSubmit, register} = useForm<Pick<SearchDto, 'query'>>({
    defaultValues: {query: ''}
  });

  const isSearchPage = location.pathname.includes('/workshop/search');

  const onSubmit = (data: Pick<SearchDto, 'query'>) => {
    navigate(`/workshop/search?data=${getSearchString(data)}`);
  };

  return (
    <AnimatePresence initial={false}>
      {!isSearchPage && (
        <motion.div {...searchBarAnimationProps}>
          <StyledSearchBar onSubmit={handleSubmit(onSubmit)}>
            <Icon size={20} weight="bold" />
            <Input {...register('query', {required: true})} placeholder="Поиск" />
          </StyledSearchBar>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
