import React from 'react';
import {ChooseBanThemeEvent, getPkgVersion} from '@opeq-dev/openquiz-machine';
import {
  BanThemeHint,
  BanThemeRow,
  Screen,
  ScreenContent,
  ScreenFooter,
  useMachineEvent,
  useMachineSelector
} from '@/features/machine';

export const FinalBansScreen = () => {
  const {event} = useMachineEvent();

  const themes = useMachineSelector(
    state =>
      getPkgVersion(state.context)
        ?.themes.filter(theme => theme.roundUuid === state.context.current.roundUuid)
        .map(theme => ({
          ...theme,
          isBanned: state.context.history.some(
            r => r.type === 'chooseBanTheme' && r.payload.themeUuid === theme.uuid
          )
        })) ?? []
  );

  const onClickBanTheme = (themeUuid: string) => {
    event<ChooseBanThemeEvent>('chooseBanTheme', {themeUuid});
  };

  return (
    <Screen>
      <ScreenContent>
        {themes.map(theme => (
          <BanThemeRow
            key={`ban-theme-${theme.uuid}`}
            theme={theme}
            isBanned={theme.isBanned}
            onClick={() => onClickBanTheme(theme.uuid)}
          />
        ))}
      </ScreenContent>

      <ScreenFooter>
        <BanThemeHint />
      </ScreenFooter>
    </Screen>
  );
};
