import React from 'react';
import toast from 'react-hot-toast';
import {Contest} from '@opeq-dev/openquiz-schema';
import {Modal, ModalProps} from '@openquiz/quiz-modal';
import {Title} from '@openquiz/quiz-ui';
import {
  ContestFeedbackData,
  ContestFeedbackForm,
  useFeedbackContest
} from '@/features/contest';

interface ContestFeedbackModalProps extends ModalProps {
  contest: Contest;
  packageUuid: string;
}

export const ContestFeedbackModal = ({
  contest,
  packageUuid,
  ...rest
}: ContestFeedbackModalProps) => {
  const {mutateAsync} = useFeedbackContest();

  const onFormSubmit = async (data: ContestFeedbackData) => {
    try {
      await mutateAsync(data);
      toast.success('Отзыв успешно отправлен');
    } catch (err) {
      toast.error((err as Error).message);
    }
  };

  return (
    <Modal {...rest}>
      <Title>Оставить отзыв о пакете</Title>
      <ContestFeedbackForm
        contest={contest}
        packageUuid={packageUuid}
        onSubmit={onFormSubmit}
        onCancel={rest.onCancel}
      />
    </Modal>
  );
};
