import axios from 'axios';
import {authStoreKey} from '@/features/auth';

/**
 *
 */
export const getPrivateAxios = () => {
  const instance = axios.create({
    baseURL: import.meta.env.VITE_API_END_POINT,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem(authStoreKey) ?? '{}')?.state?.token
      }`
    }
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === 401) {
        window.localStorage.removeItem(authStoreKey);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
