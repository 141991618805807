import {getSeedRandomNumber} from '@openquiz/quiz-ui';

const createSvgElement = (width: number, height: number) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('width', width.toString());
  svg.setAttribute('height', height.toString());
  return svg;
};

interface Options {
  center: {x: number; y: number};
  turnAngle: number;
  turnRadius: number;
  size: number;
  seed: string;
}

const icons = [
  '😀',
  '😄',
  '😁',
  '😆',
  '😅',
  '😂',
  '🤣',
  '😊',
  '😇',
  '🙂',
  '🙃',
  '😉',
  '😌',
  '😍',
  '🥰',
  '😘',
  '😗',
  '😙',
  '😚',
  '😋',
  '😛',
  '😝',
  '😜',
  '🤪',
  '🤨',
  '🧐',
  '🤓',
  '😎',
  '🤩',
  '🥳',
  '😏',
  '😒',
  '😞',
  '😔',
  '😟',
  '😕',
  '🙁',
  '☹️',
  '😣',
  '😖',
  '😫',
  '😩',
  '🥺',
  '😢',
  '😭',
  '😤',
  '😠',
  '😡',
  '🤬',
  '🤯',
  '😳',
  '🥵',
  '🥶',
  '😱',
  '😨',
  '😰',
  '😥',
  '😓',
  '🤗',
  '🤔',
  '🤭',
  '🤫',
  '🤥',
  '😶',
  '😐',
  '😑',
  '😬',
  '🙄',
  '😯',
  '😦',
  '😧',
  '😮',
  '😲',
  '🥱',
  '😴',
  '🤤',
  '😪',
  '😵',
  '🤐',
  '🥴',
  '🤢',
  '🤮',
  '🤧',
  '😷',
  '🤒',
  '🤕',
  '🤑',
  '🤠',
  '😈',
  '👿',
  '👹',
  '👺',
  '🤡',
  '💩',
  '👻',
  '💀',
  '☠️',
  '👽',
  '👾',
  '🤖',
  '🎃',
  '😺',
  '😸',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙀',
  '😿',
  '😾',
  '🙈',
  '🙉',
  '🙊',
  '💋',
  '💌',
  '💘',
  '💝',
  '💖',
  '💗',
  '💓',
  '💞',
  '💕',
  '💟',
  '❣️',
  '💔',
  '❤️',
  '🧡',
  '💛',
  '💚',
  '💙',
  '💜',
  '🤎',
  '🖤',
  '🤍',
  '🖖',
  '👌',
  '🤏',
  '✌️',
  '🤞',
  '🤟',
  '🤘',
  '🤙',
  '👈',
  '👉',
  '👆',
  '🖕',
  '👇',
  '☝️',
  '👍',
  '👎',
  '✊',
  '👊',
  '🤛',
  '🤜',
  '👏',
  '🙌',
  '👐',
  '🤲',
  '🤝',
  '🙏',
  '✍️',
  '💅',
  '🤳',
  '💪',
  '🦾',
  '🦿',
  '🦵',
  '🦶',
  '👂',
  '🦻',
  '👃',
  '👶',
  '🧵',
  '🧶',
  '👓',
  '🕶️',
  '🥽',
  '🥼',
  '🦺',
  '👔',
  '👕',
  '👖',
  '🧣',
  '🧤',
  '🧥',
  '🧦',
  '👗',
  '👘',
  '🥻',
  '🩱',
  '🩲',
  '🩳',
  '👙',
  '👚',
  '👛',
  '👜',
  '👝',
  '🎒',
  '👞'
];

export const genSpiralPattern = (width: number, height: number, options: Options) => {
  const svg = createSvgElement(width, height);

  const centerX = options.center.x;
  const centerY = options.center.y;
  const startRadius = 0;

  let currentAngle = 0;
  let currentRadius = startRadius;

  function createSmiley(x: number, y: number, radius: number, rotate: number) {
    const smiley = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    smiley.setAttribute('x', x.toString());
    smiley.setAttribute('y', y.toString());
    smiley.setAttribute('font-family', 'Noto Emoji');
    smiley.setAttribute('font-size', radius.toString());
    smiley.setAttribute('text-anchor', 'middle');
    smiley.setAttribute('transform', `rotate(${rotate}, ${x} ${y})`);

    smiley.textContent =
      icons[getSeedRandomNumber(options.seed + x, 0, icons.length - 1)];

    svg.appendChild(smiley);
  }

  // Создаем спираль из смайликов
  for (let i = 0; i < 500; i++) {
    const x = centerX + currentRadius * Math.cos((currentAngle * Math.PI) / 180);
    const y = centerY + currentRadius * Math.sin((currentAngle * Math.PI) / 180);

    currentAngle += options.turnAngle;
    currentRadius += options.turnRadius;

    if (options.size + currentRadius * 0.05 < 10) {
      continue;
    }

    createSmiley(
      x,
      y,
      options.size + currentRadius * 0.07,
      getSeedRandomNumber(options.seed + i, -45, 45)
    );
  }

  return svg;
};
