import React from 'react';
import styled from 'styled-components';
import {CaretDown, SkipForward, ThumbsDown, ThumbsUp} from '@phosphor-icons/react';
import {
  AcceptAnswerEvent,
  RejectAnswerEvent,
  SkipQuestionEvent
} from '@opeq-dev/openquiz-machine';
import {PopperMenu, Tooltip} from '@openquiz/quiz-ui';
import {
  selectCtxCurrentQuestion,
  selectTurnTeam,
  useGameService,
  useMachineEvent,
  useMachineSelector
} from '@/features/machine';
import {ControlBarButton} from '../ControlBarButton';
import {LeaderAcceptMenu} from './LeaderAcceptMenu';

const ButtonMenu = styled.div`
  display: flex;

  button:first-child {
    border-radius: 16px 0 0 16px;
    border-right: none;
    position: relative;

    ::after {
      position: absolute;
      z-index: 10000;
      right: -2px;
      top: 6px;
      bottom: 6px;
      width: 2px;
      border-radius: 1px;
      content: '';
      background-color: ${p => p.theme.colors.border.subtle};
    }
  }

  button:last-child {
    border-radius: 0 16px 16px 0;
    border-left: none;
  }
`;

export const LeaderDecisionButton = () => {
  const {isMatch} = useGameService();
  const {event} = useMachineEvent();

  const turnTeam = useMachineSelector(selectTurnTeam);
  const question = useMachineSelector(selectCtxCurrentQuestion);

  const allowDecision = isMatch([
    {game: {basicRound: {basicQuestion: 'tryAnswer'}}},
    {game: {basicRound: {noRiskQuestion: 'play'}}},
    {game: {basicRound: {secretQuestion: 'play'}}},
    {game: {basicRound: {auctionQuestion: 'play'}}}
  ]);

  const allowSkip = isMatch([
    {game: {basicRound: 'basicQuestion'}},
    {game: {basicRound: 'noRiskQuestion'}},
    {game: {basicRound: {secretQuestion: 'play'}}},
    {game: {basicRound: {secretQuestion: 'answer'}}},
    {game: {basicRound: 'variantsQuestion'}},
    {game: {basicRound: 'variantsOneByOneQuestion'}},
    {game: {basicRound: 'auctionQuestion'}}
  ]);

  const onClickAccept = () => {
    if (!turnTeam || !question) return;
    event<AcceptAnswerEvent>('acceptAnswer', {
      teamUuid: turnTeam.uuid,
      questionUuid: question.uuid,
      multiplier: 1
    });
  };

  const onClickReject = () => {
    if (!turnTeam || !question) return;
    event<RejectAnswerEvent>('rejectAnswer', {
      teamUuid: turnTeam.uuid,
      questionUuid: question.uuid
    });
  };

  const onClickSkipQuestion = () => {
    event<SkipQuestionEvent>('skipQuestion', undefined);
  };

  if (isMatch([{game: 'idle'}])) {
    return null;
  }

  return (
    <>
      <ButtonMenu>
        <ControlBarButton disabled={!allowDecision} onClick={onClickAccept}>
          <ThumbsUp size={24} weight="bold" />
          <span>Принять</span>
        </ControlBarButton>

        <PopperMenu menu={<LeaderAcceptMenu />} placement="top">
          <ControlBarButton disabled={!allowDecision}>
            <CaretDown weight="bold" />
          </ControlBarButton>
        </PopperMenu>
      </ButtonMenu>

      <Tooltip message="Отклонить" placement="top" closeOnReferencePress={true}>
        <ControlBarButton
          variant="danger"
          disabled={!allowDecision}
          onClick={onClickReject}>
          <ThumbsDown size={24} weight="bold" />
        </ControlBarButton>
      </Tooltip>

      <Tooltip
        message="Пропустить"
        placement="top"
        enabled={allowSkip}
        closeOnReferencePress={true}>
        <ControlBarButton
          variant="secondary"
          disabled={!allowSkip}
          onClick={onClickSkipQuestion}>
          <SkipForward size={24} weight="bold" />
        </ControlBarButton>
      </Tooltip>
    </>
  );
};
