export const editorPath = '/editor';

export const editorInfoPath = '/editor/:packageUuid';

export const editorStatisticsPath = '/editor/:packageUuid/statistics';

export const editorCreatePath = '/editor/create';

export const editorPublishPath = '/editor/:packageUuid/:versionUuid/publish';

export const editorTempaltePath = '/editor/:packageUuid/:versionUuid/tempalte';

export const editorRoundPath = '/editor/:packageUuid/:versionUuid/round/:roundUuid';

export const editorRoundCreatePath = '/editor/:packageUuid/:versionUuid/round/create';

export const editorRoundEditPath =
  '/editor/:packageUuid/:versionUuid/round/:roundUuid/edit';

export const editorThemeCreatePath =
  '/editor/:packageUuid/:versionUuid/round/:roundUuid/theme/create';

export const editorThemeEditPath =
  '/editor/:packageUuid/:versionUuid/round/:roundUuid/theme/:themeUuid';

export const editorQuestionCreatePath =
  '/editor/:packageUuid/:versionUuid/round/:roundUuid/theme/:themeUuid/question/create';

export const editorQuestionUpdatePath =
  '/editor/:packageUuid/:versionUuid/round/:roundUuid/theme/:themeUuid/question/:questionUuid';
