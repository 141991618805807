import React, {ReactNode} from 'react';
import {generatePath, useLocation} from 'react-router-dom';
import {Shell} from '@openquiz/quiz-ui';
import {WorkshopSidebar} from '@/components';
import {EditorSidebar, editorCreatePath} from '@/features/wshop-editor';

interface EditorLayoutProps {
  children: ReactNode;
}

export const EditorLayout = ({children}: EditorLayoutProps) => {
  const location = useLocation();

  if (location.pathname === generatePath(editorCreatePath)) {
    return <Shell sidebar={<WorkshopSidebar />}>{children}</Shell>;
  }

  return <Shell sidebar={<EditorSidebar />}>{children}</Shell>;
};
