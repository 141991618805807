import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PackageVersion} from '@opeq-dev/openquiz-schema';
import {fetchVersionDelete} from './fetchVersionDelete';
import {versionKeys} from './keys';

/**
 *
 */
export const useVersionDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchVersionDelete,
    onMutate: variables => {
      if (!variables) return;
      queryClient.setQueryData<PackageVersion[]>(
        versionKeys.all(variables),
        (versions = []) =>
          versions?.filter(version => version.uuid !== variables.versionUuid)
      );
    }
  });
};
