import React from 'react';
import styled from 'styled-components';
import {LobbySlots} from '@/features/machine';

const StyledRightSide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
`;

export const RightSidebar = () => (
  <StyledRightSide>
    <LobbySlots direction="horizontal" />
  </StyledRightSide>
);
