import {State} from 'xstate';
import {Ctx} from '@opeq-dev/openquiz-machine';

/**
 * @param state
 * @returns
 */
export const selectTeamPlayers = (teamUuid: string) => (state: State<Ctx>) =>
  state.context.users
    .filter(user => user.teamUuid === teamUuid)
    .filter(user => user.role === 'PLAYER');
