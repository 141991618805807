import {useQuery} from '@tanstack/react-query';
import {FetchQuestionsParams, fetchQuestions} from './fetchQuestions';

/**
 * Query for questions.
 * @param params
 */
export const useQuestionsQuery = (params: FetchQuestionsParams) => {
  return useQuery({
    queryKey: ['editor', 'questions', params.themeUuid],
    queryFn: () => fetchQuestions(params)
  });
};
