import React, {useEffect, useMemo} from 'react';
import {useSelector} from '@xstate/react';
import {getPkgVersion} from '@opeq-dev/openquiz-machine';
import styled from 'styled-components';
import {Button, FieldGroup, useModal} from '@openquiz/quiz-ui';
import {MagnifyingGlass} from '@phosphor-icons/react';
import {selectCtxCurrentQuestion, useGameService} from '@/features/machine';
import {AnswerModal} from '@/features/machine/components/@layouts/LeftSidebar/AnswerModal';

const StyledLeaderRightAnswer = styled.div`
  ${p => p.theme.typography.body.md};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 16px auto;
`;

const Text = styled.div`
  max-width: 620px;
  text-align: center;
  margin: 0 auto;
`;

export const LeaderRightAnswer = () => {
  const {service} = useGameService();
  const currentQuestion = useSelector(service, selectCtxCurrentQuestion);

  const {answerText, answerSteps} = useSelector(service, state => {
    const steps =
      getPkgVersion(state.context)
        ?.steps.filter(step => step.questionUuid === currentQuestion?.uuid)
        .filter(step => step.isAnswer) ?? [];

    const textAnswers = steps
      .filter(step => step.type === 'TEXT')
      .map(step => step.payload);

    const captionAnswers = steps.map(step => step.caption).filter(Boolean);

    return {
      answerText: [...textAnswers, ...captionAnswers].join(', '),
      answerSteps: steps
    };
  });

  const hasAnswerMedia = useMemo(
    () => answerSteps.some(step => step.type === 'IMAGE'),
    [answerSteps]
  );

  const [openAnswerModal, closeAnswerModal] = useModal(
    () => <AnswerModal onClose={closeAnswerModal} />,
    [answerSteps]
  );

  useEffect(() => {
    closeAnswerModal();
  }, [currentQuestion]);

  if (!answerText) {
    return null;
  }

  return (
    <StyledLeaderRightAnswer>
      <FieldGroup size="sm">
        <Text>Ответ: {answerText}</Text>
      </FieldGroup>

      {hasAnswerMedia && (
        <FieldGroup size="sm">
          <Button variant="link" onClick={openAnswerModal}>
            <MagnifyingGlass size={16} weight="bold" />
            <span>Показать содержимое</span>
          </Button>
        </FieldGroup>
      )}
    </StyledLeaderRightAnswer>
  );
};
