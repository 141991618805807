import React from 'react';
import {NavLink} from 'react-router-dom';
import {motion} from 'framer-motion';
import styled, {keyframes} from 'styled-components';
import {Logotype, getAnimationProps} from '@openquiz/quiz-ui';
import {DeviceMobile} from '@phosphor-icons/react';

const mobileFlipScreenAnimationProps = getAnimationProps(
  {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0}
  },
  {duration: 0.5}
);

const deviceMobileIconKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: translateY(-8px);
  }
  15% {
    transform: translateY(0);
  }
  30% {
    transform: rotate(90deg);
  }
  70% {
    transform: rotate(90deg);
  }
`;

const StyledMobileFlipScreen = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  backdrop-filter: blur(24px);
  z-index: 100;
`;

const DeviceMobileIcon = styled(DeviceMobile)`
  color: ${p => p.theme.colors.accent.text};
  animation: ${deviceMobileIconKeyframes} infinite ease-in-out 5000ms;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: auto;
  padding-top: 24px;
  max-width: 600px;
  width: 100%;
`;

const Footer = styled.div`
  padding-top: 24px;
  margin-top: auto;
`;

const Message = styled.div`
  ${p => p.theme.typography.body.lg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MobileFlipScreen = () => {
  return (
    <StyledMobileFlipScreen {...mobileFlipScreenAnimationProps}>
      <Content>
        <DeviceMobileIcon size={96} weight="duotone" />
        <Message>Переверните телефон</Message>
      </Content>
      <Footer>
        <NavLink to="/">
          <Logotype />
        </NavLink>
      </Footer>
    </StyledMobileFlipScreen>
  );
};
