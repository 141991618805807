import React from 'react';
import {motion} from 'framer-motion';
import {useSelector} from '@xstate/react';
import styled from 'styled-components';
import {TipCircle, getAnimationProps} from '@openquiz/quiz-ui';
import {useGameService} from '@/features/machine';
import {getPkgVersion} from '@opeq-dev/openquiz-machine';

const StyledTopBar = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  @media only screen and (min-width: 320px) {
    margin-bottom: 16px;
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 16px;
  }

  @media only screen and (min-width: 1440px) {
    margin-bottom: 24px;
  }
`;

const Label = styled.div`
  ${p => p.theme.typography.label.lg};
  display: flex;
  align-items: center;
  height: 24px;
  margin-bottom: 2px;
`;

const ToolTip = styled.div`
  display: flex;
  margin: -2px 0 0 4px;
`;

const Type = styled.div`
  ${p => p.theme.typography.caption.sm};
  color: ${p => p.theme.colors.text.subtle};
`;

const topBarMotionProps = getAnimationProps(
  {
    initial: {y: 8, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: -8, opacity: 0}
  },
  {duration: 0.2}
);

export const FinalTopBar = () => {
  const {service} = useGameService();

  const theme = useSelector(service, state =>
    getPkgVersion(state.context)
      ?.themes.filter(
        theme =>
          theme.roundUuid === state.context.current.roundUuid &&
          !state.context.history.some(
            r => r.type === 'chooseBanTheme' && r.payload.themeUuid === theme.uuid
          )
      )
      .pop()
  );

  if (!theme) {
    return null;
  }

  return (
    <StyledTopBar>
      <motion.div {...topBarMotionProps}>
        <Label>
          {theme?.name}
          {theme.description && (
            <ToolTip>
              <TipCircle message={theme.description} size="md" />
            </ToolTip>
          )}
        </Label>
        <Type>Финал</Type>
      </motion.div>
    </StyledTopBar>
  );
};
