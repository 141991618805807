import React from 'react';
import {OpenQuizGif, StartGameGif, TryAnswerGif} from '../components';
import {Scenario} from '../types/scenario';

const ID = 'onboarding-basic-help';

/**
 * This is a scenario for the basic onboarding modal.
 */
export const basicHelpScenario: Scenario = {
  id: ID,
  name: 'Как играть?',
  conditions: location => {
    if (window.localStorage.getItem(ID)) return false;
    return location.pathname?.includes('/game/') || false;
  },
  steps: [
    {
      animation: <OpenQuizGif />,
      text: (
        <>
          <h2>Welcome to OpenQuiz</h2>
          <p>
            Это многопользовательская игра, в которой игрокам предлагается ответить на
            вопросы из различных областей знания, таких как игры, музыка, кино и многое
            другое.
          </p>
        </>
      )
    },
    {
      animation: <StartGameGif />,
      text: (
        <>
          <h2>Выбор вопроса</h2>
          <p>
            В начале игры, нужно выбрать первый вопрос в одной из категорий. Вопрос будет
            сопровождаться определенным количеством очков, которые игрок может заработать
            в случае правильного ответа.
          </p>
        </>
      )
    },
    {
      animation: <TryAnswerGif />,
      text: (
        <>
          <h2>Воспроизведение вопроса</h2>
          <p>
            При воспроизведении вопроса, игрок может попытаться ответить, с помощью{' '}
            <u>
              <b>правой кнопки мыши</b>
            </u>{' '}
            или{' '}
            <u>
              <b>кнопки ответить</b>
            </u>
            . При правильном ответе, этот игрок{' '}
            <b style={{color: 'green'}}>получает очки</b> и выбирает следующий вопрос.
          </p>
        </>
      )
    }
  ]
};
