import React from 'react';
import styled from 'styled-components';
import {Package as PackageIcon} from '@phosphor-icons/react';
import {Package} from '@opeq-dev/openquiz-schema';

interface PackagePillProps {
  pkg: Package;
}

const StyledPackagePill = styled.div`
  ${p => p.theme.typography.caption.md};
  color: ${p => p.theme.colors.text.subtle};
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

const StyledPackageIcon = styled(PackageIcon)`
  display: flex;
  flex-shrink: 0;
`;

export const PackagePill = ({pkg}: PackagePillProps) => (
  <StyledPackagePill>
    <StyledPackageIcon size={20} weight="bold" />
    <span>{pkg.name}</span>
  </StyledPackagePill>
);
