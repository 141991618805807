import React from 'react';
import {Link} from 'react-router-dom';
import {MenuItem, MenuItemProps} from './MenuItem';

interface MenuLinkProps extends MenuItemProps {
  to: string;
}

export const MenuLink = ({children, to, ...rest}: MenuLinkProps) => (
  <Link to={to}>
    <MenuItem {...rest}>{children}</MenuItem>
  </Link>
);
