import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import {UploadSimple} from '@phosphor-icons/react';

interface PictureUploadProps {
  onChange: (file: File) => void;
}

const StyledPictureUpload = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  color: ${p => p.theme.colors.text.onEmphasis};
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 50%;
  margin-left: -48px;
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 80ms ease-in-out;
  z-index: 1;
  opacity: 0;
`;

const accept = {'image/*': []};

export const PictureUpload = ({onChange}: PictureUploadProps) => {
  const onDrop = useCallback(
    async (files: File[]) => {
      if (files.length === 0) return;
      onChange(files[0]);
    },
    [onChange]
  );

  const {getRootProps, getInputProps} = useDropzone({
    accept,
    multiple: false,
    onDrop
  });

  return (
    <StyledPictureUpload data-id="picture-upload" {...getRootProps()}>
      <UploadSimple size={32} weight="bold" />
      <input {...getInputProps()} />
    </StyledPictureUpload>
  );
};
