import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import styled, {css, useTheme} from 'styled-components';
import {MachineTimer} from '@opeq-dev/openquiz-machine';
import {UserLabel, UserLabelPlaceholder, getAnimationProps} from '@openquiz/quiz-ui';
import {useSfxPlayer, useTimerProgress} from '@/features/machine';
import {FetchedUser, UserProfileTooltip} from '@/features/user';

interface UserChipProps {
  userUuid: string;
  message: string;
  timer?: MachineTimer;
  isSelf?: boolean;
}

const StyledUserChip = styled(motion.div)<{$isSelf: UserChipProps['isSelf']}>`
  ${p => p.theme.typography.caption.md};
  display: inline-flex;
  align-items: center;
  padding: 0 24px;

  > span {
    position: relative;

    span {
      position: absolute;
      bottom: -2px;
      right: 0;
      left: 7px;
      height: 2px;
      border-radius: 2px;
      transition: width 300ms linear;
    }
  }

  ${p =>
    p.$isSelf &&
    css`
      > span {
        color: ${p => p.theme.colors.accent.text};

        span {
          background-color: ${p => p.theme.colors.accent.text};
        }
      }
    `}
`;

const userChipMotionProps = getAnimationProps(
  {
    initial: {y: 4, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: -4, opacity: 0}
  },
  {duration: 0.3}
);

export const UserChip = ({userUuid, message, timer, isSelf = false}: UserChipProps) => {
  const theme = useTheme();
  const progress = useTimerProgress(timer);
  const sfxPlayer = useSfxPlayer();
  const [isCountdown, setIsCountdown] = useState(false);

  useEffect(() => {
    if (!timer) return;
    const remain = (timer.duration - timer.current) / 1000;
    setIsCountdown(remain > 0 && remain <= 4);
  }, [timer]);

  useEffect(() => {
    if (isSelf && isCountdown) {
      sfxPlayer.play('countdown');
    } else {
      sfxPlayer.stop();
    }
  }, [sfxPlayer.play, sfxPlayer.stop, isSelf, isCountdown]);

  return (
    <FetchedUser
      uuid={userUuid}
      fallback={
        <StyledUserChip {...userChipMotionProps} $isSelf={isSelf}>
          <UserLabelPlaceholder layoutId={`user-chip-${userUuid}-label`} />
        </StyledUserChip>
      }>
      {user => (
        <StyledUserChip {...userChipMotionProps} $isSelf={isSelf}>
          <UserProfileTooltip user={user}>
            <UserLabel user={user} layoutId={`user-chip-${userUuid}-label`} />
          </UserProfileTooltip>
          <span
            style={{
              color: isCountdown
                ? theme.colors.danger.text
                : isSelf
                  ? theme.colors.accent.text
                  : theme.colors.text.subtle
            }}>
            {message}
            {timer && (
              <span
                style={{
                  backgroundColor: isCountdown
                    ? theme.colors.danger.text
                    : isSelf
                      ? theme.colors.accent.text
                      : theme.colors.text.subtle,
                  width: `calc(${100 - progress * 0.95}% - 8px)`
                }}
              />
            )}
          </span>
        </StyledUserChip>
      )}
    </FetchedUser>
  );
};
