import React, {
  FunctionComponent,
  MouseEvent,
  ReactNode,
  createElement,
  useMemo
} from 'react';
import {NavLink} from 'react-router-dom';
import {useLocation} from 'react-use';
import styled, {css} from 'styled-components';
import {PhosphorIcon} from '@openquiz/quiz-ui';

interface SidebarLinkProps {
  children: ReactNode;
  to: string;
  icon?: PhosphorIcon | FunctionComponent;
  variant?: 'primary' | 'secondary' | 'attention';
  badge?: ReactNode;
  onClick?: (e: MouseEvent) => void;
}

const StyledSidebarLink = styled(NavLink)<{
  $variant: SidebarLinkProps['variant'];
  $isActive: boolean;
}>`
  ${p => p.theme.typography.caption.md};
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  gap: 10px;
  overflow: hidden;
  transition: color 80ms ease-in-out;

  ${p =>
    p.$variant === 'primary' &&
    css`
      color: ${p => p.theme.colors.text.default};
    `}

  ${p =>
    p.$variant === 'secondary' &&
    css`
      color: ${p => p.theme.colors.text.subtle};
    `}

  ${p =>
    p.$variant === 'attention' &&
    css`
      color: ${p => p.theme.colors.attention.text};
    `}


  ${p =>
    p.$isActive &&
    css`
      font-weight: 500;
      color: ${p => p.theme.colors.accent.text};
    `}

  :hover {
    color: ${p => p.theme.colors.accent.text};
  }

  :last-child {
    margin-bottom: 0;
  }

  span {
    overflow: hidden;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  font-size: 18px;
  width: 20px;
  height: 20px;
  opacity: 0.8;
`;

export const SidebarLink = ({
  children,
  icon,
  variant = 'primary',
  badge,
  to,
  ...rest
}: SidebarLinkProps) => {
  const location = useLocation();
  const isActive = useMemo(
    () => location.href?.replace(location.origin || '', '') === to,
    [location.href, to]
  );
  return (
    <StyledSidebarLink {...rest} $variant={variant} $isActive={isActive} to={to}>
      {icon && (
        <Icon>
          {typeof icon === 'string'
            ? icon
            : createElement(icon, {size: 20, weight: 'bold'})}
        </Icon>
      )}
      <span>{children}</span>
      {badge}
    </StyledSidebarLink>
  );
};
