import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface DataGroupProps {
  children: ReactNode;
  label: ReactNode;
}

const StyledDataGroup = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 3px;
`;

const Entry = styled.div`
  ${p => p.theme.typography.caption.md};
`;

const Label = styled.div`
  ${p => p.theme.typography.caption.sm};
  color: ${p => p.theme.colors.text.muted};
  opacity: 0.6;
`;

export const DataGroup = ({children, label}: DataGroupProps) => (
  <StyledDataGroup>
    <Entry>{children}</Entry>
    <Label>{label}</Label>
  </StyledDataGroup>
);
