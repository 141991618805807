import React, {forwardRef} from 'react';
import {Icon} from '@phosphor-icons/react';
import styled, {useTheme} from 'styled-components';

const StyledOpenQuizIcon = styled.svg`
  border-radius: 12px;
`;

export const OpenQuizIcon: Icon = forwardRef((props, ref) => {
  const theme = useTheme();
  return (
    <StyledOpenQuizIcon
      ref={ref}
      width={props.size}
      height={props.size}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="512" height="512" fill={theme.colors.accent.emphasis || '#3B82F6'} />
      <path
        d="M330.396 383.97C374.415 358.324 404 310.617 404 256C404 174.262 337.738 108 256 108C174.262 108 108 174.262 108 256C108 337.738 174.262 404 256 404C262.079 404 268.073 403.633 273.96 402.921L242.013 362.779C180.71 357.876 145 317.424 145 257.665C145 193.615 186.067 151.938 255.405 151.938C326.528 151.938 367 193.615 367 257.665C367 305.165 344.979 340.101 304.507 355.118L330.396 383.97Z"
        fill="white"
      />
      <path
        d="M204.517 257.665C204.517 298.423 214.933 308.23 255.405 308.23C295.877 308.23 307.483 298.423 307.483 257.665C307.483 216.906 295.877 207.099 255.405 207.099C214.933 207.099 204.517 216.906 204.517 257.665Z"
        fill="white"
      />
    </StyledOpenQuizIcon>
  );
});

OpenQuizIcon.displayName = 'OpenQuizIcon';
