import {useQuery} from '@tanstack/react-query';
import {WorkshopCategory} from '@opeq-dev/openquiz-schema';
import {publicAxios} from '@/libs';

export interface WorkshopCategoryFull extends WorkshopCategory {
  _count: {
    packages: number;
  };
}

/**
 * Fetch workshop categories.
 * @returns
 */
const fetchWorkshopCategories = async () => {
  const {data} = await publicAxios.get<WorkshopCategoryFull[]>('/workshop/categories');
  return data;
};

/**
 * Use workshop categories query.
 * @returns
 */
export const useWorkshopCategories = () => {
  return useQuery({
    queryKey: ['workshop/categories'],
    queryFn: fetchWorkshopCategories
  });
};
