import {useMemo} from 'react';
import {State} from 'xstate';
import {useSelector} from '@xstate/react';
import {Ctx, getQuestionDuration, getStepDuration} from '@opeq-dev/openquiz-machine';
import {selectCtxCurrentSteps, selectTimer} from '@/features/machine';
import {useGameService} from './useGameService';

const selectPlayTimerOffset = (state: State<Ctx>) => {
  const hasPrePlay = state.context.game?.settings.prePlay;
  const timer = selectTimer(hasPrePlay ? 'prePlay' : 'play')(state);
  const steps = selectCtxCurrentSteps(state)
    .filter(s => !s.isAnswer)
    .sort((a, b) => a.position - b.position);

  const durationMap = steps
    .map(step =>
      getStepDuration(step, {
        stage: hasPrePlay ? 'pre-play' : 'play',
        count: steps.length
      })
    )
    .map((duration, index, arr) => duration + (arr[index - 1] || 0));

  const index = durationMap.findIndex(value => timer.current <= value);

  return {offset: index === 0 ? 0 : durationMap.at(index - 1) ?? 0, index};
};

export const useMediaProgress = () => {
  const {service, isMatch} = useGameService();

  const timers = useSelector(service, state => state.context.timers);
  const {offset} = useSelector(service, selectPlayTimerOffset);

  const questionDuration = useSelector(service, state =>
    getQuestionDuration(state.context)
  );

  const isPlay = isMatch([
    {game: {basicRound: {basicQuestion: 'prePlay'}}},
    {game: {basicRound: {basicQuestion: 'play'}}},
    {game: {basicRound: {noRiskQuestion: 'play'}}},
    {game: {basicRound: {secretQuestion: 'play'}}},
    {game: {basicRound: {auctionQuestion: 'play'}}},
    {game: {basicRound: {variantsQuestion: 'play'}}},
    {game: {finalRound: {question: 'play'}}}
  ]);

  const isAnswer = isMatch([
    {game: {basicRound: {basicQuestion: 'answer'}}},
    {game: {basicRound: {noRiskQuestion: 'answer'}}},
    {game: {basicRound: {secretQuestion: 'answer'}}},
    {game: {basicRound: {auctionQuestion: 'answer'}}},
    {game: {basicRound: {variantsQuestion: 'answer'}}},
    {game: {finalRound: {question: 'answer'}}}
  ]);

  const progress = useMemo(() => {
    if (isAnswer) {
      return Math.min((timers.answer.current - offset) / 1000, questionDuration.answer);
    }

    // Запускаем сразу, если есть prePlay и не играем дальше на play.
    if (timers.prePlay.duration) {
      return Math.min((timers.prePlay.current - offset) / 1000, questionDuration.pre);
    }

    return Math.min((timers.play.current - offset) / 1000, questionDuration.play);
  }, [isPlay, isAnswer]);

  return {isPlay, isAnswer, progress};
};
