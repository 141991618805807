import React, {memo, useContext, useEffect} from 'react';
import styled from 'styled-components';
import {toast} from 'sonner';
import {SpeakerSlash} from '@phosphor-icons/react';
import {Button, FieldGroup} from '@openquiz/quiz-ui';
import {useSfxListener} from './useSfxListener';
import {GameMachineContext} from '@/features/machine/providers';

const TOAST_ID = 'audio-unlock';

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  svg {
    flex-shrink: 0;
  }
`;

const ToastWrapper = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.floating};
  border-radius: 12px;
  padding: 16px;
`;

const Footer = styled.div`
  margin-left: 32px;
`;

export const AudioListener = memo(() => {
  const {
    autoplay: {status, grant}
  } = useContext(GameMachineContext);

  useEffect(() => {
    if (status === 'allow') {
      toast.dismiss(TOAST_ID);
    } else {
      toast.custom(
        () => (
          <ToastWrapper>
            <FieldGroup size="sm">
              <Row>
                <SpeakerSlash size={24} weight="bold" />
                <div>Ваш браузер запрещает воспроизведение звука.</div>
              </Row>
            </FieldGroup>
            <FieldGroup>
              <Footer>
                <Button
                  variant="link"
                  autoFocus={true}
                  onClick={() => {
                    grant();
                    toast.dismiss();
                  }}>
                  <span>Разрешить</span>
                </Button>
              </Footer>
            </FieldGroup>
          </ToastWrapper>
        ),
        {
          id: TOAST_ID,
          duration: Infinity,
          position: 'bottom-left',
          action: {
            label: 'Разрешить',
            onClick: () => grant()
          }
        }
      );
    }
  }, [status]);

  useSfxListener();

  return null;
});
