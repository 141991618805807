import React, {ReactNode} from 'react';
import {Tooltip} from '@openquiz/quiz-ui';
import {Question} from '@phosphor-icons/react';
import styled from 'styled-components';

interface TipCircleProps {
  message: ReactNode;
  size?: 'md' | 'lg';
}

const StyledTipCircle = styled.div`
  color: ${p => p.theme.colors.text.subtle};
  display: inline-flex;
  cursor: pointer;
  transition: color ease-in-out 80ms;

  &[data-open='true'],
  :hover {
    color: ${p => p.theme.colors.accent.text};
  }
`;

export const TipCircle = ({message, size}: TipCircleProps) => (
  <Tooltip message={message} distance={11} size={size}>
    <StyledTipCircle>
      <Question size={20} weight="fill" />
    </StyledTipCircle>
  </Tooltip>
);
