import {
  FetchVersionCreateParams,
  FetchVersionDeleteParams,
  FetchVersionPublishParams
} from '@/features/wshop-editor';

/**
 *
 */
export const versionKeys = {
  all: (
    params:
      | FetchVersionCreateParams
      | FetchVersionDeleteParams
      | FetchVersionPublishParams
  ) => ['editor', 'versions', {packageUuid: params.packageUuid}]
};
