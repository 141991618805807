import React, {createElement, useMemo} from 'react';
import {CheckCircle, WarningCircle, XCircle} from '@phosphor-icons/react';
import {PhosphorIcon} from '@openquiz/quiz-ui';
import {Entry, StyledCallout} from './styled';
import {CalloutProps} from './types';

const statusIconMap = new Map<CalloutProps['status'], PhosphorIcon>([
  ['success', CheckCircle],
  ['information', WarningCircle],
  ['attention', WarningCircle],
  ['danger', XCircle]
]);

const iconSizeMap = new Map<CalloutProps['size'], number>([
  ['sm', 24],
  ['md', 24],
  ['lg', 24]
]);

export const Callout = ({
  children,
  status = 'information',
  size = 'md',
  limited = false
}: CalloutProps) => {
  const icon = useMemo(() => statusIconMap.get(status), [status]);
  return (
    <StyledCallout $status={status} $size={size} $limited={limited}>
      {icon && createElement(icon, {size: iconSizeMap.get(size), weight: 'duotone'})}
      <Entry>{children}</Entry>
    </StyledCallout>
  );
};
