import React, {ReactNode, createElement} from 'react';
import styled, {css} from 'styled-components';
import {PhosphorIcon} from '@openquiz/quiz-ui';

interface ActionMessageProps {
  message: ReactNode;
  icon: PhosphorIcon;
  color: string;
  index: number;
}

const StyledActionMessage = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  gap: 12px;
  z-index: 1;

  :last-child {
    margin-bottom: 0;
  }
`;

const Entry = styled.div`
  color: ${p => p.theme.colors.text.subtle};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px 6px;
  min-height: 32px;

  span {
    color: ${p => p.theme.colors.text.default};
  }
`;

const IconType = styled.div<{$color: string; $index: number}>`
  background-color: ${p => p.$color};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 4px;
  width: 24px;
  height: 24px;

  ${p =>
    p.$index > 0 &&
    css`
      background-color: ${p => p.theme.colors.canvas.overlay};
      color: ${p.$color};
      margin: 4px;
      width: 24px;
      height: 24px;
    `}
`;

export const ActionMessage = ({message, icon, color, index}: ActionMessageProps) => {
  return (
    <StyledActionMessage>
      <IconType $color={color} $index={index}>
        {createElement(icon, {size: 16, weight: index > 0 ? 'bold' : 'fill'})}
      </IconType>
      <Entry>{message}</Entry>
    </StyledActionMessage>
  );
};
