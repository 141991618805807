import confident from '@/assets/achivevements/confident.webp';
import everyAnswersRight from '@/assets/achivevements/every-answers-right.webp';
import everyAnswersWrong from '@/assets/achivevements/every-answers-wrong.webp';
import falseStartRight from '@/assets/achivevements/false-start-right.webp';
import mostPatient from '@/assets/achivevements/most-patient.webp';
import {Achievement} from '@/features/achievement';

export const achievementsList: Achievement[] = [
  {
    id: 'game/confident',
    frame: confident,
    label: 'Уверенный',
    color: '#9dc3fe'
  },
  {
    id: 'game/every-answers-right',
    frame: everyAnswersRight,
    label: 'Мегахорош',
    color: '#f4e001'
  },
  {
    id: 'game/every-answers-wrong',
    frame: everyAnswersWrong,
    label: 'Неудачник',
    color: '#fbbb5a'
  },
  {
    id: 'game/false-start-right',
    frame: falseStartRight,
    label: 'Везунчик',
    color: '#c26b49'
  },
  {
    id: 'game/most-patient',
    frame: mostPatient,
    label: 'Терпила',
    color: '#aac8bb'
  }
];
