import React from 'react';
import {dots, hallow} from '@openquiz/quiz-ui';
import {Slide} from '@/features/home';

export const slides: Slide[] = [
  {
    id: 'update-teammode',
    type: 'update',
    title: '🎃 Хэллоуинское обновление',
    appearance: {
      color: '#c4662c',
      pattern: hallow
    },
    content: (
      <>
        <p>
          В нашем свежем Хэллоуинском обновлении представляем вам долгожданный{' '}
          <b>Командный режим!</b> Чтобы добавить праздничное настроение, мы также ввели
          хэллоуинскую тему и тематические фоны профиля.
        </p>
      </>
    ),
    link: 'https://wiki.opeq.dev/updates/halloween',
    createdAt: 1697720400000
  },
  {
    id: 'update-editor',
    type: 'update',
    title: '🌳 Летнее обновление редактора',
    appearance: {
      color: '#9ec42c',
      pattern: dots
    },
    content: (
      <>
        <p>
          Обновление редактора привнесло новый дизайн и функции. Теперь вы можете
          добавлять описание для темы, которое будет показываться при первом вопросе.
          Также появилась возможность делать несколько шагов в одном вопросе и указывать
          кастомную длительность вопроса.
        </p>
      </>
    ),
    link: 'https://discord.com/channels/932433371450179615/1042185100730839142/1117289737078702121',
    createdAt: 1686452851749
  },
  {
    id: 'update-design',
    type: 'update',
    title: '🌸 Майское обновление',
    appearance: {
      color: '#e06d82',
      pattern: dots
    },
    content: (
      <>
        <p>
          В этом обновлении добавлены новые функции, включающие вопросы с вариантами
          ответа без риска, вопросы с вариантами ответа по очереди, кнопка &quot;пас&quot;
          и расширенный поиск. Были также осуществлены улучшения дизайна навигации и
          увеличения размера шрифта для лучшего пользовательского опыта.
        </p>
      </>
    ),
    link: 'https://discord.com/channels/932433371450179615/1042185100730839142/1108685653241241690',
    createdAt: 1684399233623
  }
];
