import {RefObject, useLayoutEffect} from 'react';
import VanillaTilt from 'vanilla-tilt';

/**
 * This hook adds a tilt effect to the element.
 * @param ref
 * @param scale
 */
export const useTilt = (ref: RefObject<HTMLElement>, scale = 1.05) => {
  useLayoutEffect(() => {
    const el = ref.current;
    if (!el) return;
    VanillaTilt.init(el, {
      reverse: true,
      max: 5,
      glare: true,
      'max-glare': 0.1,
      scale,
      speed: 900
    });
  }, [ref]);
};
