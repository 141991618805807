import {TeamRecordStyle} from '@opeq-dev/openquiz-machine';

export interface TeamSlotStyle {
  style: TeamRecordStyle;
  label: string;
}

export const teamSlotStyles: TeamSlotStyle[] = [
  {style: {color: '#0880ea'}, label: 'Синяя команда'},
  {style: {color: '#dc8e11'}, label: 'Оранжевая команда'},
  {style: {color: '#259678'}, label: 'Зеленая команда'},
  {style: {color: '#da3a5c'}, label: 'Красная команда'},
  {style: {color: '#a943f3'}, label: 'Фиолетовая команда'},
  {style: {color: '#e11fa7'}, label: 'Розовая команда'}
];
