import {State} from 'xstate';
import {Ctx, UserRole} from '@opeq-dev/openquiz-machine';

/**
 *
 * @param userUuid
 * @returns
 */
export const selectUserRole =
  (userUuid: string | undefined) =>
  (state: State<Ctx>): UserRole | undefined => {
    return state.context.users.find(user => user.uuid === userUuid)?.role;
  };
