import React, {Suspense} from 'react';
import {RouteObject, createBrowserRouter, redirect} from 'react-router-dom';
import {CallbackPage, DiscordConnectPage, ProtectedRoute} from '@/features/auth';
import {HomePage} from '@/features/home';
import {GameMachine} from '@/features/machine';
import {userEditProfilePath, userProfilePath} from '@/features/user';
import {AuthorsListPage, DraftsPage, FavoritesPage} from '@/features/workshop';
import {
  editorCreatePath,
  editorInfoPath,
  editorPath,
  editorPublishPath,
  editorQuestionCreatePath,
  editorQuestionUpdatePath,
  editorRoundCreatePath,
  editorRoundEditPath,
  editorRoundPath,
  editorStatisticsPath,
  editorTempaltePath,
  editorThemeCreatePath,
  editorThemeEditPath
} from '@/features/wshop-editor';
import {ErrorBoundary, PageFallback} from '@/components';
import {RootLayout, WorkshopLayout} from '@/layouts';
import {CreatePage} from '@/pages/editor/CreatePage';
import {PackagePage} from '@/pages/workshop/PackagePage';

const LazyUserPage = React.lazy(() =>
  import('@/pages/user/UserPage').then(module => ({default: module.UserPage}))
);

const LazyUserEditPage = React.lazy(() =>
  import('@/pages/user/UserEditPage').then(module => ({default: module.UserEditPage}))
);

const LazyGameHistoryPage = React.lazy(() =>
  import('@/pages/game/GameHistoryPage').then(module => ({
    default: module.GameHistoryPage
  }))
);

const LazyWorkshopSearchPage = React.lazy(() =>
  import('@/pages/workshop/SearchPage').then(module => ({default: module.SearchPage}))
);

const LazyWorkshopContestListPage = React.lazy(() =>
  import('@/pages/workshop/ContestListPage').then(module => ({
    default: module.ContestListPage
  }))
);

// Editor

import {InfoPage} from '@/pages/editor/InfoPage';
import {PublishPage} from '@/pages/editor/PublishPage';
import {QuestionEditPage} from '@/pages/editor/QuestionEditPage';
import {QuestionCreatePage} from '@/pages/editor/QuestionCreatePage';
import {RoundEditPage} from '@/pages/editor/RoundEditPage';
import {EditorOutlet as EditorOutlet2} from '@/pages/editor/EditorOutlet';
import {RoundPage} from '@/pages/editor/RoundPage';
import {RoundCreatePage} from '@/pages/editor/RoundCreatePage';
import {StatisticsPage} from '@/pages/editor/StatisticsPage';
import {TemplatePage} from '@/pages/editor/CreateFromTemplatePage';
import {ThemeCreatePage} from '@/pages/editor/ThemeCreatePage';
import {ThemeEditPage} from '@/pages/editor/ThemeEditPage';
import {contestsJuryListPath} from '@/features/contest';

const routes: RouteObject[] = [
  {
    element: <WorkshopLayout />,
    children: [
      {
        path: '/',
        index: true,
        element: <HomePage />
      },
      {
        path: '/auth/callback',
        index: true,
        element: <CallbackPage />
      },
      {
        path: '/discord/connect',
        element: (
          <ProtectedRoute>
            <DiscordConnectPage />
          </ProtectedRoute>
        )
      },
      {
        path: userProfilePath,
        element: (
          <Suspense fallback={<PageFallback />}>
            <LazyUserPage />
          </Suspense>
        )
      },
      {
        path: userEditProfilePath,
        element: (
          <ProtectedRoute>
            <Suspense fallback={<PageFallback />}>
              <LazyUserEditPage />
            </Suspense>
          </ProtectedRoute>
        )
      },
      {
        path: '/game/:uuid/history',
        element: (
          <ProtectedRoute>
            <Suspense fallback={<PageFallback />}>
              <LazyGameHistoryPage />
            </Suspense>
          </ProtectedRoute>
        )
      },
      {
        path: '/workshop',
        children: [
          {
            path: '/workshop',
            index: true,
            loader: () => redirect('/')
          },
          {
            path: '/workshop/search',
            element: (
              <Suspense fallback={<PageFallback />}>
                <LazyWorkshopSearchPage />
              </Suspense>
            )
          },
          {
            path: '/workshop/authors',
            children: [
              {
                path: '/workshop/authors',
                index: true,
                element: <AuthorsListPage />
              }
            ]
          },
          {
            path: '/workshop/favorites',
            element: (
              <ProtectedRoute>
                <FavoritesPage />
              </ProtectedRoute>
            )
          },
          {
            path: '/workshop/drafts',
            element: (
              <ProtectedRoute>
                <DraftsPage />
              </ProtectedRoute>
            )
          },
          {
            path: contestsJuryListPath,
            element: (
              <ProtectedRoute>
                <Suspense fallback={<PageFallback />}>
                  <LazyWorkshopContestListPage />
                </Suspense>
              </ProtectedRoute>
            )
          },
          {
            path: '/workshop/:packageUuid',
            element: <PackagePage />
          }
        ]
      }
    ]
  },
  {
    path: '/editor',
    children: [
      {
        path: editorPath,
        element: (
          <ProtectedRoute>
            <EditorOutlet2 />
          </ProtectedRoute>
        ),
        children: [
          {path: editorInfoPath, element: <InfoPage />},
          {path: editorCreatePath, element: <CreatePage />},
          {path: editorStatisticsPath, element: <StatisticsPage />},
          {path: editorTempaltePath, element: <TemplatePage />},
          {path: editorRoundPath, element: <RoundPage />},
          {path: editorPublishPath, element: <PublishPage />},
          {path: editorRoundCreatePath, element: <RoundCreatePage />},
          {path: editorRoundEditPath, element: <RoundEditPage />},
          {path: editorThemeCreatePath, element: <ThemeCreatePage />},
          {path: editorThemeEditPath, element: <ThemeEditPage />},
          {path: editorQuestionCreatePath, element: <QuestionCreatePage />},
          {path: editorQuestionUpdatePath, element: <QuestionEditPage />}
        ]
      }
    ]
  }
];

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        children: routes
      },
      {
        path: '/game/:gameUuid',
        element: (
          <ProtectedRoute>
            <GameMachine />
          </ProtectedRoute>
        )
      }
    ]
  }
]);
