import {QuestionType} from '@opeq-dev/openquiz-schema';

export const questionTypeLabelMap = new Map<QuestionType, string>([
  ['BASIC', 'Обычный'],
  ['NORISK', 'Без риска'],
  ['SECRET', 'С секретом'],
  ['AUCTION', 'Аукцион'],
  ['VARIANTS', 'С вариантами (для одного)'],
  ['VARIANTS_EVERYONE', 'С вариантами (для всех)'],
  ['VARIANTS_NORISK', 'С вариантами (без риска)'],
  ['VARIANTS_EVERYONE_NORISK', 'С вариантами (для всех, без риска)'],
  ['VARIANTS_ONE_BY_ONE', 'С вариантами (по кнопке ответить)']
]);
