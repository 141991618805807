import React from 'react';
import {AnimatePresence} from 'framer-motion';
import styled from 'styled-components';
import {ReactionBubble} from '@/features/reactions';
import {useReactionsListener} from './useReactionsListener';

const StyledReactionsListener = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  user-select: none;
  pointer-events: none;
  overflow: hidden;
`;

export const ReactionsListener = () => {
  const {stack} = useReactionsListener();

  return (
    <StyledReactionsListener>
      <AnimatePresence>
        {stack.map(reaction => (
          <ReactionBubble key={`reaction-${reaction.id}`} userReaction={reaction} />
        ))}
      </AnimatePresence>
    </StyledReactionsListener>
  );
};
