import React from 'react';
import {UserRecord} from '@opeq-dev/openquiz-machine';
import {usePeerWebcam} from '@openquiz/peer-webcam';
import {DotsLoader, PlayerSlot, TeamSlotPlaceholder, WebCamera} from '@openquiz/quiz-ui';
import {UserBanner, useUserQuery} from '@/features/user';

interface HostSlotProps {
  user: UserRecord;
}

export const HostSlot = ({user}: HostSlotProps) => {
  const {data: host, isLoading} = useUserQuery(user.uuid);
  const {streams, gameUuid} = usePeerWebcam();

  const stream = streams[`${gameUuid}-${user?.uuid}`];
  const hasStream = stream?.getAudioTracks().length === 0;

  if (isLoading || !host) {
    return (
      <TeamSlotPlaceholder>
        <DotsLoader />
      </TeamSlotPlaceholder>
    );
  }

  return (
    <PlayerSlot
      team={undefined}
      user={host}
      renderBanner={() => <UserBanner user={host} />}
      renderStream={hasStream ? () => <WebCamera stream={stream} /> : undefined}
    />
  );
};
