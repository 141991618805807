import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-use';
import {ModalContext, ModalType} from './ModalContext';
import {ModalRoot} from './ModalRoot';

export interface ModalProviderProps {
  /**
   * Subtree that will receive modal context.
   */
  children: ReactNode;
}

export const ModalProvider = ({children}: ModalProviderProps) => {
  const location = useLocation();

  const [modals, setModals] = useState<Record<string, ModalType>>({});

  const onOpenModal = useCallback(
    (key: string, modal: ModalType) => setModals(m => ({...m, [key]: modal})),
    []
  );

  const onCloseModal = useCallback((key: string) => {
    return setModals(m => {
      if (!m[key]) return m;
      const newModals = {...m};
      delete newModals[key];
      return newModals;
    });
  }, []);

  const onCloseAllModals = useCallback(() => setModals({}), []);

  useEffect(() => {
    onCloseAllModals();
  }, [location]);

  return (
    <ModalContext.Provider value={{onOpenModal, onCloseModal, onCloseAllModals}}>
      {children}
      <ModalRoot modals={modals} />
    </ModalContext.Provider>
  );
};
