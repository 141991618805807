import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface UsernameProps {
  children: ReactNode;
}

const StyledUsername = styled.div`
  span {
    color: ${p => p.theme.colors.text.muted};
    font-weight: normal;
  }
`;

export const Username = ({children}: UsernameProps) => (
  <StyledUsername>{children}</StyledUsername>
);
