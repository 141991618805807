import React from 'react';
import {InfinityPackagesGrid} from '@/features/workshop';
import {Container, Page, PageTitle} from '@/components';

export const DraftsPage = () => (
  <Page title="Черновики – Мастерская">
    <Container>
      <PageTitle to={{href: '/'}} title="Черновики" />
      <InfinityPackagesGrid
        params={{filter: {ownedDrafts: true, illegal: true}, take: 6}}
      />
    </Container>
  </Page>
);
