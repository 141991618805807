import React, {useRef} from 'react';
import {useClickAway, useKey} from 'react-use';
import {X} from '@phosphor-icons/react';
import {Close, StyledModal} from './styled';
import {ModalProps} from './types';

/**
 * A modal component, used in conjunction with the `useModal` hook.
 */
export const Modal = ({
  children,
  size = 'md',
  closeOutside = false,
  onClose,
  ...rest
}: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useKey('Escape', onClose);
  useClickAway(ref, () => {
    if (!closeOutside || !onClose) return;
    onClose();
  });

  return (
    <StyledModal {...rest} ref={ref} $size={size}>
      {onClose && (
        <Close onClick={onClose}>
          <X size={20} weight="bold" />
        </Close>
      )}
      {children}
    </StyledModal>
  );
};
