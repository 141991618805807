import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface EntryProps {
  children: ReactNode;
}

const StyledEntry = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  z-index: 2;
`;

export const Entry = ({children}: EntryProps) => {
  return <StyledEntry>{children}</StyledEntry>;
};
