export const animalEmojiMap = {
  bear: '🐻',
  bird: '🐦',
  cat: '🐱',
  cetacean: '🐋',
  cow: '🐮',
  crocodilia: '🐊',
  dog: '🐶',
  fish: '🐟',
  horse: '🐎',
  insect: '🐜',
  lion: '🦁',
  rabbit: '🐰',
  rodent: '🐭',
  snake: '🐍'
};
