import {User} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export interface CurrentUser extends User {
  oAuths: {provider: string}[];
}

export const fetchProfile = async () => {
  const {data} = await getPrivateAxios().get<CurrentUser>('/users/profile');
  return data;
};
