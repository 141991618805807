import {PackageQuestion, PackageQuestionStep} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export interface FetchQuestionsParams {
  packageUuid: string;
  themeUuid: string;
}

/**
 * Fetch questions.
 * @param params
 */
export const fetchQuestions = async (params: FetchQuestionsParams) => {
  const {data} = await getPrivateAxios().get<
    (PackageQuestion & {steps: PackageQuestionStep[]})[]
  >(`/workshop/packages/${params.packageUuid}/questions`, {
    params: {themeUuid: params.themeUuid}
  });
  return data;
};
