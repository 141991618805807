import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled, {css} from 'styled-components';
import {Image, MusicNotes, YoutubeLogo} from '@phosphor-icons/react';
import {FieldRow, Tooltip} from '@openquiz/quiz-ui';
import {PackageFull} from '@/features/wshop-packages';

interface SizeBarProps {
  pkg: PackageFull;
  flatten?: boolean;
}

const StyledSizeBar = styled.div<{$flatten: boolean}>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${p =>
    p.$flatten
      ? css`
          ${p => p.theme.typography.caption.sm};
        `
      : css`
          ${p => p.theme.typography.caption.md};
        `}
`;

const Count = styled.div`
  background-color: ${p => p.theme.colors.neutral.emphasis};
  color: ${p => p.theme.colors.text.onEmphasis};
  display: flex;
  border-radius: 8px;
  padding: 4px 8px;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
`;

const Bar = styled.div<{$type: 'image' | 'audio' | 'video'}>`
  color: ${p => p.theme.colors.text.onEmphasis};
  padding: 4px 8px 4px 6px;
  display: flex;
  align-items: center;
  gap: 4px;

  :first-child {
    border-radius: 8px 0 0 8px;
  }

  :first-child:last-child {
    border-radius: 8px;
  }

  :last-child {
    border-radius: 0 8px 8px 0;
  }

  ${p =>
    p.$type === 'image' &&
    css`
      background-color: #ffb20b;
    `}

  ${p =>
    p.$type === 'audio' &&
    css`
      background-color: #8338ec;
    `}

  ${p =>
    p.$type === 'video' &&
    css`
      background-color: #ff006e;
    `}
`;

export const SizeBar = ({pkg, flatten}: SizeBarProps) => {
  if (flatten) {
    return (
      <StyledSizeBar $flatten={true}>
        <FormattedMessage
          id="workshop.questions.count"
          values={{count: pkg.meta.questions}}
        />

        {!!pkg.meta.images && (
          <Tooltip
            message={
              <FormattedMessage
                id="workshop.steps.image.count"
                values={{count: pkg.meta.images}}
              />
            }
            placement="top">
            <FieldRow size="xs">
              <Image size={16} weight="duotone" />
              <span>{pkg.meta.images}</span>
            </FieldRow>
          </Tooltip>
        )}

        {!!pkg.meta.audio && (
          <Tooltip
            message={
              <FormattedMessage
                id="workshop.steps.audio.count"
                values={{count: pkg.meta.audio}}
              />
            }
            placement="top">
            <FieldRow size="xs">
              <MusicNotes size={16} weight="duotone" />
              <span>{pkg.meta.audio}</span>
            </FieldRow>
          </Tooltip>
        )}

        {!!pkg.meta.video && (
          <Tooltip
            message={
              <FormattedMessage
                id="workshop.steps.video.count"
                values={{count: pkg.meta.video}}
              />
            }
            placement="top">
            <FieldRow size="xs">
              <YoutubeLogo size={16} weight="duotone" />
              <span>{pkg.meta.video}</span>
            </FieldRow>
          </Tooltip>
        )}
      </StyledSizeBar>
    );
  }

  return (
    <StyledSizeBar $flatten={false}>
      <Count>
        <FormattedMessage
          id="workshop.questions.count"
          values={{count: pkg.meta.questions}}
        />
      </Count>

      <Group>
        {!!pkg.meta.images && (
          <Tooltip
            message={
              <FormattedMessage
                id="workshop.steps.image.count"
                values={{count: pkg.meta.images}}
              />
            }
            placement="top">
            <Bar $type="image">
              <Image size={20} weight="fill" />
              <span>{pkg.meta.images}</span>
            </Bar>
          </Tooltip>
        )}

        {!!pkg.meta.audio && (
          <Tooltip
            message={
              <FormattedMessage
                id="workshop.steps.audio.count"
                values={{count: pkg.meta.audio}}
              />
            }
            placement="top">
            <Bar $type="audio">
              <MusicNotes size={20} weight="fill" />
              <span>{pkg.meta.audio}</span>
            </Bar>
          </Tooltip>
        )}

        {!!pkg.meta.video && (
          <Tooltip
            message={
              <FormattedMessage
                id="workshop.steps.video.count"
                values={{count: pkg.meta.video}}
              />
            }
            placement="top">
            <Bar $type="video">
              <YoutubeLogo size={20} weight="fill" />
              <span>{pkg.meta.video}</span>
            </Bar>
          </Tooltip>
        )}
      </Group>
    </StyledSizeBar>
  );
};
