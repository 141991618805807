import {Theme} from '../types/theme';
import {typography} from './typography';

export const lightTheme: Theme = {
  colors: {
    text: {
      default: '#24292f',
      muted: '#57606a',
      subtle: '#6e7781',
      onEmphasis: '#ffffff'
    },
    canvas: {
      default: '#f6f8fa',
      overlay: '#ffffff',
      inset: '#f6f8fa',
      subtle: '#f6f8fa'
    },
    border: {
      default: '#d0d7de',
      muted: 'hsla(210,18%,87%,1)',
      subtle: 'rgba(27,31,36,0.1)'
    },
    tooltip: {
      default: '#24292f',
      popper: '#ffffff'
    },
    neutral: {
      emphasis: '#6e7781',
      emphasisPlus: '#24292f',
      muted: 'rgba(175,184,193,0.2)',
      subtle: 'rgba(234,238,242,0.8)'
    },
    accent: {
      text: '#0969da',
      emphasis: '#0969da',
      muted: 'rgba(84,174,255,0.4)',
      subtle: 'rgba(59, 130, 246, 0.06)',
      focus: 'rgba(59, 130, 246, 0.3)'
    },
    success: {
      text: '#1a7f37',
      emphasis: '#2da44e',
      muted: 'rgba(74,194,107,0.4)',
      subtle: '#dafbe1',
      focus: '#dafbe1'
    },
    attention: {
      text: '#9a6700',
      emphasis: '#bf8700',
      muted: 'rgba(212,167,44,0.4)',
      subtle: '#fff8c5',
      focus: '#fff8c5'
    },
    danger: {
      text: '#cf222e',
      emphasis: '#cf222e',
      muted: 'rgba(255,129,130,0.4)',
      subtle: '#ffebe9',
      focus: '#ffebe9'
    },
    done: {
      text: '#8250df',
      emphasis: '#8250df',
      muted: 'rgba(194,151,255,0.4)',
      subtle: '#fbefff',
      focus: '#fbefff'
    }
  },
  shadow: {
    sm: '0 1px 0 rgba(27,31,36,0.04)',
    md: '0 0 1px rgba(27,31,36,0.1), 0 4px 6px rgba(140,149,159,0.05)',
    lg: '0 8px 24px rgba(140,149,159,0.2)',
    floating:
      '0 1px 0 rgba(27,31,36,0.04), 0 0 0 1px rgba(27,31,36,0.08), 0 8px 24px rgba(140,149,159,0.2)'
  },
  typography
};
