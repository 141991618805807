import {create} from 'zustand';

interface TryAnswerState {
  isBlocked: boolean;
  timerId: number | null;
  setIsBlocked: (value: boolean) => void;
  setTimerId: (id: number) => void;
}

export const useTryAnswerStore = create<TryAnswerState>(set => ({
  isBlocked: false,
  timerId: null,
  setIsBlocked: value => set(() => ({isBlocked: value})),
  setTimerId: id => set(() => ({timerId: id}))
}));
