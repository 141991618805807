import React, {ReactNode} from 'react';
import {MotionConfig} from 'framer-motion';
import {ThemeProvider, createGlobalStyle} from 'styled-components';
import {Theme} from '@openquiz/quiz-ui';
import {Normalize} from 'styled-normalize';
// Polyfills
import 'focus-visible';

interface OpenQuizProviderProps {
  children: ReactNode;
  theme: Theme;
}

const GlobalStyle = createGlobalStyle`
  * {
    outline: none;
    box-sizing: border-box;
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  html {
    font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  body {
    ${p => p.theme.typography.body.md};
    background-color: ${p => p.theme.colors.canvas.default};
    color: ${p => p.theme.colors.text.default};
    background-position: center center;
    background-attachment: fixed;
    background-image: url(${p => p.theme.backgroundImage});
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  h1, h2, h3 {
    margin-top: 0;
  }

  h2 {
    margin-bottom: 16px;
    margin-top: 24px;
  }

  h3 {
    margin-bottom: 16px;
    margin-top: 24px;
  }

  p {
    margin: 0 0 16px 0;
    :last-child {
      margin-bottom: 0;
    }
  }

  ul, ol {
    margin: 0 0 16px 0;
    padding: 0 0 0 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin: 0 0 6px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    border-left: 2px solid ${p => p.theme.colors.accent.emphasis};
    font-style: italic;
    margin: 0 0 16px 0;
    padding: 4px 16px;
    opacity: 0.5;
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    color: ${p => p.theme.colors.accent.text};
  }

  &::selection {
    background-color: ${p => p.theme.colors.accent.muted};
    color: ${p => p.theme.colors.text.onEmphasis};
  }
`;

export const OpenQuizProvider = ({children, theme}: OpenQuizProviderProps) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Normalize />
    <MotionConfig reducedMotion="user">{children}</MotionConfig>
  </ThemeProvider>
);
