import {PackageVersion} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export interface FetchAllVersionsParams {
  packageUuid: string;
}

/**
 *
 * @param params
 */
export const fetchVersions = async ({packageUuid}: FetchAllVersionsParams) => {
  const {data} = await getPrivateAxios().get<PackageVersion[]>(
    `/workshop/packages/${packageUuid}/versions`
  );
  return data;
};
