import {css} from 'styled-components';

export const typography = {
  heading: {
    h1: css`
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
    `,
    h2: css`
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    `
  },
  body: {
    xxlg: css`
      font-size: 28px;
      line-height: 40px;
    `,
    xlg: css`
      font-size: 24px;
      line-height: 34px;
    `,
    lg: css`
      font-size: 18px;
      line-height: 28px;
    `,
    md: css`
      font-size: 16px;
      line-height: 24px;
    `,
    sm: css`
      font-size: 14px;
      line-height: 20px;
    `
  },
  label: {
    lg: css`
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    `,
    md: css`
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    `,
    sm: css`
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    `
  },
  caption: {
    lg: css`
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    `,
    md: css`
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    `,
    sm: css`
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    `
  }
};
