import React, {cloneElement, useEffect, useState} from 'react';
import {useAsync} from 'react-use';
import {useForm} from 'react-hook-form';
import styled, {css} from 'styled-components';
import {
  createMediaStream,
  stopMediaStream,
  usePeerMediaStream
} from '@openquiz/peer-webcam';
import {
  Button,
  DotsLoader,
  FieldGroup,
  FieldRow,
  Modal,
  Title,
  VideoStream
} from '@openquiz/quiz-ui';
import {useDevicesList} from './useDevicesList';
import {CameraPreview} from './CameraPreview';

interface WebCameraSetupModalProps {
  onClose: () => void;
}

interface CameraCardProps {
  device: {id: string; label: string};
  active: boolean;
  onChange: () => void;
}

const StyledCameraCard = styled.div<{$active: boolean}>`
  background-color: ${p => p.theme.colors.neutral.subtle};
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  width: 87px;
  height: 87px;
  cursor: pointer;
  transition:
    opacity 80ms ease-in-out,
    transform 80ms ease-in-out;

  :hover {
    opacity: 0.8;
  }

  :active {
    transform: scale(0.98);
  }

  ${p =>
    p.$active &&
    css`
      &::after {
        border: 2px solid ${p => p.theme.colors.canvas.default};
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        border-radius: 16px;
        box-sizing: border-box;
        content: '';
        z-index: 1;
      }
    `}

  video {
    background-color: #000;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const CameraCard = ({device, active, onChange}: CameraCardProps) => {
  const [stream, setStream] = useState<MediaStream | null>(null);

  useAsync(async () => {
    setStream(await createMediaStream(device.id, {shape: 'square'}));
  }, [device.id]);

  useEffect(() => {
    return () => {
      if (!stream) return;
      stopMediaStream(stream);
    };
  }, [stream]);

  return (
    <StyledCameraCard $active={active} onClick={onChange}>
      {stream && (
        <VideoStream stream={stream} autoPlay={true} playsInline={true} muted={true} />
      )}
    </StyledCameraCard>
  );
};

const CameraCardPlaceholder = styled.div`
  background-color: ${p => p.theme.colors.neutral.subtle};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 87px;
`;

interface WebCameraSetupData {
  deviceId: string;
}

export const WebCameraSetupModal = ({onClose}: WebCameraSetupModalProps) => {
  const {status, devices} = useDevicesList();
  const {change} = usePeerMediaStream();

  const {handleSubmit, setValue, watch} = useForm<WebCameraSetupData>({
    defaultValues: {
      deviceId: ''
    }
  });

  const currentDevice = devices.find(device => device.id === watch('deviceId'));

  const placeholdersArray = new Array(4 - devices.length || 0).fill(
    <CameraCardPlaceholder>
      {status === 'loading' && <DotsLoader />}
    </CameraCardPlaceholder>
  );

  const onFormSubmit = async (data: WebCameraSetupData) => {
    const stream = await createMediaStream(data.deviceId, {shape: 'rectangle'});
    await change(stream);
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Title>Настройки камеры</Title>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <FieldGroup size="lg">
          <CameraPreview device={currentDevice} />
        </FieldGroup>

        <FieldGroup label="Источники" size="lg">
          <FieldRow align="flex-start">
            {devices.map(device => (
              <CameraCard
                key={`device-${device.id}`}
                active={currentDevice?.id === device.id}
                device={device}
                onChange={() => setValue('deviceId', device.id)}
              />
            ))}
            {placeholdersArray.map((placeholder, index) =>
              cloneElement(placeholder, {key: `source-placeholder-${index}`})
            )}
          </FieldRow>
        </FieldGroup>

        <FieldGroup>
          <FieldRow>
            <Button type="submit" disabled={!currentDevice}>
              Включить камеру
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Закрыть
            </Button>
          </FieldRow>
        </FieldGroup>
      </form>
    </Modal>
  );
};
