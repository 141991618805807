import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';

interface StoreState {
  referer: string | null;
  update: (referer: string | null) => void;
  flush: () => void;
}

export const useAuthRefererStore = create(
  persist<StoreState>(
    set => ({
      referer: null,
      update: (referer: string | null) => set({referer}),
      flush: () => set({referer: null})
    }),
    {name: 'referer-store', storage: createJSONStorage(() => window.sessionStorage)}
  )
);
