import React from 'react';
import styled, {css} from 'styled-components';
import {FieldGroup} from '@openquiz/quiz-ui';

const StyledSyntaxHelpMessage = styled.div`
  ol {
    padding: 0 24px;
    margin: 4px 0;
  }
`;

const HighlightsSyntax = styled.span<{$type: 'author' | 'category' | 'tag'}>`
  border-radius: 6px;
  padding: 0 4px;

  ${p =>
    p.$type === 'author' &&
    css`
      background-color: rgba(35, 201, 255, 0.5);
    `}

  ${p =>
    p.$type === 'category' &&
    css`
      background-color: rgba(200, 132, 166, 0.5);
    `}

  ${p =>
    p.$type === 'tag' &&
    css`
      background-color: rgba(175, 252, 65, 0.5);
    `}
`;

const HighlightsQuery = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const SyntaxHelpMessage = () => (
  <StyledSyntaxHelpMessage>
    <FieldGroup>
      <p>Поддерживается расширенный синтаксис поиска:</p>

      <ol>
        <li>
          <HighlightsSyntax $type="author">@</HighlightsSyntax> автор
        </li>
        <li>
          <HighlightsSyntax $type="category">%</HighlightsSyntax> категория
        </li>
        <li>
          <HighlightsSyntax $type="tag">#</HighlightsSyntax> тег
        </li>
      </ol>
    </FieldGroup>

    <FieldGroup label="Пример запроса">
      <HighlightsQuery>
        <HighlightsSyntax $type="author">@username</HighlightsSyntax>{' '}
        <HighlightsSyntax $type="category">%игры</HighlightsSyntax>{' '}
        <HighlightsSyntax $type="tag">#dota2</HighlightsSyntax>{' '}
      </HighlightsQuery>
    </FieldGroup>

    <FieldGroup label="Результат">
      <p>
        Вернет пакеты, которые опубликовал{' '}
        <HighlightsSyntax $type="author">username</HighlightsSyntax> в категории{' '}
        <HighlightsSyntax $type="category">игры</HighlightsSyntax> и содержит тег{' '}
        <HighlightsSyntax $type="tag">dota2</HighlightsSyntax>.
      </p>
    </FieldGroup>
  </StyledSyntaxHelpMessage>
);
