import React, {memo} from 'react';
import {VideoCamera, VideoCameraSlash} from '@phosphor-icons/react';
import {isStreamEmpty, usePeerMediaStream} from '@openquiz/peer-webcam';
import {Tooltip, useModal} from '@openquiz/quiz-ui';
import {WebCameraSetupModal} from '@/features/machine';
import {ControlBarButton} from '../ControlBarButton';

export const WebCameraButton = memo(() => {
  const {getLocalStream, stop} = usePeerMediaStream();

  const [openUserCamModal, closeUserCamModal] = useModal(
    () => <WebCameraSetupModal onClose={closeUserCamModal} />,
    []
  );

  const hasLocalStream = isStreamEmpty(getLocalStream);

  const onClickOff = () => {
    stop();
  };

  return (
    <Tooltip message="Веб камера" placement="top" closeOnReferencePress={true}>
      <ControlBarButton
        variant="secondary"
        onClick={hasLocalStream ? onClickOff : openUserCamModal}>
        {hasLocalStream ? (
          <VideoCameraSlash size={24} weight="bold" />
        ) : (
          <VideoCamera size={24} weight="bold" />
        )}
      </ControlBarButton>
    </Tooltip>
  );
});
