import {Package} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';
import {PackageFull} from './fetchPackages';

export type PackageCreateData = Pick<
  PackageFull,
  'name' | 'description' | 'difficulty' | 'files' | 'categories' | 'tags' | 'contests'
>;

/**
 * Fetch create package.
 * @param createData
 */
export const fetchPackageCreate = async (createData: PackageCreateData) => {
  const {data} = await getPrivateAxios().post<Package>('/workshop/packages', createData);
  return data;
};
