import React from 'react';
import {Helmet} from 'react-helmet';
import {Toaster} from 'react-hot-toast';
import {Toaster as SonnerToaster} from 'sonner';
import {Outlet, ScrollRestoration} from 'react-router-dom';
import {createGlobalStyle} from 'styled-components';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {OnboardingRoot} from '@openquiz/onboarding';
import {ModalProvider as ModalProviderNew} from '@openquiz/quiz-modal';
import {ModalProvider, OpenQuizProvider} from '@openquiz/quiz-ui';
import {useAnalytics, useAppearanceTheme, useBackStateObserver} from '@/hooks';
import {useUiSettingsStore} from '@/stores';
import {useAuthListener} from '@/features/auth';

const uiSizeMap = new Map([
  ['sm', 0.8],
  ['md', 1],
  ['lg', 1.25],
  ['xlg', 1.5]
]);

const GlobalStyle = createGlobalStyle<{$size: 'sm' | 'md' | 'lg' | 'xlg'}>`
  :root {
    --zoom: ${p => uiSizeMap.get(p.$size) ?? 1};
  }

  body {
    zoom: ${p => 100 * (uiSizeMap.get(p.$size) ?? 1)}%;
  }
`;

export const RootLayout = () => {
  const theme = useAppearanceTheme();
  const {size} = useUiSettingsStore();

  useAnalytics();
  useAuthListener();
  useBackStateObserver();

  return (
    <OpenQuizProvider theme={theme}>
      <Helmet>
        <meta property="og:title" content="OpenQuiz" />
        <meta
          property="og:description"
          content="Интеллектуальная игра, в которой вы можете провести время с друзьями."
        />
        <meta
          property="og:image"
          content="https://openquiz.hb.bizmrg.com/og/banner.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <GlobalStyle $size={size} />
      <SonnerToaster />
      <Toaster
        position="bottom-right"
        containerStyle={{
          top: 24,
          left: 24,
          bottom: 24,
          right: 24
        }}
        toastOptions={{
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff'
          }
        }}
      />

      <ReactQueryDevtools />
      <ModalProviderNew>
        <ModalProvider>
          <OnboardingRoot />
          <Outlet />
          <ScrollRestoration />
        </ModalProvider>
      </ModalProviderNew>
    </OpenQuizProvider>
  );
};
