import React from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {
  Button,
  Check,
  FieldGroup,
  FormSection,
  Slider,
  ErrorMessage as UiErrorMessage,
  getSliderMarks
} from '@openquiz/quiz-ui';
import {TemplateData} from '@/features/wshop-editor';

interface TemplateFromProps {
  onSubmit: (data: TemplateData) => Promise<void>;
}

const defaultValues: TemplateData = {
  rounds: 4,
  themes: 5,
  questions: 5,
  finalRound: true
};

const limits = {
  rounds: 5,
  themes: 8,
  questions: 10
};

export const TemplateFrom = ({onSubmit}: TemplateFromProps) => {
  const {
    formState: {isSubmitting, errors},
    register,
    handleSubmit,
    setValue
  } = useForm<TemplateData>({
    defaultValues
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSection label="Шаблон">
        <FieldGroup label="Раунды">
          <Slider
            {...register('rounds')}
            marks={getSliderMarks(limits.rounds)}
            step={1}
            min={1}
            max={limits.rounds}
            defaultValue={defaultValues.rounds}
            onChange={value => setValue('rounds', Number(value))}
          />
          <ErrorMessage
            errors={errors}
            name="rounds"
            render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup label="Темы">
          <Slider
            {...register('themes')}
            marks={getSliderMarks(limits.themes)}
            step={1}
            min={1}
            max={limits.themes}
            defaultValue={defaultValues.themes}
            onChange={value => setValue('themes', Number(value))}
          />
          <ErrorMessage
            errors={errors}
            name="themes"
            render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>
        <FieldGroup label="Вопросы">
          <Slider
            {...register('questions')}
            marks={getSliderMarks(limits.questions)}
            step={1}
            min={1}
            max={limits.questions}
            defaultValue={defaultValues.questions}
            onChange={value => setValue('questions', Number(value))}
          />
          <ErrorMessage
            errors={errors}
            name="questions"
            render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>
      </FormSection>

      <FormSection label="Параметры">
        <FieldGroup>
          <Check {...register('finalRound')}>
            <span>Финальный раунд</span>
          </Check>
        </FieldGroup>
      </FormSection>

      <FormSection>
        <Button type="submit" fetching={isSubmitting}>
          Создать
        </Button>
      </FormSection>
    </form>
  );
};
