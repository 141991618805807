import React from 'react';
import {CloudArrowUp} from '@phosphor-icons/react';
import styled from 'styled-components';

const StyledUploadButton = styled.div`
  background-color: ${p => p.theme.colors.canvas.subtle};
  border: 1px solid ${p => p.theme.colors.border.subtle};
  color: ${p => p.theme.colors.text.subtle};
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  :hover {
    color: ${p => p.theme.colors.text.default};
  }

  > svg {
    flex-shrink: 0;
  }
`;

const Label = styled.div`
  ${p => p.theme.typography.label.md};
`;

export const UploadButton = ({...rest}) => {
  return (
    <StyledUploadButton {...rest}>
      <CloudArrowUp size={20} weight="bold" />
      <div>
        <Label>Загрузить пак из &ldquo;Своей Игры&rdquo;</Label>
      </div>
    </StyledUploadButton>
  );
};
