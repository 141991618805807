import React from 'react';
import styled from 'styled-components';
import {Menu, MenuGroup, MenuItem, Slider} from '@openquiz/quiz-ui';
import {useUiSettingsStore} from '@/stores';

const SliderItem = styled.div`
  padding: 12px 6px 0 6px;
  margin-bottom: -8px;
  width: 120px;
`;

export const ControlBarVolumeMenu = () => {
  const {volume, updateVolume} = useUiSettingsStore();

  const onChange = (value: number | number[]) => {
    updateVolume(value as number);
  };

  const onClickUnmute = () => {
    updateVolume(0.5);
  };

  const onClickMute = () => {
    updateVolume(0);
  };

  return (
    <Menu>
      <MenuGroup>
        <SliderItem>
          <Slider value={volume} min={0} max={1} step={0.005} onChange={onChange} />
        </SliderItem>
      </MenuGroup>
      <MenuGroup>
        {volume > 0 ? (
          <MenuItem color="danger" onClick={onClickMute}>
            Выключить звук
          </MenuItem>
        ) : (
          <MenuItem onClick={onClickUnmute}>Включить звук</MenuItem>
        )}
      </MenuGroup>
    </Menu>
  );
};
