import React, {useEffect, useMemo, useRef} from 'react';
import {useInfiniteQuery} from '@tanstack/react-query';
import {AnimatePresence, useInView} from 'framer-motion';
import styled from 'styled-components';
import {Button, DotsLoader} from '@openquiz/quiz-ui';
import {
  FetchPackagesParams,
  PackageFull,
  PackageRow,
  fetchPackages
} from '@/features/wshop-packages';
import {EmptyMessage} from '@/features/wshop-search';
import {PaginatedQuery} from '@/types';

interface PackagesListProps {
  params: FetchPackagesParams;
}

const StyledPackagesList = styled.div``;

const List = styled.div`
  margin: 0 -20px;
`;

const More = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
`;

export const PackagesList = ({params}: PackagesListProps) => {
  const moreRef = useRef<HTMLDivElement>(null);
  const isMoreInView = useInView(moreRef, {margin: '0px 0px 500px 0px'});

  const {data, hasNextPage, isFetchingNextPage, isLoading, fetchNextPage} =
    useInfiniteQuery<PaginatedQuery<PackageFull>>({
      queryKey: [{params}],
      queryFn: ({pageParam}) => fetchPackages({...params, cursor: pageParam as string}),
      initialPageParam: null,
      refetchOnMount: false,
      getNextPageParam: (lastPage: {nextCursor: number}) => lastPage.nextCursor
    });

  const flatPackages = useMemo(() => data?.pages.map(p => p.data).flat() ?? [], [data]);

  const onMoreInView = () => {
    if (!hasNextPage || isFetchingNextPage) return;
    fetchNextPage();
  };

  useEffect(() => {
    if (!isMoreInView) return;
    onMoreInView();
  }, [isMoreInView, onMoreInView]);

  return (
    <StyledPackagesList>
      {isLoading ? (
        <DotsLoader />
      ) : (
        <AnimatePresence initial={false}>
          {flatPackages.length === 0 && <EmptyMessage key="empty" />}
          <List key="list">
            {flatPackages.map(pkg => (
              <PackageRow key={`package-row-${pkg.uuid}`} pkg={pkg} />
            ))}
          </List>
        </AnimatePresence>
      )}

      <More ref={moreRef}>
        {hasNextPage && (
          <Button fetching={isFetchingNextPage} variant="link" onClick={onMoreInView}>
            Загрузить еще
          </Button>
        )}
      </More>
    </StyledPackagesList>
  );
};
