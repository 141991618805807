import React from 'react';
import styled from 'styled-components';
import {Logotype} from '@openquiz/quiz-ui';

const StyledWorkshopButton = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.default};
  color: ${p => p.theme.colors.text.default};
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  transition:
    border-color ease-in-out 80ms,
    transform ease-in-out 80ms;

  :hover {
    border-color: ${p => p.theme.colors.accent.emphasis};
    transform: scale(1.01);
    > svg {
      color: ${p => p.theme.colors.accent.text};
    }
  }

  :active {
    transform: translateY(2px);
  }

  > svg {
    flex-shrink: 0;
    transition: color ease-in-out 80ms;
  }
`;

const Description = styled.div`
  ${p => p.theme.typography.body.md};
  padding-left: 30px;
`;

export const WorkshopButton = ({...rest}) => (
  <StyledWorkshopButton {...rest}>
    <Logotype />
    <Description>Выбрать из каталога паков.</Description>
  </StyledWorkshopButton>
);
