import React, {ButtonHTMLAttributes, ReactNode, createElement} from 'react';
import styled from 'styled-components';
import {Trash} from '@phosphor-icons/react';
import {PhosphorIcon} from '@openquiz/quiz-ui';

interface PreviewFileProps {
  type: 'IMAGE' | 'VIDEO' | 'AUDIO';
  src: string;
  onDelete: () => void;
}

const StyledPreviewFile = styled.div`
  position: relative;
  max-width: 400px;
  max-height: 400px;
`;

const ImagePreview = styled.img`
  background-color: #f1f3f4;
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  border-radius: 12px;
  display: block;
  width: 100%;
  max-height: 400px;
  object-fit: cover;
`;

const VideoPreview = styled.video`
  background-color: #000;
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  border-radius: 12px;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 400px;
`;

const AudioPreview = styled.audio`
  background-color: #f1f3f4;
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  border-radius: 12px;
  display: block;
  width: 100%;
  height: 96px;
`;

const Toolbar = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
`;

interface ToolbarButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  icon: PhosphorIcon;
}

const StyledToolbarButton = styled.button`
  ${p => p.theme.typography.label.sm};

  background-color: rgba(0, 0, 0, 0.6);
  color: ${p => p.theme.colors.text.onEmphasis};
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: none;
  padding: 8px;
  cursor: pointer;
  backdrop-filter: blur(8px);
  transition: background-color 80ms ease-in-out;

  :hover {
    background-color: ${p => p.theme.colors.accent.emphasis};
  }
`;

export const ToolbarButton = ({children, icon, ...rest}: ToolbarButtonProps) => {
  return (
    <StyledToolbarButton {...rest} type="button">
      {createElement(icon, {size: 16, weight: 'bold'})}
      <div>{children}</div>
    </StyledToolbarButton>
  );
};

export const PreviewFile = ({type, src, onDelete}: PreviewFileProps) => (
  <StyledPreviewFile>
    <Toolbar>
      <ToolbarButton icon={Trash} onClick={onDelete}>
        Удалить
      </ToolbarButton>
    </Toolbar>
    {type === 'IMAGE' && <ImagePreview src={src} alt="" />}
    {type === 'VIDEO' && (
      <VideoPreview controls={true}>
        <source src={src} />
      </VideoPreview>
    )}
    {type === 'AUDIO' && (
      <AudioPreview controls={true}>
        <source src={src} />
      </AudioPreview>
    )}
  </StyledPreviewFile>
);
