import {memo} from 'react';
import {useAudioPlayer} from 'react-use-audio-player';
import {Speech} from '@/features/speech';

interface SpeechAudioPlayerProps {
  speech: Speech;
}

export const SpeechAudioPlayer = memo(({speech}: SpeechAudioPlayerProps) => {
  useAudioPlayer({
    src: speech.src,
    format: 'mp3',
    autoplay: true
  });

  return null;
});
