import React from 'react';
import {FileDashed} from '@phosphor-icons/react';
import styled from 'styled-components';

const StyledDraftSticker = styled.div`
  ${p => p.theme.typography.caption.md};
  background-color: ${p => p.theme.colors.attention.emphasis};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 8px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const DraftSticker = () => (
  <StyledDraftSticker>
    <FileDashed size={20} weight="fill" />
    <span>Черновик</span>
  </StyledDraftSticker>
);
