import {useQuery} from '@tanstack/react-query';
import {FindAllUsersDto, fetchUsersList, getUsersListKey} from '@/features/user';

/**
 * This query is used to fetch a users.
 * @param findAllUsersDto
 */
export const useUsersListQuery = (findAllUsersDto: FindAllUsersDto = {}) =>
  useQuery({
    queryKey: getUsersListKey(findAllUsersDto),
    queryFn: () => fetchUsersList(findAllUsersDto)
  });
