import React, {createElement, useCallback, useMemo} from 'react';
import {State} from 'xstate';
import styled, {css} from 'styled-components';
import {
  ChooseVariantEvent,
  Ctx,
  chooseVariantGuard,
  chooseVariantOnceGuard
} from '@opeq-dev/openquiz-machine';
import {Avatar, Spinner} from '@openquiz/quiz-ui';
import {
  selectCtxCurrentQuestion,
  useGameService,
  useMachineEvent,
  useMachineGuard,
  useMachineSelector
} from '@/features/machine';
import {FetchedUser} from '@/features/user';
import {iconNumberMap} from '@/libs';

interface VariantItemProps {
  variant: {index: string; label: string};
  isCorrect?: boolean;
}

const StyledVariantItem = styled.div<{
  $isCorrect: VariantItemProps['isCorrect'];
  $disabled: boolean;
}>`
  background-color: ${p => p.theme.colors.border.default};
  border: 1px solid ${p => p.theme.colors.border.default};
  color: ${p => p.theme.colors.text.default};
  position: relative;
  display: flex;
  align-items: flex-start;
  word-break: break-word;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out,
    transform 80ms ease-in-out;

  @media only screen and (min-width: 320px) {
    ${p => p.theme.typography.label.sm};
    border-radius: 12px;
    padding: 6px 8px;
  }

  @media only screen and (min-width: 1024px) {
    ${p => p.theme.typography.label.lg};
    border-radius: 16px;
    padding: 12px 16px;
  }

  @media only screen and (min-width: 1440px) {
    ${p => p.theme.typography.label.lg};
    border-radius: 16px;
    padding: 12px 16px;
  }

  svg {
    flex-shrink: 0;
  }

  :hover {
    background-color: ${p => p.theme.colors.accent.emphasis};
    border-color: ${p => p.theme.colors.accent.subtle};
    color: ${p => p.theme.colors.text.onEmphasis};
  }

  :active {
    transform: scale(0.98);
  }

  ${p =>
    p.$isCorrect === true &&
    css`
      background-color: ${p => p.theme.colors.success.subtle};
      border-color: ${p => p.theme.colors.success.emphasis};
      color: ${p => p.theme.colors.success.text};
      pointer-events: none;
    `}

  ${p =>
    p.$isCorrect === false &&
    css`
      background-color: ${p => p.theme.colors.danger.subtle};
      border-color: ${p => p.theme.colors.danger.emphasis};
      color: ${p => p.theme.colors.danger.text};
      pointer-events: none;
    `}

  ${p =>
    p.$disabled &&
    p.$isCorrect === undefined &&
    css`
      background-color: ${p => p.theme.colors.neutral.subtle};
      border-color: ${p => p.theme.colors.border.default};
      color: ${p => p.theme.colors.text.muted};
      pointer-events: none;
    `}
`;

const ChoosePlayers = styled.div`
  position: absolute;
  right: 8px;
  top: -12px;
  display: flex;

  & > * {
    margin-right: -4px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px ${p => p.theme.colors.canvas.default};
    :last-child {
      margin-right: 0;
    }
  }
`;

const selectAlreadyChosenPlayers = (index: string) => (state: State<Ctx>) =>
  state.context.history
    .filter(
      record =>
        record.type === 'chooseVariant' &&
        record.payload.questionUuid === state.context.current.questionUuid &&
        record.payload.variant === index
    )
    .map(record => (record as ChooseVariantEvent).self.userUuid);

export const VariantItem = ({variant, isCorrect}: VariantItemProps) => {
  const {isMatch} = useGameService();
  const {event} = useMachineEvent();
  const {guard} = useMachineGuard();

  const isVariantsOneByOne = isMatch([{game: {basicRound: 'variantsOneByOneQuestion'}}]);

  const question = useMachineSelector(selectCtxCurrentQuestion);
  const alreadyChosenPlayers = useMachineSelector(
    selectAlreadyChosenPlayers(variant.index)
  );

  const icon = useMemo(
    () =>
      createElement(iconNumberMap.get(parseInt(variant.index))!, {
        size: 24,
        weight: 'duotone'
      }),
    [iconNumberMap, variant]
  );

  const onClickChoose = useCallback(() => {
    if (!question) return;
    event<ChooseVariantEvent>('chooseVariant', {
      questionUuid: question.uuid,
      variant: variant.index
    });
  }, [event, question, variant]);

  if (!question) {
    return null;
  }

  return (
    <StyledVariantItem
      $isCorrect={isCorrect}
      $disabled={
        isVariantsOneByOne
          ? !guard(chooseVariantOnceGuard)({
              questionUuid: question?.uuid,
              variant: variant.index
            })
          : !guard(chooseVariantGuard)({
              questionUuid: question?.uuid
            })
      }
      onClick={onClickChoose}>
      {icon}
      <span>{variant.label}</span>
      {isCorrect !== undefined && (
        <ChoosePlayers>
          {alreadyChosenPlayers.map(uuid => (
            <FetchedUser
              key={`choose-player-${uuid}`}
              uuid={uuid}
              fallback={<Spinner size={24} />}>
              {user => <Avatar user={user} size={24} />}
            </FetchedUser>
          ))}
        </ChoosePlayers>
      )}
    </StyledVariantItem>
  );
};
