import React, {useMemo} from 'react';
import {motion} from 'framer-motion';
import styled from 'styled-components';
import {getAnimationProps, getRandomNumber} from '@openquiz/quiz-ui';
import {useUserQuery} from '@/features/user';
import {reactionList} from '../../libs';
import {UserReaction} from '../../types';

interface ReactionBubbleProps {
  userReaction: UserReaction;
}

const StyledReactionBubble = styled(motion.div)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  :last-child {
    margin-bottom: 0;
  }
`;

const Preview = styled.div`
  font-size: 48px;
  line-height: normal;
`;

const Label = styled.div`
  ${p => p.theme.typography.caption.sm};
  background-color: ${p => p.theme.colors.accent.emphasis};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 6px;
  padding: 2px 4px;
`;

const getReactionBubbleMotionProps = () =>
  getAnimationProps(
    {
      initial: {
        y: '100vh',
        x: `${getRandomNumber(1, 1500) / 100}vw`,
        opacity: 1,
        scale: 0.2
      },
      animate: {
        y: '50vh',
        opacity: 1,
        scale: getRandomNumber(70, 150) / 100
      },
      exit: {
        y: '20vh',
        opacity: 0,
        scale: 0.2,
        transition: {duration: 0.2}
      }
    },
    {duration: 2}
  );

export const ReactionBubble = ({userReaction}: ReactionBubbleProps) => {
  const {data: user} = useUserQuery(userReaction.userUuid);
  const motionProps = useMemo(() => getReactionBubbleMotionProps(), []);
  const reaction = useMemo(
    () => reactionList.find(r => r.name === userReaction.name),
    [userReaction]
  );

  if (!reaction || !user) {
    return null;
  }

  return (
    <StyledReactionBubble {...motionProps}>
      <Preview>{reaction.icon}</Preview>
      <Label>{user.username}</Label>
    </StyledReactionBubble>
  );
};
