import {useCallback, useState} from 'react';

interface UseProgressResult {
  progress: {
    percent: number;
    eta: number;
  };
  reset: () => void;
  tick: (value: number) => void;
}

const TOTAL = 100;

/**
 * This hook is used to calculate the progress and ETA of a process.
 * @deprecated rename to useEta
 */
export const useProgress = (): UseProgressResult => {
  const [startDate, setStartDate] = useState<number>(new Date().getTime());
  const [percent, setPercent] = useState(0);
  const [eta, setEta] = useState(0);

  const reset = useCallback(() => {
    setStartDate(new Date().getTime());
    setPercent(1);
  }, [setStartDate, setPercent]);

  const tick = useCallback(
    (value: number) => {
      setPercent(Math.round(Math.max(1, value)));

      const elapsed = new Date().getTime() - startDate;

      if (value === 0) {
        setEta(Math.round(elapsed * (TOTAL - 1)));
        return;
      }

      const ratio = Math.min(Math.max((value + 1) / TOTAL, 0), 1);

      let eta = ratio * 100 === 100 ? 0 : elapsed * (TOTAL / value - 1);
      eta = isNaN(eta) || !isFinite(eta) ? 0.0 : eta;

      setEta(Math.round(eta));
    },
    [startDate, setPercent, setEta]
  );

  return {progress: {percent, eta}, reset, tick};
};
