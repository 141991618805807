import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PackageQuestion} from '@opeq-dev/openquiz-schema';
import {fetchQuestionUpdate} from './fetchQuestionUpdate';

export const useQuestionUpdateMutate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchQuestionUpdate,
    onMutate: variables => {
      if (!variables) return;

      queryClient.setQueryData<PackageQuestion[]>(
        ['editor', 'questions', variables.data.themeUuid],
        (questions = []) =>
          questions.map(question => {
            if (question.uuid === variables.data.uuid)
              return {...question, ...variables.data};
            return question;
          })
      );

      queryClient.setQueryData<PackageQuestion>(
        ['editor', 'questions', variables.data.uuid],
        question => ({...question, ...variables.data})
      );
    }
  });
};
