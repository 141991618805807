import {useQuery} from '@tanstack/react-query';
import {FetchRoundParams, fetchRound} from './fetchRound';

/**
 *
 * @param params
 */
export const useRoundQuery = (params: FetchRoundParams) =>
  useQuery({
    queryKey: [
      'editor',
      'rounds',
      {packageUuid: params.packageUuid, versionUuid: params.versionUuid},
      params.uuid
    ],
    queryFn: () => fetchRound(params)
  });
