import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

interface TableRowProps {
  children: ReactNode;
  isHeader?: boolean;
  to?: string;
}

const StyledTableRow = styled.div<{$isHeader: boolean}>`
  background-color: ${p => p.theme.colors.canvas.overlay};
  border-bottom: 1px solid ${p => p.theme.colors.border.subtle};
  color: ${p => p.theme.colors.text.default};
  display: flex;
  align-items: flex-start;
  gap: 16px;

  ${p =>
    p.$isHeader &&
    css`
      position: sticky;
      left: 0;
      top: 0;
      color: ${p => p.theme.colors.text.subtle};
      border-radius: 16px 16px 0 0;
      z-index: 1;
    `}

  &[href^="/"]:hover {
    background-color: ${p => p.theme.colors.neutral.subtle};
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 16px 16px;
  }
`;

export const TableRow = ({children, isHeader = false, to}: TableRowProps) => (
  <StyledTableRow as={to ? Link : 'div'} to={to} $isHeader={isHeader}>
    {children}
  </StyledTableRow>
);
