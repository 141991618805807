import React from 'react';
import styled from 'styled-components';
import {PackageQuestionStep} from '@opeq-dev/openquiz-schema';

interface ImageStepProps {
  step: PackageQuestionStep;
}

const StyledImageStep = styled.img`
  display: flex;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  min-width: 90%;
  pointer-events: none;
`;

export const ImageStep = ({step}: ImageStepProps) => (
  <StyledImageStep src={step.payload} />
);
