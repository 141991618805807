import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {SealCheck} from '@phosphor-icons/react';

const StyledVerifiedSticker = styled.div`
  ${p => p.theme.typography.caption.md};
  background-color: ${p => p.theme.colors.accent.emphasis};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 8px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const VerifiedSticker = () => (
  <StyledVerifiedSticker>
    <SealCheck size={20} weight="fill" />
    <FormattedMessage id="workshop.recommended-label" />
  </StyledVerifiedSticker>
);
