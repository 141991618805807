import React from 'react';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {Surface} from '@openquiz/quiz-ui';
import {Page, PageFallback, PageTitle} from '@/components';
import {
  ThemeForm,
  ThemeFormData,
  editorRoundPath,
  useEditorContext,
  useThemeQuery,
  useThemeUpdateMutation
} from '@/features/wshop-editor';

export const ThemeEditPage = () => {
  const navigate = useNavigate();
  const {themeUuid} = useParams();
  const {packageUuid, roundUuid, versionUuid} = useEditorContext();
  const {mutateAsync} = useThemeUpdateMutation();
  const {data: theme, isLoading} = useThemeQuery({
    uuid: themeUuid as string,
    packageUuid
  });

  const backHref = generatePath(editorRoundPath, {
    packageUuid,
    roundUuid,
    versionUuid
  });

  const onSubmitForm = async (data: ThemeFormData) => {
    if ('uuid' in data) {
      await mutateAsync({data: data, packageUuid});
      navigate(backHref, {replace: true});
    }
  };

  if (!theme || isLoading) {
    return <PageFallback />;
  }

  return (
    <Page>
      <PageTitle to={{href: backHref}} title={`Редактирование темы: ${theme.name}`} />
      <Surface>
        <ThemeForm theme={theme} roundUuid={roundUuid} onSubmit={onSubmitForm} />
      </Surface>
    </Page>
  );
};
