import React, {InputHTMLAttributes, ReactNode, forwardRef} from 'react';
import styled, {css} from 'styled-components';

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: 'sm' | 'md';
  extraLeft?: ReactNode;
  extraRight?: ReactNode;
}

const StyledInput = styled.div`
  position: relative;
  /* TODO: Придумать что делать с этим */
  min-width: 120px;
  max-width: 400px;
  width: 100%;
`;

const TextField = styled.input<{
  $size: InputProps['size'];
  $hasExtraLeft: boolean;
  $hasExtraRight: boolean;
}>`
  ${p => p.theme.typography.body.md};
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.default};
  color: ${p => p.theme.colors.text.default};
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px transparent;
  transition:
    background-color 80ms ease-in-out,
    border-color 80ms ease-in-out,
    color 80ms ease-in-out;

  ${p =>
    p.$size === 'sm' &&
    css`
      border-radius: 12px;
      padding: 0 16px;
      height: 36px;

      ${p.$hasExtraLeft &&
      css`
        padding-left: 28px;
      `}

      ${p.$hasExtraRight &&
      css`
        padding-right: 28px;
      `}
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      border-radius: 12px;
      padding: 0 16px;
      height: 44px;

      ${p.$hasExtraLeft &&
      css`
        padding-left: 40px;
      `}

      ${p.$hasExtraRight &&
      css`
        padding-right: 40px;
      `}
    `}

  &::placeholder {
    font-weight: normal;
    color: ${p => p.theme.colors.text.subtle};
  }

  &[data-open='true'],
  &:focus {
    border-color: ${p => p.theme.colors.accent.emphasis};
    box-shadow: 0 0 0 2px ${p => p.theme.colors.accent.focus};

    & ~ [data-id='extra-left'] {
      color: ${p => p.theme.colors.accent.emphasis};
    }
  }

  &[type='file'] {
    padding: 10px 16px;
  }

  &[readonly] {
    cursor: pointer;
  }

  &[disabled] {
    background-color: ${p => p.theme.colors.neutral.muted};
    border-color: ${p => p.theme.colors.neutral.muted};
    color: ${p => p.theme.colors.text.subtle};
  }
`;

const Extra = styled.div<{$size: InputProps['size']}>`
  color: ${p => p.theme.colors.text.subtle};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  ${p =>
    p.$size === 'sm' &&
    css`
      min-width: 32px;
      height: 32px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      min-width: 44px;
      height: 44px;
    `}
`;

const ExtraLeft = styled(Extra)`
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
`;

const ExtraRight = styled(Extra)`
  right: 0;
  top: 0;
`;

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {size = 'md', extraLeft, extraRight, ...rest},
  ref
) {
  return (
    <StyledInput>
      <TextField
        {...rest}
        ref={ref}
        $size={size}
        $hasExtraLeft={!!extraLeft}
        $hasExtraRight={!!extraRight}
      />
      {extraLeft && (
        <ExtraLeft $size={size} data-id="extra-left">
          {extraLeft}
        </ExtraLeft>
      )}
      {extraRight && (
        <ExtraRight $size={size} data-id="extra-right">
          {extraRight}
        </ExtraRight>
      )}
    </StyledInput>
  );
});
