import React, {MouseEvent, ReactNode, createElement} from 'react';
import styled, {css} from 'styled-components';
import {PhosphorIcon} from '../../../types';

export interface MenuItemProps {
  children: ReactNode;
  color?: 'default' | 'discord' | 'accent' | 'danger';
  icon?: PhosphorIcon;
  onClick?: (e: MouseEvent) => void;
}

const StyledMenuItem = styled.div<{$color: MenuItemProps['color']}>`
  ${p => p.theme.typography.caption.md};
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 -8px 1px -8px;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  transition:
    background-color 40ms ease-in-out,
    color 40ms ease-in-out;

  & svg {
    display: inline-flex;
    transition:
      background-color 40ms ease-in-out,
      color 40ms ease-in-out;
  }

  ${p =>
    p.$color === 'default' &&
    css`
      color: ${p.theme.colors.text.default};

      & > svg {
        color: ${p.theme.colors.text.subtle};
      }

      :hover {
        background-color: ${p => p.theme.colors.canvas.inset};
      }

      :active {
        background-color: ${p => p.theme.colors.accent.emphasis};
        color: ${p => p.theme.colors.text.onEmphasis};
      }
    `}

  ${p =>
    p.$color === 'accent' &&
    css`
      color: ${p => p.theme.colors.accent.text};

      & > svg {
        color: ${p => p.theme.colors.accent.text};
      }

      :hover {
        background-color: ${p => p.theme.colors.accent.subtle};
      }

      :active {
        background-color: ${p => p.theme.colors.accent.emphasis};
        color: ${p => p.theme.colors.text.onEmphasis};
      }
    `}

  ${p =>
    p.$color === 'discord' &&
    css`
      color: #5865f2;

      & > svg {
        color: #5865f2;
      }

      :hover {
        background-color: #5865f21a;
      }

      :active {
        background-color: #5865f2;
        color: #fff;
      }
    `}

  ${p =>
    p.$color === 'danger' &&
    css`
      color: ${p.theme.colors.danger.text};

      & > svg {
        color: ${p.theme.colors.danger.text};
      }

      :hover {
        background-color: ${p => p.theme.colors.danger.subtle};
      }

      :active {
        background-color: ${p => p.theme.colors.danger.emphasis};
        color: ${p => p.theme.colors.text.onEmphasis};
      }
    `}


  :active {
    & > svg {
      color: ${p => p.theme.colors.text.onEmphasis};
    }
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const MenuItem = ({children, color = 'default', icon, onClick}: MenuItemProps) => (
  <StyledMenuItem $color={color} onClick={onClick}>
    {icon && createElement(icon, {size: 20, weight: 'bold'})}
    <span>{children}</span>
  </StyledMenuItem>
);
