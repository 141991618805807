import {FunctionComponent, createElement, useMemo} from 'react';
import {UserRole} from '@opeq-dev/openquiz-machine';
import {useGameService, useMachineEvent} from '@/features/machine';
import {LeaderBar} from './LeaderBar';
import {PlayerBar} from './PlayerBar';
import {SpectatorBar} from './SpectatorBar';

const roleBarMap = new Map<UserRole | undefined, FunctionComponent>([
  ['LEADER', LeaderBar],
  ['PLAYER', PlayerBar],
  [undefined, SpectatorBar]
]);

export const ControlBar = () => {
  const {isMatch} = useGameService();
  const {role} = useMachineEvent();
  const bar = useMemo(() => roleBarMap.get(role), [role]);

  if (!bar || isMatch([{game: 'end'}])) {
    return null;
  }

  return createElement(bar);
};
