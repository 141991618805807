import {useQuery} from '@tanstack/react-query';
import {FetchAllVersionsParams, fetchVersions} from './fetchVersions';
import {versionKeys} from './keys';

/**
 *
 * @param params
 */
export const useVersionsQuery = (params: FetchAllVersionsParams) =>
  useQuery({
    queryKey: versionKeys.all(params),
    queryFn: () => fetchVersions(params)
  });
