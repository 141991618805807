import {useQuery} from '@tanstack/react-query';
import {FetchThemeParams, fetchTheme} from './fetchTheme';

/**
 *
 * @param params
 */
export const getThemeQueryKey = (params: Pick<FetchThemeParams, 'uuid'>) => [
  'editor',
  'theme',
  params.uuid
];

/**
 *
 * @param params
 */
export const useThemeQuery = (params: FetchThemeParams) =>
  useQuery({
    queryKey: getThemeQueryKey(params),
    queryFn: () => fetchTheme(params)
  });
