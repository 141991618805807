import React from 'react';
import styled from 'styled-components';
import {PackageFull} from '@/features/wshop-packages';

interface RoundSnippetProps {
  pkg: PackageFull;
  match: string;
  payload: {[key: string]: string};
}

const StyledRoundSnippet = styled.div`
  text-decoration: underline;
`;

export const RoundSnippet = ({pkg, match, payload}: RoundSnippetProps) => {
  const round = pkg.versions.at(0)?.rounds.find(r => r.uuid === payload[match]);

  if (!round) {
    return <StyledRoundSnippet>...</StyledRoundSnippet>;
  }

  return (
    <StyledRoundSnippet>
      <span>{round.name}</span>
    </StyledRoundSnippet>
  );
};
