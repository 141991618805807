import {State} from 'xstate';
import {Ctx, getPkgVersion} from '@opeq-dev/openquiz-machine';

/**
 *
 * @param state
 * @returns
 */
export const selectCtxCurrentSteps = (state: State<Ctx>) =>
  getPkgVersion(state.context)
    ?.steps.filter(step => step.questionUuid === state.context.current.questionUuid)
    .sort(value => (value.isAnswer ? 1 : -1)) ?? [];
