import {getPrivateAxios} from '@/libs';

export interface ContestFeedbackData {
  contestUuid: string;
  packageUuid: string;
  score: number;
  message: string;
}

/**
 *
 */
export const fetchContestFeedback = async (data: ContestFeedbackData) => {
  await getPrivateAxios().post<void>(
    `/workshop/contest/${data.contestUuid}/feedback`,
    data
  );
};
