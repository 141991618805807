import React from 'react';
import styled from 'styled-components';
import {Avatar} from '@openquiz/quiz-ui';
import {FetchedUser} from '@/features/user';
import {PackageFull} from '@/features/wshop-packages';

interface UsernameSnippetProps {
  pkg: PackageFull;
  match: string;
  payload: {[key: string]: string};
}

const StyledUsernameSnippet = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const UsernameSnippet = ({match, payload}: UsernameSnippetProps) => {
  if (!payload[match]) {
    return (
      <StyledUsernameSnippet>
        <span>...</span>
      </StyledUsernameSnippet>
    );
  }

  return (
    <StyledUsernameSnippet>
      <FetchedUser uuid={payload[match]} fallback={<span>...</span>}>
        {user => (
          <>
            <Avatar user={user} size={24} />
            <span>{user.username}</span>
          </>
        )}
      </FetchedUser>
    </StyledUsernameSnippet>
  );
};
