import React, {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {PaperPlaneRight} from '@phosphor-icons/react';
import {TypeBetEvent} from '@opeq-dev/openquiz-machine';
import {Button, FieldGroup, FieldRow, Input} from '@openquiz/quiz-ui';
import {
  UserChip,
  selectCtxCurrentQuestion,
  selectTimer,
  selectTurnTeam,
  useMachineEvent,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';

interface BetHintDto {
  bet: number;
}

export const PlaceInitialBetHint = () => {
  const self = useMachineSelf();
  const {event} = useMachineEvent();

  const auctionBetsTimer = useMachineSelector(selectTimer('auctionBets'));
  const question = useMachineSelector(selectCtxCurrentQuestion);
  const turnTeam = useMachineSelector(selectTurnTeam);

  const {register, handleSubmit} = useForm<BetHintDto>({
    defaultValues: {
      bet: question?.price ? question.price : 0
    }
  });

  const isSelf = self?.teamUuid === turnTeam?.uuid;
  const isScoreAbovePrice = (turnTeam?.score || 0) > (question?.price ?? 0);

  const onFormSubmit = useCallback(
    (values: BetHintDto) => {
      const bet = Math.trunc(values.bet);
      if (!question || bet <= question.price || bet > (turnTeam?.score || 0)) return;
      event<TypeBetEvent>('typeBet', {questionUuid: question.uuid, bet});
    },
    [event, question, turnTeam?.score]
  );

  const onClickNominal = useCallback(() => {
    if (!question) return;
    event<TypeBetEvent>('typeBet', {questionUuid: question.uuid, bet: question.price});
  }, [event, question]);

  const onClickAllIn = useCallback(() => {
    if (!question || !turnTeam?.score) return;
    event<TypeBetEvent>('typeBet', {questionUuid: question.uuid, bet: turnTeam.score});
  }, [event, question, turnTeam?.score]);

  if (!question || !turnTeam) {
    return null;
  }

  if (!isSelf) {
    return (
      <UserChip
        userUuid={turnTeam.leaderUuid}
        message=", делает начальную ставку"
        timer={auctionBetsTimer}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <FieldGroup>
        <FieldRow justify="center">
          <UserChip
            userUuid={turnTeam.leaderUuid}
            message=", делайте начальную ставку"
            timer={auctionBetsTimer}
            isSelf={true}
          />
        </FieldRow>
      </FieldGroup>

      <FieldGroup>
        <FieldRow justify="center">
          {isScoreAbovePrice && (
            <FieldRow size="sm">
              <div style={{width: 120}}>
                <Input
                  {...register('bet', {required: 'Пожалуйста введите ставку.'})}
                  placeholder="Ставка"
                  autoComplete="off"
                  autoFocus={true}
                  type="number"
                  size="sm"
                  min={question.price}
                  max={turnTeam.score}
                />
              </div>
              <Button type="submit" size="sm">
                <PaperPlaneRight size={16} weight="bold" />
              </Button>
            </FieldRow>
          )}

          <FieldRow size="sm">
            <Button variant="secondary" size="sm" onClick={onClickNominal}>
              Номинал ({question.price})
            </Button>

            {isScoreAbovePrice && (
              <Button
                variant="secondary"
                size="sm"
                disabled={!isScoreAbovePrice}
                onClick={onClickAllIn}>
                Ва-банк
              </Button>
            )}
          </FieldRow>
        </FieldRow>
      </FieldGroup>
    </form>
  );
};
