import {useEffect, useState} from 'react';
import {BasicTryAnswerEvent} from '@opeq-dev/openquiz-machine';
import {useTryAnswerStore} from '../stores';
import {useGameService} from './useGameService';
import {useMachineEvent} from './useMachineEvent';
import {useMachineSelector} from './useMachineSelector';
import {selectCtxCurrentQuestion} from '../selectors';

export const useSmartTryAnswer = () => {
  const {isMatch} = useGameService();
  const {event} = useMachineEvent();
  const {isBlocked, timerId, setIsBlocked, setTimerId} = useTryAnswerStore();

  const [questionPlayDate, setQuestionPlayDate] = useState(0);
  const question = useMachineSelector(selectCtxCurrentQuestion);

  const isBasicQuestionPlay = isMatch([
    {game: {basicRound: {basicQuestion: 'play'}}},
    {game: {basicRound: {variantsOneByOneQuestion: 'play'}}}
  ]);

  useEffect(() => {
    setQuestionPlayDate(isBasicQuestionPlay ? Date.now() : 0);
  }, [isBasicQuestionPlay, setQuestionPlayDate]);

  const onSmartTryAnswer = () => {
    if (!isBlocked && question && questionPlayDate) {
      setIsBlocked(true);
      event<BasicTryAnswerEvent>('basicTryAnswer', {
        questionUuid: question.uuid,
        timestamp: Date.now() - questionPlayDate
      });
    }

    if (timerId) {
      clearTimeout(timerId);
    }

    setTimerId(
      window.setTimeout(() => {
        setIsBlocked(false);
      }, 2000)
    );
  };

  return {isBlocked, onSmartTryAnswer};
};
