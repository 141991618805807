import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useCopyToClipboard} from 'react-use';
import {CheckCircle, Copy} from '@phosphor-icons/react';
import styled from 'styled-components';
import {Button, ButtonIcon, FieldGroup, Title, useWizard} from '@openquiz/quiz-ui';
import {Game} from '@/features/game';

const StyledTitle = styled(Title)`
  text-align: center;
`;

const Emoji = styled.picture`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const Message = styled.p`
  text-align: center;
`;

const LinkCallout = styled.div`
  ${p => p.theme.typography.body.md};
  background-color: ${p => p.theme.colors.neutral.subtle};
  color: ${p => p.theme.colors.text.default};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 12px 24px;
  gap: 8px;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const FinishStep = () => {
  const navigate = useNavigate();
  const {data} = useWizard();
  const [state, copyToClipboard] = useCopyToClipboard();

  const publicURL = useMemo(() => {
    return new URL(`/game/${(data as Game)?.uuid}`, window.location.origin);
  }, [data]);

  const onClickCopy = () => {
    copyToClipboard(publicURL.href);
  };

  const onClickPlay = () => {
    navigate(publicURL.pathname);
  };

  return (
    <div>
      <Emoji>
        <source
          srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/270c_fe0f/512.webp"
          type="image/webp"
        />
        <img
          src="https://fonts.gstatic.com/s/e/notoemoji/latest/270c_fe0f/512.gif"
          alt="✌"
          width={96}
          height={96}
        />
      </Emoji>
      <StyledTitle layoutId="title">Игра создана</StyledTitle>

      <FieldGroup size="lg">
        <Message>
          Поздравляем! Вы создали игру. Теперь вы можете поделиться ссылкой с друзьями и
          начать играть.
        </Message>
      </FieldGroup>

      <FieldGroup>
        <LinkCallout>
          <span>{publicURL.href}</span>
          <ButtonIcon icon={state.value ? CheckCircle : Copy} onClick={onClickCopy} />
        </LinkCallout>
      </FieldGroup>

      <FieldGroup>
        <Button stretch={true} onClick={onClickPlay}>
          Играть
        </Button>
      </FieldGroup>
    </div>
  );
};
