import React, {useEffect, useRef} from 'react';
import {useClickAway, useToggle} from 'react-use';
import {useLocation} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';
import {ArrowRight} from '@phosphor-icons/react';
import {getAnimationProps} from '@openquiz/quiz-ui';
import {CategorySidebarLink, useWorkshopCategories} from '@/features/wshop-categories';

const StyledCategoriesList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const PopperSurface = styled(motion.div)`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.floating};
  position: absolute;
  left: -16px;
  top: 0;
  padding: 12px 16px 0 16px;
  border-radius: 12px;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(5, 1fr);
  gap: 0 16px;
`;

const More = styled.div`
  ${p => p.theme.typography.body.md};
  color: ${p => p.theme.colors.text.subtle};
  display: flex;
  align-items: center;
  gap: 8px;
  transition: color 80ms ease-in-out;

  svg {
    flex-shrink: 0;
  }

  :hover {
    color: ${p => p.theme.colors.accent.text};
  }
`;

const popperSurfaceAnimationProps = getAnimationProps({
  initial: {y: -4, opacity: 0},
  animate: {y: 0, opacity: 1},
  exit: {y: -4, opacity: 0}
});

export const CategoriesList = () => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const {data: categories = []} = useWorkshopCategories();
  const [expanded, toggleExpanded] = useToggle(false);

  // TODO: refactor this
  const isLargeDisplay = useMediaQuery({query: '(min-height: 840px)'});

  useEffect(() => {
    toggleExpanded(false);
  }, [location, toggleExpanded]);

  useClickAway(ref, () => {
    toggleExpanded(false);
  });

  return (
    <StyledCategoriesList ref={ref}>
      {categories.slice(0, isLargeDisplay ? 4 : 3).map(category => (
        <CategorySidebarLink
          key={`category-sliced-link-${category.name}`}
          category={category}
        />
      ))}

      <AnimatePresence>
        {expanded && (
          <PopperSurface {...popperSurfaceAnimationProps}>
            {categories.map(category => (
              <CategorySidebarLink
                key={`category-more-link-${category.uuid}`}
                category={category}
              />
            ))}
            <div />
          </PopperSurface>
        )}
      </AnimatePresence>

      {categories.length > 5 && (
        <More onClick={toggleExpanded}>
          <ArrowRight size={20} weight="bold" />
          <span>Показать все</span>
        </More>
      )}
    </StyledCategoriesList>
  );
};
