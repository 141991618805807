import React from 'react';
import {HandTap, Palette, UserMinus, XCircle} from '@phosphor-icons/react';
import {
  BanPlayerEvent,
  ChangeTeamScoreEvent,
  ChangeTurnEvent,
  KickPlayerEvent,
  TeamRecord
} from '@opeq-dev/openquiz-machine';
import {Menu, MenuGroup, MenuItem} from '@openquiz/quiz-ui';
import {
  selectTurnTeamUuid,
  useMachineEvent,
  useMachineSelector
} from '@/features/machine';

import {ModalConfirm, useModalConfirm} from '@openquiz/quiz-modal';

interface SlotSingleMenuProps {
  team: TeamRecord;
  onClose?: () => void;
}

export const SlotSingleMenu = ({team, onClose = () => void 0}: SlotSingleMenuProps) => {
  const {event} = useMachineEvent();

  const turnTeamUuid = useMachineSelector(selectTurnTeamUuid);

  const confirmBan = useModalConfirm(props => (
    <ModalConfirm
      {...props}
      content={
        <>
          <p>Вы точно хотите забанить игрока?</p>
          <p>Игрок не сможет повторно присоединиться к игре.</p>
        </>
      }
      resolveLabel="Забанить"
    />
  ));

  const onClickChangeTurn = () => {
    event<ChangeTurnEvent>('changeTurn', {teamUuid: team.uuid});
  };

  const onClickChangeScore = () => {
    const score = prompt('Очки команды:', team.score.toString());
    if (score) {
      event<ChangeTeamScoreEvent>('changeTeamScore', {
        teamUuid: team.uuid,
        score: parseInt(score, 10)
      });
      onClose();
    }
  };

  const onClickKick = () => {
    event<KickPlayerEvent>('kickPlayer', {userUuid: team.leaderUuid});
    onClose();
  };

  const onClickBan = async () => {
    onClose();

    try {
      await confirmBan();
      event<BanPlayerEvent>('banPlayer', {userUuid: team.leaderUuid});
    } catch (err) {
      /* empty */
    }
  };

  return (
    <Menu>
      <MenuGroup>
        {turnTeamUuid !== team.uuid && (
          <MenuItem color="accent" icon={HandTap} onClick={onClickChangeTurn}>
            Передать ход
          </MenuItem>
        )}
        <MenuItem icon={Palette} onClick={onClickChangeScore}>
          Изменить очки
        </MenuItem>
      </MenuGroup>
      <MenuGroup>
        <MenuItem icon={UserMinus} color="danger" onClick={onClickKick}>
          Выгнать
        </MenuItem>
        <MenuItem icon={XCircle} color="danger" onClick={onClickBan}>
          Забанить
        </MenuItem>
      </MenuGroup>
    </Menu>
  );
};
