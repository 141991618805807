import {generatePath} from 'react-router-dom';
import {userProfilePath} from '../constants';

/**
 * This function generates a user path.
 * @param uuidOrUsername
 * @param discriminator
 * @returns
 */
export const generateUserPath = (uuidOrUsername: string, discriminator?: string) => {
  if (discriminator) {
    return `${generatePath(userProfilePath, {
      username: uuidOrUsername
    })}#${discriminator}`;
  }

  return generatePath(userProfilePath, {username: uuidOrUsername});
};
