import React, {ReactNode, createElement} from 'react';
import styled, {useTheme} from 'styled-components';
import {Warning, XCircle} from '@phosphor-icons/react';
import {PhosphorIcon} from '@openquiz/quiz-ui';

interface ValidationMessageProps {
  children?: ReactNode;
  label: ReactNode;
  level: 'danger' | 'attention';
}

const StyledValidationMessage = styled.div`
  ${p => p.theme.typography.body.sm};

  margin-bottom: 4px;

  :last-child {
    margin-bottom: 0;
  }

  ol {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    flex-shrink: 0;
  }
`;

const iconLevelMap = new Map<ValidationMessageProps['level'], PhosphorIcon>([
  ['danger', XCircle],
  ['attention', Warning]
]);

export const ValidationMessage = ({children, label, level}: ValidationMessageProps) => {
  const theme = useTheme();
  return (
    <StyledValidationMessage>
      <Label>
        {createElement(iconLevelMap.get(level) as unknown as PhosphorIcon, {
          color: theme.colors[level].text,
          size: 16,
          weight: 'bold'
        })}
        <span>{label}</span>
      </Label>
      {children}
    </StyledValidationMessage>
  );
};
