import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Action} from '@opeq-dev/openquiz-machine';
import {UserChip} from '@/features/machine-history';

interface UsersGridProps {
  history: Action[];
}

const StyledUsersGrid = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
`;

export const UsersGrid = ({history}: UsersGridProps) => {
  const userUuids: string[] = useMemo(
    () => [
      ...new Set(
        history
          .map(action => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return [action.payload?.userUuid, action.payload?.targetUserUuid].filter(
              Boolean
            );
          })
          .flat()
      )
    ],
    [history]
  );

  return (
    <StyledUsersGrid>
      {userUuids.map(uuid => (
        <UserChip key={`user-chip-${uuid}`} uuid={uuid} />
      ))}
    </StyledUsersGrid>
  );
};
