import React from 'react';
import styled from 'styled-components';
import {Avatar} from '@openquiz/quiz-ui';
import {FetchedUser} from '@/features/user';

interface UserChipProps {
  uuid: string;
}

const StyledUserChip = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const UserChip = ({uuid}: UserChipProps) => (
  <StyledUserChip>
    <FetchedUser uuid={uuid} fallback={<span>...</span>}>
      {user => (
        <>
          <Avatar user={user} size={32} />
          <span>{user.username}</span>
        </>
      )}
    </FetchedUser>
  </StyledUserChip>
);
