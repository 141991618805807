import React from 'react';
import {basicTryAnswerGuard} from '@opeq-dev/openquiz-machine';
import {
  HintRow,
  TextChip,
  selectCtxCurrentQuestion,
  selectPlayerTeam,
  selectTimer,
  useMachineGuard,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';

export const TryAnswerHint = () => {
  const self = useMachineSelf();
  const {guard} = useMachineGuard();

  const team = useMachineSelector(selectPlayerTeam(self.userUuid));
  const question = useMachineSelector(selectCtxCurrentQuestion);
  const playTimer = useMachineSelector(selectTimer('play'));

  if (!self || !team || !question) {
    return null;
  }

  if (!guard(basicTryAnswerGuard)({questionUuid: question.uuid})) {
    return (
      <TextChip>
        Осталось {Math.round((playTimer.duration - playTimer.current) / 1000)} сек.
      </TextChip>
    );
  }

  return (
    <HintRow
      type="basicTryAnswer"
      timer={playTimer}
      team={team}
      userUuid={self.userUuid}
      isSelf={true}
    />
  );

  // return (
  //   <FieldRow size="sm">
  //     <UserChip
  //       userUuid={self.userUuid}
  //       message=", попробуйте ответить"
  //       timer={playTimer}
  //       isSelf={true}
  //     />
  //     <RightClickTip />
  //   </FieldRow>
  // );
};
