import {getAnimationProps} from '@openquiz/quiz-ui';
import React from 'react';
import styled from 'styled-components';
import {AnimatePresence, motion} from 'framer-motion';
import {AchievementToast} from '@/features/achievement';
import {useAchievementsListener} from './useAchievementsListener';

const StyledAchievementsListener = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 0 96px 0;
  z-index: 100;
  pointer-events: none;
  overflow: hidden;
`;

const Radial = styled(motion.div)`
  background: radial-gradient(
    circle,
    ${p => p.theme.colors.accent.emphasis}50 5%,
    rgba(148, 187, 233, 0) 50%
  );
  position: absolute;
  left: 50%;
  top: -180px;
  margin-left: -300px;
  width: 600px;
  height: 320px;
  z-index: -1;
  pointer-events: none;
`;

const radialAnimationProps = getAnimationProps(
  {
    initial: {y: -8, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: 8, opacity: 0}
  },
  {duration: 0.2}
);

export const AchievementsListener = () => {
  const {stack, onClose} = useAchievementsListener();

  return (
    <StyledAchievementsListener>
      <AnimatePresence>
        {!!stack.length && <Radial {...radialAnimationProps} />}
        {stack.map(stat => (
          <AchievementToast
            key={`${stat.name}-${stat.userUuid}`}
            stat={stat}
            userUuid={stat.userUuid}
            onClose={onClose}
          />
        ))}
      </AnimatePresence>
    </StyledAchievementsListener>
  );
};
