import React from 'react';
import {
  AlreadyAnswerHint,
  AuctionQuestionSplash,
  BetHint,
  RenderSteps,
  Screen,
  ScreenContent,
  ScreenFooter,
  TopBar,
  useGameService
} from '@/features/machine';

export const AuctionQuestionScreen = () => {
  const {isMatch} = useGameService();

  return (
    <Screen>
      <div>
        {!isMatch([{game: {basicRound: {auctionQuestion: 'splash'}}}]) && <TopBar />}
      </div>

      <ScreenContent>
        {isMatch([{game: {basicRound: {auctionQuestion: 'splash'}}}]) && (
          <AuctionQuestionSplash />
        )}

        {isMatch([{game: {basicRound: {auctionQuestion: 'play'}}}]) && (
          <RenderSteps stage="play" />
        )}

        {isMatch([{game: {basicRound: {auctionQuestion: 'answer'}}}]) && (
          <RenderSteps stage="answer" />
        )}
      </ScreenContent>

      <ScreenFooter>
        {isMatch([{game: {basicRound: {auctionQuestion: 'bets'}}}]) && <BetHint />}
        {isMatch([{game: {basicRound: {auctionQuestion: 'play'}}}]) && (
          <AlreadyAnswerHint immediately={true} />
        )}
      </ScreenFooter>
    </Screen>
  );
};
