import React, {useMemo} from 'react';
import {HexColorInput, HexColorPicker} from 'react-colorful';
import styled, {css, useTheme} from 'styled-components';
import {colord} from 'colord';
import {Check, Palette} from '@phosphor-icons/react';
import {PopperMenu, getIsReadable} from '@openquiz/quiz-ui';

interface SwatchPickerProps {
  swatches: string[];
  value: string;
  disabled?: boolean;
  onChange: (color: string) => void;
}

const StyledSwatchPicker = styled.div<{$disabled: boolean}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  ${p =>
    p.$disabled &&
    css`
      pointer-events: none;
      filter: blur(4px);
    `}
`;

const Swatch = styled.div<{$color: string}>`
  background-color: ${p => p.$color};
  border: 1px solid ${p => colord(p.$color).darken(0.1).toHex()};
  color: transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: transform 80ms ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const CustomSwatch = styled(Swatch)`
  border-color: rgba(0, 0, 0, 0.2);
  color: ${p => p.theme.colors.text.default};
`;

const PickerSurface = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.floating};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
`;

const StyledHexColorPicker = styled(HexColorPicker)`
  display: flex;
`;

const StyledHexColorInput = styled(HexColorInput)`
  ${p => p.theme.typography.body.md};
  font-family: ui-monospace, 'Cascadia Mono', 'Segoe UI Mono', 'Ubuntu Mono',
    'Roboto Mono', Menlo, Monaco, Consolas, monospace;
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid transparent;
  color: ${p => p.theme.colors.text.default};
  text-align: center;
  border-radius: 0 0 8px 8px;
  display: flex;
  margin: -6px 0 0 0;
  padding: 14px 8px 8px 8px;
  width: 80px;
`;

export const SwatchPicker = ({
  swatches,
  value,
  disabled = false,
  onChange
}: SwatchPickerProps) => {
  const theme = useTheme();

  const isReadable = useMemo(
    () => getIsReadable(value, theme.colors.text.onEmphasis),
    [value, theme]
  );

  const isCustomColor = value && !swatches.includes(value);

  return (
    <StyledSwatchPicker $disabled={disabled}>
      {swatches.map(swatch => (
        <Swatch
          key={`color-swatch-${swatch}`}
          $color={swatch}
          onClick={() => onChange(swatch)}>
          {swatch === value && (
            <Check
              size={20}
              weight="bold"
              color={isReadable ? theme.colors.text.onEmphasis : '#000'}
            />
          )}
        </Swatch>
      ))}

      <PopperMenu
        menu={
          <PickerSurface>
            <StyledHexColorPicker color={value} onChange={onChange} />
            <StyledHexColorInput prefixed={true} color={value} onChange={onChange} />
          </PickerSurface>
        }>
        <CustomSwatch $color={isCustomColor ? value : theme.colors.canvas.default}>
          {isCustomColor ? (
            <Check
              size={20}
              weight="bold"
              color={isReadable ? theme.colors.text.onEmphasis : '#000'}
            />
          ) : (
            <Palette size={20} weight="bold" />
          )}
        </CustomSwatch>
      </PopperMenu>
    </StyledSwatchPicker>
  );
};
