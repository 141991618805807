import React, {AnchorHTMLAttributes, memo, useMemo} from 'react';
import {FormattedDate} from 'react-intl';
import styled from 'styled-components';
import uniqolor from 'uniqolor';
import {CalendarBlank} from '@phosphor-icons/react';
import {Card, CardEntry, FieldRow, Stat, Title, Tooltip} from '@openquiz/quiz-ui';
import {
  AuthorChip,
  DraftSticker,
  IllegalSticker,
  PackageFull,
  Rating,
  VerifiedSticker
} from '@/features/wshop-packages';

interface PackageCardProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  pkg: PackageFull;
}

const StyledPackageCard = styled(Card)`
  height: 420px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

  :hover {
    & [data-id='meta'] {
      opacity: 1;
      margin-top: 8px;
      height: 22px;
    }
  }
`;

const MarkArea = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
`;

const Author = styled.div`
  margin: -10px 0 0 0;
  display: flex;
`;

const Stats = styled.div`
  margin-top: 20px;
`;

export const PackageCard = memo(({pkg, ...rest}: PackageCardProps) => {
  const {color: defaultCoverColor} = useMemo(
    () => uniqolor(pkg.uuid.slice(0, 9), {saturation: [40, 50]}),
    [pkg.uuid]
  );

  const cover = useMemo(() => pkg.files[0]?.file, [pkg.files]);

  const isIllegal = useMemo(() => {
    return pkg.tags.some(t => t.tag.name === '18+');
  }, []);

  const isDraft = useMemo(
    () => pkg.versions.every(version => !version.published),
    [pkg.versions]
  );

  return (
    <StyledPackageCard
      {...rest}
      to={`/workshop/${pkg.uuid}`}
      cover={{picture: cover?.src, color: cover?.color ?? defaultCoverColor}}>
      <MarkArea>
        {pkg.isVerified && <VerifiedSticker />}
        {isIllegal && <IllegalSticker />}
        {isDraft && <DraftSticker />}
      </MarkArea>

      <CardEntry>
        <Title size="sm">{pkg.name}</Title>
        <Author>
          <AuthorChip user={pkg.user} />
        </Author>
      </CardEntry>

      <Stats>
        <FieldRow>
          <Rating
            rating={{value: pkg.rating, count: pkg._count.ratings}}
            layout="value"
          />
          <Tooltip message="Дата создания">
            <Stat icon={CalendarBlank}>
              <FormattedDate value={pkg.createdAt} dateStyle="short" />
            </Stat>
          </Tooltip>
        </FieldRow>
      </Stats>
    </StyledPackageCard>
  );
});
