import {Control, UseFormWatch, useFieldArray} from 'react-hook-form';

interface SseControlledFieldArrayParams {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  control: Control<any>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  watch: UseFormWatch<any>;
}

/**
 *
 * @param name
 * @param control
 * @param watch
 */
export const useControlledFieldArray = (
  name: string,
  {control, watch}: SseControlledFieldArrayParams
) => {
  const fieldArray = useFieldArray({
    control,
    name
  });

  return {
    ...fieldArray,
    fields: fieldArray.fields.map((field, index) => {
      return {
        ...field,
        ...watch(name)[index]
      };
    })
  };
};
