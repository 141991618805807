import React from 'react';
import styled, {css} from 'styled-components';
import {ActionMessage} from '../ActionMessage';
import {FormattedAction} from '../../types';
import {nanoid} from 'nanoid';

interface StackProps {
  actions: FormattedAction[];
  onOverlay: boolean;
}

const StyledStack = styled.div<{$onOverlay: boolean; $count: number}>`
  position: relative;
  margin-bottom: 12px;

  :last-child {
    margin-bottom: 0;
  }

  ${p =>
    p.$count > 1 &&
    css`
      background-color: ${p.$onOverlay
        ? p.theme.colors.canvas.default
        : p.theme.colors.canvas.overlay};
      box-shadow: ${p => p.theme.shadow.md};
      border-radius: 16px;
      padding: 12px;
      margin-left: -12px;
      margin-right: -12px;

      &::after {
        background-color: ${p => p.theme.colors.border.subtle};
        position: absolute;
        left: 27px;
        top: 16px;
        bottom: 16px;
        width: 1px;
        content: '';
        z-index: 0;
      }
    `}
`;

export const Stack = ({actions, onOverlay}: StackProps) => (
  <StyledStack $onOverlay={onOverlay} $count={actions.length}>
    {actions.map((action, index) => (
      <ActionMessage key={`action-message-${nanoid()}`} {...action} index={index} />
    ))}
  </StyledStack>
);
