import {useCallback, useEffect, useState} from 'react';
import {AchievementStat} from '@/features/achievement';
import {useGameService} from '@/features/machine';

export const useAchievementsListener = () => {
  const {socket} = useGameService();
  const [stack, setStack] = useState<AchievementStat[]>([]);

  const push = useCallback(
    (stat: AchievementStat) => {
      setStack(stack => [...stack, stat]);
      setTimeout(() => {
        onClose(stat);
      }, 5500);
    },
    [setStack]
  );

  const onClose = useCallback(
    (stat: AchievementStat) => {
      setStack(stack => {
        return stack.filter(s => !(s.name === stat.name && s.userUuid === stat.userUuid));
      });
    },
    [setStack]
  );

  useEffect(() => {
    if (!socket) return;
    socket.on('achievements', (data: AchievementStat[]) => {
      data.forEach((stat, index) => {
        setTimeout(() => {
          push(stat);
        }, index * 500);
      });
    });
  }, [socket, push]);

  return {stack, onClose};
};
