import React from 'react';
import styled from 'styled-components';
import {OpenQuizIcon} from '../../../icons';

const StyledLogotype = styled.div`
  color: ${p => p.theme.colors.text.default};
  display: inline-flex;
  gap: 8px;
  user-select: none;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  span:last-child {
    color: ${p => p.theme.colors.accent.emphasis};
  }
`;

export const Logotype = () => (
  <StyledLogotype>
    <OpenQuizIcon size={32} />
    <Label>
      <span>Open</span>
      <span>Quiz</span>
    </Label>
  </StyledLogotype>
);
