import React, {useMemo} from 'react';
import {AnimatePresence} from 'framer-motion';
import {
  EndScreen,
  Layout,
  LayoutProps,
  LeftSidebar,
  LobbyScreen,
  PlayScreen,
  RightSidebar,
  useGameService
} from '@/features/machine';

type RootScreenKey = 'lobby' | 'play' | 'end';

const layoutConfigMap = new Map<RootScreenKey, Omit<LayoutProps, 'children'>>([
  ['lobby', {}],
  ['play', {sidebar: <LeftSidebar />, aside: <RightSidebar />}],
  ['end', {}]
]);

const useRootScreen = () => {
  const {isMatch} = useGameService();

  const cond: Record<RootScreenKey, boolean> = {
    lobby: isMatch([{game: 'idle'}]),
    play: isMatch([{game: 'basicRound'}, {game: 'finalRound'}]),
    end: isMatch([{game: 'end'}])
  };

  return useMemo(
    () =>
      Object.entries(cond)
        .map(([key, value]) => value && key)
        .filter(Boolean)[0] as RootScreenKey,
    [cond]
  );
};

export const Router = () => {
  const rootScreen = useRootScreen();

  return (
    <AnimatePresence>
      <Layout {...(layoutConfigMap.get(rootScreen) ?? {})}>
        {rootScreen === 'lobby' && <LobbyScreen />}
        {rootScreen === 'play' && <PlayScreen />}
        {rootScreen === 'end' && <EndScreen />}
      </Layout>
    </AnimatePresence>
  );
};
