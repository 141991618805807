import {useQuery} from '@tanstack/react-query';
import {getPrivateAxios} from '@/libs';

interface EvaluatedRating {
  value: number;
  createdAt: string;
}

/**
 * Fetch evaluated rating.
 * @param uuid - Package uuid.
 */
const fetchEvaluatedRating = async (uuid: string) => {
  const {data} = await getPrivateAxios().get<EvaluatedRating | undefined>(
    `/workshop/packages/${uuid}/evaluated-rating`
  );
  return data;
};

/**
 *
 * @param uuid
 */
export const usePackageEvaluatedRating = (uuid: string) =>
  useQuery({
    queryKey: ['packages', 'evaluated-rating', {uuid}],
    queryFn: () => fetchEvaluatedRating(uuid)
  });
