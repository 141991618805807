import React, {useEffect, useRef} from 'react';
import {useSelector} from '@xstate/react';
import SimpleBar from 'simplebar-react';
import styled from 'styled-components';
import {Modal, Title} from '@openquiz/quiz-ui';
import {useGameService} from '@/features/machine';
import {HistoryTimeline} from '@/features/machine-history';
import {PackageFull} from '@/features/wshop-packages';
import 'simplebar-react/dist/simplebar.min.css';

interface HistoryModalProps {
  onClose: () => void;
}

const Entry = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .simplebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const HistoryModal = ({onClose}: HistoryModalProps) => {
  const simpleBarRef = useRef<HTMLDivElement>(null);
  const {service} = useGameService();
  const history = useSelector(service, state => state.context.history);
  const pkg = useSelector(service, state => state.context.pkg);

  useEffect(() => {
    if (!simpleBarRef.current) return;
    simpleBarRef.current.scrollTo({
      top: simpleBarRef.current.scrollHeight + 30,
      behavior: 'smooth'
    });
  }, [simpleBarRef, history]);

  return (
    <Modal size="lg" closeOutside={true} onClose={onClose}>
      <Title>История игры</Title>

      <Entry>
        <SimpleBar
          scrollableNodeProps={{ref: simpleBarRef}}
          autoHide={true}
          style={{width: '100%', maxHeight: '75vh'}}>
          <HistoryTimeline pkg={pkg as PackageFull} history={history} onOverlay={true} />
        </SimpleBar>
      </Entry>
    </Modal>
  );
};
