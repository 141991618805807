import React from 'react';
import {Link} from 'react-router-dom';
import Linkify from 'react-linkify';
import styled from 'styled-components';
import {ArrowSquareOut} from '@phosphor-icons/react';
import {User} from '@opeq-dev/openquiz-schema';
import {Button, getLineClampCss} from '@openquiz/quiz-ui';
import {UserID, generateUserPath} from '@/features/user';

interface UserFloatingIDProps {
  user: User;
}

const StyledUserFloatingID = styled.div`
  background-color: ${p => p.theme.colors.canvas.default};
  border-radius: 16px;
  box-shadow: ${p => p.theme.shadow.floating};
  width: 300px;
`;

const Entry = styled.div`
  margin-top: -32px;
  padding: 24px;
`;

const Username = styled.div`
  ${p => p.theme.typography.label.lg};
`;

const About = styled.div`
  ${p => p.theme.typography.body.md};
  ${getLineClampCss(3)}
  color: ${p => p.theme.colors.text.muted};
  margin-top: 8px;
  white-space: pre-wrap;
`;

const ProfileLink = styled.div`
  margin: 16px 0 -8px 0;
`;

export const UserFloatingID = ({user}: UserFloatingIDProps) => (
  <StyledUserFloatingID>
    <UserID user={user} size="xs" />
    <Entry>
      <Username>{user.username}</Username>

      {user.profile.about && (
        <About>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a key={key} href={decoratedHref} target="_blank" rel="noreferrer">
                {decoratedText}
              </a>
            )}>
            {user.profile.about}
          </Linkify>
        </About>
      )}

      <ProfileLink>
        <Link to={generateUserPath(user.username, user.discriminator)}>
          <Button variant="link">
            <ArrowSquareOut size={16} weight="bold" />
            <span>Перейти в профиль</span>
          </Button>
        </Link>
      </ProfileLink>
    </Entry>
  </StyledUserFloatingID>
);
