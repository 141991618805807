import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface TableProps {
  children: ReactNode;
  label?: ReactNode;
}

const StyledTable = styled.div`
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  ${p => p.theme.typography.caption.sm};
  color: ${p => p.theme.colors.text.subtle};
  margin-bottom: 8px;
`;

const Rows = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  border-radius: 16px;
  position: relative;
`;

export const Table = ({children, label}: TableProps) => (
  <StyledTable>
    {label && <Label>{label}</Label>}
    <Rows>{children}</Rows>
  </StyledTable>
);
