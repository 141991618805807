import {State} from 'xstate';
import {Ctx, getPkgVersion} from '@opeq-dev/openquiz-machine';
import {selectCtxCurrentQuestion} from '@/features/machine';

/**
 *
 * @param state
 * @returns
 */
export const selectCtxCurrentTheme = (state: State<Ctx>) => {
  const question = selectCtxCurrentQuestion(state);
  const themes = getPkgVersion(state.context).themes;
  return themes.find(theme => theme.uuid === question?.themeUuid);
};
