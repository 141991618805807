import {AnimationProps, Transition, Variant} from 'framer-motion';

type TypedVariants = {
  [key in 'initial' | 'animate' | 'exit']: Variant;
};

/**
 * This function will return props for the animated element.
 * @param variants
 * @param transition
 */
export const getAnimationProps = (
  variants: TypedVariants,
  transition: Transition = {duration: 0.08, type: 'tween'}
): AnimationProps => ({
  variants,
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
  transition
});
