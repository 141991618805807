import {useSelector} from '@xstate/react';
import {useCurrentUserQuery} from '@/features/user';
import {useGameService} from './useGameService';

/**
 *
 */
export const useMachineUser = () => {
  const {service} = useGameService();
  const {data: currentUser} = useCurrentUserQuery();
  return useSelector(service, state => {
    return state.context.users.find(user => user.uuid === currentUser?.uuid);
  });
};
