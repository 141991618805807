import React, {useCallback, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';
import {ArrowRight, X} from '@phosphor-icons/react';
import {Modal, ModalProps} from '@openquiz/quiz-modal';
import {Button, ButtonIcon} from '@openquiz/quiz-ui';
import {Glow, Step} from '../../components';
import {Scenario} from '../../types/scenario';

interface ScenarioPlayProps extends ModalProps {
  scenario: Scenario;
}

const Close = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;
`;

const Entry = styled.div`
  margin-bottom: 32px;
`;

export const ScenarioPlay = ({scenario, onCancel}: ScenarioPlayProps) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const currentStep = scenario.steps[currentStepIndex];

  const onClickNext = useCallback(() => {
    setCurrentStepIndex(i => i + 1);
  }, [setCurrentStepIndex]);

  const onFinishStep = () => {
    window.localStorage.setItem(scenario.id, new Date().getTime().toString());
    onCancel();
  };

  return (
    <Modal onCancel={onFinishStep}>
      <Glow />
      <Close>
        <ButtonIcon icon={X} onClick={onFinishStep} />
      </Close>

      <AnimatePresence initial={true} mode="wait">
        <motion.div key={`step-animation-${currentStepIndex}`}>
          {currentStep.animation}
        </motion.div>
      </AnimatePresence>

      <Entry>
        <AnimatePresence initial={false} mode="sync">
          <Step key={`step-step-${currentStepIndex}`}>{currentStep.text}</Step>
        </AnimatePresence>
      </Entry>

      {currentStepIndex === scenario.steps.length - 1 ? (
        <Button size="lg" onClick={onFinishStep}>
          <ArrowRight size={20} weight="bold" />
          <span>Начать играть!</span>
        </Button>
      ) : (
        <Button size="lg" onClick={onClickNext}>
          <ArrowRight size={20} weight="bold" />
          <span>Продолжить</span>
        </Button>
      )}
    </Modal>
  );
};
