import React from 'react';
import {Fire, Parachute, Play, Users} from '@phosphor-icons/react';
import {
  Button,
  DiscordLogotype,
  Sidebar,
  SidebarFooter,
  SidebarGroup,
  SidebarLink,
  SidebarLogotype,
  useModal as useModalOld
} from '@openquiz/quiz-ui';
import {useModal} from '@openquiz/quiz-modal';
import {CreateGameForm, GamesList} from '@/features/game';
import {UserBar, useCurrentUserQuery} from '@/features/user';
import {CategoriesList} from '@/features/wshop-categories';
import {SearchBar, getSearchString} from '@/features/wshop-search';
import {SignModal, useAuthGuardCallback} from '@/features/auth';

export const WorkshopSidebar = () => {
  const {data: currentUser} = useCurrentUserQuery();

  const openSignModal = useModal(props => <SignModal {...props} />);

  const [openCreateModal, closeCreateModal] = useModalOld(
    () => <CreateGameForm onClose={closeCreateModal} />,
    []
  );

  const onClickCreate = useAuthGuardCallback(() => {
    openCreateModal();
  });

  return (
    <Sidebar>
      <SidebarGroup>
        <SidebarLogotype />
      </SidebarGroup>

      <SearchBar />

      <SidebarGroup>
        {currentUser && <GamesList />}
        <Button onClick={onClickCreate}>
          <Play size={16} weight="bold" />
          <span>Создать игру</span>
        </Button>
      </SidebarGroup>

      <SidebarGroup>
        <SidebarLink
          icon={Fire}
          to={`/workshop/search?data=${getSearchString({sort: 'rating'})}`}>
          Лучшие паки
        </SidebarLink>
        <SidebarLink
          icon={Parachute}
          to={`/workshop/search?data=${getSearchString({sort: 'created_at'})}`}>
          Новые паки
        </SidebarLink>
        <SidebarLink icon={Users} to="/workshop/authors">
          Авторы
        </SidebarLink>
      </SidebarGroup>

      <SidebarGroup label="Категории">
        <CategoriesList />
      </SidebarGroup>

      <SidebarFooter>
        <SidebarGroup>
          <SidebarLink icon={DiscordLogotype} to="https://discord.gg/k56easeP5H">
            Discord сервер
          </SidebarLink>
        </SidebarGroup>
        <SidebarGroup>
          <UserBar onClickSign={openSignModal} />
        </SidebarGroup>
      </SidebarFooter>
    </Sidebar>
  );
};
