import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {getThemeQueryKey} from '@/features/wshop-editor';
import {fetchThemeDelete} from './fetchThemeDelete';

/**
 *
 */
export const useThemeDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchThemeDelete,
    onMutate: variables => {
      if (!variables) return;
      queryClient.removeQueries({queryKey: [getThemeQueryKey({uuid: variables.uuid})]});
      queryClient.setQueriesData<PackageTheme[]>(
        {queryKey: ['editor', 'themes']},
        (themes = []) => themes.filter(theme => theme.uuid !== variables.uuid)
      );
    }
  });
};
