import {getPrivateAxios} from '@/libs';
import {PackageFull} from './fetchPackages';
import {useQuery} from '@tanstack/react-query';

/**
 *
 * @param uuid
 * @returns
 */
export const fetchPackage = async (uuid: string) => {
  const {data} = await getPrivateAxios().get<PackageFull>(`/workshop/packages/${uuid}`);
  return data;
};

/**
 *
 * @param uuid
 */
export const usePackageQuery = (uuid: string) =>
  useQuery({
    queryKey: ['packages', {uuid}],
    queryFn: () => fetchPackage(uuid)
  });
