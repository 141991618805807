import React, {useMemo} from 'react';
import styled from 'styled-components';
import {getRandomNumber} from '../../../libs';

export interface PlaceholderLineProps {
  width?: number | string;
  height?: [height: number, margin: number];
}

const StyledPlaceholderLine = styled.div`
  background-color: ${p => p.theme.colors.neutral.muted};
  border-radius: 2px;
  margin-bottom: 1px;
`;

export const PlaceholderLine = ({width, height = [16, 4]}: PlaceholderLineProps) => {
  const computedWidth = useMemo(() => width ?? `${getRandomNumber(20, 100)}%`, [width]);
  return (
    <StyledPlaceholderLine
      style={{
        width: computedWidth,
        height: height[0],
        marginBottom: height[1]
      }}
    />
  );
};
