import {useQuery} from '@tanstack/react-query';
import {Ctx} from '@opeq-dev/openquiz-machine';
import {Package, Game as SchemaGame} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export type Game = SchemaGame<Pick<Ctx, 'users'>>;

export type UserGame = Game & {package: Pick<Package, 'name'>};

export interface FindUserGamesFilter {
  active?: boolean;
  archived?: boolean;
}

/**
 *
 * @returns
 * @param filter
 */
const fetchAllGames = async (filter: FindUserGamesFilter) => {
  const {data} = await getPrivateAxios().get<UserGame[]>('/game', {
    params: filter
  });
  return data;
};

/**
 * This query loads all games.
 * @returns
 * @param filter
 */
export const useAllGamesQuery = (filter: FindUserGamesFilter) => {
  return useQuery({
    queryKey: ['games'],
    queryFn: () => fetchAllGames(filter),
    refetchInterval: 10 * 1000
  });
};
