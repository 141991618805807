import React from 'react';
import styled from 'styled-components';
import {File} from '@opeq-dev/openquiz-schema';

interface CoverProps {
  file: File | undefined;
}

const StyledCover = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 16px;
  width: 100%;
  height: 420px;
  text-align: center;
  display: flex;
  overflow: hidden;
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};

  &::after,
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.1) 50%);
  }

  &::before {
    box-shadow:
      inset 0 1px 1px 1px rgba(255, 255, 255, 0.1),
      inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    opacity: 0.7;
    z-index: 1;
  }
`;

export const Cover = ({file}: CoverProps) => (
  <StyledCover style={file ? {backgroundImage: `url(${file.src})`} : {}} />
);
