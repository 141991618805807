import React, {ReactNode} from 'react';
import {motion} from 'framer-motion';
import styled from 'styled-components';
import {getAnimationProps} from '@openquiz/quiz-ui';

interface ScreenProps {
  children: ReactNode;
}

const StyledScreen = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const screenMotionProps = getAnimationProps({
  initial: {opacity: 0},
  animate: {opacity: 1},
  exit: {opacity: 0}
});

export const Screen = ({children}: ScreenProps) => (
  <StyledScreen {...screenMotionProps}>{children}</StyledScreen>
);
