import React, {cloneElement} from 'react';
import styled, {keyframes} from 'styled-components';
import {motion} from 'framer-motion';
import {getAnimationProps} from '@openquiz/quiz-ui';

interface SolidIndicator {
  type: 'solid';
  payload: {color: string; progress: number};
}

interface DashIndicator {
  type: 'dash';
  payload: {color: string};
}

interface FlashIndicator {
  type: 'flash';
  payload: {color: string};
}

export type Indicator = SolidIndicator | DashIndicator | FlashIndicator;

interface SlotIndicatorProps {
  indicator: Indicator;
}

const dashAnimation = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 2;
  }
`;

const flashAnimation = keyframes`
  50% {
    opacity: 0;
  }
`;

const StyledSlotIndicator = styled(motion.div)`
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 12px;
  z-index: 11;
  overflow: hidden;
  display: flex;
`;

const Rect = styled(motion.rect)<{$color: string}>`
  stroke: ${p => p.$color};
  stroke-dasharray: 100;
  stroke-width: 6px;
  stroke-linecap: round;
  fill: none;
`;

const RectDash = styled(Rect)`
  stroke-dasharray: 1 1;
  animation: ${dashAnimation} linear 500ms infinite;
`;

const RectFlash = styled(Rect)`
  animation: ${flashAnimation} linear 500ms 3;
`;

const getIndicatorElement = (indicator: Indicator) => {
  switch (indicator.type) {
    case 'solid':
      return (
        <Rect
          $color={indicator.payload.color}
          animate={{strokeDashoffset: indicator.payload.progress * 1.01}}
          transition={{duration: 0.5, ease: 'linear'}}
        />
      );
    case 'dash':
      return <RectDash $color={indicator.payload.color} />;
    case 'flash':
      return <RectFlash $color={indicator.payload.color} />;
  }
};

const slotIndicatorMotionProp = getAnimationProps(
  {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0}
  },
  {duration: 0.2}
);

export const SlotIndicator = ({indicator}: SlotIndicatorProps) => {
  const rectProps = {
    width: '100%',
    height: '100%',
    pathLength: 100,
    rx: 12,
    ry: 12
  };
  return (
    <StyledSlotIndicator {...slotIndicatorMotionProp}>
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        {cloneElement(getIndicatorElement(indicator), rectProps)}
      </svg>
    </StyledSlotIndicator>
  );
};
