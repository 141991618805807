import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {getThemeQueryKey} from '@/features/wshop-editor';
import {fetchThemeUpdate} from './fetchThemeUpdate';

/**
 *
 */
export const useThemeUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchThemeUpdate,
    onMutate: variables => {
      if (!variables) return;

      queryClient.setQueryData<PackageTheme>(
        getThemeQueryKey({uuid: variables.data.uuid}),
        theme => ({...theme, ...variables.data})
      );

      queryClient.setQueriesData<PackageTheme[]>(
        {queryKey: ['editor', 'themes']},
        (themes = []) =>
          themes.map(theme => {
            if (theme.uuid === variables.data.uuid) return {...theme, ...variables.data};
            return theme;
          })
      );
    }
  });
};
