import React, {useMemo} from 'react';
import {useGameService} from '@/features/machine';
import {BasicRoundSplash} from '@/features/machine/components/@splash-screens';
import {ChooseScreen} from './ChooseScreen';
import {AuctionQuestionScreen} from './AuctionQuestionScreen';
import {BasicQuestionScreen} from './BasicQuestionScreen';
import {NoRiskQuestionScreen} from './NoRiskQuestionScreen';
import {SecretQuestionScreen} from './SecretQuestionScreen';
import {VariantsQuestionScreen} from './VariantsQuestionScreen';
import {VariantsOneByOneQuestionScreen} from './VariantsOneByOneQuestionScreen';

export const BasicRoundScreen = () => {
  const {isMatch} = useGameService();

  const screens = useMemo(
    () => ({
      splash: isMatch([{game: {basicRound: 'idle'}}, {game: {basicRound: 'splash'}}]),
      choose: isMatch([{game: {basicRound: 'choose'}}]),
      basicQuestion: isMatch([{game: {basicRound: 'basicQuestion'}}]),
      noRiskQuestion: isMatch([{game: {basicRound: 'noRiskQuestion'}}]),
      secretQuestion: isMatch([{game: {basicRound: 'secretQuestion'}}]),
      variantsQuestion: isMatch([{game: {basicRound: 'variantsQuestion'}}]),
      variantsOneByOneQuestion: isMatch([
        {game: {basicRound: 'variantsOneByOneQuestion'}}
      ]),
      auctionQuestion: isMatch([{game: {basicRound: 'auctionQuestion'}}])
    }),
    [isMatch]
  );

  return (
    <>
      {screens.splash && <BasicRoundSplash />}
      {screens.choose && <ChooseScreen />}
      {screens.basicQuestion && <BasicQuestionScreen />}
      {screens.noRiskQuestion && <NoRiskQuestionScreen />}
      {screens.secretQuestion && <SecretQuestionScreen />}
      {screens.variantsQuestion && <VariantsQuestionScreen />}
      {screens.variantsOneByOneQuestion && <VariantsOneByOneQuestionScreen />}
      {screens.auctionQuestion && <AuctionQuestionScreen />}
    </>
  );
};
