import {File as FileType} from '@opeq-dev/openquiz-schema';
import {getPercentFrom, getPrivateAxios} from '@/libs';

interface Options {
  folder: 'user/avatars' | 'user/banners' | 'workshop/covers' | 'workshop/media';
  stepUuid?: string;
}

/**
 * This function is used to upload a file to the server.
 * @param file
 * @param options
 * @param onProgress
 * @returns
 */
export const fetchUploadFile = async (
  file: File,
  options: Options,
  onProgress?: (value: number) => void
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder', options.folder);
  if (options.stepUuid) {
    formData.append('stepUuid', options.stepUuid);
  }

  const {data} = await getPrivateAxios().post<FileType>('/file/upload', formData, {
    headers: {'Content-Type': 'multipart/form-data'},
    onUploadProgress: e => {
      if (onProgress) {
        onProgress(getPercentFrom(e.loaded, e.total ?? 0));
      }
    }
  });

  return data;
};
