import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {CheckCircle, NumberCircleOne, NumberCircleTwo} from '@phosphor-icons/react';
import {Button, Callout, FieldGroup} from '@openquiz/quiz-ui';
import {fetchDiscordConnectLink} from '@/features/auth';

interface JoinDiscordCalloutProps {
  hasConnectedDiscord: boolean;
  children: ReactNode;
}

const StyledConnectDiscordButton = styled.div``;

export const JoinDiscordCallout = ({
  hasConnectedDiscord,
  children
}: JoinDiscordCalloutProps) => {
  const onDiscordConnect = async () => {
    const win = window.open(
      ' ',
      '_blank',
      'toolbar=0,location=0,menubar=0,width=600,height=800'
    );

    const {data} = await fetchDiscordConnectLink();

    if (win) {
      win.location.href = data.url;
    }
  };

  return (
    <StyledConnectDiscordButton>
      <Callout status="information" size="sm" limited={true}>
        <FieldGroup size="sm">{children}</FieldGroup>

        <FieldGroup size="sm">
          <Button size="sm" disabled={hasConnectedDiscord} onClick={onDiscordConnect}>
            {hasConnectedDiscord ? (
              <CheckCircle size={20} weight="bold" />
            ) : (
              <NumberCircleOne size={20} weight="bold" />
            )}

            <span>Привязать Discord аккаунт</span>
          </Button>
        </FieldGroup>

        <FieldGroup size="sm">
          <a
            href="https://discord.gg/k56easeP5H"
            target="_blank"
            rel="noreferrer"
            style={{pointerEvents: hasConnectedDiscord ? 'auto' : 'none'}}>
            <Button size="sm" disabled={!hasConnectedDiscord}>
              <NumberCircleTwo size={20} weight="bold" />
              <span>Присоединиться в Discord </span>
            </Button>
          </a>
        </FieldGroup>
      </Callout>
    </StyledConnectDiscordButton>
  );
};
