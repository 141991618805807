import React, {ChangeEvent} from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {Plus} from '@phosphor-icons/react';
import {PackageQuestion, QuestionType} from '@opeq-dev/openquiz-schema';
import {ModalConfirm, useModalConfirm} from '@openquiz/quiz-modal';
import {
  Button,
  FieldGroup,
  FieldRow,
  FormSection,
  Input,
  Select,
  TipCircle,
  ErrorMessage as UiErrorMessage
} from '@openquiz/quiz-ui';
import {questionTypeLabelMap} from '@/features/machine';
import {
  QuestionFormDto,
  StepForm,
  StepSection,
  VariantsHelpCallout,
  getDefaultStep
} from '@/features/wshop-editor';
import {useControlledFieldArray} from '@/hooks';

interface QuestionFormProps {
  question?: PackageQuestion;
  nextPosition?: number;
  nextPrice?: number;
  onSubmit: (data: QuestionFormDto) => Promise<void>;
  onDelete?: () => void;
}

export const QuestionForm = ({
  question,
  nextPosition,
  nextPrice,
  onSubmit,
  onDelete
}: QuestionFormProps) => {
  const confirmChangeType = useModalConfirm(props => (
    <ModalConfirm
      {...props}
      content={<>При смене типа вопроса, есть вероятность потерять данные в шагах.</>}
      resolveLabel="Сменить тип"
    />
  ));

  const {
    formState: {isSubmitting, errors},
    handleSubmit,
    control,
    register,
    watch,
    setValue
  } = useForm<QuestionFormDto>({
    defaultValues: {
      type: 'BASIC',
      price: nextPrice ?? 1,
      position: nextPosition ?? -1,
      steps: [
        getDefaultStep({position: 0, isAnswer: false}),
        getDefaultStep({position: 0, isAnswer: true})
      ],
      ...question
    }
  });

  const {fields, insert, replace, remove} = useControlledFieldArray('steps', {
    control,
    watch
  });

  const {type} = watch();
  const isVariantsType = type.includes('VARIANTS');

  /**
   * Функция, которая предупредит, если есть вероятность потерять данные.
   * TODO: move to hook
   * @param e
   */
  const onChangeQuestionType = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!type.includes('VARIANTS') && e.target.value.includes('VARIANTS')) {
      const {steps} = watch();
      const hasData =
        steps.filter(s => !s.isAnswer).find(s => s.variants.filter(Boolean).length) ||
        steps.filter(s => !s.isAnswer).length > 1;
      if (hasData) {
        return confirmChangeType()
          .then(() => {
            setValue('type', e.target.value as QuestionType);
            const clearSteps = steps.map(s => ({...s, variants: []}));
            replace([
              ...clearSteps.filter(s => !s.isAnswer).slice(0, 1),
              ...clearSteps.filter(s => s.isAnswer)
            ]);
          })
          .catch(() => {
            setValue('type', type);
          });
      }
    }

    if (type.includes('VARIANTS') && !e.target.value.includes('VARIANTS')) {
      const {steps} = watch();
      const hasData = steps
        .filter(s => !s.isAnswer)
        .find(s => s.variants.filter(Boolean).length);
      if (hasData) {
        return confirmChangeType()
          .then(() => {
            setValue('type', e.target.value as QuestionType);
            const clearSteps = steps.map(s => ({...s, variants: []}));
            replace([
              ...clearSteps.filter(s => !s.isAnswer).slice(0, 1),
              ...clearSteps.filter(s => s.isAnswer)
            ]);
          })
          .catch(() => {
            setValue('type', type);
          });
      }
    }

    setValue('type', e.target.value as QuestionType);
  };

  const onClickAddStep = () => {
    insert(
      fields.filter(s => !s.isAnswer).length,
      getDefaultStep({
        position: Math.max(...fields.map(field => field.position)) + 1,
        isAnswer: false
      })
    );
  };

  const onClickDeleteStep = (uuid: string) => {
    remove(fields.findIndex(s => s.uuid === uuid));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSection label="Информация">
        <FieldGroup label="Тип">
          <FieldGroup size="sm">
            <Select
              {...register('type', {required: true})}
              onChange={onChangeQuestionType}>
              {Array.from(questionTypeLabelMap).map(([type, label]) => (
                <option key={`question-${type}-${type}`} value={type}>
                  {label}
                </option>
              ))}
            </Select>
          </FieldGroup>

          {isVariantsType && (
            <FieldGroup size="sm">
              <VariantsHelpCallout type={type} />
            </FieldGroup>
          )}
        </FieldGroup>

        <FieldGroup label="Стоимость">
          <Input
            {...register('price', {
              required: 'Обязательное поле',
              min: {value: 0, message: 'Минимальное значение 0.'},
              max: {value: 10000, message: 'Максимальное значение 10000.'}
            })}
            min={0}
            max={10000}
            type="number"
            style={{width: 120}}
          />
          <ErrorMessage
            errors={errors}
            name="price"
            render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>
      </FormSection>

      <FormSection label="Вопрос">
        <FieldGroup size="lg">
          {fields.map((field, index) => {
            if (field.isAnswer) return null;
            return (
              <StepSection
                key={`step-${field.id}`}
                index={field.position}
                count={fields.filter(f => !f.isAnswer).length}
                onDelete={() => onClickDeleteStep(field.uuid)}>
                <StepForm
                  control={control}
                  register={register}
                  setValue={setValue}
                  index={index}
                />
              </StepSection>
            );
          })}
        </FieldGroup>

        {!isVariantsType && fields.filter(field => !field.isAnswer).length < 3 && (
          <FieldGroup>
            <Button variant="link" onClick={onClickAddStep}>
              <Plus size={20} weight="bold" />
              <span>Добавить шаг</span>
            </Button>
          </FieldGroup>
        )}
      </FormSection>

      <FormSection label="Ответ">
        {fields.map((field, index) => {
          if (!field.isAnswer) return null;
          return (
            <StepForm
              key={`step-${field.id}`}
              control={control}
              register={register}
              setValue={setValue}
              index={index}
            />
          );
        })}
      </FormSection>

      <FormSection label="Дополнительно">
        <FieldGroup
          label={
            <FieldRow size="xs">
              <span>Источник</span>
              <TipCircle
                message={
                  <>
                    <p>Можно указать источник, откуда вы взяли этот вопрос.</p>
                    <p>
                      Если это ваш вопрос или не хотите указывать источник, оставьте поле
                      пустым.
                    </p>
                  </>
                }
                size="lg"
              />
            </FieldRow>
          }>
          <Input
            {...register('source', {
              maxLength: {value: 64, message: 'Максимальная длинна 64 символа.'}
            })}
          />
          <ErrorMessage
            errors={errors}
            name="source"
            render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>
      </FormSection>

      <FormSection>
        <FieldRow>
          <Button type="submit" fetching={isSubmitting}>
            {question ? 'Сохранить' : 'Создать'}
          </Button>
          {question && onDelete && (
            <Button variant="danger" onClick={onDelete}>
              Удалить
            </Button>
          )}
        </FieldRow>
      </FormSection>
    </form>
  );
};
