import {useQuery} from '@tanstack/react-query';
import {contestQueryKeys} from '@/features/contest';

/**
 *
 * @param uuid
 */
export function useContestDetail(uuid: string) {
  return useQuery(contestQueryKeys.detail(uuid));
}
