import React from 'react';
import styled, {css} from 'styled-components';
import {PackageTheme} from '@opeq-dev/openquiz-schema';

interface BanThemeRowProps {
  theme: PackageTheme;
  isBanned: boolean;
  onClick: () => void;
}

const StyledBanThemeRow = styled.div<{$isBanned: boolean}>`
  ${p => p.theme.typography.label.md};
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.sm};
  margin-bottom: 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 5vh;
  cursor: pointer;
  user-select: none;
  transition:
    background-color ease-in-out 80ms,
    color ease-in-out 80ms,
    transform ease-in-out 80ms,
    box-shadow ease-in-out 80ms;

  ${p =>
    p.$isBanned &&
    css`
      background-color: ${p.theme.colors.canvas.default};
      border: 1px solid ${p.theme.colors.border.muted};
      color: ${p.theme.colors.border.subtle};
      box-shadow: none;
      pointer-events: none;
    `}

  :hover {
    box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
    transform: scale(1.02);
  }

  :active {
    background-color: ${p => p.theme.colors.accent.emphasis};
    color: ${p => p.theme.colors.text.onEmphasis};
    box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
    transform: translateY(0px);
  }
`;

export const BanThemeRow = ({theme, isBanned, onClick}: BanThemeRowProps) => (
  <StyledBanThemeRow $isBanned={isBanned} onClick={onClick}>
    {theme.name}
  </StyledBanThemeRow>
);
