import {useQuery} from '@tanstack/react-query';
import {fetchUser, getUserQueryKey} from '@/features/user';

/**
 * This query fetches a user from the API.
 * @param uuidOrUsername
 * @param discriminator
 */
export const useUserQuery = (uuidOrUsername: string, discriminator?: string) => {
  const prettyDiscriminator = discriminator?.replace('#', '');
  return useQuery({
    queryKey: getUserQueryKey(uuidOrUsername, prettyDiscriminator),
    queryFn: () => fetchUser(uuidOrUsername, prettyDiscriminator),
    refetchOnMount: false
  });
};
