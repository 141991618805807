import {useQuery} from '@tanstack/react-query';
import {useAuthStore} from '@/features/auth';
import {fetchProfile} from '@/features/user';

const emptyProfile = async () => null;

/**
 * This query returns the current user.
 */
export const useCurrentUserQuery = () => {
  const {token} = useAuthStore();
  return useQuery({
    queryKey: ['profile'],
    queryFn: token ? fetchProfile : emptyProfile,
    refetchOnMount: false
  });
};
