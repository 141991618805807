import React from 'react';
import {
  TextChip,
  UserChip,
  selectHost,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';

export const LeaderReviewHint = () => {
  const self = useMachineSelf();
  const leader = useMachineSelector(selectHost);

  if (self && leader && self.userUuid === leader?.uuid) {
    return (
      <UserChip
        userUuid={self.userUuid}
        message=", проверьте ответы игроков"
        isSelf={true}
      />
    );
  }

  return <TextChip>Ведущий проверяет ответы игроков</TextChip>;
};
