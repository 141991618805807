import {Theme} from '../types/theme';
import {typography} from './typography';

export const darkTheme: Theme = {
  colors: {
    text: {
      default: '#c9d1d9',
      muted: '#8b949e',
      subtle: '#6e7681',
      onEmphasis: '#ffffff'
    },
    canvas: {
      default: '#0d1117',
      overlay: '#161b22',
      inset: '#010409',
      subtle: '#161b22'
    },
    border: {
      default: '#30363d',
      muted: '#21262d',
      subtle: 'rgba(240,246,252,0.08)'
    },
    tooltip: {
      default: '#21262d',
      popper: '#161b22'
    },
    neutral: {
      emphasis: '#6e7681',
      emphasisPlus: '#6e7681',
      muted: 'rgba(110,118,129,0.4)',
      subtle: 'rgba(110,118,129,0.1)'
    },
    accent: {
      text: '#58a6ff',
      emphasis: '#1f6feb',
      muted: 'rgba(56,139,253,0.4)',
      subtle: 'rgba(56,139,253,0.15)',
      focus: 'rgba(56,139,253,0.15)'
    },
    success: {
      text: '#3fb950',
      emphasis: '#238636',
      muted: 'rgba(46,160,67,0.4)',
      subtle: 'rgba(46,160,67,0.15)',
      focus: 'rgba(46,160,67,0.15)'
    },
    attention: {
      text: '#d29922',
      emphasis: '#9e6a03',
      muted: 'rgba(187,128,9,0.4)',
      subtle: 'rgba(187,128,9,0.15)',
      focus: 'rgba(187,128,9,0.15)'
    },
    danger: {
      text: '#f85149',
      emphasis: '#da3633',
      muted: 'rgba(248,81,73,0.4)',
      subtle: 'rgba(248,81,73,0.15)',
      focus: 'rgba(248,81,73,0.15)'
    },
    done: {
      text: '#a371f7',
      emphasis: '#8957e5',
      muted: 'rgba(163,113,247,0.4)',
      subtle: 'rgba(163,113,247,0.15)',
      focus: 'rgba(163,113,247,0.15)'
    }
  },
  shadow: {
    sm: '0 1px 0 rgba(27,31,36,0.04)',
    md: '0 4px 6px rgba(0,0,0,0.05)',
    lg: '0 4px 24px rgba(0,0,0,0.2)',
    floating:
      '0 1px 0 rgba(27,31,36,0.04), 0 0 0 1px rgba(255,255,255,0.08), 0 4px 24px rgba(0,0,0,0.3)'
  },
  typography
};
