import React, {useEffect, useRef} from 'react';
import {useLocalStorage} from 'react-use';
import SimpleBar from 'simplebar-react';
import {useSelector} from '@xstate/react';
import {getPkgVersion} from '@opeq-dev/openquiz-machine';
import styled from 'styled-components';
import {
  ChooseQuestionHint,
  Screen,
  ScreenContent,
  ScreenFooter,
  ThemeRow,
  useGameService
} from '@/features/machine';
import 'simplebar-react/dist/simplebar.min.css';

const Floating = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .simplebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const ChooseScreen = () => {
  const simpleBarRef = useRef<HTMLDivElement>(null);
  const [chooseScreenScroll, setChooseScreenScroll] = useLocalStorage(
    'choose-screen/scroll',
    0
  );

  const {service} = useGameService();

  const themes = useSelector(
    service,
    state =>
      getPkgVersion(state.context)?.themes.filter(
        theme => theme.roundUuid === state.context.current.roundUuid
      ) ?? []
  );

  useEffect(() => {
    if (!simpleBarRef.current) return;
    simpleBarRef.current.scrollTo({top: chooseScreenScroll});

    simpleBarRef.current.onscroll = () => {
      setChooseScreenScroll(simpleBarRef.current?.scrollTop);
    };
  }, [simpleBarRef]);

  return (
    <Screen>
      <ScreenContent>
        <Floating>
          <SimpleBar
            scrollableNodeProps={{ref: simpleBarRef}}
            autoHide={false}
            style={{width: '100%', height: '100%'}}>
            {themes.map(theme => (
              <ThemeRow key={`key-${theme.uuid}`} theme={theme} />
            ))}
          </SimpleBar>
        </Floating>
      </ScreenContent>
      <ScreenFooter>
        <ChooseQuestionHint />
      </ScreenFooter>
    </Screen>
  );
};
