import React from 'react';
import {Modal, ModalCloseCallback, Title} from '@openquiz/quiz-ui';
import {SettingsForm} from '@/features/user';

interface SettingsModalProps {
  onClose: ModalCloseCallback;
}

export const SettingsModal = ({onClose}: SettingsModalProps) => (
  <Modal onClose={onClose}>
    <Title>Настройки</Title>
    <SettingsForm />
  </Modal>
);
