import {useMemo} from 'react';
import {File, Package} from '@opeq-dev/openquiz-schema';

/**
 *
 * @param pkg
 * @returns
 */
export const usePackageCover = (pkg: Package) =>
  useMemo(() => pkg.files[0]?.file as File | undefined, [pkg.files]);
