import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {FinishLoadingEvent, getSplashDuration} from '@opeq-dev/openquiz-machine';
import {getAnimationProps} from '@openquiz/quiz-ui';
import {
  selectCtxCurrentQuestion,
  selectCtxCurrentSteps,
  selectCtxCurrentTheme,
  useMachineEvent,
  useMachineSelector
} from '@/features/machine';

const StyledLoadingStage = styled.div`
  text-align: center;
`;

const PreloadArea = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1111;
  pointer-events: none;
`;

const Price = styled.div`
  ${p => p.theme.typography.heading.h1};
  margin-bottom: 4px;
`;

const ThemeName = styled.div`
  color: ${p => p.theme.colors.text.muted};
`;

const Description = styled(motion.div)`
  margin-top: 24px;
  max-width: 440px;
  color: ${p => p.theme.colors.text.muted};
`;

const motionProps = getAnimationProps(
  {
    initial: {y: 0, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: -64, opacity: 0}
  },
  {duration: 0.2}
);

const descriptionMotionProps = getAnimationProps(
  {
    initial: {y: -8, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: -32, opacity: 0}
  },
  {duration: 0.2}
);

export const LoadingStage = () => {
  // const {service} = useGameService();
  // const {onUserFinishLoading} = useEmit();

  const {event} = useMachineEvent();

  const theme = useMachineSelector(selectCtxCurrentTheme);
  const question = useMachineSelector(selectCtxCurrentQuestion);
  const steps = useMachineSelector(selectCtxCurrentSteps);

  const media = useMemo(
    () => steps.filter(step => !step.isAnswer && step.type !== 'TEXT'),
    [steps]
  );

  const isFirstSeen = useMachineSelector(state => getSplashDuration(state.context) > 600);

  useEffect(() => {
    if (question?.uuid && media.length === 0) {
      event<FinishLoadingEvent>('finishLoading', {questionUuid: question?.uuid});
    }
  }, [question, media, event]);

  const onMediaLoaded = () => {
    if (!question?.uuid) return;
    event<FinishLoadingEvent>('finishLoading', {questionUuid: question?.uuid});
  };

  return (
    <StyledLoadingStage>
      {question && theme && (
        <motion.div {...motionProps} layoutId={question.uuid}>
          <Price>{question.price}</Price>
          <ThemeName>{theme.name}</ThemeName>{' '}
          {isFirstSeen && theme?.description && (
            <Description {...descriptionMotionProps}>{theme.description}</Description>
          )}
        </motion.div>
      )}

      <PreloadArea>
        {media.map(m => {
          if (m.type === 'IMAGE') {
            return <img key="image" src={m.payload} alt="" onLoad={onMediaLoaded} />;
          }

          if (m.type === 'AUDIO') {
            return (
              <audio
                key="audio"
                src={m.payload}
                autoPlay={false}
                onLoadedData={onMediaLoaded}
              />
            );
          }

          if (m.type === 'VIDEO') {
            return (
              <video
                key="video"
                src={m.payload}
                autoPlay={false}
                onLoadedData={onMediaLoaded}
              />
            );
          }
        })}
      </PreloadArea>
    </StyledLoadingStage>
  );
};
