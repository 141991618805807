import React from 'react';
import styled from 'styled-components';
import {PackageQuestionStep} from '@opeq-dev/openquiz-schema';
import {useMediaPlay, useMediaProgress} from '../../../hooks';
import {RenderAutoplay} from '@/features/machine';

interface VideoStepProps {
  step: PackageQuestionStep;
}

const StyledVideoStep = styled.div`
  position: relative;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  pointer-events: none;
  overflow: hidden;

  video {
    display: flex;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }
`;

export const VideoStep = ({step}: VideoStepProps) => {
  const {progress} = useMediaProgress();
  const {mediaRef} = useMediaPlay();

  return (
    <StyledVideoStep>
      <RenderAutoplay>
        {({muted}) => (
          <video
            ref={mediaRef}
            src={`${step.payload}#t=${progress}`}
            controls={false}
            playsInline={true}
            muted={muted}
          />
        )}
      </RenderAutoplay>
    </StyledVideoStep>
  );
};
