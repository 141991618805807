import React, {ButtonHTMLAttributes, ReactNode, forwardRef} from 'react';
import styled, {css} from 'styled-components';

export interface StyleObject {
  colors: {background: string; border: string; text: string};
}

interface ButtonMoreProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  styleObject?: StyleObject;
}

const StyledButtonMore = styled.button<{$styleObject?: StyleObject}>`
  ${p =>
    p.$styleObject
      ? css`
          background-color: ${p.$styleObject.colors.background};
          color: ${p.$styleObject.colors.text};

          &[data-open='true'],
          :hover {
            background-color: ${p.$styleObject.colors.text};
            color: ${p.theme.colors.text.onEmphasis};
          }
        `
      : css`
          background-color: rgba(0, 0, 0, 0.3);
          color: ${p => p.theme.colors.text.onEmphasis};
          backdrop-filter: blur(16px);

          &[data-open='true'],
          :hover {
            background-color: ${p => p.theme.colors.accent.emphasis};
            color: ${p => p.theme.colors.text.onEmphasis};
          }
        `}

  ${p => p.theme.typography.body.sm};

  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0 8px;
  min-width: 32px;
  height: 28px;
  cursor: pointer;
  z-index: 10;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out,
    transform 80ms ease-in-out;

  &:active {
    transform: scale(0.95);
  }
`;

export const ButtonMore = forwardRef<HTMLButtonElement, ButtonMoreProps>(
  function ButtonMore({children, styleObject, ...rest}, ref) {
    return (
      <StyledButtonMore ref={ref} {...rest} $styleObject={styleObject}>
        {children}
      </StyledButtonMore>
    );
  }
);
