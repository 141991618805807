import {DependencyList, FunctionComponent, useCallback, useContext, useMemo} from 'react';
import {
  ModalCloseCallback,
  ModalContext,
  ModalOpenCallback,
  ModalType
} from '../providers';
import {getRandomString} from '../libs';

/**
 * Check whether the argument is a stateless component.
 */
const isFunctionalComponent = (Component: FunctionComponent) => {
  const prototype = Component.prototype;
  return !prototype || !prototype.isReactComponent;
};

/**
 * React hook for showing modal windows
 */
export const useModal = (component: ModalType, deps: DependencyList = []) => {
  if (!isFunctionalComponent(component)) {
    throw new Error(
      'Only stateless components can be used as an argument to useModal. You have probably passed a class component where a function was expected.'
    );
  }

  const key = useMemo(getRandomString, []);
  const modal = useMemo(() => component, deps);
  const context = useContext(ModalContext);

  const showModal: ModalOpenCallback = useCallback(() => {
    context.onOpenModal(key, modal);
  }, [deps]);

  const hideModal: ModalCloseCallback = useCallback(
    options => {
      if (options?.closeAll) {
        context.onCloseAllModals();
        return;
      }
      context.onCloseModal(key);
    },
    [key, context, deps]
  );

  return [showModal, hideModal] as [ModalOpenCallback, ModalCloseCallback];
};
