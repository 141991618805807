import {useMemo} from 'react';
import {
  Bird,
  Butterfly,
  Cat,
  Dog,
  Fish,
  Flower,
  Heart,
  Moon,
  PawPrint,
  Pen,
  Star
} from '@phosphor-icons/react';
import {PhosphorIcon, getSeedRandomNumber} from '@openquiz/quiz-ui';

const icons = [Bird, Butterfly, Cat, Dog, Fish, Flower, Heart, Moon, Pen, Star, PawPrint];

/**
 * This hook will return a random icon for a specific seed.
 * @param seed
 */
export const useRandomAnimalIcon = (seed: string): PhosphorIcon => {
  return useMemo(() => icons[getSeedRandomNumber(seed, 0, icons.length - 1)], [seed]);
};
