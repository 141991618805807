import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useCurrentUserQuery} from '@/features/user';
import {GameMachineProvider} from './providers';
import {Router} from './components';

export const GameMachine = () => {
  const {gameUuid} = useParams();
  const {data: user} = useCurrentUserQuery();

  const contextMenuListener = (event: MouseEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    document.addEventListener('contextmenu', contextMenuListener);
    return () => {
      document.removeEventListener('contextmenu', contextMenuListener);
    };
  }, []);

  if (!user) {
    return null;
  }

  return (
    <GameMachineProvider userUuid={user.uuid} gameUuid={gameUuid as string}>
      <Router />
    </GameMachineProvider>
  );
};
