import React, {useEffect} from 'react';
import {useInterval} from 'react-use';
import {motion, useAnimate, useCycle} from 'framer-motion';
import styled from 'styled-components';

const StyledTryAnswerGif = styled.div`
  position: relative;
  margin: -64px -64px 0 -64px;
  padding: 64px 0 0 0;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const HintRow = styled(motion.div)`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Avatar = styled.img`
  background-color: ${p => p.theme.colors.neutral.subtle};
  border-radius: 50%;
  display: block;
  width: 24px;
  height: 24px;
`;

const Label = styled.div`
  position: relative;
`;

const Progress = styled(motion.div)`
  background-color: ${p => p.theme.colors.accent.emphasis};
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
`;

const Button = styled(motion.div)`
  ${p => p.theme.typography.label.md};
  background-color: ${p => p.theme.colors.accent.emphasis};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  text-align: center;
  height: 40px;
`;

export const TryAnswerGif = () => {
  const [scope, animate] = useAnimate();
  const [x, cycleX] = useCycle(100, 100, 80, 60, 40, 20, 0, 0);

  useInterval(() => {
    cycleX();
  }, 500);

  useEffect(() => {
    animate(scope.current, {width: `${x}%`}, {duration: 0.5});
  }, [x]);

  return (
    <StyledTryAnswerGif>
      <HintRow
        initial={{y: 32, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        transition={{
          delay: 0.1
        }}>
        <Avatar src="https://fastly.picsum.photos/id/836/32/32.jpg?hmac=hWxkuIu17x-7L-4YpjqdT5IuR4kTNzrZnMsBRB5VZUE" />
        <Label>
          <b>User Name</b>
          <span>,&nbsp;попробуйте ответить</span>
          <Progress ref={scope} />
        </Label>
      </HintRow>

      <Button
        initial={{y: 32, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        transition={{
          delay: 0.2
        }}>
        Ответить
      </Button>
    </StyledTryAnswerGif>
  );
};
