import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface SidebarFooterProps {
  children: ReactNode;
}

export const StyledSidebarFooter = styled.div`
  margin-top: auto;
`;

export const SidebarFooter = ({children}: SidebarFooterProps) => (
  <StyledSidebarFooter>{children}</StyledSidebarFooter>
);
