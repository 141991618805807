import React from 'react';
import {FlagCheckered, Star} from '@phosphor-icons/react';
import {Spinner} from '@openquiz/quiz-ui';
import {CoverScribble, usePackageQuery} from '@/features/wshop-packages';
import {
  GameEvaluationStep,
  GameResultsStep,
  WizardScreen,
  useMachineSelector
} from '@/features/machine';

export const EndScreen = () => {
  const ctx = useMachineSelector(state => state.context);
  const {data: pkg, isLoading} = usePackageQuery(ctx.pkg?.uuid as string);

  if (!pkg || isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <CoverScribble file={pkg.files[0]?.file} />
      <WizardScreen
        title="Игра окончена"
        steps={[
          {
            title: 'Оценка пака',
            icon: Star,
            element: <GameEvaluationStep pkg={pkg} />
          },
          {
            title: 'Результаты игры',
            icon: FlagCheckered,
            element: <GameResultsStep />
          }
        ]}
      />
    </>
  );
};
