import {getPercentFrom, getPrivateAxios} from '@/libs';

export interface ConverterData {
  id: number;
}

export interface ConverterStatusData {
  progress: number;
  value: string | null;
  error: string | null;
}

/**
 *
 * @param file
 * @param onProgress
 */
export const fetchConvertPackage = async (
  file: File,
  onProgress: (progress: number) => void
) => {
  const formData = new FormData();
  formData.append('file', file);

  const {data} = await getPrivateAxios().post<ConverterData>(
    '/workshop/converter',
    formData,
    {
      headers: {'Content-Type': 'multipart/form-data'},
      onUploadProgress: e => {
        onProgress(getPercentFrom(e.loaded, e.total ?? 0));
      }
    }
  );

  return data;
};

/**
 *
 * @param id
 */
export const fetchConvertStatus = async (id: number) => {
  const {data} = await getPrivateAxios().get<ConverterStatusData>(
    `/workshop/converter/${id}`
  );
  return data;
};
