import React, {HTMLAttributes, forwardRef} from 'react';
import styled from 'styled-components';

export interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Property for specifying the size of the spinner.
   */
  size?: number;
}

const StyledSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(function Spinner(
  {size = 16, ...rest},
  ref
) {
  return (
    <StyledSpinner ref={ref} style={{width: size, height: size}} {...rest}>
      <svg
        width={`${size * 0.8}px`}
        height={`${size * 0.8}px`}
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(2.5 2.5)" strokeWidth="5">
            <circle strokeOpacity=".5" cx="16" cy="16" r="16" />
            <path d="M32 16c0-9.94-8.06-16-16-16">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 16 16"
                to="360 16 16"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </StyledSpinner>
  );
});
