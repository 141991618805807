import React from 'react';
import {HandTap} from '@phosphor-icons/react';
import {
  ChooseResponderEvent,
  TeamRecord,
  chooseResponderGuard
} from '@opeq-dev/openquiz-machine';
import {ButtonMore} from '@openquiz/quiz-ui';
import {
  useGameService,
  useMachineEvent,
  useMachineGuard,
  useMachineSelf
} from '@/features/machine';

interface ActionButtonProps {
  team: TeamRecord;
}

export const ActionButton = ({team}: ActionButtonProps) => {
  const {event} = useMachineEvent();
  const self = useMachineSelf();
  const {guard} = useMachineGuard();
  const {isMatch} = useGameService();

  const isChooseResponder =
    guard(chooseResponderGuard)({teamUuid: team.uuid}) &&
    isMatch([{game: {basicRound: {secretQuestion: 'splash'}}}]);

  const onClickChooseResponder = () => {
    event<ChooseResponderEvent>('chooseResponder', {teamUuid: team.uuid});
  };

  if (isChooseResponder) {
    return (
      <ButtonMore onClick={onClickChooseResponder}>
        <HandTap size={20} weight="bold" />
        <span>{team.uuid === self.teamUuid ? 'Оставить' : 'Передать'}</span>
      </ButtonMore>
    );
  }

  return null;
};
