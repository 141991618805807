import {DefaultTheme} from 'styled-components';
import {Indicator} from '@openquiz/quiz-ui';

export const getChooseIndicator = (theme: DefaultTheme): Indicator => {
  return {type: 'dash', payload: {color: theme.colors.attention.emphasis}};
};

export const getProgressIndicator = (
  theme: DefaultTheme,
  progress: number
): Indicator => {
  return {
    type: 'solid',
    payload: {
      color: progress >= 85 ? theme.colors.danger.emphasis : theme.colors.accent.emphasis,
      progress
    }
  };
};

export const getProgressAttentionIndicator = (
  theme: DefaultTheme,
  progress: number
): Indicator => {
  return {
    type: 'solid',
    payload: {
      color:
        progress >= 85 ? theme.colors.danger.emphasis : theme.colors.attention.emphasis,
      progress
    }
  };
};

export const getSuccessIndicator = (theme: DefaultTheme): Indicator => {
  return {type: 'flash', payload: {color: theme.colors.success.emphasis}};
};

export const getErrorIndicator = (theme: DefaultTheme): Indicator => {
  return {type: 'flash', payload: {color: theme.colors.danger.emphasis}};
};

export const getPassIndicator = (theme: DefaultTheme): Indicator => {
  return {type: 'solid', payload: {color: theme.colors.border.default, progress: 0}};
};
