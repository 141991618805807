import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Spinner} from '@openquiz/quiz-ui';
import styled from 'styled-components';
import {useAuthRefererStore, useAuthStore} from '@/features/auth';
import {Page} from '@/components';

const StyledCallbackPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CallbackPage = () => {
  const [searchParams] = useSearchParams();
  const {referer, flush} = useAuthRefererStore();
  const {update} = useAuthStore();

  useEffect(() => {
    const accessToken = searchParams.get('accessToken');

    if (accessToken) {
      update(accessToken);
      window.location.replace(referer || '/');
    }

    return () => {
      flush();
    };
  }, [searchParams, referer, flush, update]);

  return (
    <Page>
      <StyledCallbackPage>
        <Spinner size={24} />
      </StyledCallbackPage>
    </Page>
  );
};
