import {getPrivateAxios} from '@/libs';
import {PackageRound} from '@opeq-dev/openquiz-schema';

export interface FetchRoundParams {
  uuid: string;
  versionUuid: string;
  packageUuid: string;
}

/**
 *
 * @param params
 */
export const fetchRound = async (params: FetchRoundParams) => {
  const {data} = await getPrivateAxios().get<PackageRound>(
    `/workshop/packages/${params.packageUuid}/rounds/${params.uuid}`,
    {params: {versionUuid: params.versionUuid}}
  );
  return data;
};
