import React from 'react';
import {NavLink, generatePath} from 'react-router-dom';
import styled from 'styled-components';
import {Plus} from '@phosphor-icons/react';
import {editorQuestionCreatePath} from '@/features/wshop-editor';

interface QuestionCreateButtonProps {
  nextPosition: number;
  nextPrice: number;
  packageUuid: string;
  versionUuid: string;
  roundUuid: string;
  themeUuid: string;
}

const StyledQuestionCreateButton = styled(NavLink)`
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.muted};
  position: relative;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  height: 68px;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  transition:
    box-shadow 80ms ease-in-out,
    transform 80ms ease-in-out;

  :hover {
    box-shadow: ${p => p.theme.shadow.md};
  }

  :active {
    box-shadow: ${p => p.theme.shadow.floating};
    transform: translateY(2px) scale(0.98);
  }
`;

export const QuestionCreateButton = ({
  nextPosition,
  nextPrice,
  packageUuid,
  versionUuid,
  roundUuid,
  themeUuid
}: QuestionCreateButtonProps) => (
  <StyledQuestionCreateButton
    to={generatePath(editorQuestionCreatePath, {
      packageUuid,
      versionUuid,
      roundUuid,
      themeUuid
    })}
    state={{nextPosition, nextPrice}}>
    <Plus size={24} weight="bold" />
  </StyledQuestionCreateButton>
);
