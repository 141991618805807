import React from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {WorkshopCategoryFull} from '@/features/wshop-categories';
import {getSearchString} from '@/features/wshop-search';

interface CategorySidebarLinkProps {
  category: WorkshopCategoryFull;
}

const StyledCategorySidebarLink = styled(Link)<{$isActive: boolean}>`
  ${p => p.theme.typography.body.md};
  color: ${p => p.theme.colors.text.default};
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
  transition: color 80ms ease-in-out;

  ${p =>
    p.$isActive &&
    css`
      color: ${p => p.theme.colors.accent.text};
      font-weight: 500;
    `}

  :hover {
    color: ${p => p.theme.colors.accent.text};
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const Emoji = styled.div`
  display: flex;
  text-align: center;
  font-size: 16px;
  width: 20px;
  line-height: 24px;
`;

const CountBadge = styled.div`
  ${p => p.theme.typography.label.sm};
  background-color: ${p => p.theme.colors.accent.emphasis};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 6px;
  padding: 2px 4px;
  text-align: center;
  min-width: 20px;
`;

export const CategorySidebarLink = ({category}: CategorySidebarLinkProps) => {
  const [searchParams] = useSearchParams();
  const isActive = searchParams.get('data')?.includes(category.name) || false;

  return (
    <StyledCategorySidebarLink
      $isActive={isActive}
      to={`/workshop/search?data=${getSearchString({
        query: `%${category.name}`,
        sort: 'created_at'
      })}`}>
      <Emoji>{category.emoji}</Emoji>
      <div>{category.name}</div>
      {!!category._count.packages && (
        <CountBadge style={{backgroundColor: category.color}}>
          {category._count.packages}
        </CountBadge>
      )}
    </StyledCategorySidebarLink>
  );
};
