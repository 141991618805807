import React from 'react';
import {Link, generatePath} from 'react-router-dom';
import {BookmarkSimple, Pencil, PlayCircle} from '@phosphor-icons/react';
import {Button, FieldGroup, FieldRow} from '@openquiz/quiz-ui';
import {CurrentUserGuard} from '@/features/user';
import {
  Cover,
  CoverScribble,
  PackageFull,
  usePackageCover,
  usePackageFavorite,
  usePackageRun
} from '@/features/wshop-packages';
import {editorInfoPath} from '@/features/wshop-editor';
import {useAuthGuardCallback} from '@/features/auth';

interface PackageSidebarProps {
  pkg: PackageFull;
}

export const PackageSidebar = ({pkg}: PackageSidebarProps) => {
  const {mutateAsync} = usePackageFavorite();

  const runPackage = usePackageRun({
    packageUuid: pkg.uuid,
    versionUuid: pkg.versions[0]?.uuid
  });

  const cover = usePackageCover(pkg);
  const isFavorite = !!pkg.favorites.length;

  const onClickCreate = useAuthGuardCallback(() => {
    runPackage();
  });

  const onClickFavorite = useAuthGuardCallback(async () => {
    await mutateAsync(pkg.uuid);
  });

  return (
    <div>
      <CoverScribble file={cover} />

      <FieldGroup size="lg">
        <FieldRow justify="center">
          <Cover file={cover} />
        </FieldRow>
        <CoverScribble file={cover} />
      </FieldGroup>

      <FieldGroup size="lg">
        {pkg.versions[0]?.uuid && (
          <FieldGroup>
            <Button stretch={true} onClick={onClickCreate}>
              <PlayCircle size={20} weight="fill" />
              <span>Создать игру</span>
            </Button>
          </FieldGroup>
        )}

        <FieldGroup>
          <FieldRow justify="center">
            <Button variant="link" onClick={onClickFavorite}>
              <BookmarkSimple size={20} weight={isFavorite ? 'fill' : 'bold'} />
              <span>{isFavorite ? 'Убрать' : 'В закладки'}</span>
            </Button>
            <CurrentUserGuard user={pkg.user}>
              <Link
                style={{display: 'flex'}}
                to={generatePath(editorInfoPath, {packageUuid: pkg.uuid})}>
                <Button variant="link">
                  <Pencil size={16} weight="bold" />
                  <span>Редактировать</span>
                </Button>
              </Link>
            </CurrentUserGuard>
          </FieldRow>
        </FieldGroup>
      </FieldGroup>
    </div>
  );
};
