import React from 'react';
import {motion} from 'framer-motion';
import styled from 'styled-components';
import {getAnimationProps} from '@openquiz/quiz-ui';

const StyledGlow = styled(motion.div)`
  background-image: radial-gradient(
      at 14% 0%,
      hsla(264, 54%, 61%, 0.3) 0px,
      transparent 60%
    ),
    radial-gradient(at 88% 12%, hsla(190, 93%, 47%, 0.3) 0px, transparent 60%);
  position: absolute;
  left: -64px;
  top: -64px;
  right: -64px;
  bottom: 25%;
  z-index: 0;
  pointer-events: none;
`;

const motionProps = getAnimationProps(
  {
    initial: {y: -64, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: 32, opacity: 0}
  },
  {duration: 1}
);

export const Glow = () => <StyledGlow {...motionProps} />;
