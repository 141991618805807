import React, {ReactNode} from 'react';
import {Helmet} from 'react-helmet';

interface PageProps {
  children: ReactNode;
  title?: string;
}

export const Page = ({children, title = 'OpenQuiz'}: PageProps) => (
  <div>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </div>
);
