import React from 'react';
import {Crown, UserMinus, XCircle} from '@phosphor-icons/react';
import {
  BanPlayerEvent,
  ChangeTeamLeaderEvent,
  KickPlayerEvent,
  TeamRecord,
  UserRecord,
  changeTeamLeaderGuard,
  hostUniversalGuard,
  kickPlayerGuard
} from '@opeq-dev/openquiz-machine';
import {Menu, MenuGroup, MenuItem} from '@openquiz/quiz-ui';
import {ModalConfirm, useModalConfirm} from '@openquiz/quiz-modal';
import {useMachineEvent, useMachineGuard} from '@/features/machine';

interface PlayerMenuProps {
  team: TeamRecord;
  user: UserRecord;
  onClose?: () => void;
}

export const PlayerMenu = ({team, user, onClose = () => void 0}: PlayerMenuProps) => {
  const {guard} = useMachineGuard();
  const {event} = useMachineEvent();

  const isTeamLeader = team.leaderUuid === user.uuid;

  const confirmBan = useModalConfirm(props => (
    <ModalConfirm
      {...props}
      content={
        <>
          <p>Вы точно хотите забанить игрока?</p>
          <p>Игрок не сможет повторно присоединиться к игре.</p>
        </>
      }
      resolveLabel="Забанить"
    />
  ));

  const onClickSetTeamLeader = () => {
    event<ChangeTeamLeaderEvent>('changeTeamLeader', {
      teamUuid: team.uuid,
      userUuid: user.uuid
    });
    onClose();
  };

  const onClickKick = () => {
    event<KickPlayerEvent>('kickPlayer', {userUuid: user.uuid});
    onClose();
  };

  const onClickBan = async () => {
    onClose();

    try {
      await confirmBan();
      event<BanPlayerEvent>('banPlayer', {userUuid: user.uuid});
    } catch (err) {
      /* empty */
    }
  };

  return (
    <Menu>
      <MenuGroup>
        {guard(changeTeamLeaderGuard)({teamUuid: team.uuid, userUuid: user.uuid}) &&
          !isTeamLeader && (
            <MenuItem icon={Crown} onClick={onClickSetTeamLeader}>
              Передать лидера
            </MenuItem>
          )}
      </MenuGroup>
      <MenuGroup>
        {guard(kickPlayerGuard)({userUuid: user.uuid}) && (
          <MenuItem icon={UserMinus} color="danger" onClick={onClickKick}>
            Выгнать
          </MenuItem>
        )}
        {guard(hostUniversalGuard)(undefined) && (
          <MenuItem icon={XCircle} color="danger" onClick={onClickBan}>
            Забанить
          </MenuItem>
        )}
      </MenuGroup>
    </Menu>
  );
};
