import React from 'react';
import {HandTap, Palette, TextAa} from '@phosphor-icons/react';
import {
  ChangeTeamNameEvent,
  ChangeTeamScoreEvent,
  ChangeTeamStyleEvent,
  ChangeTurnEvent,
  TeamRecord,
  changeTeamNameGuard,
  changeTeamScoreGuard,
  hostUniversalGuard
} from '@opeq-dev/openquiz-machine';
import {Menu, MenuCustomItem, MenuGroup, MenuItem} from '@openquiz/quiz-ui';
import {
  TeamSlotStyle,
  selectTeams,
  selectTurnTeamUuid,
  teamSlotStyles,
  useMachineEvent,
  useMachineGuard,
  useMachineSelector
} from '@/features/machine';
import styled, {css} from 'styled-components';

interface SlotTeamMenuProps {
  team: TeamRecord;
  onClose?: () => void;
}

const SwatchGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 143px;
`;

const ColorSwatch = styled.div<{$color: string; $disabled: boolean}>`
  background-color: ${p => p.$color};
  border-radius: 8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition:
    opacity ease-in-out 80ms,
    transform ease-in-out 80ms;

  ${p =>
    p.$disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const SlotTeamMenu = ({team, onClose = () => void 0}: SlotTeamMenuProps) => {
  const {event} = useMachineEvent();
  const {guard} = useMachineGuard();

  const turnTeamUuid = useMachineSelector(selectTurnTeamUuid);
  const alreadyUsedStyles = useMachineSelector(selectTeams).map(team => team.style);

  const onClickChangeTurn = () => {
    event<ChangeTurnEvent>('changeTurn', {teamUuid: team.uuid});
  };

  const onClickChangeScore = () => {
    const score = prompt('Очки команды:', team.score.toString());
    if (score) {
      event<ChangeTeamScoreEvent>('changeTeamScore', {
        teamUuid: team.uuid,
        score: parseInt(score, 10)
      });
      onClose();
    }
  };

  const onClickChangeName = () => {
    const name = prompt('Название команды:', team.name);
    if (name) {
      event<ChangeTeamNameEvent>('changeTeamName', {teamUuid: team.uuid, name});
      onClose();
    }
  };

  const onClickChangeStyle = (slotStyle: TeamSlotStyle) => {
    const isOriginalStyleName = teamSlotStyles.some(
      slotStyle =>
        slotStyle.style.color === team.style.color && slotStyle.label === team.name
    );

    if (isOriginalStyleName) {
      event<ChangeTeamNameEvent>('changeTeamName', {
        teamUuid: team.uuid,
        name: slotStyle.label
      });
    }

    event<ChangeTeamStyleEvent>('changeTeamStyle', {
      teamUuid: team.uuid,
      style: slotStyle.style
    });
  };

  return (
    <Menu>
      <MenuGroup>
        {guard(hostUniversalGuard)({}) && turnTeamUuid !== team.uuid && (
          <MenuItem color="accent" icon={HandTap} onClick={onClickChangeTurn}>
            Передать ход
          </MenuItem>
        )}
      </MenuGroup>
      <MenuGroup>
        {guard(changeTeamScoreGuard)({teamUuid: team.uuid}) && (
          <MenuItem icon={Palette} onClick={onClickChangeScore}>
            Изменить очки
          </MenuItem>
        )}
        {guard(changeTeamNameGuard)({teamUuid: team.uuid}) && (
          <MenuItem icon={TextAa} onClick={onClickChangeName}>
            Изменить название
          </MenuItem>
        )}
      </MenuGroup>
      <MenuGroup>
        {guard(changeTeamNameGuard)({teamUuid: team.uuid}) && (
          <MenuCustomItem icon={Palette}>
            <div style={{marginBottom: 8}}>Оформление</div>
            <SwatchGrid>
              {teamSlotStyles.map(teamSlotStyle => (
                <ColorSwatch
                  key={`color-swatch-${teamSlotStyle.style.color}`}
                  $color={teamSlotStyle.style.color}
                  $disabled={alreadyUsedStyles.some(
                    usedStyle => usedStyle.color === teamSlotStyle.style.color
                  )}
                  onClick={() => onClickChangeStyle(teamSlotStyle)}
                />
              ))}
            </SwatchGrid>
          </MenuCustomItem>
        )}
      </MenuGroup>
    </Menu>
  );
};
