import React from 'react';
import styled from 'styled-components';
import {Card} from '@openquiz/quiz-ui';
import {WorkshopCategoryFull} from '@/features/wshop-categories';
import {getSearchString} from '@/features/wshop-search';

interface CategoryCardProps {
  category: WorkshopCategoryFull;
}

const StyledCategoryCard = styled(Card)`
  padding: 16px;
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Emoji = styled.div`
  font-size: 20px;
  line-height: 24px;
  display: flex;
  flex-shrink: 0;
`;

const Name = styled.div`
  ${p => p.theme.typography.label.lg};
  margin-right: auto;
  flex-shrink: 0;
`;

const CountBadge = styled.div`
  background-color: ${p => p.theme.colors.canvas.default};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 8px;
  text-align: center;
  padding: 0 8px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const CategoryCard = ({category}: CategoryCardProps) => (
  <StyledCategoryCard
    to={`/workshop/search?data=${getSearchString({
      query: `%${category.name}`,
      sort: 'created_at'
    })}`}>
    <CardContainer>
      <Emoji>{category.emoji}</Emoji>
      <Name>{category.name}</Name>
      <CountBadge style={{backgroundColor: category.color}}>
        {category._count.packages}
      </CountBadge>
    </CardContainer>
  </StyledCategoryCard>
);
