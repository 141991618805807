import {Package} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';
import {PackageFull} from './fetchPackages';

export type PackageUpdateData = Pick<
  PackageFull,
  | 'uuid'
  | 'name'
  | 'description'
  | 'difficulty'
  | 'files'
  | 'categories'
  | 'tags'
  | 'contests'
>;

/**
 * Fetch update package.
 * @param updateData
 */
export const fetchPackageUpdate = async (updateData: PackageUpdateData) => {
  const {data} = await getPrivateAxios().patch<Package>(
    `/workshop/packages/${updateData.uuid}`,
    updateData
  );
  return data;
};
