import React, {useMemo} from 'react';
import {Cube} from '@phosphor-icons/react';
import styled, {css} from 'styled-components';
import uniqolor from 'uniqolor';
import {Package} from '@opeq-dev/openquiz-schema';

interface PackageSwatchProps {
  pkg: Package;
  size?: 'sm' | 'md';
}

const StyledPackageSwatch = styled.div<{
  $isLight: boolean;
  $size: PackageSwatchProps['size'];
}>`
  background-color: ${p => p.theme.colors.border.muted};
  color: ${p => p.theme.colors.text.onEmphasis};
  display: flex;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;

  ${p =>
    p.$size === 'sm' &&
    css`
      border-radius: 6px;
      width: 20px;
      height: 20px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      border-radius: 8px;
      width: 24px;
      height: 24px;
    `}
`;

const iconSizeMap = new Map<PackageSwatchProps['size'], number>([
  ['sm', 12],
  ['md', 16]
]);

export const PackageSwatch = ({pkg, size = 'md'}: PackageSwatchProps) => {
  const {color, isLight} = useMemo(() => uniqolor(pkg.uuid), [pkg.uuid]);
  return (
    <StyledPackageSwatch $isLight={isLight} $size={size} style={{backgroundColor: color}}>
      <Cube size={iconSizeMap.get(size)} weight="bold" />
    </StyledPackageSwatch>
  );
};
