import React, {Children, ReactNode, Ref, forwardRef} from 'react';
import styled from 'styled-components';

export interface GridProps {
  /**
   * An array of items to display in the grid.
   */
  children: ReactNode;
  /**
   * The number of columns per line.
   */
  cols: {
    sm: number;
    md: number;
    lg: number;
  };
  /**
   * Distance between columns and lines.
   */
  gap?: number;
}

const StyledGrid = styled.div<{$cols: GridProps['cols']; $gap: number}>`
  display: grid;
  flex-wrap: wrap;
  width: 100%;
  gap: ${p => p.$gap}px;

  @media only screen and (min-width: 320px) {
    grid-template-columns: repeat(${({$cols}) => $cols.sm}, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(${({$cols}) => $cols.md}, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1440px) {
    grid-template-columns: repeat(${({$cols}) => $cols.lg}, minmax(0, 1fr));
  }
`;

/**
 * The component organizes items in a grid.
 */
export const Grid = forwardRef(function forwardedGrid(
  {children, cols, gap = 20}: GridProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <StyledGrid ref={ref} $cols={cols} $gap={gap}>
      {Children.map(children, child => (child ? child : null))}
    </StyledGrid>
  );
});
