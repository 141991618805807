import {useMutation, useQueryClient} from '@tanstack/react-query';
import {contestQueryKeys, fetchContestFeedback} from '@/features/contest';

/**
 *
 */
export function useFeedbackContest() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchContestFeedback,
    onSuccess(_, variables) {
      queryClient.invalidateQueries({
        queryKey: contestQueryKeys.detail(variables.contestUuid).queryKey
      });
      queryClient.invalidateQueries({
        queryKey: contestQueryKeys.list._def
      });
    }
  });
}
