import React, {ReactNode, useEffect, useMemo} from 'react';
import {useLocation} from 'react-use';
import {AnimatePresence} from 'framer-motion';
import {Root} from '@openquiz/quiz-modal';
import {modalStore} from '../../modalStore';

export interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider = ({children}: ModalProviderProps) => {
  const location = useLocation();
  const {modals, reset} = modalStore();
  const current = useMemo(() => modals.at(-1), [modals]);

  useEffect(() => {
    reset();
  }, [location]);

  return (
    <>
      {children}
      <AnimatePresence mode="wait">
        {current && <Root key={`modal-${current.id}`}>{current.element}</Root>}
      </AnimatePresence>
    </>
  );
};
