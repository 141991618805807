import React from 'react';
import toast from 'react-hot-toast';
import {generatePath, useNavigate} from 'react-router-dom';
import {Surface} from '@openquiz/quiz-ui';
import {InformationForm, editorInfoPath} from '@/features/wshop-editor';
import {PackageCreateData, fetchPackageCreate} from '@/features/wshop-packages';
import {Page, PageTitle} from '@/components';

export const CreatePage = () => {
  const navigate = useNavigate();

  const onFormSubmit = async (data: PackageCreateData) => {
    try {
      const pkg = await fetchPackageCreate(data);
      navigate(generatePath(editorInfoPath, {packageUuid: pkg.uuid}));
      toast.success('Пак успешно создан');
    } catch (err) {
      toast.error('При создании пака произошла ошибка');
    }
  };

  return (
    <Page>
      <PageTitle to={{href: '/'}} title="Создание пака" />
      <Surface>
        <InformationForm onSubmit={onFormSubmit} />
      </Surface>
    </Page>
  );
};
