import {User} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

/**
 * This function fetches a user from the API.
 * @param uuidOrUsername
 * @param discriminator
 */
export const fetchUser = async (
  uuidOrUsername: string | undefined,
  discriminator?: string
) => {
  const {data} = await getPrivateAxios().get<User>(`/users/${uuidOrUsername}`, {
    params: {discriminator}
  });

  return data;
};
