import {getPrivateAxios} from '@/libs';

export interface Speech {
  uuid: string;
  src: string;
  duration: number;
  voice: number;
}

interface CreateSpeechDto {
  text: string;
}

/**
 *
 */
export const fetchSpeechCreate = async (createSpeechDto: CreateSpeechDto) => {
  const {data} = await getPrivateAxios().post<Speech>('/speech', createSpeechDto);
  return data;
};
