import React, {
  FunctionComponent,
  createElement,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {AnimatePresence, motion, useAnimation} from 'framer-motion';
import {WizardContext} from './WizardContext';
import {AnimatedStep} from './AnimatedStep';

export interface WizardProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  steps: Map<string, FunctionComponent<any>>;
  initial: string;
}

export const WizardProvider = ({steps, initial}: WizardProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const contentControls = useAnimation();

  const [key, setKey] = useState<string>(initial);
  const [data, setData] = useState<unknown>(undefined);

  useEffect(() => {
    if (!contentRef.current) return;
    contentControls.set({height: contentRef.current.clientHeight});
  }, []);

  useEffect(() => {
    if (!contentRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      contentControls.start({height: entries[0].target.clientHeight});
    });

    resizeObserver.observe(contentRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [contentRef, key]);

  const stepComponent = useMemo(() => steps.get(key), [key, steps]);

  const onChangeStep = (key: string, data?: unknown) => {
    setKey(key);
    setData(data);
  };

  return (
    <WizardContext.Provider value={{key, data, onChangeStep}}>
      <motion.div style={{position: 'relative'}} animate={contentControls}>
        <AnimatePresence initial={false}>
          <AnimatedStep key={key}>
            <div ref={contentRef}>
              {stepComponent && createElement(stepComponent, {data})}
            </div>
          </AnimatedStep>
        </AnimatePresence>
      </motion.div>
    </WizardContext.Provider>
  );
};
