import React, {ReactNode} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useToggle} from 'react-use';
import styled, {css} from 'styled-components';
import {ArrowCircleRight, Trash} from '@phosphor-icons/react';
import {ModalConfirm, useModalConfirm} from '@openquiz/quiz-modal';
import {Tooltip, getAnimationProps} from '@openquiz/quiz-ui';

interface StepSectionProps {
  children: ReactNode;
  index: number;
  count: number;
  onDelete?: () => void;
}

const StyledStepSection = styled.div`
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
  user-select: none;
  transition: margin-bottom 80ms ease-in-out;

  :last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.div<{$expanded: boolean}>`
  ${p => p.theme.typography.label.md};
  color: ${p => p.theme.colors.text.muted};
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 8px;
  cursor: pointer;
  transition: color 80ms ease-in-out;

  svg {
    flex-shrink: 0;
    transition: transform 80ms ease-in-out;
  }

  ${p =>
    p.$expanded &&
    css`
      color: ${p => p.theme.colors.text.default};
      svg {
        transform: rotate(45deg);
      }
    `}

  :hover {
    color: ${p => p.theme.colors.text.default};
  }
`;

const Divider = styled.div`
  border-bottom: 1px dashed ${p => p.theme.colors.border.muted};
  width: 100%;
`;

const Action = styled.div`
  display: flex;
  cursor: pointer;
  opacity: 0.2;
  transition:
    color 80ms ease-in-out,
    opacity 80ms ease-in-out;

  :hover {
    color: ${p => p.theme.colors.danger.text};
    opacity: 1;
  }
`;

const Entry = styled(motion.div)``;

const labelsMap = new Map([
  [1, 'Первый шаг'],
  [2, 'Второй шаг'],
  [3, 'Третий шаг']
]);

const entryMotionProps = getAnimationProps({
  initial: {height: 0, opacity: 0},
  animate: {height: 'auto', opacity: 1},
  exit: {height: 0, opacity: 0}
});

export const StepSection = ({children, index, count, onDelete}: StepSectionProps) => {
  const [expanded, setExpanded] = useToggle(true);

  // TODO: убрать от сюда
  const confirmDelete = useModalConfirm(props => (
    <ModalConfirm
      {...props}
      // title=""
      content={<>При удалении шага, содержимое шага будет потеряно.</>}
      resolveLabel="Удалить"
    />
  ));

  const computedExpanded = count > 1 ? expanded : true;

  const onClickDelete = () => {
    if (!onDelete) return;
    confirmDelete()
      .then(onDelete)
      .catch(() => void 0);
  };

  return (
    <StyledStepSection>
      {count > 1 && (
        <Head>
          <Label $expanded={computedExpanded} onClick={setExpanded}>
            <ArrowCircleRight size={20} weight="bold" />
            <span>{labelsMap.get(index + 1)}</span>
          </Label>
          <Divider />
          {index > 0 && onDelete && computedExpanded && (
            <Tooltip message="Удалить" placement="top">
              <Action onClick={onClickDelete}>
                <Trash size={20} weight="bold" />
              </Action>
            </Tooltip>
          )}
        </Head>
      )}

      <AnimatePresence initial={false} mode="wait">
        {computedExpanded && <Entry {...entryMotionProps}>{children}</Entry>}
      </AnimatePresence>
    </StyledStepSection>
  );
};
