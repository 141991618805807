import React, {createElement, useMemo} from 'react';
import {NavLink, generatePath} from 'react-router-dom';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {DotsSixVertical} from '@phosphor-icons/react';
import styled, {css, keyframes} from 'styled-components';
import {PackageQuestion, PackageQuestionStep} from '@opeq-dev/openquiz-schema';
import {Tooltip} from '@openquiz/quiz-ui';
import {questionTypeIconMap} from '@/features/machine';
import {editorQuestionUpdatePath} from '@/features/wshop-editor';
import {TooltipPreview} from './TooltipPreview';

type QuestionFull = PackageQuestion & {steps: PackageQuestionStep[]};

interface QuestionCardProps {
  question: QuestionFull;
  packageUuid: string;
  versionUuid: string;
  roundUuid: string;
  themeUuid: string;
  highlighted: boolean;
}

const highlightedAnimation = (color: string) => keyframes`
  from {
    border-color: orange;
  }
  to {
    border-color: ${color};
  }
`;

const StyledQuestionCard = styled(NavLink)<{$highlighted: boolean}>`
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.muted};
  position: relative;
  color: inherit;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  min-width: 80px;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  transition:
    box-shadow 80ms ease-in-out,
    transform 80ms ease-in-out;

  :hover {
    box-shadow: ${p => p.theme.shadow.md};
  }

  :active {
    box-shadow: ${p => p.theme.shadow.floating};
    transform: translateY(2px) scale(0.98);
  }
  ${p =>
    p.$highlighted &&
    css`
       {
        animation: ${highlightedAnimation(
          p.theme.colors.border.muted
        )} ease-in-out 3000ms;
      }
    `}
`;

const DragIcon = styled(DotsSixVertical)`
  color: ${p => p.theme.colors.text.subtle};
  position: absolute;
  right: 4px;
  top: 11px;

  :hover {
    color: ${p => p.theme.colors.accent.text};
  }
`;

const Price = styled.div`
  ${p => p.theme.typography.caption.md};
  color: ${p => p.theme.colors.text.default};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  svg {
    color: ${p => p.theme.colors.text.subtle};
  }
`;

const Text = styled.div`
  ${p => p.theme.typography.body.sm};
  color: ${p => p.theme.colors.text.subtle};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const getQuestionPreviewText = (question: QuestionFull) => {
  const answer = question.steps.filter(step => step.isAnswer)[0];

  const answerText = answer?.type === 'TEXT' ? answer?.payload : answer?.caption;

  if (answerText) {
    return answerText;
  }

  const payload = question.steps.filter(step => step.type === 'TEXT')[0]?.payload;

  if (payload) {
    return payload;
  }

  const caption = question.steps.filter(step => step.caption !== null)[0]?.caption;

  return caption ?? 'Без текста';
};

export const QuestionCard = ({
  question,
  packageUuid,
  versionUuid,
  roundUuid,
  themeUuid,
  highlighted
}: QuestionCardProps) => {
  const {attributes, listeners, setNodeRef, transition, isDragging, transform} =
    useSortable({
      id: question.uuid
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const typeIcon = useMemo(() => questionTypeIconMap.get(question.type), [question.type]);

  const text = useMemo(() => getQuestionPreviewText(question), [question.steps]);

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Tooltip
        message={<TooltipPreview question={question} />}
        enabled={!isDragging}
        delay={{open: 600}}
        size="lg">
        <StyledQuestionCard
          $highlighted={highlighted}
          to={generatePath(editorQuestionUpdatePath, {
            questionUuid: question.uuid,
            packageUuid,
            versionUuid,
            roundUuid,
            themeUuid
          })}>
          <DragIcon {...listeners} size={20} weight="bold" />
          <Price>
            {typeIcon && createElement(typeIcon, {size: 16, weight: 'duotone'})}
            {question.price}
          </Price>
          <Text>{text}</Text>
        </StyledQuestionCard>
      </Tooltip>
    </div>
  );
};
