import React from 'react';
import {useGameService, useTryAnswerListener} from '@/features/machine';
import {BasicRoundScreen} from './BasicRoundScreen';
import {FinalRoundScreen} from './FinalRoundScreen';

export const PlayScreen = () => {
  useTryAnswerListener();
  const {isMatch} = useGameService();

  return (
    <>
      {isMatch([{game: 'basicRound'}]) && <BasicRoundScreen />}
      {isMatch([{game: 'finalRound'}]) && <FinalRoundScreen />}
    </>
  );
};
