import {useSelector} from '@xstate/react';
import {Guard} from '@opeq-dev/openquiz-machine';
import {useGameService} from '@/features/machine';
import {useMachineSelf} from './useMachineSelf';

export const useMachineGuard = () => {
  const {service} = useGameService();
  const self = useMachineSelf();

  const guard = useSelector(service, state => {
    return <T>(guard: Guard<T>) =>
      (payload: T) => {
        return guard(state.context)(self, payload);
      };
  });

  return {guard};
};
