import {useCallback, useEffect} from 'react';

/**
 * This hook will listen to the window focus event.
 * @param fn
 */
export const useWindowFocus = (fn: () => void) => {
  const listenFocus = useCallback(() => {
    fn();
  }, [fn]);

  useEffect(() => {
    window.addEventListener('focus', listenFocus);
    return () => {
      window.removeEventListener('focus', listenFocus);
    };
  }, [listenFocus]);
};
