import React from 'react';
import styled, {css} from 'styled-components';

interface CardPlaceholderProps {
  hasCover?: boolean;
}

const StyledCardPlaceholder = styled.div<{$hasCover: boolean}>`
  background-color: ${p => p.theme.colors.neutral.subtle};
  border-radius: 20px;
  max-width: 320px;
  min-height: 264px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${p =>
    p.$hasCover &&
    css`
      color: ${p => p.theme.colors.text.onEmphasis};
      justify-content: flex-end;
      height: 350px;
    `}
`;

export const CardPlaceholder = ({hasCover = false}: CardPlaceholderProps) => (
  <StyledCardPlaceholder $hasCover={hasCover} />
);
