import {getPrivateAxios} from '@/libs';

export interface FetchVersionDeleteParams {
  packageUuid: string;
  versionUuid: string;
}

/**
 *
 * @param params
 */
export const fetchVersionDelete = async ({
  packageUuid,
  versionUuid
}: FetchVersionDeleteParams) => {
  const {data} = await getPrivateAxios().delete<void>(
    `/workshop/packages/${packageUuid}/versions/${versionUuid}`
  );
  return data;
};
