import React from 'react';
import {Outlet} from 'react-router-dom';
import {Shell} from '@openquiz/quiz-ui';
import {WorkshopSidebar} from '@/components';

export const WorkshopLayout = () => (
  <Shell sidebar={<WorkshopSidebar />}>
    <Outlet />
  </Shell>
);
