import {useCallback, useContext} from 'react';
import {useSelector} from '@xstate/react';
import {GameMachineContext} from '../providers';

type BasicQuestion =
  | 'idle'
  | 'splash'
  | 'loading'
  | 'prePlay'
  | 'play'
  | 'tryAnswerBuffer'
  | 'tryAnswer'
  | 'answer';

type NoRiskQuestion = 'idle' | 'splash' | 'play' | 'answer';

type SecretQuestion = 'idle' | 'splash' | 'play' | 'answer';

type VariantsQuestion = 'idle' | 'splash' | 'play' | 'answer';

type VariantsOneByOneQuestion =
  | 'idle'
  | 'splash'
  | 'prePlay'
  | 'play'
  | 'tryAnswerBuffer'
  | 'tryAnswer'
  | 'answer';

type AuctionQuestion = 'idle' | 'splash' | 'bets' | 'play' | 'answer';

type BasicRound =
  | 'idle'
  | 'splash'
  | 'choose'
  | 'fetch'
  | {basicQuestion: BasicQuestion}
  | 'basicQuestion'
  | {noRiskQuestion: NoRiskQuestion}
  | 'noRiskQuestion'
  | {secretQuestion: SecretQuestion}
  | 'secretQuestion'
  | {variantsQuestion: VariantsQuestion}
  | 'variantsQuestion'
  | {variantsOneByOneQuestion: VariantsOneByOneQuestion}
  | 'variantsOneByOneQuestion'
  | {auctionQuestion: AuctionQuestion}
  | 'auctionQuestion'
  | 'done'
  | 'end';

type FinalRound =
  | 'idle'
  | 'splash'
  | 'bans'
  | 'bets'
  | {question: 'idle' | 'play' | 'review' | 'answer' | 'end'}
  | 'question'
  | 'end';

interface TypedValue {
  game:
    | 'idle'
    | {basicRound: BasicRound}
    | 'basicRound'
    | {finalRound: FinalRound}
    | 'finalRound'
    | 'end';
}

/**
 *
 */
export const useGameService = () => {
  const {service, socket, emit} = useContext(GameMachineContext);

  const {matches} = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    service!,
    state => state,
    (a, b) => {
      return JSON.stringify(a.value) === JSON.stringify(b.value);
    }
  );

  const isMatch = useCallback((states: TypedValue[]) => states.some(matches), [matches]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return {service: service!, socket, matches, isMatch, emit};
};
