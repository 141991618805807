import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export interface FetchThemeParams {
  uuid: string;
  packageUuid: string;
}

/**
 *
 * @param params
 */
export const fetchTheme = async (params: FetchThemeParams) => {
  const {data} = await getPrivateAxios().get<PackageTheme>(
    `/workshop/packages/${params.packageUuid}/themes/${params.uuid}`
  );
  return data;
};
