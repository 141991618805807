import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface CardEntryProps {
  children: ReactNode;
}

const StyledCardEntry = styled.div`
  margin-top: auto;
`;

export const CardEntry = ({children}: CardEntryProps) => (
  <StyledCardEntry>{children}</StyledCardEntry>
);
