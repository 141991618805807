import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {useSelector} from '@xstate/react';
import {SplashScreen, selectPause, useGameService} from '@/features/machine';

interface ScreenContentProps {
  children?: ReactNode;
}

const StyledScreenContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;

  @media only screen and (min-width: 320px) {
    margin-bottom: 16px;
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 16px;
  }

  @media only screen and (min-width: 1440px) {
    margin-bottom: 24px;
  }
`;

export const ScreenContent = ({children}: ScreenContentProps) => {
  const {service} = useGameService();
  const pause = useSelector(service, selectPause);

  if (pause) {
    // TODO: унести в отдельный экран
    return (
      <SplashScreen
        icon={
          <picture>
            <source
              srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f634/512.webp"
              type="image/webp"
            />
            <img
              src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f634/512.gif"
              alt="😴"
              width={96}
              height={96}
            />
          </picture>
        }>
        <h2>Пауза</h2>
      </SplashScreen>
    );
  }

  return <StyledScreenContent>{children}</StyledScreenContent>;
};
