import {PackageTheme} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export type ThemeUpdateData = PackageTheme;

export interface FetchThemeUpdateParams {
  data: ThemeUpdateData;
  packageUuid: string;
}

/**
 *
 * @param params
 */
export const fetchThemeUpdate = async (params: FetchThemeUpdateParams) => {
  const {data} = await getPrivateAxios().patch<PackageTheme>(
    `/workshop/packages/${params.packageUuid}/themes/${params.data.uuid}`,
    params.data
  );
  return data;
};
