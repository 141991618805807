import React, {Children, HTMLAttributes, ReactNode, forwardRef} from 'react';
import styled, {css} from 'styled-components';

export interface FieldRowProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  size?: 'packed' | 'xs' | 'sm' | 'md' | 'lg' | 'xlg';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  align?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
}

const StyledFieldRow = styled.div<{
  $size: FieldRowProps['size'];
  $justify: FieldRowProps['justify'];
  $align: FieldRowProps['align'];
}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > a {
    display: flex;
  }

  ${p =>
    p.$justify &&
    css`
      justify-content: ${p.$justify};
    `}

  ${p =>
    p.$justify?.includes('space-') &&
    css`
      width: 100%;
    `}

  ${p =>
    p.$align &&
    css`
      align-items: ${p.$align};
    `}

  ${p =>
    p.$size === 'packed' &&
    css`
      gap: -4px;
    `}

  ${p =>
    p.$size === 'xs' &&
    css`
      gap: 4px;
    `}

  ${p =>
    p.$size === 'sm' &&
    css`
      gap: 8px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      gap: 16px;
    `}

  ${p =>
    p.$size === 'lg' &&
    css`
      gap: 24px;
    `}

  ${p =>
    p.$size === 'xlg' &&
    css`
      gap: 32px;
    `}

  &:empty {
    display: none;
  }

  & > * {
    margin-bottom: 0;
  }
`;

export const FieldRow = forwardRef<HTMLDivElement, FieldRowProps>(function FieldRow(
  {children, size = 'md', justify = 'flex-start', align = 'center', ...rest},
  ref
) {
  return (
    <StyledFieldRow {...rest} ref={ref} $size={size} $justify={justify} $align={align}>
      {Children.map(children, child => (child ? child : null))}
    </StyledFieldRow>
  );
});
