import {useState} from 'react';

interface Params {
  initial: number[];
  mode: 'single' | 'multiple';
}

export const useMemoryCollapsedList = ({initial, mode}: Params) => {
  const [expandedArray, setExpandedArray] = useState<number[]>(initial);

  const onClickToggle = (index: number) => () => {
    if (mode === 'single') {
      setExpandedArray([index]);
      return;
    }

    if (expandedArray.includes(index)) {
      setExpandedArray(arr => arr.filter(a => a !== index));
      return;
    }

    setExpandedArray(values => [...values, index]);
  };

  const getItemProps = (index: number) => {
    return {expanded: expandedArray.includes(index), onClickToggle: onClickToggle(index)};
  };

  return {expandedArray, getItemProps};
};
