import React, {useMemo, useRef} from 'react';
import {FormattedDate} from 'react-intl';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {AnimatePresence, motion, useInView} from 'framer-motion';
import {CalendarBlank} from '@phosphor-icons/react';
import {Stat, Tooltip, getAnimationProps, getLineClampCss} from '@openquiz/quiz-ui';
import {AuthorChip, Meta, PackageFull, Rating} from '@/features/wshop-packages';
import {useDeviceMedia} from '@/hooks';

interface PackageRowProps {
  pkg: PackageFull;
}

const StyledPackageRow = styled(Link)`
  position: relative;
  border-radius: 16px;
  display: flex;
  padding: 20px;
  width: 100%;
  margin: 0 0 1px 0;
  color: inherit;
  transition:
    background-color 80ms ease-in-out,
    transform 80ms ease-in-out;

  &::after {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: -1px;
    height: 1px;
    border-bottom: 1px dotted ${p => p.theme.colors.border.subtle};
    content: '';
  }

  :hover {
    background-color: ${p => p.theme.colors.neutral.subtle};
  }

  :active {
    transform: scale(0.99);
  }
`;

const AnimatedWrapper = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  gap: 24px;
`;

const CoverWrapper = styled.div`
  background-color: ${p => p.theme.colors.border.subtle};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  border-radius: 12px;
  flex-shrink: 0;
  overflow: hidden;

  @media only screen and (min-width: 320px) {
    width: 64px;
    height: 64px;
  }

  @media only screen and (min-width: 1024px) {
    width: 130px;
    height: 130px;
  }
`;

const Cover = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Entry = styled.div`
  margin-right: auto;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 4px;
  word-break: break-all;
`;

const Desc = styled.div<{$lines: number}>`
  ${p => getLineClampCss(p.$lines)};
  ${p => p.theme.typography.body.md};
  color: ${p => p.theme.colors.text.default};
  margin-bottom: 16px;
  max-width: 600px;
  white-space: pre-wrap;
`;

const Footer = styled.div`
  color: ${p => p.theme.colors.text.subtle};
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 16px;
`;

const animatedWrapperMotionProps = getAnimationProps(
  {
    initial: {y: 4, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: -4, opacity: 0}
  },
  {duration: 0.1}
);

export const PackageRow = ({pkg}: PackageRowProps) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const isInView = useInView(ref, {margin: '200px'});
  const device = useDeviceMedia();

  const cover = useMemo(() => pkg.files[0]?.file, [pkg.files]);

  const lines = useMemo(
    () =>
      pkg.description
        ? Math.max(1, Math.min(3, Math.round(pkg.description.length / 70)))
        : 0,
    [pkg.description]
  );

  const rowHeight = useMemo(
    () => (device === 'mobile' ? 94 + 28 : 94) + lines * 24,
    [device, lines]
  );

  const nameHeight =
    device === 'mobile' ? Math.max(1, Math.round(pkg.name.length / 14)) * 26 : 26;

  const categoriesHeight =
    device === 'mobile'
      ? Math.max(
          1,
          Math.round(pkg.categories.map(c => c.category.name).join().length / 7)
        ) * 32
      : 32;

  const tagsHeight =
    device === 'mobile'
      ? Math.max(1, Math.round(pkg.tags.map(c => c.tag.name).join().length / 22)) * 14
      : 14;

  return (
    <StyledPackageRow
      ref={ref}
      style={{minHeight: rowHeight + nameHeight + categoriesHeight + tagsHeight}}
      to={`/workshop/${pkg.uuid}`}>
      <AnimatePresence initial={false}>
        {isInView && (
          <AnimatedWrapper {...animatedWrapperMotionProps}>
            <CoverWrapper>{cover?.src && <Cover src={cover?.src ?? ''} />}</CoverWrapper>

            <Entry>
              <Name>{pkg.name}</Name>
              <Desc $lines={lines}>{pkg.description}</Desc>

              <Footer>
                <Rating
                  rating={{value: pkg.rating, count: pkg._count.ratings}}
                  layout="value"
                />
                <AuthorChip user={pkg.user} />
                <Tooltip message="Дата создания">
                  <Stat icon={CalendarBlank}>
                    <FormattedDate value={pkg.createdAt} dateStyle="short" />
                  </Stat>
                </Tooltip>
              </Footer>

              <Meta pkg={pkg} hasSizeBar={true} />
            </Entry>
          </AnimatedWrapper>
        )}
      </AnimatePresence>
    </StyledPackageRow>
  );
};
