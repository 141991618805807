import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {generatePath} from 'react-router-dom';
import styled, {useTheme} from 'styled-components';
import {FieldSection, LineChart, StatsCard} from '@openquiz/quiz-ui';
import {usePackageQuery, usePackageStatsQuery} from '@/features/wshop-packages';
import {editorInfoPath, useEditorContext} from '@/features/wshop-editor';
import {Page, PageFallback, PageTitle} from '@/components';

const CustomGrid = styled.div`
  display: grid;

  .chart {
    grid-area: chart;
  }

  @media only screen and (min-width: 320px) {
    grid-template-areas: 'chart';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto auto;
    gap: 16px;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-areas:
      'chart .'
      'chart .';
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 16px;
  }

  @media only screen and (min-width: 1440px) {
    grid-template-areas:
      'chart .'
      'chart .';
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 16px;
  }
`;

export const StatisticsPage = () => {
  const theme = useTheme();
  const {packageUuid} = useEditorContext();
  const {data: pkg, isLoading} = usePackageQuery(packageUuid);
  const {data: statistics, isLoading: isLoadingStatistics} =
    usePackageStatsQuery(packageUuid);

  const currentRangeStarts = useMemo(() => {
    if (!statistics?.chart) return 0;
    return statistics.chart.reduce((acc, obj) => acc + obj.value.all, 0);
  }, [statistics?.chart]);

  if (isLoading || isLoadingStatistics || !pkg || !statistics) {
    return <PageFallback />;
  }

  return (
    <Page>
      <PageTitle
        to={{href: generatePath(editorInfoPath, {packageUuid}), force: true}}
        title="Статистика"
      />

      <FieldSection size="lg">
        <CustomGrid>
          <div className="chart">
            <StatsCard
              label="Количество запусков"
              value={[currentRangeStarts, statistics.starts]}
              extra="За последние 2 недели">
              <LineChart
                data={statistics.chart}
                lines={[
                  {
                    key: 'all',
                    label: 'Запусков',
                    strokeColor: theme.colors.accent.emphasis,
                    strokeWidth: 3
                  }
                ]}
              />
            </StatsCard>
          </div>

          <StatsCard label="Процент доигрывания" value={<>{statistics.percents}%</>} />
          <StatsCard
            label="Рейтинг"
            value={pkg.rating}
            extra={
              <FormattedMessage
                id="workshop.rating.count"
                values={{count: pkg._count.ratings}}
              />
            }
          />
        </CustomGrid>
      </FieldSection>
    </Page>
  );
};
