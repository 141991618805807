import {useEffect} from 'react';
import ReactGA from 'react-ga4';
import {usePostHog} from 'posthog-js/react';
import {useCurrentUserQuery} from '@/features/user';

/**
 * This hook initializes Sentry, Google Analytics and sets the current user.
 */
export const useAnalytics = () => {
  const posthog = usePostHog();
  const {data: currentUser} = useCurrentUserQuery();

  useEffect(() => {
    if (import.meta.env.DEV) return;

    ReactGA.initialize('G-V9LMYBM4N9');
  }, []);

  useEffect(() => {
    if (!currentUser) return;
    posthog?.identify(currentUser.uuid, {
      email: currentUser.email
    });
  }, [posthog, currentUser]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const username = [currentUser.username, currentUser.discriminator].join('#');

    ReactGA.set({
      user_properties: {
        uuid: currentUser.uuid,
        email: currentUser.email,
        username
      }
    });
  }, [currentUser]);
};
