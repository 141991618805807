import {useMachineSelector} from '@/features/machine';
import {useCurrentUserQuery} from '@/features/user';

export const useMachineSelf = () => {
  const {data: currentUser} = useCurrentUserQuery();
  return useMachineSelector(state => {
    const user = state.context.users.find(user => user.uuid === currentUser?.uuid);
    return user
      ? {teamUuid: user.teamUuid as string, userUuid: user.uuid}
      : {teamUuid: '', userUuid: currentUser?.uuid as string};
  });
};
