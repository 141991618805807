import React, {VideoHTMLAttributes, useEffect, useRef} from 'react';

interface VideoStreamProps extends VideoHTMLAttributes<HTMLVideoElement> {
  stream: MediaStream;
}

export const VideoStream = ({stream, ...rest}: VideoStreamProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.srcObject = stream;
  }, [stream, videoRef.current]);

  return <video {...rest} ref={videoRef} />;
};
