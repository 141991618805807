import React from 'react';
import toast from 'react-hot-toast';
import {generatePath, useNavigate} from 'react-router-dom';
import {ModalConfirm, useModalConfirm} from '@openquiz/quiz-modal';
import {Surface} from '@openquiz/quiz-ui';
import {InformationForm, useEditorContext} from '@/features/wshop-editor';
import {
  PackageCreateData,
  PackageUpdateData,
  fetchPackageDelete,
  packagePath,
  usePackageQuery,
  usePackageUpdate
} from '@/features/wshop-packages';
import {Page, PageFallback, PageTitle} from '@/components';

export const InfoPage = () => {
  const navigate = useNavigate();
  const {packageUuid} = useEditorContext();
  const {data: pkg, isLoading} = usePackageQuery(packageUuid);
  const {mutateAsync} = usePackageUpdate();

  const confirmDelete = useModalConfirm(props => (
    <ModalConfirm
      {...props}
      content={<>Это действие нельзя отменить.</>}
      resolveLabel="Удалить"
    />
  ));

  const onFormSubmit = async (data: PackageCreateData | PackageUpdateData) => {
    if ('uuid' in data) {
      try {
        await mutateAsync(data);
        toast.success('Изменения успешно сохранены');
      } catch (err) {
        toast.error('При изменении пака произошла ошибка');
      }
    }
  };

  const onDelete = async () => {
    if (!pkg) return;
    try {
      await confirmDelete();
      await fetchPackageDelete(pkg.uuid);
      navigate('/workshop/drafts');
    } catch (err) {
      /* empty */
    }
  };

  if (!pkg || isLoading) {
    return <PageFallback />;
  }

  return (
    <Page>
      <PageTitle
        to={{href: generatePath(packagePath, {packageUuid}), force: true}}
        title="Информация о паке"
      />
      <Surface>
        <InformationForm pkg={pkg} onSubmit={onFormSubmit} onDelete={onDelete} />
      </Surface>
    </Page>
  );
};
