import React from 'react';
import {useTheme} from 'styled-components';
import {tickDateFormatter} from './dateFormatter';

interface TickCustomProps {
  x: number;
  y: number;
  payload: {
    value: string;
  };
}

export const TickCustom = ({x, y, payload}: TickCustomProps) => {
  const theme = useTheme();
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={12}
        textAnchor="middle"
        fill={theme.colors.text.subtle}
        fontSize={12}>
        {tickDateFormatter(payload.value)}
      </text>
    </g>
  );
};
