import React, {useEffect} from 'react';
import {useInterval} from 'react-use';
import {motion, useAnimate, useCycle} from 'framer-motion';
import styled from 'styled-components';
import {FieldRow} from '@openquiz/quiz-ui';

const StyledStartGameGif = styled.div`
  position: relative;
  margin: -64px -64px 0 -64px;
  padding: 64px 0 0 0;
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
`;

const Area = styled.div`
  position: relative;
  z-index: 1;
`;

const ThemeName = styled(motion.div)`
  color: ${p => p.theme.colors.text.muted};
  margin-bottom: 4px;
  font-weight: 500;
`;

const Card = styled(motion.div)`
  position: relative;
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 120px;
  height: 60px;
  z-index: 1;
`;

export const StartGameGif = () => {
  const [scope, animate] = useAnimate();
  const [x, cycleX] = useCycle({scale: 1}, {scale: 0.9});

  useInterval(() => {
    cycleX();
  }, 1000);

  useEffect(() => {
    animate(scope.current, {scale: x.scale});
  }, [x]);

  return (
    <StyledStartGameGif>
      <Area>
        <ThemeName
          initial={{y: 32, opacity: 0}}
          animate={{y: 0, opacity: 1}}
          exit={{y: 32, opacity: 0}}>
          Название темы
        </ThemeName>
        <FieldRow>
          <Card
            initial={{y: 32, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            exit={{y: 32, opacity: 0}}>
            400
          </Card>
          <Card
            ref={scope}
            initial={{y: 32, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            exit={{y: 32, opacity: 0}}
            transition={{
              delay: 0.1
            }}>
            500
          </Card>
          <Card
            initial={{y: 32, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            exit={{y: 32, opacity: 0}}
            transition={{
              delay: 0.2
            }}>
            600
          </Card>
        </FieldRow>
      </Area>
    </StyledStartGameGif>
  );
};
