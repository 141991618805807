import React, {forwardRef} from 'react';
import {At} from '@phosphor-icons/react';
import {StyledCreatorLabel} from './styled';
import {CreatorLabelProps} from './types';

const iconSizeMap = new Map([
  ['sm', 14],
  ['md', 16]
]);

export const CreatorLabel = forwardRef<HTMLDivElement, CreatorLabelProps>(
  function CreatorLabel({children, size = 'md'}, ref) {
    return (
      <StyledCreatorLabel ref={ref} $size={size}>
        <At size={iconSizeMap.get(size)} weight="bold" />
        <span>{children}</span>
      </StyledCreatorLabel>
    );
  }
);
