import {createContext} from 'react';

export interface EditorContextType {
  packageUuid: string;
  roundUuid: string;
  versionUuid: string;
}

/**
 * Modal Context Object
 */
export const EditorContext = createContext<EditorContextType>({
  packageUuid: '',
  roundUuid: '',
  versionUuid: ''
});
