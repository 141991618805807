import React, {useMemo} from 'react';
import {
  CornersIn,
  CornersOut,
  Faders,
  FastForward,
  Gear,
  Pause,
  Play,
  SignOut
} from '@phosphor-icons/react';
import {Menu, MenuGroup, MenuItem, useModal} from '@openquiz/quiz-ui';
import {SettingsModal} from '@/features/user';
import {
  ConfirmDisconnectModal,
  MachineSettingsModal,
  selectPause,
  useMachineEvent,
  useMachineSelector
} from '@/features/machine';
import {SkipRoundEvent, TogglePauseEvent} from '@opeq-dev/openquiz-machine';

interface ControlBarMoreMenuProps {
  onClose?: () => void;
}

const checkSupportFullScreen = () =>
  typeof document.body.requestFullscreen !== 'undefined' ||
  typeof document.exitFullscreen !== 'undefined';

export const ControlBarMoreMenu = ({onClose = () => void 0}: ControlBarMoreMenuProps) => {
  const {role, event} = useMachineEvent();

  const isSupportFullScreen = useMemo(() => checkSupportFullScreen(), []);
  const pause = useMachineSelector(selectPause);

  const [openMachineSettingModal, closeMachineSettingModal] = useModal(
    () => <MachineSettingsModal onClose={closeMachineSettingModal} />,
    []
  );

  const [openSettingModal, closeSettingModal] = useModal(
    () => <SettingsModal onClose={closeSettingModal} />,
    []
  );

  const [openConfirmDisconnectModal, closeConfirmDisconnectModal] = useModal(
    () => <ConfirmDisconnectModal onClose={closeConfirmDisconnectModal} />,
    []
  );

  const onClickToggleFullScreen = () => {
    onClose();

    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const onClickSkipRound = () => {
    event<SkipRoundEvent>('skipRound', undefined);
    onClose();
  };

  const onClickGameSettings = () => {
    onClose();
    openMachineSettingModal();
  };

  const onClickSettings = () => {
    onClose();
    openSettingModal();
  };

  const onClickDisconnect = () => {
    openConfirmDisconnectModal();
    onClose();
  };

  const onClickPause = () => {
    event<TogglePauseEvent>('togglePause', undefined);
    onClose();
  };

  return (
    <Menu>
      {role === 'LEADER' && (
        <MenuGroup label="Лидер">
          <MenuItem icon={Gear} onClick={onClickGameSettings}>
            Параметры игры
          </MenuItem>
          <MenuItem icon={FastForward} onClick={onClickSkipRound}>
            Следующий раунд
          </MenuItem>
          {pause ? (
            <MenuItem icon={Play} onClick={onClickPause}>
              Продолжить
            </MenuItem>
          ) : (
            <MenuItem icon={Pause} onClick={onClickPause}>
              Пауза
            </MenuItem>
          )}
        </MenuGroup>
      )}

      <MenuGroup label="Приложение">
        {isSupportFullScreen && (
          <MenuItem
            icon={document.fullscreenElement ? CornersIn : CornersOut}
            onClick={onClickToggleFullScreen}>
            {document.fullscreenElement ? 'Обычный режим' : 'На весь экран'}
          </MenuItem>
        )}
        <MenuItem icon={Faders} onClick={onClickSettings}>
          Настройки
        </MenuItem>
      </MenuGroup>

      <MenuGroup>
        <MenuItem icon={SignOut} color="danger" onClick={onClickDisconnect}>
          Покинуть игру
        </MenuItem>
      </MenuGroup>
    </Menu>
  );
};
