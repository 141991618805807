import React, {memo, useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';
import {PackageQuestionStep} from '@opeq-dev/openquiz-schema';
import {QuestionStepStage, lineReadDuration} from '@opeq-dev/openquiz-machine';
import {AnimatedText} from './AnimatedText';
import {SpeechAudioPlayer} from '@/features/machine/components/RenderSteps/SpeechAudioPlayer';

interface TextStepProps {
  stage: QuestionStepStage;
  step: PackageQuestionStep;
}

const StyledTextStep = styled.div`
  max-width: 600px;
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
  user-select: none;

  font-size: 28px;
  line-height: 140%;
`;

const getLinesWithDuration = (text: string) => {
  const lines = text.split('\n').map((text, index) => ({
    text,
    delay: index > 0 ? lineReadDuration(text) : 0
  }));

  lines.forEach((line, index) => {
    lines[index] = {
      ...line,
      delay: index > 0 ? lines[index - 1].delay + line.delay : line.delay
    };
  });

  return lines;
};

export const TextStep = memo(({stage, step}: TextStepProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const parentHeight = ref.current.parentElement?.getBoundingClientRect().height || 0;
    const refHeight = ref.current.getBoundingClientRect().height;
    const refComputedStyle = window.getComputedStyle(ref.current);
    const containmentRatio = parentHeight / refHeight;

    if (containmentRatio < 1.2) {
      ref.current.style.fontSize = `${
        parseInt(refComputedStyle.fontSize) * (containmentRatio * 0.9)
      }px`;
    }
  }, [ref]);

  const speed = stage === 'pre-play' ? 0.2 : 0.003;

  const lines = useMemo(
    () => getLinesWithDuration(step.payload),
    [getLinesWithDuration, step.payload]
  );

  return (
    <StyledTextStep ref={ref}>
      {step.speech && <SpeechAudioPlayer speech={step.speech} />}
      {lines.map((line, index) => (
        <AnimatedText
          key={`${step.uuid}-${index}`}
          text={line.text}
          delay={stage === 'pre-play' ? line.delay : speed}
          speed={speed}
        />
      ))}
    </StyledTextStep>
  );
});
