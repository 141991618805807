import React from 'react';
import {ControlBarContainer} from '../ControlBarContainer';
import {ControlBarGroup} from '../ControlBarGroup';
import {ControlBarMore} from '../ControlBarMore';
import {ControlBarVolume} from '../ControlBarVolume';
import {PlayerAnswerButton} from '../PlayerAnswerButton';
import {PlayerFinalAnswers} from '../PlayerFinalAnswers';
import {WebCameraButton} from '../WebCameraButton';
import {ReactionButton} from '../ReactionButton';

export const PlayerBar = () => (
  <>
    <PlayerFinalAnswers />

    <ControlBarContainer>
      <ControlBarGroup>
        <PlayerAnswerButton />
      </ControlBarGroup>

      <ControlBarGroup>
        <ReactionButton />
        <WebCameraButton />
        <ControlBarVolume />
      </ControlBarGroup>

      <ControlBarGroup>
        <ControlBarMore />
      </ControlBarGroup>
    </ControlBarContainer>
  </>
);
