import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PackageRound} from '@opeq-dev/openquiz-schema';
import {fetchRoundUpdate} from './fetchRoundUpdate';

/**
 *
 */
export const useRoundUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchRoundUpdate,
    onMutate: variables => {
      queryClient.setQueryData<PackageRound[]>(
        [
          'editor',
          'rounds',
          {packageUuid: variables.packageUuid, versionUuid: variables.versionUuid}
        ],
        (rounds = []) =>
          rounds.map(round => {
            if (round.uuid === variables.data.uuid) {
              return {...round, ...variables.data};
            }
            return round;
          })
      );

      queryClient.setQueryData<PackageRound>(
        [
          'editor',
          'rounds',
          {packageUuid: variables.packageUuid, versionUuid: variables.versionUuid},
          variables.data.uuid
        ],
        variables.data
      );
    }
  });
};
