import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PackageFull, fetchPackageUpdate} from '../api';

export const usePackageUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchPackageUpdate,
    onSuccess: pkg => {
      queryClient.setQueryData<PackageFull>(['packages', {uuid: pkg.uuid}], data => {
        if (!data) return;
        return {...data, ...pkg};
      });
    }
  });
};
