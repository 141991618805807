import React from 'react';
import {Smiley} from '@phosphor-icons/react';
import {PopperMenu, Tooltip} from '@openquiz/quiz-ui';
import {ControlBarButton} from '../ControlBarButton';
import {ReactionMenu} from './ReactionMenu';

export const ReactionButton = () => (
  <Tooltip message="Реакции" placement="top" closeOnReferencePress={true}>
    <div>
      <PopperMenu menu={<ReactionMenu />}>
        <ControlBarButton variant="secondary">
          <Smiley size={24} weight="bold" />
        </ControlBarButton>
      </PopperMenu>
    </div>
  </Tooltip>
);
