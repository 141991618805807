import {State} from 'xstate';
import {Ctx, getPkgVersion} from '@opeq-dev/openquiz-machine';

/**
 *
 * @param state
 * @returns
 */
export const selectCtxCurrentQuestion = (state: State<Ctx>) =>
  getPkgVersion(state.context)?.questions.find(
    question => question.uuid === state.context.current.questionUuid
  );
