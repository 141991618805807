import React from 'react';
import {useKey} from 'react-use';
import styled, {css} from 'styled-components';
import {X} from '@phosphor-icons/react';
import {ModalProps} from '../../types';

const StyledModal = styled.div<{$size: ModalProps['size']}>`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.floating};
  position: relative;
  margin-top: 10%;
  width: 100%;
  padding: 48px;
  border-radius: 16px;
  overflow: hidden;
  height: auto;

  @media only screen and (min-width: 320px) {
    padding: 32px;
  }

  ${p =>
    p.$size === 'sm' &&
    css`
      max-width: 400px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      max-width: 460px;
    `}
`;

const ButtonClose = styled.button`
  background-color: ${p => p.theme.colors.neutral.muted};
  color: ${p => p.theme.colors.text.subtle};
  border-radius: 8px;
  border: none;
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 32px;
  height: 32px;
  z-index: 1;
  cursor: pointer;
  transition:
    background-color ease-in-out 80ms,
    color ease-in-out 80ms;

  &:hover {
    background-color: ${p => p.theme.colors.neutral.emphasisPlus};
    color: ${p => p.theme.colors.text.onEmphasis};
  }
`;

export const Modal = ({children, size = 'md', onCancel}: ModalProps) => {
  useKey('Escape', onCancel);
  return (
    <StyledModal $size={size}>
      <ButtonClose onClick={onCancel}>
        <X size={24} weight="bold" />
      </ButtonClose>
      {children}
    </StyledModal>
  );
};
