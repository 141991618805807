export const createEmptyAudioTrack = () => {
  const ctx = new AudioContext();
  const oscillator = ctx.createOscillator();
  const dst = oscillator.connect(ctx.createMediaStreamDestination());
  oscillator.start();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const track = dst.stream.getAudioTracks()[0];
  return Object.assign(track, {enabled: false});
};

/**
 * The function creates a media stream with two empty tracks.
 */
export const createEmptyMediaStream = () => {
  return new MediaStream([createEmptyAudioTrack()]);
};
