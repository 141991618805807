import {
  PackageQuestion,
  PackageQuestionStep,
  PackageRound,
  PackageTheme
} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export interface ValidateMessage {
  data:
    | string[]
    | Pick<PackageRound, 'uuid' | 'name' | 'versionUuid'>[]
    | Pick<PackageTheme, 'uuid' | 'name' | 'roundUuid' | 'versionUuid'>[]
    | {
        question: Pick<PackageQuestion, 'uuid' | 'price' | 'themeUuid' | 'versionUuid'>;
        theme: Pick<PackageTheme, 'uuid' | 'name' | 'roundUuid' | 'versionUuid'>;
      }[]
    | {
        step: Pick<
          PackageQuestionStep,
          'uuid' | 'position' | 'isAnswer' | 'questionUuid' | 'versionUuid'
        > & {hash: string};
        question: Pick<PackageQuestion, 'uuid' | 'price' | 'themeUuid' | 'versionUuid'>;
        theme: Pick<PackageTheme, 'uuid' | 'name' | 'roundUuid' | 'versionUuid'>;
      }[];
  message: string;
}

/**
 * Fetch the validation of a package.
 * @param uuid
 */
export const fetchPackageValidate = async (uuid: string) => {
  const {data} = await getPrivateAxios().post<ValidateMessage[]>(
    `/workshop/packages/${uuid}/validate`
  );
  return data;
};
