import React, {useRef} from 'react';
import {useKey} from 'react-use';
import {FormattedDate} from 'react-intl';
import {motion} from 'framer-motion';
import styled from 'styled-components';
import {UserStats} from '@opeq-dev/openquiz-schema';
import {getAnimationProps, useTilt} from '@openquiz/quiz-ui';
import {Achievement, AchievementPicture} from '@/features/achievement';

interface AchievementPreviewProps {
  achievement: Achievement;
  stat: UserStats;
  onClose: () => void;
}

const StyledAchievementPreview = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  height: 100%;
  z-index: 1111;
  user-select: none;
  backdrop-filter: blur(64px);
`;

const Entry = styled(motion.div)`
  @media only screen and (min-width: 320px) {
    max-width: 320px;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 512px;
  }

  @media only screen and (min-width: 1440px) {
    max-width: 512px;
  }
`;

const Info = styled(motion.div)`
  ${p => p.theme.typography.body.xlg};
  color: ${p => p.theme.colors.text.onEmphasis};
  text-align: center;
  opacity: 0.6;
`;

const Label = styled.div`
  opacity: 0.5;
`;

const achievementPreviewAnimationProps = getAnimationProps(
  {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0}
  },
  {duration: 0.2}
);

const infoAnimationProps = getAnimationProps(
  {
    initial: {y: -8, opacity: 0},
    animate: {y: 0, opacity: 1},
    exit: {y: -8, opacity: 0}
  },
  {duration: 0.2}
);

export const AchievementPreview = ({
  achievement,
  stat,
  onClose
}: AchievementPreviewProps) => {
  const ref = useRef<HTMLImageElement>(null);

  useTilt(ref);
  useKey('Escape', onClose);

  return (
    <StyledAchievementPreview {...achievementPreviewAnimationProps} onClick={onClose}>
      <Entry layoutId={stat.name}>
        <AchievementPicture ref={ref} achievement={achievement} stat={stat} />
      </Entry>

      <Info {...infoAnimationProps}>
        <Label>Получено</Label>
        <FormattedDate value={stat.createdAt} dateStyle="long" />
      </Info>
    </StyledAchievementPreview>
  );
};
