import {useEffect, useRef, useState} from 'react';

export const useAutoPlayService = () => {
  const ref = useRef<HTMLVideoElement>(null);
  const [status, setStatus] = useState<'idle' | 'allow' | 'disallow'>('idle');

  const grant = () => {
    if (!ref.current) return;

    ref.current
      .play()
      .then(() => {
        setStatus('allow');
      })
      .catch(() => {
        setStatus('disallow');
      });
  };

  useEffect(() => {
    window.addEventListener('click', () => {
      if (!ref.current) return;
      ref.current
        .play()
        .then(() => {
          setStatus('allow');
        })
        .catch(() => {
          setStatus('disallow');
        });
    });
  }, [ref.current]);

  return {ref, status, grant};
};
