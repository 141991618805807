import {create} from 'zustand';
import {persist} from 'zustand/middleware';

interface AuthState {
  token: string | null;
  update: (token: string | null) => void;
  logout: () => void;
}

export const authStoreKey = 'auth-store';

export const useAuthStore = create(
  persist<AuthState>(
    set => ({
      token: null,
      update: (token: string | null) => set({token}),
      logout: () => set({token: null})
    }),
    {name: authStoreKey}
  )
);
