import React, {ReactNode} from 'react';
import {FormattedDate} from 'react-intl';
import styled from 'styled-components';
import {colord} from 'colord';
import {ArrowSquareOut} from '@phosphor-icons/react';
import {OpenQuizIcon} from '@openquiz/quiz-ui';

export interface Slide {
  id: string;
  title: string;
  type: string;
  appearance: {
    color: string;
    pattern: string;
  };
  content: ReactNode;
  link: string;
  createdAt: number;
}

interface UpdateSlideProps {
  slide: Slide;
}

const StyledUpdateSlide = styled.div<{$appearance: Slide['appearance']}>`
  background-image: linear-gradient(
    160deg,
    ${p => colord(p.$appearance.color).darken(0.1).toHex()} 0%,
    ${p => p.$appearance.color} 100%
  );
  background-color: ${p => p.theme.colors.accent.emphasis};
  color: ${p => p.theme.colors.text.onEmphasis};
  position: relative;
  flex: 0 0 100%;
  min-width: 0;

  @media only screen and (min-width: 320px) {
    border-radius: 0;
    padding: 24px 24px 40px 24px;
  }

  @media only screen and (min-width: 1024px) {
    border-radius: 20px;
    padding: 40px 64px;
  }
`;

const Head = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  @media only screen and (min-width: 320px) {
    flex-direction: column;
    margin-bottom: 16px;
    gap: 16px;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    margin-bottom: 24px;
    gap: 24px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  display: block;
  z-index: 0;
  opacity: 0.2;
`;

const Type = styled.div`
  ${p => p.theme.typography.body.md};
  opacity: 0.5;
`;

const Title = styled.div`
  ${p => p.theme.typography.heading.h1};
`;

const Content = styled.div`
  ${p => p.theme.typography.body.md};

  position: relative;
  word-break: break-word;
  max-width: 740px;

  p {
    margin-bottom: 16px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 16px;
    margin-top: -8px;
  }

  ul:last-child {
    margin-bottom: 0;
  }
`;

const Links = styled.div`
  ${p => p.theme.typography.body.md};
`;

const DiscordLink = styled.a`
  ${p => p.theme.typography.label.md};
  color: inherit;
  display: inline-flex;
  align-items: center;
  gap: 4px;

  :hover {
    text-decoration: underline;
    opacity: 0.5;
  }
`;

export const UpdateSlide = ({slide}: UpdateSlideProps) => {
  return (
    <StyledUpdateSlide $appearance={slide.appearance}>
      <Overlay style={{backgroundImage: `url(${slide.appearance.pattern})`}} />
      <Head>
        <OpenQuizIcon size={56} />
        <div>
          <Type>
            Обновление,{' '}
            <FormattedDate value={new Date(slide.createdAt)} dateStyle="long" />
          </Type>
          <Title>{slide.title}</Title>
        </div>
      </Head>
      <Content>
        {slide.content}
        <Links>
          <DiscordLink href={slide.link} target="_blank" rel="noreferrer">
            <ArrowSquareOut size={20} weight="bold" />
            <span>Подробнее</span>
          </DiscordLink>
        </Links>
      </Content>
    </StyledUpdateSlide>
  );
};
