import React, {useMemo} from 'react';
import {colord} from 'colord';
import styled from 'styled-components';
import {TeamRecord} from '@opeq-dev/openquiz-machine';
import {UserLabel} from '@openquiz/quiz-ui';
import {FetchedUser} from '@/features/user';

interface TeamPillProps {
  team: TeamRecord;
  userUuid: string;
}

const StyledTeamPill = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 4px 6px;
  min-width: 64px;
  height: 28px;
`;

export const TeamPill = ({team, userUuid}: TeamPillProps) => {
  const styleObject = useMemo(
    () => ({
      colors: {
        background: colord(team.style.color).alpha(0.1).toRgbString(),
        border: colord(team.style.color).alpha(0.25).toRgbString(),
        text: team.style.color
      }
    }),
    [team.style.color]
  );

  return (
    <StyledTeamPill
      style={{
        backgroundColor: styleObject.colors.background,
        color: styleObject.colors.text
      }}>
      <FetchedUser uuid={userUuid}>
        {user => <UserLabel user={user} layoutId={`user-chip-${user.uuid}-label`} />}
      </FetchedUser>
    </StyledTeamPill>
  );
};
