import React from 'react';
import {useSelector} from '@xstate/react';
import {SplashScreen, selectCtxCurrentRound, useGameService} from '@/features/machine';

export const FinalRoundSplash = () => {
  const {service} = useGameService();
  const round = useSelector(service, selectCtxCurrentRound);

  if (!round) return null;

  return (
    <SplashScreen
      icon={
        <picture>
          <source
            srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f31f/512.webp"
            type="image/webp"
          />
          <img
            src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f31f/512.gif"
            alt="🌟"
            width={96}
            height={96}
          />
        </picture>
      }>
      <h1>Финальный раунд</h1>
    </SplashScreen>
  );
};
