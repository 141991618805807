import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
  Check,
  FieldGroup,
  FieldRow,
  FieldSection,
  Select,
  TipCircle,
  Toggle
} from '@openquiz/quiz-ui';
import {useUiSettingsStore} from '@/stores';

export const SettingsForm = () => {
  const {theme, size, sfx, hotkeys, updateTheme, updateSize, updateSfx, updateHotkeys} =
    useUiSettingsStore();

  const {register, control, watch} = useForm({
    defaultValues: {theme, size, sfx, hotkeys}
  });

  const value = watch();

  useEffect(() => {
    updateTheme(value.theme);
  }, [value.theme]);

  useEffect(() => {
    updateSize(value.size);
  }, [value.size]);

  useEffect(() => {
    updateSfx(value.sfx);
  }, [value.sfx]);

  useEffect(() => {
    updateHotkeys(value.hotkeys);
  }, [value.hotkeys]);

  return (
    <form>
      <FieldSection label="Приложение">
        <FieldGroup label="Тема" labelWidth="50%" inline={true} size="lg">
          <Check {...register('theme')} type="radio" value="auto">
            Системная
          </Check>
          <Check {...register('theme')} type="radio" value="light">
            Светлая
          </Check>
          <Check {...register('theme')} type="radio" value="dark">
            Темная
          </Check>
        </FieldGroup>

        <FieldGroup label="Размер интерфейса" labelWidth="50%" inline={true}>
          <Select {...register('size')}>
            <option value="sm">Маленький</option>
            <option value="md">Стандартный</option>
            <option value="lg">Большой</option>
            <option value="xlg">Очень большой</option>
          </Select>
        </FieldGroup>
      </FieldSection>

      <FieldSection label="Игра" size="lg">
        <FieldGroup size="sm">
          <Controller
            control={control}
            name="sfx"
            render={({field: {onChange, onBlur, value}}) => (
              <Toggle
                value={value}
                label="Звуки интерфейса"
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup size="sm">
          <Controller
            control={control}
            name="hotkeys"
            render={({field: {onChange, onBlur, value}}) => (
              <Toggle
                value={value}
                label={
                  <FieldRow size="xs">
                    <span>Горячие клавиши</span>
                    <TipCircle message={<p>Возможность ответить с помощью пробела.</p>} />
                  </FieldRow>
                }
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </FieldGroup>
      </FieldSection>
    </form>
  );
};
