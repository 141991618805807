import React from 'react';
import {Helmet} from 'react-helmet';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {CrownSimple} from '@phosphor-icons/react';
import {ConnectHostEvent, getPkgVersion} from '@opeq-dev/openquiz-machine';
import {
  DiscordLogotype,
  Logotype,
  SidebarFooter,
  SidebarGroup,
  SidebarLink,
  TeamSlotPlaceholder,
  Sidebar as UiSidebar
} from '@openquiz/quiz-ui';
import {
  HostSlot,
  selectHost,
  useMachineEvent,
  useMachineSelector
} from '@/features/machine';
import {RoundRow} from './RoundRow';

const HostSlotWrapper = styled.div`
  width: 100%;
  max-width: 240px;
  height: 160px;
`;

export const LeftSidebar = () => {
  const {event} = useMachineEvent();

  const game = useMachineSelector(state => state.context.game);
  const leader = useMachineSelector(selectHost);
  const rounds = useMachineSelector(state => getPkgVersion(state.context)?.rounds ?? []);
  const currentRoundIndex = useMachineSelector(state =>
    rounds.findIndex(r => r.uuid === state.context.current.roundUuid)
  );

  const onClickConnectHost = () => {
    event<ConnectHostEvent>('connectHost', undefined);
  };

  return (
    <UiSidebar>
      <Helmet>
        <title>Игра – {game?.name}</title>
      </Helmet>

      <SidebarGroup>
        <NavLink style={{display: 'inline-flex'}} to="/">
          <Logotype />
        </NavLink>
      </SidebarGroup>

      <SidebarGroup>
        <HostSlotWrapper>
          {leader ? (
            <HostSlot user={leader} />
          ) : (
            <TeamSlotPlaceholder pointer={true} onClick={onClickConnectHost}>
              <CrownSimple size={32} />
              <div>Быть ведущим</div>
            </TeamSlotPlaceholder>
          )}
        </HostSlotWrapper>
      </SidebarGroup>

      <SidebarGroup>
        {rounds.map((round, index) => (
          <RoundRow
            key={`round-${round.uuid}`}
            round={round}
            status={
              currentRoundIndex >= index
                ? currentRoundIndex === index
                  ? 'in-progress'
                  : 'finished'
                : 'not-started'
            }
          />
        ))}
      </SidebarGroup>

      <SidebarFooter>
        <SidebarGroup>
          <SidebarLink icon={DiscordLogotype} to="https://discord.gg/k56easeP5H">
            Discord сервер
          </SidebarLink>
        </SidebarGroup>
      </SidebarFooter>
    </UiSidebar>
  );
};
