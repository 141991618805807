import React, {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import styled, {css} from 'styled-components';
import {debounce} from 'throttle-debounce';
import {InputAutosize} from '@openquiz/quiz-ui';

interface EditableTitleProps {
  size?: 'h1' | 'h3';
  tabIndex?: number;
  value: string;
  onChange: (value: string) => void;
}

const StyledEditableTitle = styled(InputAutosize)<{$size: EditableTitleProps['size']}>`
  ${p =>
    p.$size === 'h1' &&
    css`
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
    `}

  ${p =>
    p.$size === 'h3' &&
    css`
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    `}
`;

export const EditableTitle = ({
  size = 'h1',
  tabIndex,
  value,
  onChange
}: EditableTitleProps) => {
  const {register, watch, setValue} = useForm<{title: string}>({
    defaultValues: {
      title: value
    },
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const onDebouncedChange = useCallback(debounce(500, onChange), [onChange]);

  useEffect(() => {
    setValue('title', value);
  }, [value, setValue]);

  useEffect(() => {
    const subscription = watch(value => {
      if (!value.title) return;
      onDebouncedChange(value.title);
    });
    return () => subscription.unsubscribe();
  }, [onDebouncedChange, watch]);

  return (
    <StyledEditableTitle
      {...register('title', {
        required: 'Обязательное поле',
        minLength: {value: 3, message: 'Минимальная длинна 3 символа.'},
        maxLength: {value: 32, message: 'Максимальная длинна 32 символа.'}
      })}
      $size={size}
      tabIndex={tabIndex}
    />
  );
};
