import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {ArrowLeft} from '@phosphor-icons/react';
import styled from 'styled-components';

interface TitleBackLinkOnProps {
  children: ReactNode;
  to?: string;
  onClick?: () => void;
}

const StyledTitleBackLink = styled.button`
  ${p => p.theme.typography.label.md};
  color: ${p => p.theme.colors.text.subtle};
  position: relative;
  background-color: transparent;
  margin-bottom: 12px;
  padding: 0;
  border: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  z-index: 20;

  :hover {
    color: ${p => p.theme.colors.accent.text};
  }
`;

export const TitleBackLink = ({children, to, onClick}: TitleBackLinkOnProps) => {
  if (to) {
    return (
      <StyledTitleBackLink as={Link} to={to}>
        <ArrowLeft weight="bold" size={16} />
        <span>{children}</span>
      </StyledTitleBackLink>
    );
  }

  return (
    <StyledTitleBackLink onClick={onClick}>
      <ArrowLeft weight="bold" size={16} />
      <span>{children}</span>
    </StyledTitleBackLink>
  );
};
