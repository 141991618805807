import React, {HTMLAttributes, MutableRefObject, ReactNode} from 'react';
import {ensuredForwardRef} from 'react-use';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {useTilt} from '../../../hooks';

interface CardCoverProps {
  picture?: string;
  color: string;
}

interface CardProps extends HTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  to: string;
  state?: unknown;
  cover?: CardCoverProps | undefined;
}

const StyledCard = styled(Link)<{$hasCover: boolean}>`
  background-color: ${p => p.theme.colors.canvas.overlay};
  color: ${p => p.theme.colors.text.default};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  position: relative;
  border-radius: 20px;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  transition:
    transform ease-in-out 80ms,
    box-shadow ease-in-out 80ms;

  ${p =>
    p.$hasCover &&
    css`
      color: ${p => p.theme.colors.text.onEmphasis};
    `}

  :hover {
    box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md},
      ${p => p.theme.shadow.lg};
  }

  :active {
    transform: scale(1) !important;
    box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  }
`;

const Cover = styled.div<{$cover: CardCoverProps}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 20px;
  overflow: hidden;
  pointer-events: none;

  &::after,
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.1) 50%);
  }

  &::before {
    background: linear-gradient(0deg, ${p => p.$cover.color} 0%, rgba(0, 0, 0, 0.1) 100%);
    box-shadow:
      inset 0 1px 1px 1px rgba(255, 255, 255, 0.1),
      inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    opacity: 0.7;
    z-index: 1;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Card = ensuredForwardRef<HTMLAnchorElement, CardProps>(function Card(
  {children, to, state, cover, ...rest}: CardProps,
  ref: MutableRefObject<HTMLAnchorElement>
) {
  useTilt(ref);
  return (
    <StyledCard {...rest} ref={ref} to={to} state={state} $hasCover={!!cover}>
      {cover && (
        <Cover $cover={cover}>
          {cover.picture && <img src={cover.picture} alt="" />}
        </Cover>
      )}
      {children}
    </StyledCard>
  );
});
