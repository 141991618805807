import React from 'react';
import styled from 'styled-components';
import {Package, PackageVersion} from '@opeq-dev/openquiz-schema';
import {PackageSwatch} from '@/features/workshop';

interface PackageRowCardProps {
  pkg: Package & {versions: PackageVersion[]};
}

const StyledPackageRow = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.default};
  color: ${p => p.theme.colors.text.default};
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const Name = styled.div`
  ${p => p.theme.typography.label.lg};
`;

export const PackageRow = ({pkg}: PackageRowCardProps) => (
  <StyledPackageRow>
    <PackageSwatch pkg={pkg} size="md" />
    <div>
      <Name>{pkg.name}</Name>
    </div>
  </StyledPackageRow>
);
