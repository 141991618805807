import {
  createEmptyMediaStream,
  stopMediaStream,
  usePeerEmitter,
  usePeerWebcam
} from '@openquiz/peer-webcam';
import {useCallback, useMemo} from 'react';

/**
 * The hook returns a media stream from a webcam or an empty stream.
 */
export const usePeerMediaStream = () => {
  const {peer, gameUuid, userUuid, streams, addStream} = usePeerWebcam();
  const {emitCall, emitDrop} = usePeerEmitter();

  /**
   * The function will call back active peers with a new media stream.
   */
  const getLocalStream = useMemo(
    () => streams[`${gameUuid}-${userUuid}`] || createEmptyMediaStream(),
    [streams, gameUuid, userUuid]
  );

  /**
   * The function will call back active peers with a new media stream.
   * @param stream
   */
  const change = useCallback(
    async (stream: MediaStream) => {
      addStream(`${gameUuid}-${userUuid}`, stream);

      // TODO: без перезвона заменить стрим, заменить видео дорожку на аудио.

      await Promise.all(
        Object.keys(peer?.connections ?? {}).map(async peerId => {
          await emitDrop(peerId);
          await emitCall(peerId, stream);
        })
      );
    },
    [gameUuid, userUuid, addStream, emitDrop, emitCall, peer]
  );

  /**
   * The function will stop the stream from the webcam and call back the active peers with an empty stream.
   */
  const stop = useCallback(async () => {
    const stream = streams[`${gameUuid}-${userUuid}`];

    if (stream) {
      stopMediaStream(stream);
      addStream(`${gameUuid}-${userUuid}`, createEmptyMediaStream());
    }

    // TODO: без перезвона заменить стрим, заменить видео дорожку на аудио.

    await Promise.all(
      Object.keys(peer?.connections ?? {}).map(async peerId => {
        await emitDrop(peerId);
        await emitCall(peerId, createEmptyMediaStream());
      })
    );
  }, [gameUuid, userUuid, streams, addStream, peer, stopMediaStream]);

  return {
    getLocalStream,
    change,
    stop
  };
};
