import React from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {Surface} from '@openquiz/quiz-ui';
import {
  PublishForm,
  editorPath,
  fetchVersionPublish,
  useEditorContext
} from '@/features/wshop-editor';
import {Page, PageTitle} from '@/components';

export const PublishPage = () => {
  const navigate = useNavigate();
  const {packageUuid, versionUuid} = useEditorContext();
  const backHref = generatePath(editorPath, {packageUuid});

  const onSubmit = async () => {
    await fetchVersionPublish({packageUuid, versionUuid});
    navigate(`/workshop/${packageUuid}`);
  };

  return (
    <Page>
      <PageTitle to={{href: backHref}} title="Публикация изменений" />
      <Surface>
        <PublishForm
          onSubmit={onSubmit}
          packageUuid={packageUuid}
          versionUuid={versionUuid}
        />
      </Surface>
    </Page>
  );
};
