import React from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {TextAa} from '@phosphor-icons/react';
import {User} from '@opeq-dev/openquiz-schema';
import {Avatar} from '@openquiz/quiz-ui';
import {
  CurrentUserGuard,
  UserBanner,
  UserStatisticsBar,
  useUserBanner
} from '@/features/user';
import {PictureUpload} from './PictureUpload';

interface UserIDProps {
  user: User;
  size?: 'xs' | 'sm' | 'md';
  onChangePicture?: (file: File) => void;
}

const StyledUserID = styled.div<{$size: UserIDProps['size']}>`
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  position: relative;
  margin-bottom: 48px;
  border-radius: 16px;
  transition: background-color 160ms ease-in-out;

  ${p =>
    p.$size === 'xs' &&
    css`
      max-width: 300px;
      border-radius: 12px;
    `}

  ${p =>
    p.$size === 'sm' &&
    css`
      max-width: 400px;
    `}

  ${p => p.$size === 'md' && css``}
`;

const PatternWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;
`;

const GridContainer = styled.div<{$size: UserIDProps['size']}>`
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  padding: 12px;

  ${p =>
    p.$size === 'xs' &&
    css`
      grid-template-columns: 96px 1fr;
    `}

  ${p =>
    p.$size === 'sm' &&
    css`
      grid-template-columns: 128px 1fr;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      grid-template-columns: 188px 1fr;
    `}
`;

const Picture = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  transform: translateY(40px);

  &:hover [data-id='picture-upload'] {
    opacity: 1;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;

  @media only screen and (min-width: 320px) {
    max-width: 140px;
  }

  @media only screen and (min-width: 1024px) {
    max-width: none;
  }
`;

const Actions = styled.div`
  margin-bottom: 8px;
`;

const ActionButton = styled(Link)<{$isReadable: boolean}>`
  ${p => p.theme.typography.label.md};
  background-color: ${p =>
    p.$isReadable ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'};
  color: ${p => p.theme.colors.text.onEmphasis};
  border-radius: 12px;
  padding: 8px 12px;
  border: none;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  gap: 5px;
  cursor: pointer;
  backdrop-filter: blur(24px);
  transition: background-color 160ms ease-in-out;

  &:hover {
    background-color: ${p => p.theme.colors.accent.emphasis};
  }
`;

const avatarSize = {
  xs: 72,
  sm: 96,
  md: 128
};

export const UserID = ({user, size = 'md', onChangePicture}: UserIDProps) => {
  const {isReadable} = useUserBanner(user);

  return (
    <StyledUserID $size={size}>
      <PatternWrapper>
        <UserBanner user={user} />
      </PatternWrapper>

      <GridContainer $size={size}>
        <Picture>
          {onChangePicture && <PictureUpload onChange={onChangePicture} />}
          <Avatar user={user} size={avatarSize[size]} stroked={true} />
        </Picture>

        <Content>
          {size === 'md' && (
            <CurrentUserGuard user={user}>
              <Actions>
                <ActionButton $isReadable={isReadable} to="/u/edit">
                  <TextAa size={20} weight="bold" />
                  <span>Изменить</span>
                </ActionButton>
              </Actions>
            </CurrentUserGuard>
          )}
          <UserStatisticsBar user={user} contrast={isReadable} compact={size !== 'md'} />
        </Content>
      </GridContainer>
    </StyledUserID>
  );
};
