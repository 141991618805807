import React, {useEffect} from 'react';
import {SplashScreen, useSfxPlayer} from '@/features/machine';

export const NoRiskQuestionSplash = () => {
  const sfxPlayer = useSfxPlayer();

  useEffect(() => {
    sfxPlayer.play('noRisk');
  }, [sfxPlayer.play]);

  return (
    <SplashScreen
      icon={
        <picture>
          <source
            srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f38a/512.webp"
            type="image/webp"
          />
          <img
            src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f38a/512.gif"
            alt="🎊"
            width={96}
            height={96}
          />
        </picture>
      }>
      <h2>Вопрос без риска</h2>
      <p>На этот вопрос, вы можете ответить без риска потерять очки.</p>
    </SplashScreen>
  );
};
