import React, {useCallback} from 'react';
import {PaperPlaneRight} from '@phosphor-icons/react';
import {useForm} from 'react-hook-form';
import {Button, FieldGroup, FieldRow, Input} from '@openquiz/quiz-ui';
import {
  TextChip,
  UserChip,
  selectPlayerTeam,
  selectTimer,
  useMachineEvent,
  useMachineGuard,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';
import {
  PassFinalBetEvent,
  TypeFinalBetEvent,
  typeFinalBetGuard
} from '@opeq-dev/openquiz-machine';

interface FinalBetHintDto {
  bet: number;
}

export const FinalBetHint = () => {
  const self = useMachineSelf();
  const {event} = useMachineEvent();
  const {guard} = useMachineGuard();

  const finalBetsTimer = useMachineSelector(selectTimer('finalBets'));
  const playerTeam = useMachineSelector(selectPlayerTeam(self?.userUuid ?? ''));

  const {register, handleSubmit} = useForm<FinalBetHintDto>({
    defaultValues: {
      bet: 0
    }
  });

  const onClickAllIn = useCallback(() => {
    event<TypeFinalBetEvent>('typeFinalBet', {bet: playerTeam?.score || 0});
  }, [event, playerTeam?.score]);

  const onClickPass = useCallback(() => {
    event<PassFinalBetEvent>('passFinalBet', undefined);
  }, [event]);

  const onFormSubmit = (values: FinalBetHintDto) => {
    const bet = Math.trunc(values.bet);
    if (bet < 1 || bet > (playerTeam?.score || 0)) return;
    event<TypeFinalBetEvent>('typeFinalBet', {bet});
  };

  if (!self) {
    return null;
  }

  if (!guard(typeFinalBetGuard)({bet: 1})) {
    return <TextChip>Вы не можете поставить ставку.</TextChip>;
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <FieldGroup>
        <FieldRow justify="center">
          <UserChip
            userUuid={self.userUuid}
            message=", делайте ставку"
            timer={finalBetsTimer}
            isSelf={true}
          />
        </FieldRow>
      </FieldGroup>

      <FieldRow>
        <FieldRow size="sm">
          <div style={{width: 120}}>
            <Input
              {...register('bet', {required: 'Пожалуйста введите ставку.'})}
              placeholder="Ставка"
              autoComplete="off"
              autoFocus={true}
              type="number"
              size="sm"
              min={1}
              max={playerTeam?.score || 0}
            />
          </div>
          <Button size="sm" type="submit">
            <PaperPlaneRight size={16} weight="bold" />
            <span>Отправить</span>
          </Button>
        </FieldRow>
        <FieldRow size="sm">
          <Button variant="secondary" size="sm" onClick={onClickAllIn}>
            Ва-банк
          </Button>
          <Button variant="secondary" size="sm" onClick={onClickPass}>
            Пас
          </Button>
        </FieldRow>
      </FieldRow>
    </form>
  );
};
