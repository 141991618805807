import React from 'react';
import {useNavigate} from 'react-router-dom';
import {CrownSimple} from '@phosphor-icons/react';
import styled from 'styled-components';
import {Button, TeamSlotPlaceholder} from '@openquiz/quiz-ui';
import {HostSlot, LobbySlots, selectHost, useMachineSelector} from '@/features/machine';

const StyledGameResultsStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const HostSlotWrapper = styled.div`
  width: 100%;
  max-width: 240px;
  height: 160px;
`;

const Slots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 32px;
  width: 100%;
  gap: 32px;
  overflow: hidden;
`;

export const GameResultsStep = () => {
  const navigate = useNavigate();
  const leader = useMachineSelector(selectHost);

  const onClickClose = () => {
    navigate('/');
  };

  return (
    <StyledGameResultsStep>
      <Slots>
        <HostSlotWrapper>
          {leader ? (
            <HostSlot user={leader} />
          ) : (
            <TeamSlotPlaceholder pointer={true} onClick={() => void 0}>
              <CrownSimple size={32} />
              <div>Быть ведущим</div>
            </TeamSlotPlaceholder>
          )}
        </HostSlotWrapper>
        <LobbySlots direction="vertical" />
      </Slots>

      <Button variant="secondary" onClick={onClickClose}>
        Покинуть игру
      </Button>
    </StyledGameResultsStep>
  );
};
