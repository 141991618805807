import {getPrivateAxios} from '@/libs';

/**
 * Fetch favorite package.
 * @param uuid
 * @returns
 */
export const fetchPackageFavorite = async (uuid: string) => {
  await getPrivateAxios().post<void>(`/workshop/packages/${uuid}/favorite`);
};
