import React, {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {FormattedDate} from 'react-intl';
import styled from 'styled-components';
import {debounce} from 'throttle-debounce';
import {Check, FieldGroup, FieldRow, Input, Select, TipCircle} from '@openquiz/quiz-ui';
import {SearchDto} from '@/features/workshop';
import {SyntaxHelpMessage} from '@/features/wshop-search';

interface SearchFiltersProps {
  data: SearchDto;
  onSubmit: (data: SearchDto) => void;
}

const StyledSearchFilters = styled.form`
  margin-bottom: 24px;
`;

export const SearchFilters = ({data, onSubmit}: SearchFiltersProps) => {
  const {handleSubmit, register, watch, setValue} = useForm<SearchDto>({
    defaultValues: data
  });

  useEffect(() => {
    setValue('query', data.query);
    setValue('sort', data.sort);
    setValue('illegal', data.illegal);
  }, [data]);

  const onChangeInput = useCallback(() => {
    const data = watch();

    if (data.illegal) {
      window.localStorage.setItem('search.illegal', 'true');
    } else {
      window.localStorage.removeItem('search.illegal');
    }

    onSubmit(data);
  }, [onSubmit, watch]);

  const debouncedChangeHandler = useCallback(
    debounce(500, () => {
      const data = watch();
      onSubmit(data);
    }),
    [onSubmit, watch]
  );

  return (
    <StyledSearchFilters onSubmit={handleSubmit(onSubmit)}>
      <FieldGroup>
        <FieldRow>
          <FieldGroup label="Запрос">
            <Input
              {...register('query', {required: true, onChange: debouncedChangeHandler})}
              type="text"
              autoFocus={true}
              placeholder="Поиск"
              extraRight={<TipCircle message={<SyntaxHelpMessage />} size="lg" />}
              style={{width: 324}}
            />
          </FieldGroup>
          <FieldGroup label="Сортировка">
            <Select {...register('sort', {onChange: onChangeInput})}>
              <option value="created_at">По дате публикации</option>
              <option value="rating">По рейтингу</option>
              <option value="name">По названию</option>
            </Select>
          </FieldGroup>
        </FieldRow>
      </FieldGroup>

      <FieldGroup>
        <FieldRow>
          <Check {...register('illegal', {onChange: onChangeInput})} type="checkbox">
            Показывать 18+
          </Check>
          {data.createdAt && (
            <div style={{lineHeight: '20px'}}>
              <FormattedDate value={data.createdAt} dateStyle="long" /> –{' '}
              <FormattedDate value={new Date()} dateStyle="long" />
            </div>
          )}
        </FieldRow>
      </FieldGroup>
    </StyledSearchFilters>
  );
};
