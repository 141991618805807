import basic from '../../assets/patterns/basic.svg';
import cross from '../../assets/patterns/cross.svg';
import snowflake from '../../assets/patterns/snowflake.svg';
import jail from '../../assets/patterns/jail.svg';
import dots from '../../assets/patterns/dots.svg';
import stones from '../../assets/patterns/stones.svg';
import skulls from '../../assets/patterns/skulls.svg';
import skulls2 from '../../assets/patterns/skulls2.svg';
import candies from '../../assets/patterns/candies.svg';
import candles from '../../assets/patterns/candles.svg';
import graves from '../../assets/patterns/graves.svg';
import hallow from '../../assets/patterns/hallow.svg';
import hallowMix from '../../assets/patterns/hallowMix.svg';
import hallowMix2 from '../../assets/patterns/hallowMix2.svg';
import hallowMix3 from '../../assets/patterns/hallowMix3.svg';
import pumpkins from '../../assets/patterns/pumpkins.svg';
import spiders from '../../assets/patterns/spiders.svg';

export interface PatternStyle {
  backgroundSize: string;
  backgroundRepeat: string;
  backgroundPosition: string;
}
export interface Pattern {
  src: string;
  style?: PatternStyle;
}

export type PatternKey =
  | 'basic'
  | 'cross'
  | 'snowflake'
  | 'jail'
  | 'dots'
  | 'stones'
  | 'skulls'
  | 'skulls2'
  | 'candies'
  | 'candles'
  | 'graves'
  | 'hallow'
  | 'hallowMix'
  | 'hallowMix2'
  | 'hallowMix3'
  | 'pumpkins'
  | 'spiders';

const noRepeatStyles: PatternStyle = {
  backgroundSize: 'auto 100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center'
};

/**
 *
 */
export const availablePatterns = new Map<PatternKey, Pattern>([
  ['basic', {src: basic}],
  ['cross', {src: cross}],
  ['snowflake', {src: snowflake}],
  ['jail', {src: jail}],
  ['dots', {src: dots}],
  ['stones', {src: stones}],
  ['hallow', {src: hallow}],
  [
    'skulls',
    {
      src: skulls,
      style: noRepeatStyles
    }
  ],
  [
    'skulls2',
    {
      src: skulls2,
      style: noRepeatStyles
    }
  ],
  [
    'candies',
    {
      src: candies,
      style: noRepeatStyles
    }
  ],
  [
    'candles',
    {
      src: candles,
      style: noRepeatStyles
    }
  ],
  [
    'graves',
    {
      src: graves,
      style: noRepeatStyles
    }
  ],
  [
    'hallowMix',
    {
      src: hallowMix,
      style: noRepeatStyles
    }
  ],
  [
    'hallowMix2',
    {
      src: hallowMix2,
      style: noRepeatStyles
    }
  ],
  [
    'hallowMix3',
    {
      src: hallowMix3,
      style: noRepeatStyles
    }
  ],
  [
    'pumpkins',
    {
      src: pumpkins,
      style: noRepeatStyles
    }
  ],
  [
    'spiders',
    {
      src: spiders,
      style: noRepeatStyles
    }
  ]
]);

export const availableForPickPatterns = new Map<PatternKey, Pattern>([
  ['basic', {src: basic}],
  ['cross', {src: cross}],
  ['snowflake', {src: snowflake}],
  ['jail', {src: jail}],
  ['dots', {src: dots}],
  ['stones', {src: stones}]
]);
