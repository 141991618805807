import React, {ButtonHTMLAttributes, ReactNode, forwardRef} from 'react';
import styled, {css} from 'styled-components';

type ControlBarButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'discord'
  | 'danger';

interface ControlBarButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: ControlBarButtonVariant;
}

const variantMap = new Map<ControlBarButtonVariant, ReturnType<typeof css>>([
  [
    'primary',
    css`
      background-color: ${p => p.theme.colors.accent.emphasis};
      border-color: ${p => p.theme.colors.accent.emphasis};
      color: ${p => p.theme.colors.text.onEmphasis};
      :hover {
        filter: saturate(200%);
      }
    `
  ],
  [
    'secondary',
    css`
      background-color: ${p => p.theme.colors.border.default};
      border-color: ${p => p.theme.colors.border.default};
      color: ${p => p.theme.colors.text.muted};
      :hover {
        background-color: ${p => p.theme.colors.accent.emphasis};
        border-color: ${p => p.theme.colors.accent.emphasis};
        color: ${p => p.theme.colors.text.onEmphasis};
      }
    `
  ],
  [
    'tertiary',
    css`
      background-color: transparent;
      border-color: ${p => p.theme.colors.border.default};
      color: ${p => p.theme.colors.text.muted};
      :hover {
        background-color: ${p => p.theme.colors.accent.emphasis};
        border-color: ${p => p.theme.colors.accent.emphasis};
        color: ${p => p.theme.colors.text.onEmphasis};
      }
    `
  ],
  [
    'discord',
    css`
      background-color: #5865f2;
      border-color: #5865f2;
      color: ${p => p.theme.colors.text.onEmphasis};
      :hover {
        background-color: #6b77fb;
        border-color: #6b77fb;
        color: ${p => p.theme.colors.text.onEmphasis};
      }
    `
  ],
  [
    'danger',
    css`
      background-color: ${p => p.theme.colors.danger.emphasis};
      border-color: ${p => p.theme.colors.danger.emphasis};
      color: ${p => p.theme.colors.text.onEmphasis};
      :hover {
        filter: saturate(200%);
      }
    `
  ]
]);

const StyledControlBarButton = styled.button<{
  $variant: ControlBarButtonVariant;
}>`
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 8px;
  cursor: pointer;
  transition:
    background-color 80ms ease-in-out,
    border-color 80ms ease-in-out,
    color 80ms ease-in-out,
    filter 80ms ease-in-out,
    opacity 80ms ease-in-out,
    transform 80ms ease-in-out;

  ${p => variantMap.get(p.$variant)};

  @media only screen and (min-width: 320px) {
    ${p => p.theme.typography.label.sm};
    padding: 8px 12px;
    border-radius: 16px;
  }

  @media only screen and (min-width: 1024px) {
    ${p => p.theme.typography.label.md};
    padding: 12px 16px;
    border-radius: 16px;
  }

  @media only screen and (min-width: 1440px) {
    ${p => p.theme.typography.label.md};
    padding: 12px 16px;
    border-radius: 16px;
  }

  svg {
    display: flex;
  }

  span {
    padding-right: 2px;
  }

  :hover {
    transform: scale(1.01);
  }

  :active {
    transform: scale(0.98);
  }

  &[disabled] {
    background-color: ${p => p.theme.colors.border.subtle};
    border-color: ${p => p.theme.colors.border.subtle};
    color: ${p => p.theme.colors.text.default};
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const ControlBarButton = forwardRef<HTMLButtonElement, ControlBarButtonProps>(
  function ({children, variant = 'primary', ...rest}, ref) {
    return (
      <StyledControlBarButton ref={ref} $variant={variant} {...rest}>
        {children}
      </StyledControlBarButton>
    );
  }
);
