import React from 'react';
import {useKey} from 'react-use';
import styled from 'styled-components';
import {KeyReturn, Warning} from '@phosphor-icons/react';
import {Button, FieldGroup, FieldRow, Title} from '@openquiz/quiz-ui';
import {ModalConfirmProps} from '../../types';

const StyledModalConfirm = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.floating};
  position: relative;
  margin-top: 10%;
  width: 100%;
  overflow: hidden;
  max-width: 440px;
  border-radius: 16px;
  padding: 32px;
  height: auto;
`;

const Head = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 8px;

  svg {
    color: ${p => p.theme.colors.danger.text};
    flex-shrink: 0;
  }
`;

const Entry = styled.div`
  margin-bottom: 24px;
  padding-left: 46px;
`;

export const ModalConfirm = ({
  title = 'Подтвердите действие',
  content = '...',
  resolveLabel = 'Отправить',
  rejectLabel = 'Отмена',
  onConfirm,
  onCancel
}: ModalConfirmProps) => {
  useKey('Escape', onCancel);
  return (
    <StyledModalConfirm>
      <Head>
        <Warning size={30} weight="bold" />
        <Title>{title}</Title>
      </Head>
      <Entry>{content}</Entry>
      <FieldGroup>
        <FieldRow justify="flex-end">
          <Button variant="secondary" onClick={onCancel}>
            {rejectLabel}
          </Button>
          <Button variant="danger-filled" autoFocus={true} onClick={onConfirm}>
            <span>{resolveLabel}</span>
            <KeyReturn size={20} weight="bold" />
          </Button>
        </FieldRow>
      </FieldGroup>
    </StyledModalConfirm>
  );
};
