import React, {ReactNode} from 'react';
import {ArrowCircleDownRight, ArrowCircleRight} from '@phosphor-icons/react';
import styled, {css} from 'styled-components';

interface ListRowProps {
  children: ReactNode;
  label: ReactNode;
  expanded: boolean;
  onClickToggle?: () => void;
}

const StyledListRow = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  box-shadow: ${p => p.theme.shadow.sm}, ${p => p.theme.shadow.md};
  margin-bottom: 12px;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 80ms ease-in-out;

  :last-child {
    margin-bottom: 0;
  }

  :hover {
    transform: scale(1.01);
  }
`;

const Label = styled.div<{$expanded: boolean}>`
  ${p => p.theme.typography.label.lg};
  display: flex;
  align-items: center;
  gap: 8px;
  transition: margin 200ms ease-in-out;

  ${p =>
    p.$expanded &&
    css`
      margin-bottom: 12px;
    `}

  svg {
    color: ${p => p.theme.colors.text.muted};
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const Entry = styled.div`
  ${p => p.theme.typography.body.md};
`;

export const ListRow = ({children, label, expanded, onClickToggle}: ListRowProps) => {
  return (
    <StyledListRow onClick={onClickToggle}>
      <Label $expanded={expanded}>
        {expanded ? (
          <ArrowCircleDownRight size={20} weight="fill" />
        ) : (
          <ArrowCircleRight size={20} weight="fill" />
        )}
        <span>{label}</span>
      </Label>
      {expanded && <Entry>{children}</Entry>}
    </StyledListRow>
  );
};
