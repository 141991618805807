import {Action} from '@opeq-dev/openquiz-machine';
import {
  selectUserRole,
  useGameService,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';

export const useMachineEvent = () => {
  const {emit} = useGameService();
  const self = useMachineSelf();
  const role = useMachineSelector(selectUserRole(self.userUuid));

  const event = <T extends Action>(type: T['type'], payload: T['payload']) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return emit({type, self, payload});
  };

  return {role, event};
};
