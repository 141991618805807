import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {CrownSimple} from '@phosphor-icons/react';
import {ConnectHostEvent} from '@opeq-dev/openquiz-machine';
import {Logotype, TeamSlotPlaceholder} from '@openquiz/quiz-ui';
import {PackagePill} from '@/features/workshop';
import {
  HostSlot,
  LobbySlots,
  Screen,
  ScreenContent,
  selectGame,
  selectHost,
  selectPkg,
  useMachineEvent,
  useMachineSelector
} from '@/features/machine';

const Brand = styled.div`
  margin-bottom: 24px;
`;

const Info = styled.div`
  margin-bottom: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  ${p => p.theme.typography.heading.h2};
  margin-bottom: 8px;
`;

const HostSlotWrapper = styled.div`
  width: 100%;
  max-width: 240px;
  height: 160px;
`;

const Slots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 16px 0;
  gap: 32px;
  overflow: hidden;
`;

export const LobbyScreen = () => {
  const {event} = useMachineEvent();
  const game = useMachineSelector(selectGame);
  const pkg = useMachineSelector(selectPkg);
  const leader = useMachineSelector(selectHost);

  const onClickConnectHost = () => {
    event<ConnectHostEvent>('connectHost', undefined);
  };

  return (
    <Screen>
      <ScreenContent>
        <Brand>
          <NavLink to="/">
            <Logotype />
          </NavLink>
        </Brand>

        <Info>
          <Title>{game?.name}</Title>
          {pkg && <PackagePill pkg={pkg} />}
        </Info>

        <Slots>
          <HostSlotWrapper>
            {leader ? (
              <HostSlot user={leader} />
            ) : (
              <TeamSlotPlaceholder pointer={true} onClick={onClickConnectHost}>
                <CrownSimple size={32} />
                <div>Быть ведущим</div>
              </TeamSlotPlaceholder>
            )}
          </HostSlotWrapper>
          <LobbySlots direction="vertical" />
        </Slots>
      </ScreenContent>
    </Screen>
  );
};
