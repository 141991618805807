import {
  Coin,
  FastForward,
  Gear,
  Hand,
  HandCoins,
  HandFist,
  HandPointing,
  KeyReturn,
  Pause,
  Play,
  SignIn,
  SignOut,
  SkipForward,
  Swap,
  ThumbsDown,
  ThumbsUp,
  XCircle
} from '@phosphor-icons/react';
import {Action} from '@opeq-dev/openquiz-machine';
import {PhosphorIcon, Theme} from '@openquiz/quiz-ui';

export interface FormattedActionProps {
  icon: PhosphorIcon;
  color: (colors: Theme['colors']) => string;
  message: string;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

/**
 *
 */
export const formattedActionPropsMap: PartialRecord<
  Action['type'],
  FormattedActionProps
> = {
  start: {
    icon: Play,
    color: colors => colors.success.text,
    message: 'Ведущий :userUuid запустил игру'
  },
  kickPlayer: {
    icon: HandFist,
    color: colors => colors.danger.text,
    message: 'Ведущий :userUuid кикнул игрока :targetUserUuid'
  },
  banPlayer: {
    icon: HandFist,
    color: colors => colors.danger.text,
    message: 'Ведущий :userUuid забанил игрока :targetUserUuid'
  },
  changeTurn: {
    icon: Swap,
    color: colors => colors.text.subtle,
    message: 'Ведущий :userUuid передал ход игроку :targetUserUuid'
  },
  changeTeamScore: {
    icon: HandCoins,
    color: colors => colors.text.subtle,
    message: 'Ведущий :userUuid изменил игроку :targetUserUuid очки'
  },
  skipRound: {
    icon: FastForward,
    color: colors => colors.text.subtle,
    message: 'Ведущий :userUuid пропустил раунд'
  },
  skipQuestion: {
    icon: SkipForward,
    color: colors => colors.text.subtle,
    message: 'Ведущий :userUuid пропустил вопрос'
  },
  acceptAnswer: {
    icon: ThumbsUp,
    color: colors => colors.success.text,
    message: 'Правильный ответ от :targetUserUuid'
  },
  acceptFinalAnswer: {
    icon: ThumbsUp,
    color: colors => colors.success.text,
    message: 'Правильный ответ от :targetUserUuid'
  },
  rejectAnswer: {
    icon: ThumbsDown,
    color: colors => colors.danger.text,
    message: 'Неправильный ответ от :targetUserUuid'
  },
  rejectFinalAnswer: {
    icon: ThumbsDown,
    color: colors => colors.danger.text,
    message: 'Неправильный ответ от :targetUserUuid'
  },
  togglePause: {
    icon: Pause,
    color: colors => colors.text.subtle,
    message: ':userUuid (поставил/снял) паузу'
  },
  changeSettings: {
    icon: Gear,
    color: colors => colors.text.subtle,
    message: ':userUuid изменил настройки игры'
  },
  connectPlayer: {
    icon: SignIn,
    color: colors => colors.done.text,
    message: ':role :userUuid зашел в игру'
  },
  connectHost: {
    icon: SignIn,
    color: colors => colors.done.text,
    message: ':role :userUuid зашел в игру'
  },
  disconnectUser: {
    icon: SignOut,
    color: colors => colors.danger.text,
    message: ':userUuid вышел из игры'
  },
  chooseQuestion: {
    icon: HandPointing,
    color: colors => colors.accent.text,
    message: ':userUuid выбрал вопрос :questionUuid'
  },
  chooseResponder: {
    icon: HandPointing,
    color: colors => colors.accent.text,
    message: ':userUuid передал вопрос игроку :targetUserUuid'
  },
  chooseBanTheme: {
    icon: HandPointing,
    color: colors => colors.accent.text,
    message: ':userUuid забанил тему :themeUuid'
  },
  basicTryAnswer: {
    icon: Hand,
    color: colors => colors.accent.text,
    message: ':userUuid попробовал ответить'
  },
  typeFinalAnswer: {
    icon: KeyReturn,
    color: colors => colors.accent.text,
    message: ':userUuid отправил ответ'
  },
  typeBet: {
    icon: Coin,
    color: colors => colors.accent.text,
    message: ':userUuid поставил ставку :bet'
  },
  typeFinalBet: {
    icon: Coin,
    color: colors => colors.accent.text,
    message: ':userUuid поставил ставку :bet'
  },
  passTryAnswer: {
    icon: XCircle,
    color: colors => colors.attention.text,
    message: ':userUuid пасанул'
  },
  passBet: {
    icon: XCircle,
    color: colors => colors.attention.text,
    message: ':userUuid пасанул'
  },
  passFinalBet: {
    icon: XCircle,
    color: colors => colors.attention.text,
    message: ':userUuid пасанул'
  },
  '#system/setTryAnswerTimeout': {
    icon: ThumbsDown,
    color: colors => colors.danger.text,
    message: 'Игрок :userUuid не успел ответить'
  },
  '#system/setNextRound': {
    icon: FastForward,
    color: colors => colors.text.subtle,
    message: 'Следующий раунд :roundUuid'
  },
  '#system/setVariantDecision': {
    icon: HandPointing,
    color: colors => colors.accent.text,
    message: ':userUuid выбрал :decision'
  }
};
