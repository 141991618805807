import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from 'recharts';
import styled, {useTheme} from 'styled-components';
import {TickCustom} from './TickCustom';
import {TooltipCustom} from './TooltipCustom';
import {tickDateFormatter} from './dateFormatter';

export interface LineConfig {
  key: string;
  label: string;
  strokeColor: string;
  strokeWidth: number;
}

interface LineChartProps {
  data: {date: Date | string; value: Record<string, number>}[];
  lines: LineConfig[];
}

const StyledLineChart = styled.div`
  margin: -8px -20px -16px -20px;
`;

export const LineChart = ({data, lines}: LineChartProps) => {
  const theme = useTheme();

  return (
    <StyledLineChart>
      <ResponsiveContainer width="100%" height={150}>
        <RechartsLineChart
          data={data}
          margin={{
            top: 8,
            left: 24,
            right: 24,
            bottom: 0
          }}>
          <CartesianGrid
            strokeDasharray="0"
            horizontal={false}
            stroke={theme.colors.border.subtle}
          />

          <XAxis
            dataKey="date"
            padding={{left: 0, right: 0}}
            axisLine={false}
            tickLine={false}
            tick={props => <TickCustom {...props} />}
            tickFormatter={tickDateFormatter}
            minTickGap={8}
          />

          <Tooltip content={<TooltipCustom />} />

          {lines.map(line => (
            <Line
              key={`chart-line-${line.key}`}
              dataKey={`value.${line.key}`}
              name={line.label}
              type="monotone"
              strokeWidth={line.strokeWidth}
              stroke={line.strokeColor}
              dot={false}
            />
          ))}
        </RechartsLineChart>
      </ResponsiveContainer>
    </StyledLineChart>
  );
};
