import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {Package, Sword, Trophy} from '@phosphor-icons/react';
import {User} from '@opeq-dev/openquiz-schema';
import {StatChip} from './StatChip';

interface UserStatisticsBarProps {
  user: User;
  contrast: boolean;
  compact: boolean;
}

const StyledUserStatisticsBar = styled.div<{$contrast: boolean}>`
  background-color: ${p =>
    p.$contrast ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'};
  color: ${p => p.theme.colors.text.onEmphasis};
  backdrop-filter: blur(24px);
  border-radius: 12px;
  padding: 8px 12px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  transition: background-color 160ms ease-in-out;
`;

export const UserStatisticsBar = ({user, contrast, compact}: UserStatisticsBarProps) => {
  const intl = useIntl();

  const gameCount = useMemo(
    () => user.stats.find(s => s.name === 'game/count:all')?.value ?? 0,
    [user.stats]
  );

  const winCount = useMemo(
    () => user.stats.find(s => s.name === 'game/count:win')?.value ?? 0,
    [user.stats]
  );

  return (
    <StyledUserStatisticsBar $contrast={contrast}>
      <StatChip icon={Package}>
        <span data-id="count">{user._count.packages}</span>
        {!compact && (
          <span data-id="value">
            {intl
              .formatMessage({id: 'user.package.count'}, {count: user._count.packages})
              .replace(`${user._count.packages} `, '')}
          </span>
        )}
      </StatChip>
      <StatChip icon={Sword}>
        <span data-id="count">{gameCount}</span>
        {!compact && (
          <span data-id="value">
            {intl
              .formatMessage({id: 'user.game.count'}, {count: gameCount})
              .replace(`${gameCount} `, '')}
          </span>
        )}
      </StatChip>
      <StatChip icon={Trophy}>
        <span data-id="count">{winCount}</span>
        {!compact && (
          <span data-id="value">
            {intl
              .formatMessage({id: 'user.win.count'}, {count: winCount})
              .replace(`${winCount} `, '')}
          </span>
        )}
      </StatChip>
    </StyledUserStatisticsBar>
  );
};
