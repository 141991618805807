import React, {ButtonHTMLAttributes, createElement, forwardRef} from 'react';
import styled from 'styled-components';
import {PhosphorIcon} from '../../../types';

interface ButtonIconProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  /**
   * Icon, inside the button.
   */
  icon: PhosphorIcon;
  /**
   * Button will be disabled.
   */
  disabled?: boolean;
}

const StyledButtonIcon = styled.button`
  background-color: transparent;
  color: ${p => p.theme.colors.text.subtle};
  border-radius: 8px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: none;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
  cursor: pointer;
  user-select: none;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out,
    transform 80ms ease-in-out;

  &[data-open='true'],
  :hover,
  :focus-visible {
    background-color: ${p => p.theme.colors.canvas.inset};
    color: ${p => p.theme.colors.text.default};
  }

  :focus-visible {
    box-shadow: 0 0 0 2px ${p => p.theme.colors.accent.focus};
  }

  :active {
    background-color: ${p => p.theme.colors.accent.subtle};
    color: ${p => p.theme.colors.accent.text};
    transform: scale(0.98);
  }

  &[disabled] {
    background-color: ${p => p.theme.colors.neutral.muted};
    border-color: ${p => p.theme.colors.neutral.muted};
    color: ${p => p.theme.colors.text.subtle};
    pointer-events: none;
  }
`;

/**
 * The component is a button with an icon.
 */
export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
  function ButtonIcon({icon, type = 'button', ...rest}, ref) {
    return (
      <StyledButtonIcon {...rest} ref={ref} type={type}>
        {createElement(icon, {size: 20, weight: 'bold'})}
      </StyledButtonIcon>
    );
  }
);
