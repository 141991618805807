import React from 'react';
import styled from 'styled-components';
import {Callout, Spinner} from '@openquiz/quiz-ui';
import {useThemesQuery} from '@/features/wshop-editor';

interface ThemesEntryProps {
  packageUuid: string;
  roundUuid: string;
}

const StyledThemesEntry = styled.div`
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 2px;

    :last-child {
      margin-bottom: 0;
    }

    ::before {
      background-color: ${p => p.theme.colors.neutral.emphasis};
      border-radius: 4px;
      margin: 0 9px;
      display: inline-flex;
      width: 10px;
      height: 10px;
      content: '';
    }
  }
`;

export const ThemesEntry = ({packageUuid, roundUuid}: ThemesEntryProps) => {
  const {data: themes = [], isLoading} = useThemesQuery({packageUuid, roundUuid});

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <StyledThemesEntry>
      {themes.length ? (
        <ol>
          {themes.map(theme => (
            <li key={`theme-${theme.uuid}`}>{theme.name}</li>
          ))}
        </ol>
      ) : (
        <Callout status="danger">В этом раунде нет тем и вопросов.</Callout>
      )}
    </StyledThemesEntry>
  );
};
