import React, {ReactElement, cloneElement} from 'react';
import {User} from '@opeq-dev/openquiz-schema';
import {DotsLoader} from '@openquiz/quiz-ui';
import {useUserQuery} from '@/features/user';

interface FetchedUserProps {
  uuid: string;
  fallback?: ReactElement;
  children: (user: User) => ReactElement;
}

export const FetchedUser = ({
  uuid,
  fallback = <DotsLoader />,
  children
}: FetchedUserProps) => {
  const {data: user, isLoading} = useUserQuery(uuid ?? null);

  if (isLoading || !user) {
    return cloneElement(fallback);
  }

  return children(user);
};
