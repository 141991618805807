import React, {MouseEvent, ReactNode} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {ArrowCircleLeft} from '@phosphor-icons/react';
import {useBackStateStore} from '@/stores';

interface PageTitleProps {
  to?: {
    href: string;
    force?: boolean;
  };
  title: ReactNode;
  extra?: ReactNode;
}

const StyledPageTitle = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
`;

const Left = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  width: auto;
  flex-shrink: 0;
`;

const BackLink = styled(Link)`
  color: ${p => p.theme.colors.text.subtle};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  transition: color ease-in-out 80ms;

  :hover {
    color: ${p => p.theme.colors.accent.text};
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  flex-shrink: 0;
`;

const Extra = styled.div`
  flex-shrink: 0;
`;

export const PageTitle = ({to, title, extra}: PageTitleProps) => {
  const navigate = useNavigate();
  const {href, shift} = useBackStateStore();

  const onClickBack = (e: MouseEvent) => {
    if (to?.force) {
      shift();
      return;
    }

    const prevHref = href[href.length - 2];

    if (prevHref) {
      e.preventDefault();
      navigate(-1);
      shift();
    }
  };

  return (
    <StyledPageTitle>
      <Left>
        {to && (
          <BackLink to={to.href} onClick={onClickBack}>
            <ArrowCircleLeft size={24} weight="bold" />
          </BackLink>
        )}
        <Title>{title}</Title>
      </Left>
      {extra && <Extra>{extra}</Extra>}
    </StyledPageTitle>
  );
};
