import React, {createElement, forwardRef, useMemo} from 'react';
import {User} from '@opeq-dev/openquiz-schema';
import {useRandomAnimalIcon, useRandomColor} from '@openquiz/quiz-ui';
import {Empty, Image, StyledAvatar} from './styled';

interface AvatarProps {
  user: User;
  size?: number | string;
  stroked?: boolean;
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(function Avatar(
  {user, size = 32, stroked = false},
  ref
) {
  const randomIcon = useRandomAnimalIcon(user.uuid);
  const randomColor = useRandomColor(user.uuid);

  const file = useMemo(
    () => user?.pictures?.find(p => p.type === 'AVATAR')?.file,
    [user.pictures]
  );

  const url = useMemo(() => {
    return file
      ? file.src.includes('?')
        ? `${file.src.split('?')[0]}%3F${encodeURIComponent(file.src.split('?')[1])}`
        : file.src
      : null;
  }, [file]);

  if (!url) {
    return (
      <StyledAvatar ref={ref}>
        <Empty
          $stroked={stroked}
          data-id="empty-circle"
          style={{width: size, height: size, backgroundColor: randomColor}}>
          {createElement(randomIcon, {
            size: typeof size === 'number' ? size * 0.65 : '65%',
            weight: 'bold'
          })}
        </Empty>
      </StyledAvatar>
    );
  }

  return (
    <StyledAvatar ref={ref}>
      <Image $stroked={stroked} style={{width: size, height: size}} src={url} alt="" />
    </StyledAvatar>
  );
});
