import React, {useCallback} from 'react';
import styled from 'styled-components';
import {throttle} from 'throttle-debounce';
import {SendReactionEvent} from '@opeq-dev/openquiz-machine';
import {Menu} from '@openquiz/quiz-ui';
import {useMachineEvent} from '@/features/machine';
import {Reaction, reactionList} from '@/features/reactions';

const StyledReactionMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 2px 0;
  margin: 0 -6px;
  user-select: none;
`;

const ReactionButton = styled.button`
  background-color: transparent;
  color: ${p => p.theme.colors.text.muted};
  display: flex;
  align-items: center;
  justify-content: center;
  text-anchor: middle;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  width: 36px;
  height: 36px;
  font-size: 24px;
  line-height: 36px;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out;

  &:hover {
    background-color: ${p => p.theme.colors.neutral.subtle};
    color: ${p => p.theme.colors.accent.text};
  }

  &:active {
    background-color: ${p => p.theme.colors.accent.emphasis};
    color: ${p => p.theme.colors.text.onEmphasis};
  }

  &[disabled] {
    background-color: ${p => p.theme.colors.accent.subtle};
    border-color: transparent !important;
    color: ${p => p.theme.colors.text.subtle};
    pointer-events: none;
  }
`;

export const ReactionMenu = () => {
  const {event} = useMachineEvent();

  const onDebouncedClick = useCallback(
    throttle(
      500,
      (reaction: Reaction['name']) => {
        event<SendReactionEvent>('sendReaction', {reaction});
      },
      {noLeading: false, noTrailing: false}
    ),
    []
  );

  return (
    <Menu>
      <StyledReactionMenu>
        {reactionList.map(r => (
          <ReactionButton
            key={`reaction-${r.name}`}
            onClick={() => onDebouncedClick(r.name)}>
            {r.icon}
          </ReactionButton>
        ))}
      </StyledReactionMenu>
    </Menu>
  );
};
