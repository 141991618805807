import {PackageRound} from '@opeq-dev/openquiz-schema';
import {getPrivateAxios} from '@/libs';

export type RoundCreateData = Pick<PackageRound, 'name' | 'type'>;

export interface FetchRoundCreateParams {
  data: RoundCreateData;
  packageUuid: string;
  versionUuid: string;
}

/**
 *
 * @param params
 */
export const fetchRoundCreate = async (params: FetchRoundCreateParams) => {
  const {data} = await getPrivateAxios().post<PackageRound>(
    `/workshop/packages/${params.packageUuid}/rounds`,
    {...params.data, versionUuid: params.versionUuid}
  );
  return data;
};
