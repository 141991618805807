import {useQuery} from '@tanstack/react-query';
import {fetchPackageValidate} from './fetchPackageValidate';

/**
 * Query for validating a package.
 * @param uuid
 */
export const usePackageValidateQuery = (uuid: string) =>
  useQuery({
    queryKey: ['editor', 'package', uuid],
    queryFn: () => fetchPackageValidate(uuid)
  });
