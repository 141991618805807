import React, {useEffect, useState} from 'react';
import {useAsync} from 'react-use';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';
import {VideoCameraSlash} from '@phosphor-icons/react';
import {createMediaStream, stopMediaStream} from '@openquiz/peer-webcam';
import {DotsLoader, VideoStream, getAnimationProps} from '@openquiz/quiz-ui';

interface CameraPreviewProps {
  device?: {id: string; label: string};
}

const StyledCameraPreview = styled.div`
  background-color: ${p => p.theme.colors.neutral.subtle};
  position: relative;
  border-radius: 16px;
  width: 100%;
  height: 264px;
  overflow: hidden;

  video {
    background-color: transparent;
    display: block;
    width: 100%;
    height: 100%;
    transition: opacity 240ms ease-in-out;
  }
`;

const LoaderWrapper = styled(motion.div)`
  color: ${p => p.theme.colors.text.subtle};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  z-index: 1;
`;

const loaderMotionProps = getAnimationProps(
  {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0}
  },
  {duration: 0.2}
);

export const CameraPreview = ({device}: CameraPreviewProps) => {
  // const ref = useRef<HTMLVideoElement>(null);
  const [loading, setLoading] = useState(true);

  const [stream, setStream] = useState<MediaStream | null>(null);

  useAsync(async () => {
    if (!device?.id) return;
    setLoading(true);
    setStream(await createMediaStream(device.id, {shape: 'rectangle'}));
  }, [device?.id]);

  useEffect(() => {
    return () => {
      if (!stream) return;
      stopMediaStream(stream);
    };
  }, [stream]);

  const onLoadStart = () => {
    setLoading(true);
  };

  const onLoadedData = () => {
    setLoading(false);
  };

  return (
    <StyledCameraPreview>
      <AnimatePresence mode="popLayout">
        {!device && (
          <LoaderWrapper {...loaderMotionProps}>
            <VideoCameraSlash size={32} weight="bold" />
            <p>Предпросмотр веб-камеры</p>
          </LoaderWrapper>
        )}
        {device && loading && (
          <LoaderWrapper {...loaderMotionProps}>
            <DotsLoader />
          </LoaderWrapper>
        )}
      </AnimatePresence>

      {stream && (
        <VideoStream
          stream={stream}
          autoPlay={true}
          playsInline={true}
          muted={true}
          onLoadStart={onLoadStart}
          onLoadedData={onLoadedData}
        />
      )}
    </StyledCameraPreview>
  );
};
