import React from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {Surface} from '@openquiz/quiz-ui';
import {Page, PageTitle} from '@/components';
import {
  RoundCreateData,
  RoundForm,
  editorInfoPath,
  editorRoundPath,
  useEditorContext,
  useRoundCreateMutation
} from '@/features/wshop-editor';

export const RoundCreatePage = () => {
  const navigate = useNavigate();
  const {packageUuid, versionUuid} = useEditorContext();
  const {mutateAsync} = useRoundCreateMutation();
  const backHref = generatePath(editorInfoPath, {packageUuid});

  const onSubmitForm = async (data: RoundCreateData) => {
    const round = await mutateAsync({data: {...data}, packageUuid, versionUuid});
    navigate(
      generatePath(editorRoundPath, {packageUuid, versionUuid, roundUuid: round.uuid}),
      {replace: true}
    );
  };

  return (
    <Page>
      <PageTitle to={{href: backHref}} title="Создание раунда" />
      <Surface>
        <RoundForm onSubmit={onSubmitForm} />
      </Surface>
    </Page>
  );
};
