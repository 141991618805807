import React from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {PackageRound} from '@opeq-dev/openquiz-schema';
import {
  Button,
  FieldGroup,
  FormSection,
  Input,
  Select,
  ErrorMessage as UiErrorMessage
} from '@openquiz/quiz-ui';

interface RoundFormProps {
  round?: PackageRound;
  onSubmit: (data: PackageRound) => Promise<void>;
}

export const RoundForm = ({round, onSubmit}: RoundFormProps) => {
  const {
    formState: {isSubmitting, errors},
    register,
    handleSubmit
  } = useForm<PackageRound>({
    defaultValues: {
      name: '',
      type: 'BASIC',
      position: -1,
      ...round
    }
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSection label="Информация">
        <FieldGroup label="Название">
          <Input
            {...register('name', {
              required: 'Обязательное поле',
              minLength: {value: 3, message: 'Минимальная длинна 3 символа.'},
              maxLength: {value: 32, message: 'Максимальная длинна 32 символа.'}
            })}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({message}) => <UiErrorMessage>{message}</UiErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup label="Тип">
          <Select {...register('type', {required: true})}>
            <option value="BASIC">Обычный</option>
            <option value="FINAL">Финал</option>
          </Select>
        </FieldGroup>
      </FormSection>

      <FormSection>
        <Button type="submit" fetching={isSubmitting}>
          Создать
        </Button>
      </FormSection>
    </form>
  );
};
