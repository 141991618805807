import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface SidebarProps {
  children: ReactNode;
}

const StyledSidebar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Sidebar = ({children}: SidebarProps) => (
  <StyledSidebar>{children}</StyledSidebar>
);
