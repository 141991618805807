import {MediaConnection} from 'peerjs';
import {usePeerWebcam} from '@openquiz/peer-webcam';

/**
 * The hook returns functions for calling peers.
 */
export const usePeerEmitter = () => {
  const {peer, gameUuid, userUuid, addStream} = usePeerWebcam();

  /**
   * The function will answer the call with a new media stream.
   * @param connection
   * @param stream
   */
  const emitAnswer = async (connection: MediaConnection, stream: MediaStream) => {
    addStream(`${gameUuid}-${userUuid}`, stream);
    connection.answer(stream);
    connection.on('stream', s => addStream(connection.peer, s));
  };

  /**
   * The function will call the peer with a new media stream.
   * @param peerId
   * @param stream
   */
  const emitCall = async (peerId: string, stream: MediaStream) => {
    addStream(`${gameUuid}-${userUuid}`, stream);
    const connection = peer?.call(peerId, stream);
    connection?.on('stream', s => addStream(peerId, s));
  };

  /**
   * The function stops all calls with the specified peer.
   * @param peerId
   */
  const emitDrop = async (peerId: string) => {
    if (!peer?.connections) return;

    Object.keys(peer.connections).forEach(connectionId => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      peer.connections[connectionId].forEach(connection => {
        if (connection.peer === peerId) {
          connection.close();
        }
      });
    });
  };

  return {emitAnswer, emitCall, emitDrop};
};
