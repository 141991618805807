import React from 'react';
import {Link, generatePath, useNavigate} from 'react-router-dom';
import toast from 'react-hot-toast';
import styled, {useTheme} from 'styled-components';
import {PencilSimple, Plus, Trash} from '@phosphor-icons/react';
import {ModalConfirm, useModalConfirm} from '@openquiz/quiz-modal';
import {Button, FieldGroup, FieldRow} from '@openquiz/quiz-ui';
import {EditableTitle, Page, PageFallback, PageTitle} from '@/components';
import {
  ThemesGrid,
  editorInfoPath,
  editorRoundEditPath,
  editorThemeCreatePath,
  useEditorContext,
  useRoundDeleteMutation,
  useRoundQuery,
  useRoundUpdateMutation
} from '@/features/wshop-editor';

const Extra = styled(FieldRow)`
  display: flex;
  height: 32px;
`;

export const RoundPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {packageUuid, roundUuid, versionUuid} = useEditorContext();
  const {mutateAsync: updateMutateAsync} = useRoundUpdateMutation();
  const {mutateAsync: deleteMutateAsync} = useRoundDeleteMutation();
  const {data: round, isLoading} = useRoundQuery({
    uuid: roundUuid,
    packageUuid,
    versionUuid
  });

  const confirmDeleteRound = useModalConfirm(props => (
    <ModalConfirm
      {...props}
      content={<>При удалении раунда, будут удалены все темы и вопросы в этих темах.</>}
      resolveLabel="Удалить"
    />
  ));

  const backHref = generatePath(editorInfoPath, {packageUuid});

  const onChangeName = async (name: string) => {
    if (!round) return;
    await updateMutateAsync({
      data: {...round, name},
      versionUuid: round.versionUuid,
      packageUuid
    });
    toast.success('Имя раунда изменено');
  };

  const onClickDeleteRound = async () => {
    try {
      await confirmDeleteRound();
      await deleteMutateAsync({packageUuid, versionUuid, roundUuid});
      navigate(backHref, {replace: true});
    } catch (e) {
      /* empty */
    }
  };

  if (!round || isLoading) {
    return <PageFallback />;
  }

  return (
    <Page key={roundUuid}>
      <PageTitle
        to={{href: backHref}}
        title={<EditableTitle value={round.name} onChange={onChangeName} />}
        extra={
          <Extra>
            <Link
              to={generatePath(editorRoundEditPath, {
                packageUuid,
                roundUuid,
                versionUuid
              })}>
              <Button variant="link" size="sm">
                <PencilSimple size={20} weight="bold" />
                <span>Редактировать</span>
              </Button>
            </Link>
            <Button
              variant="link"
              color={theme.colors.danger.text}
              size="sm"
              onClick={onClickDeleteRound}>
              <Trash size={20} weight="bold" />
              <span>Удалить</span>
            </Button>
          </Extra>
        }
      />

      <FieldGroup>
        <Link
          to={generatePath(editorThemeCreatePath, {
            packageUuid,
            roundUuid,
            versionUuid
          })}>
          <Button variant="link" size="sm">
            <Plus size={20} weight="bold" />
            <span>Создать тему</span>
          </Button>
        </Link>
      </FieldGroup>
      <ThemesGrid />
    </Page>
  );
};
