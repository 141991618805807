import React, {ReactNode} from 'react';
import {useParams} from 'react-router-dom';
import {EditorContext} from './EditorContext';

export interface EditorProviderProps {
  /**
   * Subtree that will receive modal context.
   */
  children: ReactNode;
}

export const EditorProvider = ({children}: EditorProviderProps) => {
  const {packageUuid, roundUuid, versionUuid} = useParams();

  return (
    <EditorContext.Provider
      value={{
        packageUuid: packageUuid as string,
        roundUuid: roundUuid as string,
        versionUuid: versionUuid as string
      }}>
      {children}
    </EditorContext.Provider>
  );
};
