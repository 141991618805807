import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PackageVersion} from '@opeq-dev/openquiz-schema';
import {fetchVersionCreate} from './fetchVersionCreate';
import {versionKeys} from './keys';

/**
 *
 */
export const useVersionCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchVersionCreate,
    onSuccess: (data, variables) => {
      queryClient.setQueryData<PackageVersion[]>(
        versionKeys.all(variables),
        (versions = []) => [...versions, data]
      );
    }
  });
};
