import React, {InputHTMLAttributes, ReactNode, forwardRef, useMemo} from 'react';
import {Check as CheckIcon} from '@phosphor-icons/react';
import styled from 'styled-components';
import {nanoid} from 'nanoid';

export interface CheckProps extends InputHTMLAttributes<HTMLInputElement> {
  children: ReactNode;
  type?: 'checkbox' | 'radio';
  caption?: ReactNode;
}

const StyledCheck = styled.div`
  ${p => p.theme.typography.caption.md};
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;

  :last-of-type {
    margin-bottom: 0;
  }

  input[type='radio'] + div {
    border-radius: 50%;
  }

  input:focus-visible + div {
  }

  input:checked + div {
    background-color: ${p => p.theme.colors.accent.emphasis};
    border-color: ${p => p.theme.colors.accent.emphasis};
    color: ${p => p.theme.colors.text.onEmphasis};
  }

  input:checked + div div {
    background-color: ${p => p.theme.colors.text.onEmphasis};
  }

  input:checked[disabled] + div {
    color: ${p => p.theme.colors.text.muted};
  }

  input:checked[disabled] + div div {
    background-color: ${p => p.theme.colors.text.muted};
    color: ${p => p.theme.colors.text.muted};
  }

  input[disabled] + div {
    background-color: ${p => p.theme.colors.neutral.muted};
    border-color: ${p => p.theme.colors.neutral.muted};
  }
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
`;

const Input = styled.input`
  position: relative;
  margin-right: calc(10px - 1px);
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
`;

const Box = styled.div`
  background-color: ${p => p.theme.colors.canvas.overlay};
  border: 1px solid ${p => p.theme.colors.border.default};
  border-radius: 6px;
  color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  pointer-events: none;
  transition:
    background-color ease-in-out 80ms,
    border-color ease-in-out 80ms,
    color ease-in-out 80ms;
`;

const DotIcon = styled.div`
  background-color: transparent;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const Entry = styled.div``;

const Label = styled.label`
  ${p => p.theme.typography.caption.md};
  display: inline-flex;
  user-select: none;
  cursor: pointer;
  gap: 4px;
`;

const Caption = styled.div`
  padding-right: 40px;
  margin-top: 2px;
  font-size: 14px;
  line-height: 16px;
  color: ${p => p.theme.colors.text.subtle};
`;

export const Check = forwardRef<HTMLInputElement, CheckProps>(function Check(
  {children, type = 'checkbox', caption, ...inputProps},
  ref
) {
  const id = useMemo(
    () => `${inputProps.name ?? type}-${nanoid()}`,
    [type, inputProps.name]
  );

  return (
    <>
      <StyledCheck>
        <InputWrapper>
          <Input {...inputProps} ref={ref} id={id} type={type} />
          <Box>
            {type === 'checkbox' ? <CheckIcon size={16} weight="bold" /> : <DotIcon />}
          </Box>
        </InputWrapper>
        <Entry>
          <Label htmlFor={id}>{children}</Label>
          {caption && <Caption>{caption}</Caption>}
        </Entry>
      </StyledCheck>
    </>
  );
});
