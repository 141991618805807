import React from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import {Surface} from '@openquiz/quiz-ui';
import {Page, PageTitle} from '@/components';
import {
  TemplateData,
  TemplateFrom,
  editorInfoPath,
  fetchFillVersion,
  useEditorContext
} from '@/features/wshop-editor';

export const TemplatePage = () => {
  const navigate = useNavigate();
  const {packageUuid, versionUuid} = useEditorContext();
  const queryClient = useQueryClient();
  const backHref = generatePath(editorInfoPath, {packageUuid});

  const onSubmitForm = async (data: TemplateData) => {
    await fetchFillVersion({data, packageUuid, versionUuid});
    await queryClient.refetchQueries({
      queryKey: ['editor', 'rounds', {packageUuid, versionUuid}]
    });
    navigate(backHref);
  };

  return (
    <Page>
      <PageTitle to={{href: backHref}} title="Создание раунда" />
      <Surface>
        <TemplateFrom onSubmit={onSubmitForm} />
      </Surface>
    </Page>
  );
};
