import React, {FunctionComponent, useMemo} from 'react';
import styled, {useTheme} from 'styled-components';
import {Action} from '@opeq-dev/openquiz-machine';
import {
  BetSnippet,
  DecisionSnippet,
  FormattedAction,
  QuestionSnippet,
  RoleSnippet,
  RoundSnippet,
  Stack,
  UsernameSnippet,
  getFormattedAction,
  getStackedActions,
  stackConfigs
} from '@/features/machine-history';
import {PackageFull} from '@/features/wshop-packages';

interface HistoryTimelineProps {
  pkg: PackageFull;
  history: Action[];
  onOverlay?: boolean;
}

const StyledHistoryTimeline = styled.div`
  position: relative;
  padding: 0 12px;

  &::after {
    background-color: ${p => p.theme.colors.border.subtle};
    position: absolute;
    left: 27px;
    top: 4px;
    bottom: 4px;
    width: 1px;
    content: '';
    z-index: -1;
  }
`;

/* eslint-disable  @typescript-eslint/no-explicit-any */
const snippetsMap: Map<string, FunctionComponent<any>> = new Map([
  ['role', RoleSnippet],
  ['userUuid', UsernameSnippet],
  ['targetUserUuid', UsernameSnippet],
  ['questionUuid', QuestionSnippet],
  ['roundUuid', RoundSnippet],
  ['decision', DecisionSnippet],
  ['bet', BetSnippet]
]);

export const HistoryTimeline = ({
  pkg,
  history,
  onOverlay = false
}: HistoryTimelineProps) => {
  const {colors} = useTheme();

  const stacks = useMemo(
    () =>
      getStackedActions(history, stackConfigs)
        .map(stack => (Array.isArray(stack) ? stack : [stack]))
        .map(stack => {
          return stack
            .map(action => getFormattedAction(action, {pkg, colors, snippetsMap}))
            .filter(Boolean) as FormattedAction[];
        }),
    [pkg, colors, history]
  );

  return (
    <StyledHistoryTimeline>
      {stacks.map((actions, index) => (
        <Stack key={`stack-${index}`} onOverlay={onOverlay} actions={actions} />
      ))}
    </StyledHistoryTimeline>
  );
};
