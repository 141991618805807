import React, {useState} from 'react';
import {VideoStream} from '@openquiz/quiz-ui';
import {StyledWebCamera} from './styled';

interface WebCameraProps {
  stream: MediaStream;
}

export const WebCamera = ({stream}: WebCameraProps) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <StyledWebCamera style={{opacity: loaded ? 1 : 0}}>
      <VideoStream
        stream={stream}
        autoPlay={true}
        playsInline={true}
        muted={true}
        disablePictureInPicture={true}
        onLoadedData={() => {
          setLoaded(true);
        }}
      />
    </StyledWebCamera>
  );
};
