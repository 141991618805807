import {Ctx} from '@opeq-dev/openquiz-machine';
import {State} from 'xstate';

/**
 * Select the score of a team.
 * @param teamUuid
 * @returns
 */
export const selectTeamScore = (teamUuid: string | null) => (state: State<Ctx>) =>
  state.context.teams.find(team => team.uuid === teamUuid)?.score || 0;
