import {useCallback, useEffect} from 'react';
import {EventObject} from 'xstate';
import {sfxSprite, useGameService, useSfxPlayer} from '@/features/machine';

export const useSfxListener = () => {
  const {service} = useGameService();
  const sfxPlayer = useSfxPlayer();

  const listen = useCallback(
    (e: EventObject) => {
      if (sfxSprite[e.type]) {
        sfxPlayer.play(e.type);
      }
    },
    [sfxPlayer.play]
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    service.eventListeners.clear();
    service.onEvent(listen);
  }, [service, listen]);
};
