import React from 'react';
import {generatePath} from 'react-router-dom';
import {Plus} from '@phosphor-icons/react';
import {PackageRound} from '@opeq-dev/openquiz-schema';
import {SidebarLink, Spinner} from '@openquiz/quiz-ui';
import {SortableList} from '@/features/workshop';
import {
  RoundSidebarRow,
  editorRoundCreatePath,
  editorTempaltePath,
  useEditorContext,
  useRoundUpdateMutation,
  useRoundsQuery
} from '@/features/wshop-editor';

interface RoundsListProps {
  versionUuid: string;
}

export const RoundsList = ({versionUuid}: RoundsListProps) => {
  const {packageUuid} = useEditorContext();
  const {mutateAsync} = useRoundUpdateMutation();
  const {data: rounds = [], isLoading} = useRoundsQuery({packageUuid, versionUuid});

  const sortedRounds = rounds.sort((a, b) => a.position - b.position);

  const onDragEnd = async (items: string[]) => {
    items.forEach((uuid, position) => {
      const round = rounds.find(t => t.uuid === uuid);
      if (!round) return;
      mutateAsync({
        data: {...round, position},
        versionUuid: round.versionUuid,
        packageUuid
      });
    });
  };

  const onChange = async (round: PackageRound) => {
    await mutateAsync({data: round, versionUuid: round.versionUuid, packageUuid});
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {!sortedRounds.length && (
        <SidebarLink
          icon={Plus}
          variant="secondary"
          to={generatePath(editorTempaltePath, {
            packageUuid,
            versionUuid: versionUuid
          })}>
          Создать из шаблона
        </SidebarLink>
      )}
      <SortableList
        items={sortedRounds.map(r => r.uuid)}
        strategy="vertical"
        onDragEnd={onDragEnd}>
        {rounds.map(round => (
          <RoundSidebarRow
            key={`round-sidebar-row-${round.uuid}`}
            round={round}
            packageUuid={packageUuid}
            onChange={onChange}
          />
        ))}
      </SortableList>

      <SidebarLink
        icon={Plus}
        variant="secondary"
        to={generatePath(editorRoundCreatePath, {
          packageUuid,
          versionUuid: versionUuid
        })}>
        Создать раунд
      </SidebarLink>
    </>
  );
};
