import React from 'react';
import {
  HintRow,
  TextChip,
  UserChip,
  selectHost,
  selectTurnTeam,
  useMachineSelector,
  useMachineSelf
} from '@/features/machine';

export const ChooseQuestionHint = () => {
  const self = useMachineSelf();
  const turnTeam = useMachineSelector(selectTurnTeam);
  const leader = useMachineSelector(selectHost);

  if (!self) {
    return null;
  }

  if (!turnTeam) {
    if (leader) {
      return (
        <UserChip
          userUuid={leader.uuid}
          message=", думает, кто будет выбирать вопрос"
          isSelf={leader.uuid === self?.userUuid}
        />
      );
    }

    return <TextChip>Чтобы начать игру, нужно выбрать лидера</TextChip>;
  }

  return (
    <HintRow
      type="chooseQuestion"
      team={turnTeam}
      userUuid={turnTeam.leaderUuid}
      isSelf={turnTeam.leaderUuid === self.userUuid}
    />
  );
};
