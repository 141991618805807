import React, {useEffect} from 'react';
import {useSelector} from '@xstate/react';
import {
  SplashScreen,
  selectCtxCurrentQuestion,
  useGameService,
  useSfxPlayer
} from '@/features/machine';

export const VariantsQuestionSplash = () => {
  const {service} = useGameService();
  const sfxPlayer = useSfxPlayer();
  const question = useSelector(service, selectCtxCurrentQuestion);

  useEffect(() => {
    sfxPlayer.play('secret');
  }, [sfxPlayer.play]);

  return (
    <SplashScreen
      icon={
        <picture>
          <source
            srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/270c_fe0f/512.webp"
            type="image/webp"
          />
          <img
            src="https://fonts.gstatic.com/s/e/notoemoji/latest/270c_fe0f/512.gif"
            alt="✌"
            width={96}
            height={96}
          />
        </picture>
      }>
      <h2>Вопрос с вариантами ответа</h2>
      <p>На этот вопрос вы можете ответить, выбрав один из предложенных вариантов.</p>
      {(question?.type === 'VARIANTS_EVERYONE' ||
        question?.type === 'VARIANTS_EVERYONE_NORISK') && (
        <p>Могут ответить все игроки.</p>
      )}
      {(question?.type === 'VARIANTS_NORISK' ||
        question?.type === 'VARIANTS_EVERYONE_NORISK') && <p>Вопрос без риска.</p>}
    </SplashScreen>
  );
};
