import React, {ReactNode, useEffect} from 'react';
import {MediaConnection} from 'peerjs';
import {Socket} from 'socket.io-client';
import {usePeerEmitter, usePeerMediaStream} from './hooks';
import {usePeerWebcam} from './store';
import {stopMediaStream} from './libs';

interface PeerProviderProps {
  children: ReactNode;
  socket: Socket;
  gameUuid: string;
  userUuid: string;
}

export const PeerProvider = ({
  children,
  socket,
  gameUuid,
  userUuid
}: PeerProviderProps) => {
  const {peer, initPeer, removeStream} = usePeerWebcam();
  const {emitAnswer, emitCall, emitDrop} = usePeerEmitter();
  const {getLocalStream} = usePeerMediaStream();

  /**
   * Listen when a peer call.
   * @param connection
   */
  const listenPeerCall = async (connection: MediaConnection) => {
    await emitAnswer(connection, getLocalStream);
  };

  /**
   * Listen when a peer is connected.
   * @param peerId
   */
  const listenPeerConnected = async (peerId: string) => {
    await emitCall(peerId, getLocalStream);
  };

  /**
   * Listen when a peer is disconnected.
   * @param peerId
   */
  const listenPeerDisconnected = async (peerId: string) => {
    await emitDrop(peerId);
    removeStream(peerId);
  };

  useEffect(() => {
    initPeer(gameUuid, userUuid);
  }, [initPeer]);

  useEffect(() => {
    if (peer) {
      peer.on('open', peerId => {
        socket.emit('p2p', {type: 'connected', payload: {id: peerId}});
      });
      return () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.currentMediaStream) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          stopMediaStream(window.currentMediaStream);
        }
        peer.destroy();
        peer.disconnect();
      };
    }
  }, [peer, socket]);

  useEffect(() => {
    if (!peer) return;

    peer.on('call', listenPeerCall);
    socket.on('p2p/connected', listenPeerConnected);
    socket.on('p2p/disconnected', listenPeerDisconnected);

    return () => {
      peer.removeListener('call', listenPeerCall);
      socket?.removeListener('p2p/connected', listenPeerConnected);
      socket?.removeListener('p2p/disconnected', listenPeerDisconnected);
    };
  }, [peer, socket, listenPeerCall, listenPeerConnected, listenPeerDisconnected]);

  return <>{children}</>;
};
