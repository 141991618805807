import {State} from 'xstate';
import {Ctx} from '@opeq-dev/openquiz-machine';

/**
 * Select the turn team uuid.
 * @param state
 * @returns
 */
export const selectTurnTeam = (state: State<Ctx>) =>
  state.context.teams.find(team => team.uuid === state.context.current.turnTeamUuid);
