import {useEffect, useRef} from 'react';
import {selectPause, useMachineSelector, useMediaProgress} from '@/features/machine';
import {useUiSettingsStore} from '@/stores';

export const useMediaPlay = () => {
  const mediaRef = useRef<HTMLVideoElement>(null);
  const pause = useMachineSelector(selectPause);
  const {isPlay, isAnswer} = useMediaProgress();
  const {volume} = useUiSettingsStore();

  useEffect(() => {
    if (!mediaRef?.current) return;
    if (pause || (!isPlay && !isAnswer)) {
      mediaRef?.current.pause();
    } else {
      mediaRef?.current.play();
    }
  }, [mediaRef, isPlay, isAnswer, pause]);

  useEffect(() => {
    if (!mediaRef?.current) return;
    mediaRef.current.volume = volume;
  }, [mediaRef, volume]);

  return {mediaRef};
};
