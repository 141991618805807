import React, {ReactNode, createElement, useEffect, useLayoutEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {useModal} from '@openquiz/quiz-modal';
import {SignModal, useAuthRefererStore, useAuthStore} from '@/features/auth';

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute = ({children}: ProtectedRouteProps) => {
  const {referer, update} = useAuthRefererStore();
  const {token} = useAuthStore();

  const openSignModal = useModal(props => createElement(SignModal, props), {
    persistent: true
  });

  useLayoutEffect(() => {
    if (!token && !referer) {
      update(window.location.pathname);
    }
  }, [referer, token]);

  useEffect(() => {
    if (!token) {
      openSignModal();
    }
  }, [token]);

  if (!token) {
    return <Navigate to="/" />;
  }

  return children;
};
