import React, {forwardRef} from 'react';
import {Avatar} from '@openquiz/quiz-ui';
import {Caption, Entry, StyledUserLabel, Username} from './styled';
import {UserLabelProps} from './types';

export const UserLabel = forwardRef<HTMLDivElement, UserLabelProps>(function UserLabel(
  {user, caption, layoutId, ...rest},
  ref
) {
  return (
    <StyledUserLabel {...rest} ref={ref} layoutId={layoutId} $hasCaption={!!caption}>
      <Avatar user={user} size={caption ? 32 : 20} />
      <Entry>
        <Username>{user.username}</Username>
        {caption && <Caption>{caption}</Caption>}
      </Entry>
    </StyledUserLabel>
  );
});
