import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface TextChipProps {
  children: ReactNode;
}

const StyledTextChip = styled.div`
  ${p => p.theme.typography.caption.md};
  color: ${p => p.theme.colors.text.muted};
  text-align: center;
`;

export const TextChip = ({children}: TextChipProps) => {
  return (
    <StyledTextChip>
      <div>{children}</div>
    </StyledTextChip>
  );
};
