import React from 'react';
import {ThumbsUp} from '@phosphor-icons/react';
import {AcceptAnswerEvent} from '@opeq-dev/openquiz-machine';
import {Menu, MenuGroup, MenuItem} from '@openquiz/quiz-ui';
import {
  selectCtxCurrentQuestion,
  selectTurnTeam,
  useMachineEvent,
  useMachineSelector
} from '@/features/machine';

export const LeaderAcceptMenu = ({onClose = () => void 0}) => {
  const {event} = useMachineEvent();

  const turnTeam = useMachineSelector(selectTurnTeam);
  const question = useMachineSelector(selectCtxCurrentQuestion);

  const onClickAccept = (multiplier: number) => {
    if (!turnTeam || !question) return;
    event<AcceptAnswerEvent>('acceptAnswer', {
      teamUuid: turnTeam.uuid,
      questionUuid: question.uuid,
      multiplier
    });
    onClose();
  };

  return (
    <Menu>
      <MenuGroup>
        <MenuItem color="default" icon={ThumbsUp} onClick={() => onClickAccept(2)}>
          Принять (x2)
        </MenuItem>
        <MenuItem color="default" icon={ThumbsUp} onClick={() => onClickAccept(0.5)}>
          Принять (x0.5)
        </MenuItem>
      </MenuGroup>
    </Menu>
  );
};
